import React, { useState } from "react";
import MainChart1 from "./chart1/MainChart1";
import MainChart2 from "./chart2/MainChart2";
import MainChart3 from "./chart3/MainChart3";
import { useSearchParams } from "react-router-dom";
import TabChartStatic from "./TabChartStatic";
import MainChart4 from "./chart4/MainChart4";
import MainChart5 from "./chart5/MainChart5";
import MainChart6 from "./chart6/MainChart6";
import MainChart7 from "./chart7/MainChart7";
import MainChart8 from "./chart8/MainChart8";
import MainChart9 from "./chart9/MainChart9";
import MainChart10 from "./chart10/MainChart10";
import MainTreeChartShm from "../tree-chart-shm/MainTreeChartShm";

const MainTreeChartStatic = () => {
  const [serachParams, setSearchParams] = useSearchParams();
  const [showChartSHM, setShowChartSHM] = useState(false)

  const c = serachParams.get("c");
  const [activeChart, setActiveChart] = useState(c || "chart1");
  let sidebar = localStorage.getItem('hide_sidebar')
  return (
    <div className="flex flex-col gap-2 p-4">

      {showChartSHM
        ?
        <MainTreeChartShm />
        : !c ? (
          <TabChartStatic showChartSHM={showChartSHM} setActiveChart={setActiveChart} />
        ) : (
          <div>
            {activeChart === "chart1" ? (
              <MainChart1 setActiveChart={setActiveChart} />
            ) : activeChart === "chart2" ? (
              <MainChart2 setActiveChart={setActiveChart} />
            ) : activeChart === "chart3" ? (
              <MainChart3 setActiveChart={setActiveChart} sidebar={sidebar} />
            ) : activeChart === "chart4" ? (
              <MainChart4 setActiveChart={setActiveChart} sidebar={sidebar} />
            ) : activeChart === "chart5" ? (
              <MainChart5 setActiveChart={setActiveChart} sidebar={sidebar} />
            ) : activeChart === "chart6" ? (
              <MainChart6 setActiveChart={setActiveChart} sidebar={sidebar} />
            ) : activeChart === "chart7" ? (
              <MainChart7 setActiveChart={setActiveChart} sidebar={sidebar} />
            ) : activeChart === "chart8" ? (
              <MainChart8 setActiveChart={setActiveChart} sidebar={sidebar} />
            ) : activeChart === "chart9" ? (
              <MainChart9 setActiveChart={setActiveChart} sidebar={sidebar} />
            ) : activeChart === "chart10" ? (
              <MainChart10 setActiveChart={setActiveChart} sidebar={sidebar} />
            ) : (
              <MainChart1 setActiveChart={setActiveChart} />
            )}
          </div>
        )}

    </div>
  );
};

export default MainTreeChartStatic;
