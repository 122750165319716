import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../context/auth/AuthContext";
import API from "../../../../api/API";
import TableListActivities from "./TableListActivities";
import LoadingData from "../../LoadingData";
import NotfoundData from "../../NotfoundData";

const MainListActivities = ({
  refreshActivitiesList,
  setRefreshActivitiesList,
}) => {
  const { token } = useContext(AuthContext);
  const [fullData, setfullData] = useState([-1]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setRefreshActivitiesList(1);
  }, [setRefreshActivitiesList]);
  useEffect(() => {
    if (!token || refreshActivitiesList === 0) return;
    setLoading(true);
    const getIdentification = async () => {
      try {
        const res = await API.get("/api/v1/risk/identification/me", {
          headers: { Authorization: token },
        });
        setRefreshActivitiesList(0);
        console.log(res.data.sort((a) => (a.statusType === "done" ? -1 : 1)));
        setfullData(res.data);
      } catch (error) {
        setError(true);
        console.log(error);
      } finally {
        setLoading(false);
        setError(false);
      }
    };
    getIdentification();
  }, [token, refreshActivitiesList, setRefreshActivitiesList]);

  return loading ? (
    <LoadingData />
  ) : error ? (
    <NotfoundData />
  ) : (
    <TableListActivities
      listActivities={fullData}
      setRefreshActivitiesList={setRefreshActivitiesList}
    />
  );
};

export default MainListActivities;
