import React from "react";
import { PiMapPin } from "react-icons/pi";

const InfoBox = ({ fullData, heightHandler }) => {
  const filterNumberDirect = fullData?.telephoneRelated?.filter((item) => item?.TelePhoneType === 'direct')
  const filterNumberInDirect = fullData?.telephoneRelated?.filter((item) => item?.TelePhoneType === 'inDirect')
  const filterNumberMobile = fullData?.telephoneRelated?.filter((item) => item?.TelePhoneType === 'Mobile')
  // const filterNumberFax = fullData?.telephoneRelated?.filter((item) => item?.TelePhoneType === 'fax')

  const filterEmailPersonal = fullData?.emailRelated?.filter((item) => item?.emailType === 'personal')
  const filterEmailCompany = fullData?.emailRelated?.filter((item) => item?.emailType === 'company')

  return (
    <div className="flex items-start flex-col gap-0">

      <div className="flex flex-col items-center w-full border-b border-caspian-gray2/30 px-1">
        <div className="flex items-start w-full px-3 py-1">
          <div className="w-full flex flex-col  justify-center gap-1">
            <span className="text-xs text-caspian-gray4">توضیحات </span>
            <span className="text-xs">{fullData?.description ? fullData?.description : "-"}</span>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center w-full border-b border-caspian-gray2/30 px-1">
        {filterNumberDirect?.length > 0 ? (
          filterNumberDirect?.map((item, index) => (
            <div key={index} className={`flex items-center w-full px-3 py-1 `}>
              <div className="basis-1/2 flex flex-col justify-center gap-2">
                <span className="text-xs text-caspian-gray4">شماره تماس مستقیم</span>
              </div>
              <div className={`basis-1/2 flex flex-col justify-center `}>
                <span className="text-xs">{item?.preCode ? item?.preCode + "-" : ""}{item?.number}</span>
                <span className="text-xs">{item?.secondNumber ? (" داخلی " + item?.secondNumber) : (" ")}</span>

              </div>
            </div>
          ))
        ) : (<div className="flex items-center pt-1 w-full px-3 py-1 ">
          <div className="basis-1/2 flex flex-col justify-center">
            <span className="text-xs text-caspian-gray4">شماره تماس مستقیم</span>
          </div>
          <div className="basis-1/2">
            <span className="text-xs">-</span>
          </div>
        </div>
        )}
      </div>

      <div className="flex flex-col items-center w-full border-b border-caspian-gray2/30 px-1 ">
        <div className="flex items-center w-full px-3 py-1  ">
          <div className="basis-1/2 flex flex-col justify-center gap-2">
            <span className="text-xs text-caspian-gray4">رابط </span>
          </div>
          <div className="basis-1/2">
            <span className="text-xs">-</span>
          </div>
        </div>

      </div>

      <div className="flex flex-col items-center w-full border-b border-caspian-gray2/30 px-1">
        {filterNumberMobile?.length > 0 ?
          (filterNumberMobile?.map((item, index) => (
            <div key={index} className="flex items-center w-full px-3 py-1">
              <div className="basis-1/2 flex flex-col  justify-center gap-2">
                <span className="text-xs text-caspian-gray4">شماره تماس رابط</span>
              </div>
              <div className="basis-1/2">
                <span className="text-xs">-</span>
              </div>
            </div>
          ))
          ) : (
            <div className="flex items-center w-full px-3 py-1">
              <div className="basis-1/2 flex flex-col  justify-center gap-2">
                <span className="text-xs text-caspian-gray4">شماره تماس رابط</span>
              </div>
              <div className="basis-1/2">
                <span className="text-xs">-</span>
              </div>
            </div>
          )}
      </div>

      {heightHandler &&
        <div className="w-full flex flex-col items-center">

          <div className="flex flex-col items-center w-full border-b border-caspian-gray2/30 px-1">
            <div className="flex items-center w-full px-3 py-1">
              <div className="basis-1/2 flex flex-col  justify-center gap-2">
                <span className="text-xs text-caspian-gray4">کدپستی </span>
              </div>
              <div className="basis-1/2">
                <span className="text-xs">{fullData?.companyRelated?.postalCode ? fullData?.companyRelated?.postalCode : "-"}</span>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center w-full border-b border-caspian-gray2/30 px-1">
            <div className="flex items-center w-full px-3 py-1">
              <div className="basis-1/2 flex flex-col  justify-center gap-2">
                <span className="text-xs text-caspian-gray4">کشور</span>
              </div>
              <div className="basis-1/2">
                <span className="text-xs">{fullData?.companyRelated?.country ? fullData?.companyRelated?.country : "-"}</span>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center w-full border-b border-caspian-gray2/30 px-1">
            <div className="flex items-center w-full px-3 py-1">
              <div className="basis-1/2 flex flex-col  justify-center gap-2">
                <span className="text-xs text-caspian-gray4">استان</span>
              </div>
              <div className="basis-1/2">
                <span className="text-xs">{fullData?.companyRelated?.province ? fullData?.companyRelated?.province : "-"}</span>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center w-full border-b border-caspian-gray2/30 px-1">
            <div className="flex items-center w-full px-3 py-1">
              <div className="basis-1/2 flex flex-col  justify-center gap-2">
                <span className="text-xs text-caspian-gray4">شهر</span>
              </div>
              <div className="basis-1/2">
                <span className="text-xs">{fullData?.companyRelated?.city}</span>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center w-full border-b border-caspian-gray2/30 px-1">

            <div className="flex items-center w-full px-3 py-1">
              <div className="basis-1/2 flex flex-col  justify-center gap-2">
                <span className="text-xs text-caspian-gray4">منطقه</span>
              </div>
              <div className="basis-1/2">
                <span className="text-xs">{fullData?.companyRelated?.district}</span>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center w-full border-b border-caspian-gray2/30 px-1">
            <div className="flex items-center w-full px-3 py-1">
              <div className="basis-1/2 flex flex-col  justify-center gap-2">
                <span className="text-xs text-caspian-gray4">آدرس</span>
              </div>
              <div className="basis-1/2">
                <span className="text-xs">{fullData?.companyRelated?.address}</span>
              </div>
            </div>
          </div>


          <div className="flex flex-col items-center w-full border-b border-caspian-gray2/30 px-1">
            <div className="flex items-center w-full px-3 py-1">
              <div className="basis-1/2 flex flex-col justify-center gap-2">
                <span className="text-xs text-caspian-gray4">لوکیشن </span>
              </div>
              <div className="basis-1/2 w-full">
                <div className="flex items-center gap-2 text-caspian-secondary-500">
                  <PiMapPin />
                  <span className="text-xs">{fullData?.locationRelated?.geo}
                    {fullData?.locationRelated?.address ? fullData?.locationRelated?.address : "-"}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center w-full border-b border-caspian-gray2/30 px-1">s</div>

        </div >

      }
    </div >
  );
};

export default InfoBox;
