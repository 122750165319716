import React, { useContext, useState } from "react";
import {
  PiCaretLeftBold,
  PiCheckCircleFill,
  PiTrash,
  PiXCircleFill,
} from "react-icons/pi";
import { AuthContext } from "../../../../../context/auth/AuthContext";
import API from "../../../../../api/API";

const RisksRiskDetailsMain = ({ risk, setRefreshRiskRisks, isDoing }) => {
  const { token } = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const [showIsDelete, setShowIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const removeHandler = async () => {
    API.delete(`/api/v1/risk/identification/detail/${deleteId}/`, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        setRefreshRiskRisks(1);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="overflow-hidden rounded-t-md w-[90%] mx-auto border border-caspian-secondary-500">
      <div className="flex items-center justify-between w-full">
        <div
          onClick={() => {
            setShow(!show);
          }}
          className="w-[100%] p-2 flex flex-col gap-2 cursor-pointer bg-caspian-secondary-50/40"
        >
          <div className="w-full flex items-center justify-between">
            <span className="text-xs font-bold">{risk?.title}</span>

            <PiCaretLeftBold
              className={
                show
                  ? "-rotate-90 duration-500 text-caspian-on_primary/80"
                  : "rotate-0 duration-500 text-caspian-on_primary/80"
              }
            />
          </div>

          <div className="flex flex-col gap-2">
            {/* مشخصات شناسایی کننده */}
            <div className="flex flex-col gap-1 relative">
              {/* <span className="text-xs text-caspian-on_primary/80 font-bold">
                جزئیات شناسایی:
              </span> */}
              <div className="flex flex-col gap-1 border-r-caspian-gray2 border-r-2 pr-2">
                <div className="flex items-center gap-2">
                  <p className="text-xs text-caspian-on_primary">
                    شناسایی کننده:
                  </p>

                  <p className="text-xs text-caspian-on_primary">
                    {risk?.creatorDetail?.creator?.firstName +
                      " " +
                      risk?.creatorDetail?.creator?.lastName}
                  </p>
                </div>
                <div className="flex items-center gap-2">
                  <p className="text-xs text-caspian-on_primary">
                    تیم شناسایی کننده:
                  </p>

                  <p className="text-xs text-caspian-on_primary">
                    {risk?.creatorDetail?.team?.title}
                  </p>
                </div>
                <div className="flex items-center gap-2">
                  <p className="text-xs text-caspian-on_primary">
                    تاریخ شناسایی:
                  </p>

                  <p className="text-xs text-caspian-on_primary">
                    {new Date(risk?.created_at).toLocaleDateString("fa-IR")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* حذف */}
        {isDoing && (
          <div className="w-[10%]">
            <div className="z-50">
              <div className="relative flex items-center justify-center">
                <button
                  onClick={async () => {
                    await setDeleteId(risk?.id);
                    await setShowIsDelete(true);
                  }}
                  className="text-caspian-error2 text-lg"
                >
                  <PiTrash />
                </button>
                <div
                  className={
                    risk?.id === deleteId && showIsDelete
                      ? "absolute left-0 top-0 bottom-0 flex items-center gap-0.5 w-fit bg-caspian-surface shadow-[0_0_5px_#00000050] p-0.5 rounded-md duration-500"
                      : "hidden duration-500"
                  }
                >
                  <PiCheckCircleFill
                    onClick={() => {
                      removeHandler();
                      setShowIsDelete(false);
                    }}
                    className="text-caspian-primary-600 text-lg cursor-pointer"
                  />
                  <PiXCircleFill
                    onClick={() => {
                      setShowIsDelete(false);
                      setDeleteId(null);
                    }}
                    className="text-caspian-gray text-lg cursor-pointer"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {show && (
        <div className="flex flex-col gap-0">
          <div className="p-2 flex flex-col gap-2 bg-caspian-surface">
            {/* منشا */}
            <div className="flex flex-col gap-1 relative">
              <span className="text-xs text-caspian-on_primary/80 font-bold">
                منشا:
              </span>
              <div className="flex flex-col gap-1 border-r-caspian-gray2 border-r-2 pr-2">
                {risk?.processRelated?.map((p, i) => (
                  <p key={i} className="text-xs text-caspian-on_primary">
                    {p?.title}
                  </p>
                ))}
                {risk?.objectiveGoalRelated?.map((g, ig) => (
                  <p key={ig} className="text-xs text-caspian-on_primary">
                    {g?.title}
                  </p>
                ))}
              </div>
            </div>
          </div>
          {/* اثر */}
          <div className="flex flex-col gap-1 p-2 bg-[#FFF3B2]">
            <span className="text-xs text-caspian-on_primary/80 font-bold">
              اثر:
            </span>
            <div className="flex flex-col gap-1 border-r-caspian-gray2 border-r-2 pr-2">
              <p className="text-xs text-caspian-on_primary">{risk?.effect}</p>
            </div>
          </div>
          <div className="bg-[#FFF9DB] p-2 flex flex-col gap-2">
            {/* فرایند های تاثیرپذیر */}
            <div className="flex flex-col gap-1">
              <span className="text-xs text-caspian-on_primary/80 font-bold">
                فرایندهای تاثیرپذیر:
              </span>
              <div className="flex flex-col gap-1 border-r-caspian-gray2 border-r-2 pr-2">
                {risk?.groupProcessImpressRelated?.map((gp, igp) => (
                  <p key={igp} className="text-xs text-caspian-on_primary">
                    {gp?.title} - {gp?.groupCode}
                  </p>
                ))}
              </div>
            </div>
            {/* هدف کلان تاثیرپذیر */}
            <div className="flex flex-col gap-1">
              <span className="text-xs text-caspian-on_primary/80 font-bold">
                هدف کلان تاثیرپذیر:
              </span>
              <div className="flex flex-col gap-1 border-r-caspian-gray2 border-r-2 pr-2">
                {risk?.goalImpressRelated?.map((gi, igi) => (
                  <p key={igi} className="text-xs text-caspian-on_primary">
                    {gi?.title} - {gi?.id}
                  </p>
                ))}
              </div>
            </div>
            {/* ذینفع تاثیرپذیر */}
            <div className="flex flex-col gap-1">
              <span className="text-xs text-caspian-on_primary/80 font-bold">
                ذینفع تاثیرپذیر:
              </span>
              <div className="flex flex-col gap-1 border-r-caspian-gray2 border-r-2 pr-2">
                {risk?.riskImpactStackholdersRelated[0]?.stackHoldersRiskImpact?.map(
                  (gi, igi) => (
                    <p key={igi} className="text-xs text-caspian-on_primary">
                      {gi?.title} - {gi?.code}
                    </p>
                  )
                )}
              </div>
            </div>
          </div>
          <div className="bg-[#FFFCEB] p-2 flex flex-col gap-2 rounded-b-md">
            {/* علت وقوع خطا */}
            <div className="flex flex-col gap-1">
              <span className="text-xs text-caspian-on_primary/80 font-bold">
                علت وقع خطا:
              </span>
              <div className="flex flex-col gap-1 border-r-caspian-gray2 border-r-2 pr-2">
                <p className="text-xs text-caspian-on_primary">{risk?.cause}</p>
              </div>
            </div>
          </div>
          <div className="bg-[#FFFEF9] p-2 flex flex-col gap-2 rounded-b-md">
            {/* کنترل‌های موجود */}
            <div className="flex flex-col gap-1">
              <span className="text-xs text-caspian-on_primary/80 font-bold">
                کنترل‌های موجود:
              </span>
              <div className="flex flex-col gap-1 border-r-caspian-gray2 border-r-2 pr-2">
                <p className="text-xs text-caspian-on_primary">
                  {risk?.control}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RisksRiskDetailsMain;
