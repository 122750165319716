import React, { useContext, useEffect, useState } from "react";
import MainAddStandardForm from "./add-standard-form/MainAddStandardForm";
import API from "../../../../../api/API";
import { AuthContext } from "../../../../../context/auth/AuthContext";

const MainStepOne = ({
  setActiveTab,
  msList,
  auditDate,
  auditSection,
  scheduleTimeId,
  setScheduleTimeId,
  auditId,
  windowHeight,
}) => {
  const { token } = useContext(AuthContext);
  const [disabled, setDisabled] = useState(false);
  const [allMsId, setAllMsId] = useState([]);

  const latestItems = {};

  allMsId.forEach((item) => {
    latestItems[item.title] = item;
  });

  const result = Object.values(latestItems);

  
  const submitHandler = async () => {
    if (scheduleTimeId) return setActiveTab("StepTwo");
    let auditTimeData = {
      auditDate: auditDate.mDate,
      auditProfileRelated: +auditId,
      auditSectionRelated: auditSection?.id,
      clauseRelated: result.flatMap((item) => item.standardIds),
    };
    try {
      console.log(auditTimeData)
      const res = await API.post(
        `/api/v1/audit/profile/schedule/time/`,
        auditTimeData,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log(res.data);
      setScheduleTimeId(res?.data?.id);
      setActiveTab("StepTwo");
    } catch (error) {
      console.log(error);
    }
  };

  const [scheduleData, setScheduleData] = useState([]);
  const [scheduleDataId, setScheduleDataId] = useState([]);

  useEffect(() => {
    if (!scheduleTimeId || !token) return;
    const getScheduleData = async () => {
      try {
        const res = await API.get(
          `/api/v1/audit/profile/schedule/time/${scheduleTimeId}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        console.log(res.data);
        console.log(res.data.clauseRelated.flatMap((d) => d.id));
        setScheduleDataId(res.data.clauseRelated.flatMap((d) => d.id));
        setScheduleData(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getScheduleData();
  }, [token, scheduleTimeId]);

  useEffect(() => {
    if (result.flatMap((item) => item.standardIds)?.length < 1) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [result]);

  return (
    <div
      className={`w-full flex flex-col justify-between gap-2 pt-4 ${
        windowHeight > 700 ? "min-h-[50vh] xl:min-h-[55vh] 2xl:min-h-[60vh]" : "min-h-[70vh] xl:min-h-[70vh] 2xl:min-h-[70vh]"
      }`}
    >
      <div className="flex flex-col gap-2 h-full">
        <p className="text-xs text-caspian-gray4">
          برای هر استاندارد، بند مورد نظر را انتخاب کنید.
        </p>
        <div className="p-1 overflow-y-auto w-full relative">
          <div className="max-h-[280px] 2xl:max-h-[400px] flex flex-col gap-2">
            {msList?.map((m) =>
              m?.standardsRealated?.map((s, i) => (
                <MainAddStandardForm
                  key={i}
                  title={m?.msRelated?.symbol ? m?.msRelated?.symbol : "نامشخص"}
                  standard={s?.standardNumber}
                  standardId={s?.id}
                  allMsId={allMsId}
                  setAllMsId={setAllMsId}
                  scheduleData={scheduleData}
                  scheduleDataId={scheduleDataId}
                />
              ))
            )}
          </div>
        </div>
      </div>

      <div className="flex items-center gap-2 h-10">
        <button
          disabled={disabled ? true : false}
          onClick={submitHandler}
          className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-secondary-500 text-caspian-surface text-xs p-1.5 rounded-md"
        >
          مرحله بعد
        </button>
      </div>
    </div>
  );
};

export default MainStepOne;
