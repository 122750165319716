import React from 'react';
import { useNavigate } from 'react-router-dom';

const SingleListAudit = ({ id, title, startDate, auditStatus }) => {
    const navigate = useNavigate();
    const today = (new Date().getFullYear()) + '-' + ((new Date().getMonth() + 1).toString().padStart(2, '0')) + '-' + (new Date().getDate().toString().padStart(2, '0'))
    // console.log('today', today);
    // console.log('startDate', startDate < today);
    const navigateHandle = () => {
        if (auditStatus === 'approve') {
            navigate(`/audit/${id}`)
        }
        else {
            navigate(`/audit/${id}`)
            // navigate(`/audit/create/${id}`)
        }
    }
    return (
        <div className='border border-[#B5B5B5] rounded-lg mx-2 2xl:mx-4 cursor-pointer' onClick={navigateHandle}>
            <div className='flex gap-4 border-b border-[#E2E2E2] py-2 px-2 xl:px-4 items-center'>
                <span className='text-caspian-brown-700 text-xs xl:text-base'>
                    ممیزی داخلی
                </span>
                <span className='text-xs xl:text-sm'> {title}</span>
                <span className='text-xs xl:text-sm'>{new Date(startDate).toLocaleDateString("fa-IR").split('/')[0]}</span>
            </div>
            <div className='px-2 xl:px-4 h-8 relative text-[#7C7B7B]'>
                {
                    startDate >= today && <div className={`${startDate === today ? 'bg-[#3BB758]' : 'bg-[#D9D9D9]'} w-5 h-5 rounded-full absolute -top-3 left-4 right-auto`}></div>
                }

                { startDate>=today &&
                    (auditStatus === 'scheduleResponsible' ? <span className='text-xs'> در انتظار بررسی پروفایل</span> :
                        auditStatus === 'review' ? <span className='text-xs'> در انتظار تایید پروفایل</span> :
                            auditStatus === 'confirm' ? <span className='text-xs'> در انتظار تصویب پروفایل</span> :
                                <span className='text-xs'>در انتظار برنامه ریزی</span>)
                }
            </div>
        </div>
    );
}

export default SingleListAudit;
