import React, { useState } from 'react';
import DateTime from './forms/DateTime';
import TypeAuditList from './forms/TypeAuditList';
import YearAuditList from './forms/YearAuditList';
import { PiCircleNotch } from 'react-icons/pi';
import API from '../../../api/API';

const AuditTimeComponent = ({ year, setYear, startDate, setStartDate, endDate, setEndDate, type, setType
    , round, setRound, typeList, typeSelected, setTypeSelected, scopeBtn, setScopeBtn, token, setIdProfile
}) => {
    const [isLoading, setIsLoadig] = useState(false);
    const handleSubmit = async () => {
        let formData = {
            title: round,
            auditType: type,
            startDate,
            endDate,
        }
        console.log('formData', formData);
        setIsLoadig(true);
        try {
            const res = await API.post('/api/v1/audit/profile/detial/', formData, { headers: { Authorization: token } })
            if (res?.data) {
                setIsLoadig(false);
                setScopeBtn(true);
                console.log(res?.data);
                setIdProfile(res?.data?.id);
            }
        } catch (error) {
            setIsLoadig(false);
            console.log(error);
        }
    }
    console.log(scopeBtn);
    return (
        <div className='flex flex-col gap-4'>
            {/* label */}
            <div className='flex gap-8 items-baseline '>
                <span className='whitespace-nowrap text-[#3C77A2] text-base xl:text-lg'>زمان ممیزی</span>
                <div className='w-full border-t-2 border-caspian-gray2 h-1 relative'>
                    <span className='w-3 h-3 bg-caspian-gray1 rounded-full absolute right-0 -top-2'></span>
                </div>
            </div>
            {/* data entry */}
            <div className='flex flex-col gap-2'>
                {/* startDate and year */}
                <div className='flex justify-between items-center'>
                    <div>
                        {/* year */}
                        {/* <YearAuditList
                                year={year}
                                setYear={setYear}
                                yearList={yearList}
                                yearSelected={yearSelected}
                                setYearSelected={setYearSelected}
                                lable='نوع ممیزی'
                            /> */}
                    </div>
                    <div className={`${scopeBtn?'pointer-events-none':''} flex flex-col gap-2`}>
                        <span className='text-xs text-caspian-gray4'>تاریخ شروع دوره</span>
                        <DateTime setDate={setStartDate} />
                    </div>
                </div>
                {/* type and round and endDate */}
                <div className='flex justify-between items-center'>
                    <div className='flex gap-4'>
                        <div className='w-[150px]'>
                            <TypeAuditList
                                type={type}
                                setType={setType}
                                typeList={typeList}
                                typeSelected={typeSelected}
                                setTypeSelected={setTypeSelected}
                                lable='نوع ممیزی'
                                scopeBtn={scopeBtn}
                            />
                        </div>

                        <div className='flex flex-col gap-2'>
                            {type === 'period' ?
                                <span className='text-caspian-gray4 text-xs'>دوره چندم</span>
                                : <span className='text-caspian-gray4 text-xs'>مورد چندم</span>
                            }
                            {type === 'period' ? <input
                                disabled={scopeBtn}
                                className={`${scopeBtn?'pointer-events-none':''} border border-caspian-gray1 rounded-md w-[115px] p-1 text-center`}
                                type="number"
                                min={0}
                                onChange={(e) => setRound(e.target.value)} />
                                :
                                <input
                                    className='border border-caspian-gray1 rounded-md w-[115px] p-2 text-xs'
                                    type="text"
                                    onChange={(e) => setRound(e.target.value)} />}
                        </div>
                    </div>
                    <div className={`${scopeBtn ? 'pointer-events-none' : ''} flex flex-col gap-2`}>
                        <span className='text-xs text-caspian-gray4'>تاریخ پایان دوره</span>
                        <DateTime setDate={setEndDate} scopeBtn={scopeBtn} />
                    </div>
                </div>
            </div>
            {
                !scopeBtn && <button
                    onClick={handleSubmit}
                    className='bg-[#3C77A2] text-xs xl:text-sm flex justify-center items-center gap-2 text-caspian-surface rounded-lg py-2 px-4 w-fit'>
                    <span>تعیین دامنه</span>
                    <span className='animate-spin'>{isLoading && <PiCircleNotch />}</span>
                </button>
            }
        </div>
    );
}

export default AuditTimeComponent;
