export const data = [
    {
        id: 1,
        code: 'F4367',
        type: 'OBS',
        question: {id:1,code:'AQ20026',title:'سازمان شما عوامل داخلی و خارجی موثر بر سیستم مدیریت ایمنی و بهداشت شغلی را شناسایی و ارزیابی میکند ؟',
            criteria:'شناسایی عوامل داخلی و خارجی ، درنظر گرفتن نیاز های ذینفعان و ارزیابی منظم برای بهبود سیستم ایمنی و بهداشت شغلی است.'
        },
        description: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، ',
        requirement: [
            { id: 1, standard: 'ISO90001:2015', clause: [{ id: 1, name: '1-1-5' }, { id: 2, name: '1-3-5' }] },
            { id: 2, standard: 'ISO45001:2018', clause: [{ id: 1, name: '4-2' }, { id: 2, name: '5-1' }] },
        ],
        request: [],
    },
    {
        id: 2,
        code: 'F4867',
        type: 'NC',
        question: {id:1,code:'AQ20026',title:'سازمان شما عوامل داخلی و خارجی موثر بر سیستم مدیریت ایمنی و بهداشت شغلی را شناسایی و ارزیابی میکند ؟',
            criteria:'شناسایی عوامل داخلی و خارجی ، درنظر گرفتن نیاز های ذینفعان و ارزیابی منظم برای بهبود سیستم ایمنی و بهداشت شغلی است.'
        },
        description: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، ',
        requirement: [
            { id: 1, standard: 'ISO90001:2015', clause: [{ id: 1, name: '2-5' }, { id: 2, name: '3-4' }] }
        ],
        request: [{ id: 1, name: 'IR679867' }, { id: 1, name: 'IR68590' }, { id: 2, name: 'IR41589' }],
    },
    {
        id: 3,
        code: 'F4867',
        type: 'OK',
        question: {id:1,code:'AQ20026',title:'سازمان شما عوامل داخلی و خارجی موثر بر سیستم مدیریت ایمنی و بهداشت شغلی را شناسایی و ارزیابی میکند ؟',
            criteria:'شناسایی عوامل داخلی و خارجی ، درنظر گرفتن نیاز های ذینفعان و ارزیابی منظم برای بهبود سیستم ایمنی و بهداشت شغلی است.'
        },
        description: 'شرح یافته',
        requirement: [
            { id: 1, standard: 'ISO90001:2015', clause: [{ id: 1, name: '2-5' }, { id: 2, name: '3-4' }, { id: 3, name: '9-1' }, { id: 4, name: '8-4-5' }, { id: 5, name: '0-2' }] }
        ],
        request: [{ id: 1, name: 'IR679867' },  { id: 2, name: 'IR41589' }, { id: 3, name: 'IR41589' }],
    },
    {
        id: 4,
        code: 'F4867',
        type: 'N/A',
        question: {id:1,code:'AQ20028',title:'موضوع تستی',
            criteria:'تستی'
        },
        description: 'شرح',
        requirement: [
            { id: 1, standard: 'ISO90001:2015', clause: [{ id: 1, name: '2-5' }, { id: 2, name: '3-4' }, { id: 3, name: '9-1' }, { id: 4, name: '8-4-5' }, { id: 5, name: '0-2' }] }
        ],
        request: [{ id: 1, name: 'IR10009' }],
    },
]