import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const AuditPlannigRow = ({ auditSectionRelated, auditDate, AuditScheduleRelatedResponsible, ScheduleStatus }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const days = ['یکشنبه', 'دوشنبه', 'سه‌شنبه', 'چهارشنبه', 'پنج‌شنبه', 'جمعه']
    const dayDate = (day) => {
        return days[day];
    }
    function convertTimeFormat(time) {
        let [hours, minutes] = time?.split(":");
        return `${hours}:${minutes}`;
    }
    // console.log(new Date(auditDate));
    return (
        <tr
            onClick={() => navigate(`/audit/${id}/schedule/${auditSectionRelated?.id}`)}
            className='cursor-pointer text-xs whitespace-nowrap odd:bg-caspian-primary-50 bg-caspian-surface'>
            <td className='py-3 w-[20%] text-center align-top' title={auditSectionRelated?.sectionRelated?.title}>
                <p className='w-[90%] whitespace-normal line-clamp-1 text-center'>
                    {auditSectionRelated?.sectionRelated?.title}
                </p>
            </td>
            <td className='py-3 w-[5%] text-center align-top text-[#3C77A2]'>
                {dayDate(new Date(auditDate).getDay())}
            </td>
            <td className='py-3 w-[5%] text-center align-top text-[#3C77A2]'>
                {new Date(auditDate).toLocaleDateString('fa-ir')}
            </td>
            <td className='py-3 w-[10%] text-center align-top'>
                QMS/IMS
            </td>
            <td className='py-3 w-[10%] text-center flex items-center gap-2 justify-between align-top'>
                <div className='flex gap-1 items-center'>
                    <div className='w-2 h-2 rounded-full bg-[#10B981]'></div>
                    {convertTimeFormat(AuditScheduleRelatedResponsible[0]?.startTime)}
                </div>
                <div className='flex gap-1 items-center'>
                    <div className='w-2 h-2 rounded-full bg-[#FF5B5B]'></div>
                    {convertTimeFormat(AuditScheduleRelatedResponsible[0]?.endTime)}
                </div>
            </td>
            <td className='py-3 w-[10%] text-center align-top'>
                -
            </td>
            <td className='py-3 w-[10%] text-center align-top text-[#3C77A2]'>
                {ScheduleStatus}
            </td>
            <td className='py-3 w-[5%] text-center align-top text-[#3C77A2]'>
                اقدام
            </td>
        </tr>
    );
}

export default AuditPlannigRow;
