const Modal = ({ children }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center w-full h-full z-[999]">
      <div className="fixed inset-0 bg-caspian-on_primary/30 z-50 p-4"></div>
      <div className="z-[1000] fixed flex items-center justify-center w-[90%] md:w-full">
        {children}
      </div>
    </div>
  );
};

export default Modal;
