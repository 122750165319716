import React, { useContext, useEffect, useRef, useState } from "react";
import API from "../../../../../../../../api/API";
import { AuthContext } from "../../../../../../../../context/auth/AuthContext";

const StepThreeFirstForm = ({
  objectiveRelated,
  setObjectiveRelated,
  riskRelated,
  setRiskRelated,
  correctiveactionRelated,
  setCorrectiveactionRelated,
  mosavabBaznegari,
  setMosavabBaznegari,
  other,
  setOther,
}) => {
  const { token } = useContext(AuthContext);
  const riskRelatedRef = useRef();
  const objectiveRelatedRef = useRef();
  const correctiveactionRelatedRef = useRef();
  const mosavabBaznegariRef = useRef();
  const otherRef = useRef();
  const [objectiveData, setObjectiveData] = useState([]);

  console.log(objectiveRelated)
  useEffect(() => {
    if (!token) return;
    const getObjective = async () => {
      try {
        const res = await API.get("/api/v1/goal/objective/", {
          headers: { Authorization: token },
        });
        console.log(res.data);
        setObjectiveData(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getObjective();
  }, [token]);
  return (
    <div>
      <div className="flex flex-col gap-2">
        {/* هدف خرد */}
        <div className="w-1/2 flex flex-col gap-1.5">
          <label className="text-xs text-caspian-gray">هدف خرد</label>
          <select
            value={objectiveRelated}
            onChange={(e) => setObjectiveRelated(e.target.value)}
            className="text-xs border border-caspian-gray2 rounded-md p-1"
          >
            <option value={-1} className="text-xs">
              انتخاب کنید
            </option>
            {objectiveData?.map((a, i) => (
              <option value={a.id} key={i} className="text-xs">
                {a.title}
              </option>
            ))}
          </select>
        </div>
        {/* ریسک مرتبط */}
        <div className="w-1/2 flex flex-col gap-1.5">
          <label className="text-xs text-caspian-gray">ریسک مرتبط</label>
          <input
            ref={riskRelatedRef}
            name="title"
            defaultValue={riskRelated}
            onChange={(e) => setRiskRelated(e.target.value)}
            type="text"
            placeholder="ریسک مرتبط برنامه اجرایی را وارد کنید"
            className="outline-none border border-caspian-gray1 p-1.5 text-xs rounded-md"
          />
        </div>
        {/* اقدام اصلاحی مرتبط */}
        <div className="w-1/2 flex flex-col gap-1.5">
          <label className="text-xs text-caspian-gray">
            اقدام اصلاحی مرتبط
          </label>
          <input
            ref={correctiveactionRelatedRef}
            name="title"
            defaultValue={correctiveactionRelated}
            onChange={(e) => setCorrectiveactionRelated(e.target.value)}
            type="text"
            placeholder="اقدام اصلاحی مرتبط برنامه اجرایی را وارد کنید"
            className="outline-none border border-caspian-gray1 p-1.5 text-xs rounded-md"
          />
        </div>
        {/* مصوبات بازنگری */}
        <div className="w-1/2 flex flex-col gap-1.5">
          <label className="text-xs text-caspian-gray">مصوبات بازنگری</label>
          <input
            ref={mosavabBaznegariRef}
            name="title"
            defaultValue={mosavabBaznegari}
            onChange={(e) => setMosavabBaznegari(e.target.value)}
            type="text"
            placeholder="مصوبات بازنگری برنامه اجرایی را وارد کنید"
            className="outline-none border border-caspian-gray1 p-1.5 text-xs rounded-md"
          />
        </div>
        {/* سایر */}
        <div className="w-1/2 flex flex-col gap-1.5">
          <label className="text-xs text-caspian-gray">سایر موارد</label>
          <input
            ref={otherRef}
            name="title"
            defaultValue={other}
            onChange={(e) => setOther(e.target.value)}
            type="text"
            placeholder="سایر موارد برنامه اجرایی را وارد کنید"
            className="outline-none border border-caspian-gray1 p-1.5 text-xs rounded-md"
          />
        </div>
      </div>
    </div>
  );
};

export default StepThreeFirstForm;
