import React, { useContext, useRef, useState } from 'react'
import Modal from '../../../modal/Modal'
import { PiX } from 'react-icons/pi'
import { AuthContext } from '../../../../context/auth/AuthContext';
import API from '../../../../api/API';

const ModalDescriptionReport = ({ handleReport, setShowModalDesc, reportId, setShowModalFrom }) => {
    const { token } = useContext(AuthContext);
    const descRef = useRef()
    const [description, setDescription] = useState("")
    const [checked, setChecked] = useState(true)

    const submitData = async () => {
        if (!token || !reportId) return;
        let formData = {
            description: description,
            is_completed: true
        }
        try {
            console.log(formData)
            const res = await API.patch(`/api/v1/actionplan/taskreport/${reportId}/`, formData,
                { headers: { Authorization: token } }
            )
            console.log(res?.data)
            handleReport()
            setShowModalDesc(false)
            setShowModalFrom(false)

        } catch (error) {
            console.log(error)
        }
    }
    const clearData = () => {
        setChecked(!checked)
        setDescription("")
        descRef.current.value = ""
    }

    return (
        <Modal>
            <div className='flex flex-col items-center  bg-caspian-surface rounded-lg pt-5 h-[500px] w-[400px] md:w-[480px]'>

                <div className='w-full flex justify-end px-5'>
                    <PiX className='text-xl cursor-pointer text-caspian-gray4'
                        onClick={() => setShowModalDesc(false)} />
                </div>

                <div className='flex flex-col items-center justify-between h-full w-full gap-5'>
                    <span className='px-5 text-xs 2xl:text-sm text-caspian-gray4 w-full text-start '>
                        در صورت لزوم برای این گزارش توضیحات مورد نظر خود را بنویسید
                    </span>

                    <div className='flex flex-col items-center gap-5 w-full px-5 h-full'>
                        <div className='flex items-center justify-start w-full border-2 border-caspian-gray1/80 py-2 px-5 rounded-lg gap-5'>
                            <input type='checkbox' id='desc'
                                onClick={clearData} />

                            <label htmlFor='desc' className={`text-xs 2xl:text-sm font-semibold
                         ${checked ? "text-caspian-gray1" : "text-caspian-on_primary"}`}>
                                توضیحی ندارم
                            </label>
                        </div>

                        <textarea
                            disabled={checked ? false : true}
                            onChange={(e) => setDescription(e.target.value)}
                            ref={descRef}
                            className={`resize-none px-5 h-full py-3 w-full font-semibold placeholder:font-normal text-xs 2xl:text-sm outline-none border-2 border-caspian-gray2 rounded-lg
                            ${checked ? "placeholder:text-caspian-gray4" : "placeholder:text-caspian-gray4/30"}`}
                            placeholder='توضیحات برنامه اجرایی را وارد کنید'
                        />
                    </div>

                    <div className='w-full flex flex-col items-start gap-3 p-5 pt-0'>
                        <button
                            onClick={submitData}
                            disabled={(!checked || description?.length > 0) ? false : true}
                            className={`text-xs 2xl:text-sm  text-caspian-surface duration-500 w-[80px] h-8 rounded-[4px] 
                                ${(!checked || description?.length > 0) ? "bg-caspian-primary-500" : "bg-caspian-gray"}`}>
                            ثبت
                        </button>
                    </div>
                </div>
            </div>

        </Modal >
    )
}

export default ModalDescriptionReport