import React, { useEffect, useState } from 'react';
import Modal from '../../../../modal/Modal';
import { PiX } from "react-icons/pi";
import StackedBarChart3 from './StackedBarChart3';

const ModalRequest = ({ myData2, setShow2 }) => {
    console.log("myData2", myData2);
    const [data, setData] = useState([{
        standard: '26001',
        clause: [{ name: '1' }, { name: '2' }, { name: '3' }, { name: '4' },
        { name: '5' }],
        ir: [{ name: '1', value: 2 }, { name: '3', value: 5 }, { name: '4', value: 4 },],
        cr: [{ name: '1', value: 5 }, { name: '2', value: 3 }, { name: '3', value: 1 }, { name: '4', value: 1 },],
        car: [{ name: '1', value: 3 }, { name: '2', value: 1 }, { name: '3', value: 1 }, { name: '4', value: 4 },]
    }, {
        standard: '9001',
        clause: [{ name: '5' }, { name: '6' }, { name: '7' }],
        ir: [{ name: '5', value: 1 }, { name: '6', value: 5 }, { name: '7', value: 4 },],
        cr: [{ name: '5', value: 2 }, { name: '6', value: 1 }],
        car: [{ name: '5', value: 3 }]
    }, {
        standard: '45001',
        clause: [{ name: '7' }, { name: '8' }, { name: '9' }],
        ir: [{ name: '7', value: 3 }, { name: '9', value: 2 }],
        cr: [{ name: '7', value: 2 }, { name: '9', value: 2 }],
        car: [{ name: '7', value: 5 }, { name: '9', value: 1 }]
    }]);
    const [filterData, setFilterData] = useState(f => data?.filter(d => d?.standard === myData2));
    console.log(filterData);
    const [filter, setFilter] = useState(["ir", "cr", "car"]); // نمایش هر دو به صورت پیش‌فرض

    const handleFilterChange = (event) => {
        const value = event.target.value;
        if (value === "all") {
            setFilter(["ir", "cr", "car"]); // نمایش همه
        } else {
            setFilter([value]); // نمایش فقط ir یا cr
        }
    };

    return (
        <Modal>
            <div className='bg-caspian-surface w-[70%] p-3 rounded-md'>
                <PiX onClick={() => setShow2(false)} className='mr-auto cursor-pointer' />
                <h1 className='text-caspian-gray4 text-sm mb-2 text-center'>نمایش درخواست‌ها براساس بندهای اصلی استادارد {myData2}</h1>
                <div className='flex justify-between'>
                    <select name="" id="" className='w-[200px] border rounded text-caspian-gray4 border-caspian-gray1' onChange={handleFilterChange}>
                        <option value='all'>همه</option>
                        <option value='ir'>IR</option>
                        <option value='cr'>CR</option>
                        <option value='car'>CAR</option>
                    </select>
                </div>

                <div className='ltr overflow-y-auto'>
                    {/* <StackedBarChart data={myData} filter={filter} /> */}
                    <StackedBarChart3 data={filterData} filter={filter} />
                </div>
            </div>
        </Modal>
    );
}

export default ModalRequest;
