import React, { useState } from "react";
import { set } from "react-hook-form";


const ToggleSwitch = ({setFollowUp,followUp}) => {
    const [isOn, setIsOn] = useState(false);


    const handleToggle = () => {
        setIsOn(!isOn);
        setFollowUp(!followUp);
    };


    return (
        <div
            className={`relative inline-flex items-center border border-[#D9D9D9] h-[25px] w-[60px] cursor-pointer rounded-full transition-colors ${isOn ? "bg-[#FFCCCC]" : "bg-[#D9D9D9]"
                }`}
            onClick={handleToggle}
        >
            <span
                className={`h-5 w-5 rounded-full shadow-md transform transition-transform ${isOn ? "-translate-x-9 bg-caspian-error2" : "-translate-x-0 bg-caspian-surface "
                    }`}
            />
        </div>
    );
};


export default ToggleSwitch;

