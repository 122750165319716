import React from "react";

const MembersList = ({
  userList,
  setShowActivityTema,
  setMemberId,
  allActivities,
  memberId,
  isSelectSubject,
}) => {
  const getUser = async (id) => {
    if (isSelectSubject) return;
    setMemberId(id);
    setShowActivityTema(false);
  };
  const getActivity = async () => {
    if (isSelectSubject) return;
    setMemberId(null);
    setShowActivityTema(true);
  };
  return (
    <div className="w-[40%] ">
      <div className="mb-2 bg-caspian-secondary-400 text-caspian-surface flex items-center justify-center w-full p-2 rounded-t-md text-xs">
        <span>لیست افراد تیم</span>
      </div>
      <button
        onClick={getActivity}
        className={
          memberId
            ? "mb-2 bg-caspian-gray3 border border-caspian-gray2 text-xs flex items-center justify-center p-2 rounded-md w-full"
            : "mb-2 bg-caspian-green-50 border border-caspian-green-500 text-xs flex items-center justify-center p-2 rounded-md w-full"
        }
      >
        مشاهده عملکرد کل تیم
      </button>
      <div className="flex flex-col gap-1 w-full h-[258px] 2xl:h-[360px] overflow-y-auto">
        {userList?.map((m, i) => (
          <div
            onClick={() => getUser(m)}
            key={i}
            className={
              memberId !== m
                ? "flex items-center justify-between gap-1 w-full bg-caspian-gray3 border border-caspian-gray2 p-2 rounded-md cursor-pointer"
                : "flex items-center justify-between gap-1 w-full bg-caspian-secondary-50 border border-caspian-secondary-500 p-2 rounded-md cursor-pointer"
            }
          >
            <span className="text-xs text-caspian-gray4">
              {m?.member?.firstName + " " + m?.member?.lastName}
            </span>
            <div className="flex items-center gap-5">
              <span className="text-xs text-caspian-gray4 basis-1/2">
                کارشناس
              </span>
              {!isSelectSubject && (
                <span className="text-xs text-caspian-gray4 basis-1/2">
                  {allActivities} / {m?.listDone?.length}
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MembersList;
