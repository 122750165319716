import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../context/auth/AuthContext";
import API from "../../../../api/API";
import TableListRisks from "./TableListRisks";
import LoadingData from "../../LoadingData";
import NotfoundData from "../../NotfoundData";

const MainListRisks = ({ refreshRisksList, setRefreshRisksList }) => {
  const { token } = useContext(AuthContext);
  const [fullData, setfullData] = useState([-1]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setRefreshRisksList(1);
  }, [setRefreshRisksList]);
  useEffect(() => {
    if (!token || refreshRisksList === 0) return;
    setLoading(true);
    const getIdentification = async () => {
      try {
        const res = await API.get("/api/v1/risk/profile/", {
          headers: { Authorization: token },
        });
        console.log(res.data)
        setRefreshRisksList(0);
        // console.log(res.data.sort((a) => (a.statusType === "done" ? -1 : 1)));
        setfullData(res.data);
      } catch (error) {
        setError(true);
        console.log(error);
      } finally {
        setLoading(false);
        setError(false);
      }
    };
    getIdentification();
  }, [token, refreshRisksList, setRefreshRisksList]);

  return loading ? (
    <LoadingData />
  ) : error ? (
    <NotfoundData />
  ) : (
    <TableListRisks
      listRisks={fullData}
      setRefreshRisksList={setRefreshRisksList}
    />
  );
};

export default MainListRisks;
