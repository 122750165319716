import React, { useContext, useEffect, useRef, useState } from "react";
import CommentSingle from "./CommentSingle";
import { PiChatText, PiArrowsCounterClockwiseFill } from "react-icons/pi";
import { PiXLight } from "react-icons/pi";
import { AuthContext } from "../../../context/auth/AuthContext";
import API from "../../../api/API";

const CommentsMain = ({ setShowComments, fullData, valueForm, setValueForm, urlPost, urlGet, formData, accessRead }) => {
  const { token, user } = useContext(AuthContext)

  const valueRef = useRef();
  const scrollRef = useRef();

  const [listComments, setListComments] = useState([])
  const [isDisable, setIsDisable] = useState(false);
  const [refresh, setRefresh] = useState(0)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setRefresh(1)
  }, [setRefresh])

  useEffect(() => {
    if (valueForm?.length < 1) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [valueForm]);

  useEffect(() => {
    if (listComments) {
      scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [listComments]);

  // post Comment
  const submitData = async () => {
    if (!token || !formData?.object_id) return;

    try {
      const res = await API.post(`${urlPost}`, formData,
        { headers: { Authorization: token } }
      )
      console.log(res?.data)
      clearHandler()
      setRefresh(1)
    } catch (error) {
      console.log(error)
    }
  }

  // get comment 
  useEffect(() => {
    if (!token || !user || refresh !== 1 || !formData?.object_id) return;

    const handleComment = async () => {
      setLoading(true)
      try {
        const res = await API.get(`${urlGet}${formData?.object_id}`,
          { headers: { Authorization: token } }
        )
        console.log(res?.data)
        setListComments(res?.data)
        setRefresh(0)
        setLoading(false)

      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }
    handleComment()
  }, [formData?.object_id, token, user, refresh, urlGet])

  const clearHandler = () => {
    setValueForm("");
    valueRef.current.value = "";
  };

  const refreshList = () => {
    setRefresh(1)
    setLoading(true)
  }

  return (
    <div className="z-[1500]">
      <div className="flex z-50 flex-col justify-between shadow-xl bg-caspian-surface rounded-lg bottom-4 left-16 w-[365px] fixed">
        <div className="w-full flex flex-col items-center min-h-[77vh] max-h-[77vh]">
          <div className="bg-caspian-secondary-300 w-full rounded-t-md p-3">
            <div className="flex justify-between  items-center h-full">
              <div className="flex justify-start items-center gap-2">
                <PiChatText className="text-caspian-surface text-xl 2xl:text-2xl" />
                <h2 className="text-sm 2xl:text-base text-caspian-surface ">دیدگاه</h2>
              </div>
              <div className="flex items-center gap-2">
                <PiArrowsCounterClockwiseFill
                  className={`text-caspian-surface cursor-pointer text-xl ${loading ? 'animate-spin' : ""}`}
                  onClick={refreshList}
                />
                <PiXLight
                  className="text-caspian-surface cursor-pointer text-2xl"
                  onClick={() => setShowComments(false)}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center w-full min-h-full h-full overflow-y-auto">
            {listComments?.map((item, index) => (
              <CommentSingle accessRead={accessRead} key={index} scrollRef={scrollRef} index={index}
               setRefresh={setRefresh} listComments={listComments} item={item} fullData={fullData} />
            ))}
          </div>
        </div>

        {accessRead ?
          <div className="w-full shadow-2xl h-24 border-t-2 border-caspian-gray1 rounded-b-lg bg-caspian-background flex justify-center items-center">
            <span className="text-sm text-caspian-gray">شما دسترسی برای گذاشتن دیدگاه ندارید.</span>
          </div>
          :
          <div className="w-full shadow-2xl h-24 border-t-2 border-caspian-gray1 rounded-b-lg bg-caspian-background relative flex gap-2 items-end">
            <textarea
              ref={valueRef}
              rows={5}
              onChange={(e) => setValueForm(e.target.value)}
              placeholder="دیدگاه خود را ارسال کنید"
              className="resize-none w-full relative px-2 bg-caspian-background rounded-b-lg text-caspian-gray  outline-none text-xs"
            />
            <div className="flex flex-col gap-2 items-center relative left-1 bottom-3">
              <button
                type="button"
                onClick={submitData}
                disabled={isDisable ? true : false}
                className={`rounded text-xs text-caspian-surface py-2 px-3 duration-700
                 ${valueForm?.length < 1 ? "bg-caspian-gray1" : "bg-[#01BC8D] cursor-pointer text-caspian-surface"}`}>
                ارسال
              </button>

              <button
                onClick={clearHandler}
                disabled={isDisable ? true : false}
                className={` rounded-lg text-xs duration-700 
                ${valueForm?.length < 1 ? "text-caspian-gray1" : "cursor-pointer text-caspian-error2"}`}>
                پاک کردن
              </button>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default CommentsMain;
