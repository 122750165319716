import React, { useContext, useEffect, useState } from 'react';
import ListSection from '../../create-form-question/ListSection';
import { PiDotOutlineFill, PiPlusBold, PiTrash } from 'react-icons/pi';
import StandardModal from '../clause-standard/StandardModal';
import ProcessModal from '../process-activity/ProcessModal';
import DeleteAlertBox from '../alert-box/DeleteAlertBox';
import API from '../../../../../../api/API';
import { AuthContext } from '../../../../../../context/auth/AuthContext';


const StepTwo = ({ idPost, handleDelete, loadingAlert, setShowModal, q, setQ, setRefresh,
    showSection, setShowSection, activitiesSelected2, setActivitiesSelected2, activitiesFinalSelected2,
    setActivitiesFinalSelected2, list3, setList3, final, setFinal, all, setAll
    , edit, setEdit
}) => {
    const { token } = useContext(AuthContext);
    // console.log(idPost);
    const [questionDetails, setQuestionDetails] = useState([-1]);

    //section
    // const [showSection, setShowSection] = useState(false);
    // // const [sectionSelected, setSectionSelected] = useState(null);
    // const [activitiesSelected2, setActivitiesSelected2] = useState([]);
    // const [activitiesFinalSelected2, setActivitiesFinalSelected2] =
    //     useState(activitiesSelected2);
    // const [list3, setList3] = useState([]);
    // const [final, setFinal] = useState(list3);
    // const [processSchedulesList, setProcessSchedulesList] = useState([]);

    const [showMoadl1, setShowModal1] = useState(false);
    const [activitiesSelected3, setActivitiesSelected3] = useState([]);
    const [activitiesFinalSelected3, setActivitiesFinalSelected3] =
        useState(activitiesSelected3);
    const [list1, setList1] = useState([-1]);

    const [showModal2, setShowModal2] = useState(false);

    const [list2, setList2] = useState([]);
    const [formData2, setFormData2] = useState([]);

    const [showDisable, setShowDisable] = useState(true);

    const [activeTab, setActiveTab] = useState('standard');
    const [showAlert2, setShowAlert2] = useState(false);
    useEffect(() => {
        if (!token || !idPost || q === 0) return
        const fetchList = async () => {
            try {
                const res = await API.get(`/api/v1/audit/question/detial/${idPost}/`, { headers: { Authorization: token } });
                if (res?.data) {
                    console.log('res', res?.data);
                    setQuestionDetails(res.data);
                    setQ(0)
                }
            }
            catch (err) {
                console.log(err);
            }
        }
        fetchList();
    }, [q, questionDetails, token, setQuestionDetails, idPost])
    useEffect(() => {
        setShowDisable(idPost ? false : true);
    }, [idPost, showDisable])

    const removeItem = async (id) => {
        // const list = list2?.activity?.filter(d => d.id !== id);
        // setList2({ ...list2, activity: list });
        console.log(id);
        try {
            const res = await API.delete(`/api/v1/audit/question/process/${id}/`, { headers: { Authorization: token } })
            setQ(1);
        }
        catch (err) {
            console.log(err);
        }
    }

    const removeSection = async (id, idSec) => {
        let itemsDlt = questionDetails?.questionSectionRelated[0]?.sectionRelatedQuestion?.filter(i =>i?.id!==id)
        itemsDlt=itemsDlt?.map(i=>i?.id)
        setActivitiesFinalSelected2(itemsDlt)
        setActivitiesSelected2(itemsDlt)
        let formData = {
            sectionRelatedQuestion: itemsDlt,
            questionSectionRelated: idPost
        }
        console.log('formData section', formData);
        try {
            // console.log(id);
            const res = await API.put(`/api/v1/audit/question/section/${idSec}/`, formData,
                { headers: { Authorization: token } });
            if (res?.data) {
                setQ(1)
                console.log('patch sec', res?.data);
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    const rmvItem = async (id) => {
        // console.log(id);
        // const list = list1?.clause?.filter(d => d.id !== id);
        // setList1({ ...list1, clause: list });
        try {
            const res = await API.delete(`/api/v1/audit/question/clause/${id}/`, { headers: { Authorization: token } })
            setQ(1)
        }
        catch (err) {
            console.log(err);
        }
    }

    const editHandle = () => {
        setEdit(true);
    }

    // console.log('questionDetails', questionDetails, 'q', q);

    return (

        <div className='flex flex-col h-[60%] justify-between relative'>
            {/* {showDisable && <div className='h-full overflow-hidden w-full absolute inset-0 bg-caspian-gray4/40 z-50 border-2 border-dashed border-caspian-gray4 rounded-sm'></div>} */}

            <div className={`${!idPost || edit ? 'pointer-events-none' : 'pointer-events-auto'}`}>
                <div className="tabButton flex justify-around  items-center px-2 2xl:px-5 py-1 2xl:py-2">
                    <div className={`${activeTab === 'standard' ? 'border-b-2 pb-1 border-[#3C77A2]' : ''} text-[#3C77A2]  text-xs 2xl:text-sm cursor-pointer flex gap-1`} onClick={() => setActiveTab('standard')}>

                        <span>استاندارد</span>
                        {
                            questionDetails?.questionClauseRelatedQuestion?.length > 0 &&
                            <span>{'(' + questionDetails?.questionClauseRelatedQuestion?.length + ')'}</span>
                        }
                        <span>و</span>
                        <span>بندهای مرتبط</span>
                        {
                            questionDetails?.questionClauseRelatedQuestion?.length > 0 &&
                            <span>{'(' + questionDetails?.questionClauseRelatedQuestion?.reduce((prev, current) => {
                                return prev + current?.clauseRelated?.length
                            }, 0) + ')'}</span>
                        }
                    </div>

                    <div className={`${activeTab === 'section' ? 'border-b-2 pb-1 border-[#3C77A2]' : ''} text-[#3C77A2]  text-xs 2xl:text-sm cursor-pointer flex gap-1.5`} onClick={() => setActiveTab('section')}>
                        <span>واحد مرتبط</span>
                        {questionDetails?.questionSectionRelated?.length > 0 &&
                            <span className='text-caspian-secondary-500'>
                                {'(' +
                                    questionDetails?.questionSectionRelated[questionDetails?.questionSectionRelated?.length - 1]?.sectionRelatedQuestion
                                        ?.length + ')'}
                            </span>
                        }
                    </div>

                    <div className={`${activeTab === 'process' ? 'border-b-2 pb-1 border-[#3C77A2]' : ''} text-[#3C77A2]  text-xs 2xl:text-sm cursor-pointer flex gap-1`} onClick={() => setActiveTab('process')}>

                        <span>فرآیند</span>
                        {
                            questionDetails?.questionRelatedQuestionProcess?.length > 0 &&
                            <span>{'(' + questionDetails?.questionRelatedQuestionProcess?.length + ')'}</span>
                        }
                        <span>و</span>
                        <span>فعالیت‌های مرتبط</span>
                        {
                            questionDetails?.questionRelatedQuestionProcess?.length > 0 &&
                            <span>{'(' + questionDetails?.questionRelatedQuestionProcess?.reduce((prev, current) => {
                                return prev + current?.processActivityRelated?.length
                            }, 0) + ')'}</span>
                        }
                    </div>
                </div>
                <div className='max-h-[40vh] overflow-y-auto px-2 2xl:px-5'>
                    <div className={`${!idPost || edit ? 'pointer-events-none' : 'pointer-events-auto'}`}>
                        {activeTab === 'standard' &&
                            <>
                                <p className='text-[#3C77A2] flex items-center w-max gap-2 text-xs 2xl:text-sm px-3 cursor-pointer pb-2'
                                    onClick={() => setShowModal1(true)}
                                >
                                    <span>افزودن</span>
                                    <PiPlusBold />
                                </p>
                                <div className='px-3'>
                                    {/* list */}
                                    {
                                        questionDetails?.questionClauseRelatedQuestion?.map((item, index) =>
                                            <div key={item.id} className='text-xs 2xl:text-sm py-2 px-4 rounded-md odd:bg-[#E4F0F1] group '>
                                                <div className='flex justify-between items-center'>
                                                    <p className='text-[#5F5F5F] font-bold'>
                                                        {item?.standardRelated?.standardNumber}
                                                    </p>
                                                    <div className='hidden group-hover:inline-block'>

                                                        <PiTrash className='text-sm cursor-pointer text-[#3C77A2]' onClick={() => rmvItem(item.id)} />
                                                    </div>
                                                </div>
                                                {
                                                    item?.clauseRelated?.map(i =>
                                                        <div className='flex gap-10 items-center text-xs p-2' key={i.id}>
                                                            <p className='text-[#5F5F5F] '>
                                                                {i?.clauseNumber}
                                                            </p>
                                                            <p>
                                                                {i?.title}
                                                            </p>
                                                        </div>)
                                                }
                                            </div>
                                        )
                                    }
                                </div>

                                {
                                    showMoadl1 && <StandardModal setShowModal1={setShowModal1} activitiesFinalSelected3={activitiesFinalSelected3} setActivitiesFinalSelected3={setActivitiesFinalSelected3}
                                        activitiesSelected3={activitiesSelected3} setActivitiesSelected3={setActivitiesSelected3}
                                        idPost={idPost}
                                        setList1={setList1}
                                        q={q}
                                        setQ={setQ}
                                    />
                                }
                            </>
                        }

                    </div>
                    <div>
                        {activeTab === 'section' && <>
                            <div className='flex justify-between items-center'>
                                <p className='text-[#3C77A2] flex items-center w-max gap-2 px-3  text-xs 2xl:text-sm cursor-pointer pb-2'
                                    onClick={() => setShowSection(true)}>
                                    <span>افزودن </span>
                                    <PiPlusBold />
                                </p>
                            </div>
                            <div className='px-3'>
                                {
                                    questionDetails?.questionSectionRelated
                                        ?.map((item, index) =>
                                            item?.sectionRelatedQuestion.map(i => <div key={i.id} className='text-xs 2xl:text-sm py-2 px-4 rounded-md odd:bg-[#E4F0F1] group '>
                                                <div className='flex justify-between items-center'>
                                                    <p className='text-[#5F5F5F]'>
                                                        {i?.title}
                                                    </p>
                                                    <div className='hidden group-hover:inline-block'>

                                                        <PiTrash className='text-sm cursor-pointer text-[#3C77A2]'
                                                            onClick={() => removeSection(i?.id, item?.id)} />
                                                    </div>
                                                </div>
                                            </div>)
                                        )
                                }
                            </div>
                            {showSection && (
                                <ListSection
                                    finalSelected={activitiesFinalSelected2}
                                    setFinalSelected={setActivitiesFinalSelected2}
                                    selected={activitiesSelected2}
                                    setSelected={setActivitiesSelected2}
                                    setShowSection={setShowSection}
                                    showSection={showSection}
                                    title="لیست واحد ها"
                                    url={`/api/v1/organization/structure/section/`}
                                    setFinal={setFinal}
                                    final={final}
                                    setList3={setList3}
                                    q={q}
                                    setQ={setQ}
                                    idPost={idPost}
                                    all={all}
                                    setAll={setAll}
                                />
                            )}
                        </>
                        }
                    </div>
                    <div>
                        {activeTab === 'process' && <>
                            <p className='text-[#3C77A2] flex items-center w-max gap-2 text-xs 2xl:text-sm px-3 cursor-pointer pb-2'
                                onClick={() => setShowModal2(true)}
                            >
                                <span>افزودن </span>
                                <PiPlusBold />
                            </p>
                            <div className=' px-3'>
                                {/* list */}
                                {
                                    questionDetails?.questionRelatedQuestionProcess?.map((i, index) =>
                                        <div key={index} className='text-xs 2xl:text-sm py-2 px-4 rounded-md odd:bg-[#E4F0F1] group '>
                                            <div className='flex justify-between items-center'>
                                                <p className='flex gap-6 items-center text-[#5F5F5F] font-bold'>
                                                    <span>فرآیند:</span>
                                                    <span>{i?.processProfileRelated?.activityProfileProcessRelated[0]?.processTitle}</span>
                                                </p>
                                                <div className='hidden group-hover:inline-block'>
                                                    <PiTrash className='text-sm cursor-pointer text-[#3C77A2]' onClick={() => removeItem(i?.id)} />
                                                </div>
                                            </div>
                                            <div className='flex gap-2 items-start text-xs p-2'>
                                                <span className='text-[#5F5F5F]'>فعالیت:</span>
                                                <div>
                                                    {
                                                        i?.processActivityRelated?.map(item =>
                                                            <div className='mb-2' key={item.id}>
                                                                <p
                                                                    className='flex items-center'
                                                                >
                                                                    <PiDotOutlineFill size={18} />
                                                                    <span> {item?.title}</span>
                                                                </p>
                                                            </div>)
                                                    }
                                                </div>
                                            </div>
                                        </div>)
                                }
                            </div>
                            {
                                showModal2 && <ProcessModal
                                    // setFormData2={setFormData2}
                                    setList2={setList2}
                                    setShowModal2={setShowModal2}
                                    idPost={idPost}
                                    q={q}
                                    setQ={setQ}
                                />
                            }
                        </>}

                    </div>
                    {/* </div> */}

                </div>
            </div>
            <div className='flex gap-4 py-3 px-2 2xl:px-5 shadow-[10px_0px_20px_-15px_#000000]'>
                {/* دکمه تکمیل */}
                <button
                    onClick={() => {
                        setRefresh(true);
                        setShowModal(false)
                    }}
                    disabled={!idPost || edit ? true : false}
                    className='p-1 2xl:p-1.5 w-[130px] text-xs  text-center rounded-md bg-[#3C77A2] text-caspian-surface
                disabled:bg-caspian-gray4 disabled:text-caspian-gray2'>
                    تکمیل سوال
                </button>
                {/* <button
                    disabled={!idPost || edit ? true : false}
                    onClick={editHandle}
                    className='p-1.5 w-[130px] text-xs text-center rounded-md border border-[#3C77A2] text-[#3C77A2]
                 disabled:border-caspian-gray4 disabled:text-caspian-gray4'>
                    ویرایش سوال
                </button> */}
                <button onClick={() => idPost ? setShowAlert2(true) : setShowModal(false)} className='text-xs 2xl:text-xs text-caspian-error2'>
                    لغو
                </button>
                {
                    showAlert2 && <DeleteAlertBox
                        idPost={idPost}
                        handleDelete={handleDelete}
                        loadingAlert={loadingAlert}
                        setShowAlert={setShowAlert2}
                    />
                }
            </div>
        </div >
    );
}

export default StepTwo;
