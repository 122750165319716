import React, { useState } from "react";
import Modal from "../../../../modal/Modal";
import { PiX } from "react-icons/pi";
import MainSample from "./MainSample";
import MainMembers from "./MainMembers";
import ModalParent from "../../../../modal/ModalParent";

const MainCreateForm = ({ setShowCreateForm, setRefreshTeamsList }) => {
  const [title, setTitle] = useState("");
  const [riskTeamType, setRiskTeamType] = useState("identify");
  const [riskTeamSubjectType, setRiskTeamSubjectType] = useState("operational");
  const [description, setDescription] = useState("");
  const [aim, setAim] = useState("");
  const [msSelectedId, setMsSelectedId] = useState(null);

  const [showMemberList, setShowMemberList] = useState(false);

  return (
    <ModalParent>
      <div className="w-1/2 h-[400px] max-h-full rounded-md flex flex-col gap-0 fixed top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 bg-caspian-surface shadow-[0_0_10px_#00000050]">
        <div className="flex items-center justify-between w-full p-2">
          <span className="text-xs">ایجاد تیم جدید</span>
          <PiX
            onClick={() => setShowCreateForm(false)}
            className="cursor-pointer"
          />
        </div>
        {showMemberList ? (
          <MainMembers
            title={title}
            riskTeamType={riskTeamType}
            riskTeamSubjectType={riskTeamSubjectType}
            msSelectedId={msSelectedId}
            aim={aim}
            description={description}
            setShowMemberList={setShowMemberList}
            setShowCreateForm={setShowCreateForm}
            setRefreshTeamsList={setRefreshTeamsList}
          />
        ) : (
          <MainSample
            title={title}
            riskTeamType={riskTeamType}
            riskTeamSubjectType={riskTeamSubjectType}
            msSelectedId={msSelectedId}
            aim={aim}
            description={description}
            setTitle={setTitle}
            setRiskTeamType={setRiskTeamType}
            setRiskTeamSubjectType={setRiskTeamSubjectType}
            setMsSelectedId={setMsSelectedId}
            setAim={setAim}
            setDescription={setDescription}
            setShowMemberList={setShowMemberList}
            setShowCreateForm={setShowCreateForm}
          />
        )}
      </div>
    </ModalParent>
  );
};

export default MainCreateForm;
