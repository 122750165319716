import React, { useRef, useState } from 'react';
import Modal from "../../../modal/Modal";
import { PiX } from 'react-icons/pi';
const ListTag = ({
    setFinalSelected,
    finalSelected,
    selected,
    setSelected,
    setShowTag,
    showTag }) => {
    const ref = useRef(null);
    const [items, setItems] = useState([]);
    const [isTyping, setIsTyping] = useState(false);
    const [search, setSearch] = useState('');
    const [suggestion, setSuggestion] = useState([]);
    const [check, setCheck] = useState(false);

    const handleType = (e) => {
        let val = e.target.value;
        if (val.length < 2) {
            setIsTyping(false);
            return
        }
        if (e.key === 'Enter') {
            if (selected.some(item => item === val)) {
                setCheck(true);
                return
            }
            // if (!items.some(item => item === val)) {
            //     setItems([...items, e.target.value])
            // }
            setSelected([...selected, e.target.value])
            ref.current.value = '';
            setIsTyping(false);
            setCheck(false);
            return
        }
        let sug = items.filter(item => item.toString().toLocaleLowerCase().includes(val.toString().toLocaleLowerCase()));
        setSuggestion(sug);
        setIsTyping(true)
    }
    const clickSuggest = (item) => {
        ref.current.value = item;
        ref.current.focus();
        setIsTyping(false)
    }
    const rmv = (index) => {
        setSelected(selected => selected.filter((s, i) => i !== index))
    }
    const submitHandle = () => {
        // if (selected?.length > 0) {
            setFinalSelected(selected);
            setShowTag(false);
        // }
    }
    return (
        <Modal>
            <div className='bg-caspian-surface flex flex-col gap-4 rounded-md min-h-44 max-h-fit p-5 w-1/3 border border-caspian-gray2'>
                <button className='mr-auto' onClick={() => setShowTag(false)}>
                    <PiX className='text-lg text-caspian-gray4' />
                </button>
                <div className='flex flex-col gap-2'>
                    <p className=''>
                    {check && <span className='text-caspian-error2 text-xs'>تگ تکراری است.</span>}
                    </p>
                    <input
                        placeholder='تگ مورد نظر را بنویسید...'
                        ref={ref} type="text" className='border border-caspian-gray2 p-1.5 rounded-md w-full placeholder:text-xs' onKeyUp={(e) => handleType(e)} />
                    <div className='relative'>
                        <div className='flex h-fit max-h-20 overflow-y-auto flex-col gap-2 px-2 absolute bg-caspian-gray2/90 z-50 left-0 right-0 rounded-lg shadow-sm'>
                            {
                                isTyping && suggestion.map((item, index) => <span
                                    onClick={() => clickSuggest(item)}
                                    key={index}
                                    className=' text-caspian-gray4 rounded-md text-sm p-1.5'>
                                    {item}
                                </span>)
                            }
                        </div>
                    </div>
                </div>
                <div className='flex gap-2 flex-wrap'>
                    {
                        selected.length < 1 ? (<p className='text-xs text-caspian-gray4 w-full text-center'>تگی اضافه نشده</p>) :
                            selected.map((item, index) =>
                                <div
                                    key={index} className='flex items-center gap-1 w-fit bg-caspian-secondary-10 text-caspian-secondary-400 p-1.5 rounded-full'>
                                    <span className='text-xs xl:text-sm whitespace-nowrap'>{item}</span>
                                    <PiX className='text-xs cursor-pointer'
                                        onClick={() => rmv(index)} />
                                </div>)
                    }

                </div>
                <button
                    onClick={submitHandle}
                    className='bg-caspian-secondary-400 text-caspian-surface rounded-md py-1'>ثبت</button>
            </div>
        </Modal>
    );
}

export default ListTag;
