import React, { useState } from "react";
import RiskNavbar from "../RiskNavbar";
import MainListActivities from "./list-activities/MainListActivities";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { PiCaretLeft } from "react-icons/pi";
import NewRiskNavbar from "../NewRiskNavbar";

const MainActivites = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [refreshActivitiesList, setRefreshActivitiesList] = useState(0);
  return (
    <div className="flex flex-col gap-0">
      {/* <RiskNavbar buttonActive="activities" /> */}
      <NewRiskNavbar buttonActive={"activities"} />
      {params?.id ? (
        <div className="flex flex-col gap-0 w-full">
          <div className="flex items-center justify-between w-full">
            <button
              onClick={() => navigate(`.`)}
              className="mr-auto flex items-center gap-2 text-caspian-secondary-700 p-2"
            >
              <span className="text-sm">بازگشت</span>
              <PiCaretLeft />
            </button>
          </div>
          <Outlet context={[params?.id]} />
        </div>
      ) : (
        <MainListActivities
          refreshActivitiesList={refreshActivitiesList}
          setRefreshActivitiesList={setRefreshActivitiesList}
        />
      )}
    </div>
  );
};

export default MainActivites;
