import React from 'react';
import { PiX } from 'react-icons/pi';
import Modal from '../../../../../modal/Modal';

const NaModal = ({ setShowNa }) => {
    return (
        <Modal>
            <div className='w-[36%] h-[36%] bg-caspian-surface rounded-lg flex flex-col'>
                <div className='px-12 py-4 text-base flex justify-between items-center border-b border-caspian-darkgray-100'>
                    <span>N/A</span>
                    <PiX className='cursor-pointer' onClick={() => setShowNa(false)} />
                </div>
                <div className='px-12 py-2 flex flex-col gap-4'>
                <div className='flex flex-col gap-2'>
                    <span className='text-sm text-caspian-gray4'>توضیحات</span>
                    <textarea className='border border-[#E5E7EB] resize-none text-caspian-gray text-sm placeholder:text-caspian-gray placeholder:text-sm rounded-lg py-2 px-4'
                    placeholder='توضیحات خود را یادداشت کنید.'
                        cols="20" rows="20"></textarea>
                </div>
                <button className='text-caspian-surface bg-[#3C77A2] rounded-lg text-sm w-[100px] flex justify-center items-center py-2'>
                    <span>ثبت </span>
                </button>
                </div>
            </div>
        </Modal>
    );
}

export default NaModal;
