import React, { useEffect, useRef, useState } from "react";
import { PiCaretDownBold, PiMagnifyingGlassBold } from "react-icons/pi";

const ProcessFilter = ({
  setProcess,
  processSelected,
  setProcessSelected,
  showListProcess,
  setShowListProcess,
  processList,
  lable,
}) => {
  let menuRef = useRef();

  const [open, setOpen] = useState(false);
  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const [searchByProcess, setSearchByProcess] = useState("");

  let searchProcess = [];

  if (processList !== -1) {
    searchProcess = searchByProcess
      ? processList?.filter((d) =>
          d?.processProfileRelated?.activityProfileProcessRelated[0]?.processTitle
            .toLocaleLowerCase()
            .includes(searchByProcess.toLocaleLowerCase())
        )
      : processList;
  }

  return (
    <div
      ref={menuRef}
      className="flex flex-col gap-1.5 w-1/2 md:w-1/3 relative"
    >
      <label className="text-xs 2xl:text-sm text-caspian-gray4">{lable}</label>
      <div
        onClick={() => {
          setOpen(!open);
          // setShowListProcess(!showListProcess);
        }}
        className="text-xs outline outline-1 outline-caspian-gray1 p-2 rounded-md bg-caspian-transparent cursor-pointer flex items-center justify-between"
      >
        {processSelected && (
          <span className="text-xs">
            {processSelected?.processProfileRelated
              ?.activityProfileProcessRelated[0]?.processTitle.length > 20
              ? processSelected?.processProfileRelated?.activityProfileProcessRelated[0]?.processTitle.slice(
                  0,
                  20
                ) + "..."
              : processSelected?.processProfileRelated
                  ?.activityProfileProcessRelated[0]?.processTitle}
          </span>
        )}
        {processSelected.length < 1 && (
          <span className="text-caspian-gray1 text-xs">همه فرایندها</span>
        )}
        <PiCaretDownBold className="text-caspian-gray" />
      </div>
      {
        <div
          className={
            open
              ? "rounded-md shadow-[0_0_5px_#00000050] w-full flex flex-col gap-3 py-3 absolute left-0 right-0 top-[58px] bg-caspian-surface z-50"
              : "hidden"
          }
        >
          <div className="relative w-[92%] mx-auto">
            <input
              onChange={(e) => {
                setSearchByProcess(e.target.value);
              }}
              type="search"
              className="outline-none outline-caspian-gray1 p-1 text-xs rounded-md outline-1 w-full placeholder:text-xs placeholder:text-caspian-gray pr-6"
              placeholder="جستجو"
            />
            <button className="absolute top-1/2 -translate-y-1/2 right-1">
              <PiMagnifyingGlassBold className="text-caspian-gray text-" />
            </button>
          </div>
          <div className="flex flex-col gap-2 w-full max-h-[200px] overflow-y-scroll">
            <div
              onClick={() => {
                setProcessSelected([]);
                setOpen(false);
              }}
              className="flex items-center justify-start gap-2 text-xs hover:bg-caspian-secondary-100 py-1 px-2 w-full cursor-pointer duration-300"
            >
              <span>همه فرایندها</span>
            </div>
            {searchProcess?.length > 0 &&
              searchProcess.map((m, i) => (
                <div
                  key={i}
                  onClick={() => {
                    setProcessSelected(m);
                    setProcess(m?.id);
                    console.log(m?.id);
                    setOpen(false);
                    setSearchByProcess("");
                  }}
                  className="flex items-center justify-start gap-2 text-xs hover:bg-caspian-secondary-100 py-1 px-2 w-full cursor-pointer duration-300"
                >
                  <span className="">
                    {
                      m?.processProfileRelated?.activityProfileProcessRelated[0]
                        ?.processTitle
                    }
                  </span>
                </div>
              ))}
          </div>
        </div>
      }
    </div>
  );
};

export default ProcessFilter;
