import React, { useContext, useEffect, useState } from "react";
import Modal from "../../../modal/Modal";
import { PiX } from "react-icons/pi";
import { AuthContext } from "../../../../context/auth/AuthContext";
import API from "../../../../api/API";
import MembersList from "./MembersList";
import Profile from "./Profile";
import MainRiskTeamSubject from "./risk-team-subject/MainRiskTeamSubject";
import ListProcess from "./risk-team-subject/ListProcess";
import ListGoal from "./risk-team-subject/ListGoal";
import MainTeamActivity from "./team-activity/MainTeamActivity";
import ModalParent from "../../../modal/ModalParent";

const MainProfile = ({
  showProfile,
  setShowProfile,
  profileId,
  profileTitle,
  setRefreshTeamsList,
}) => {
  const { token } = useContext(AuthContext);
  const [showActivityTema, setShowActivityTema] = useState(true);
  const [memberId, setMemberId] = useState(null);
  const [profileData, setProfileData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refreshTeamProfile, setRefreshTeamProfile] = useState(0);
  const isSelectSubject =
    profileData?.statusType === "selectSubject" ? true : false;
  const isReady = profileData?.statusType === "ready" ? true : false;
  useEffect(() => {
    setRefreshTeamProfile(1);
  }, []);
  useEffect(() => {
    const getUser = async () => {
      if (!token || !profileId) return;
      if (refreshTeamProfile === 0) return;

      setLoading(true);
      try {
        const res = await API.get(`/api/v1/risk/team/detail/${profileId}/`, {
          headers: { Authorization: token },
        });
        console.log(res?.data);
        setProfileData(res?.data);
        setRefreshTeamProfile(0);
        setRefreshTeamsList(1);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    getUser();
  }, [token, profileId, refreshTeamProfile, setRefreshTeamsList]);

  console.log(memberId);
  return (
    <ModalParent>
      <div className="flex flex-col gap-0 min-w-[70vw] max-w-[70vw] xl:min-w-[60vw] xl:max-w-[60vw] max-h-[90vh] z-50 bg-caspian-surface rounded-md shadow-[0_0_5px_#00000050] overflow-hidden">
        <div className="flex items-center justify-between w-full p-2 border-b border-b-caspian-gray2">
          <span className="text-xs text-caspian-gray font-bold">
            {profileTitle}
          </span>
          <PiX
            onClick={() => setShowProfile(false)}
            className="cursor-pointer"
          />
        </div>
        {loading ? (
          <div className="flex items-center justify-center w-full h-full min-h-[90vh] max-h-[90vh]">
            <span className="text-caspian-gray4 text-xs">
              در حال بارگزاری اطلاعات...
            </span>
          </div>
        ) : (
          <div className="flex flex-col gap-0 w-full h-full">
            <Profile profileData={profileData} />
            <div className="flex items-start justify-stretch gap-2 w-full p-2">
              <MembersList
                isSelectSubject={isSelectSubject}
                allActivities={
                  profileData?.riskTeamSubjectType === "operational"
                    ? profileData?.teamSpecializedRiskOperationalSubject &&
                      profileData?.teamSpecializedRiskOperationalSubject[0]
                        ?.groupProcessRelatedRiskTeam?.length
                    : profileData?.teamSpecializedRiskStrategicSubject &&
                      profileData?.teamSpecializedRiskStrategicSubject[0]
                        ?.goalsRelatedRiskTeam?.length
                }
                memberId={memberId}
                setMemberId={setMemberId}
                userList={profileData?.members}
                setShowActivityTema={setShowActivityTema}
              />
              <div className="flex flex-col gap-0 w-[60%] h-full bg-caspian-secondary-50">
                <div className="bg-caspian-secondary-400 text-caspian-surface flex items-center justify-center w-full p-2 rounded-t-md text-xs">
                  {profileData?.riskTeamSubjectType === "operational" ? (
                    <span>لیست گروه فرایندی</span>
                  ) : (
                    <span>لیست اهداف کلان</span>
                  )}
                </div>
                <div className="max-h-[308px] 2xl:max-h-[408px] py-2 overflow-y-auto">
                  {!isSelectSubject && showActivityTema ? (
                    <MainTeamActivity
                      profileData={profileData}
                      isReady={isReady}
                    />
                  ) : (
                    ((profileData?.riskTeamSubjectType === "operational" &&
                      profileData?.teamSpecializedRiskOperationalSubject
                        ?.length) === 0 ||
                      (profileData?.riskTeamSubjectType === "strategic" &&
                        profileData?.teamSpecializedRiskStrategicSubject
                          ?.length === 0)) && (
                      <MainRiskTeamSubject
                        profileId={profileId}
                        riskTeamObject={profileData?.riskTeamSubjectType}
                        setRefreshTeamProfile={setRefreshTeamProfile}
                      />
                    )
                  )}
                  {memberId && (
                    <div className="">
                      {profileData?.riskTeamSubjectType === "operational" && (
                        <ListProcess
                          member={memberId}
                          processList={
                            profileData?.teamSpecializedRiskOperationalSubject
                          }
                        />
                      )}
                      {profileData?.riskTeamSubjectType === "strategic" && (
                        <ListGoal
                          goalList={
                            profileData?.teamSpecializedRiskStrategicSubject
                          }
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </ModalParent>
  );
};

export default MainProfile;
