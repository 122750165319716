import React from 'react'
import OverBudget from './OverBudget';
import RemainingBudget from './RemainingBudget';

const MainChartCostReport = ({ fullData }) => {
    // let cost = 500; //بودجه , totalCost
    // let costProgress = 300; //بودجه مصرف شده , usageProgress    
    // let totalCost = 800; // بودجه اضافی , cost

    return (
        <div className='flex flex-col items-center w-full '>


            {fullData?.costRelatedreportProfile?.map((use) => (
                <div
                    key={use?.id}
                    className='w-full'>
                    {((fullData?.taskRelated?.costProgress + use?.totalCost) > fullData?.taskRelated?.cost)
                        // {(costProgress + totalCost) > cost
                        ? (
                            // <OverBudget cost={cost} costProgress={costProgress} totalCost={totalCost} />

                            <OverBudget fullData={fullData} costProgress={fullData?.taskRelated?.costProgress} cost={fullData?.taskRelated?.cost} totalCost={use?.totalCost} />
                        )
                        : (

                            // <RemainingBudget cost={cost} costProgress={costProgress} totalCost={totalCost} />

                            <RemainingBudget costProgress={fullData?.taskRelated?.costProgress} cost={fullData?.taskRelated?.cost} totalCost={use?.totalCost} />
                        )
                    }
                </div>
            ))}




            {/*             
                  <div className='flex flex-col items-center w-full gap-2'>

                        بودجه  
                            <div className='flex items-center w-full gap-2'>
                                <div className='relative flex flex-col items-end basis-3/6'>
                                    <span className='text-xs text-caspian-on_primary/80'>{total?.totalCost} ریال </span>
                                    <div
                                        style={{
                                            width: `${(cost + use?.usageProgress > total?.totalCost)
                                                ? `${100 - ((((cost + use?.usageProgress) - total?.totalCost) / total?.totalCost) * 100)}%`
                                                : '100%'}`
                                        }}
                                        className={`h-1 rounded-lg bg-caspian-gray4`}>

                                    </div>

                                    <div
                                        style={{ left: `${100 - ((((cost + use?.usageProgress) - total?.totalCost) / total?.totalCost) * 100)}%` }}
                                        className={`border-l border-dashed border-l-caspian-gray h-16 absolute top-4
                                            `}>
                                    </div>

                                </div>
                                <div className='flex basis-2/6'>
                                    <span className='text-xs 2xl:text-sm'>بودجه</span>
                                </div>
                                <div className='flex basis-1/6'>
                                    <div className='w-3 h-3 rounded-sm bg-caspian-gray4'></div>
                                </div>
                            </div>

                            بودجه مصرف شده 
                            <div className='flex items-center w-full gap-2'>
                                <div className='flex flex-col items-end basis-3/6'>
                                    <div className='flex flex-col items-end'
                                        style={{
                                            width: `${Math.round(100 - ((((cost + use?.usageProgress) - total?.totalCost) / total?.totalCost) * 100))}%`
                                        }}>
                                        <span className='w-full text-xs text-center text-caspian-on_primary/80'>{use?.usageProgress} ریال</span>
                                        <div
                                            style={{
                                                width: `${(cost + use?.usageProgress > total?.totalCost)
                                                    && `${(use?.usageProgress / total?.totalCost) * 100}%`}`
                                            }}
                                            className={`h-1 rounded-lg bg-caspian-darkblue-700`}>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex basis-2/6'>
                                    <span className='text-xs 2xl:text-sm'>بودجه مصرف شده</span>
                                </div>
                                <div className='flex basis-1/6'>
                                    <div className={`w-3 h-3 rounded-sm bg-caspian-darkblue-700`}></div>
                                </div>
                            </div>

                            بودجه هزینه این مرحله 
                            <div className='flex items-center w-full gap-2'>
                                <div className='flex flex-col items-end w-full basis-3/6'>
                                    <div className='flex flex-col w-full'
                                        style={{
                                            width: `${Math.round(100 - ((((cost + use?.usageProgress) - total?.totalCost) / total?.totalCost) * 100))}%`,
                                            maxWidth: `${Math.round(100 - ((((cost + use?.usageProgress) - total?.totalCost) / total?.totalCost) * 100))}%`
                                        }}
                                    >
                                        <span className='w-full text-xs text-center text-caspian-on_primary/80'>
                                            {cost} ریال
                                        </span>

                                        <div className='flex justify-end w-full'>
                                            <div
                                                style={{
                                                    width: `${((((cost + use?.usageProgress) - total?.totalCost) / total?.totalCost) * 100)}%`,
                                                }}
                                                className={`h-1 rounded-lg bg-caspian-blue-400`}>

                                            </div>

                                            <div style={{ width: `${(use?.usageProgress / total?.totalCost) * 100}%` }}></div>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex basis-2/6'>
                                    <span className='text-xs 2xl:text-sm'>جمع هزینه این مرحله</span>
                                </div>
                                <div className='flex basis-1/6'>
                                    <div className={`w-3 h-3 rounded-sm bg-caspian-blue-400`}></div>
                                </div>
                            </div>

                           بودجه اضافی 
                            <div className='flex items-center w-full gap-2'>
                                <div className='flex flex-col items-start w-full basis-3/6'>

                                    <div
                                        style={{ width: `${(((cost + use?.usageProgress) - total?.totalCost) / total?.totalCost) * 100}%` }}
                                        className={` h-1 rounded-lg bg-caspian-error2`}>
                                    </div>

                                    <span className='w-full text-xs text-center text-caspian-on_primary/80'>
                                        {((cost + use?.usageProgress) - total?.totalCost)}
                                    </span>
                                </div>


                                <div className='flex basis-2/6'>
                                    <span className='text-xs 2xl:text-sm'>اضافه بودجه مصرفی</span>
                                </div>
                                <div className='flex basis-1/6'>
                                    <div className={`w-3 h-3 rounded-sm bg-caspian-error2`}></div>
                                </div>

                            </div>
                        </div>
*/}


        </div >
    )
}

export default MainChartCostReport