import React, { useRef, useEffect } from "react";
import * as d3 from "d3";


const StackedBarChart3 = ({ data, filter }) => {
    const svgRef = useRef();


    useEffect(() => {
        const width = 800;
        const height = 400;
        const margin = { top: 20, right: 30, bottom: 30, left: 40 };


        // تنظیم داده‌ها بر اساس ساختار جدید
        const clauseData = data[0]?.clause?.map(clause => {
            const irValue = data[0].ir.find(ir => ir.name === clause.name)?.value || 0;
            const crValue = data[0].cr.find(cr => cr.name === clause.name)?.value || 0;
            const carValue = data[0].car.find(car => car.name === clause.name)?.value || 0;
            return {
                ...clause,
                ir: irValue,
                cr: crValue,
                car: carValue
            };
        });


        // محاسبه مقدار حداکثری محور Y
        const maxYValue = d3.max(clauseData, d => d.ir + d.cr+ d.car);


        // مقیاس محور x و y
        const x = d3.scaleBand()
            .domain(clauseData.map(d => d.name))
            .range([margin.left, width - margin.right])
            .padding(0.1);


        const y = d3.scaleLinear()
            .domain([0, maxYValue])
            .nice()
            .range([height - margin.bottom, margin.top]);


        const color = d3.scaleOrdinal()
            .domain(["ir", "cr","car"])
            .range(["#1f77b4", "#ff7f0e","gray"]);


        // داده‌های انباشته‌شده با فیلتر
        const stackedData = d3.stack()
            .keys(filter)
            .value((d, key) => d[key] || 0)(clauseData);


        const svg = d3.select(svgRef.current)
            .attr("width", width)
            .attr("height", height);


        svg.selectAll("*").remove();


        // رسم محور x
        svg.append("g")
            .attr("transform", `translate(0,${height - margin.bottom})`)
            .call(d3.axisBottom(x));


        // رسم محور y
        svg.append("g")
            .attr("transform", `translate(${margin.left},0)`)
            .call(d3.axisLeft(y));


        // رسم بارهای انباشته
        svg.append("g")
            .selectAll("g")
            .data(stackedData)
            .enter()
            .append("g")
            .attr("fill", d => color(d.key))
            .selectAll("rect")
            .data(d => d)
            .enter()
            .append("rect")
            .attr("x", d => x(d.data.name))
            .attr("y", d => y(d[1]))
            .attr("height", d => y(d[0]) - y(d[1]))
            .attr("width", x.bandwidth());


        // اضافه کردن لگند
        const legend = svg.append("g")
            .attr("transform", `translate(${width - margin.right - 80},${margin.top})`);


        filter.forEach((key, i) => {
            legend.append("rect")
                .attr("x", 0)
                .attr("y", i * 20)
                .attr("width", 15)
                .attr("height", 15)
                .attr("fill", color(key));


            legend.append("text")
                .attr("x", 20)
                .attr("y", i * 20 + 12)
                .text(key)
                .style("font-size", "12px")
                .attr("alignment-baseline", "middle");
        });


    }, [data, filter]);


    return <svg ref={svgRef}></svg>;
};


export default StackedBarChart3;

