import { useNavigate } from "react-router-dom";

const SingleTableListReport = ({
  id,
  title,
  manager,
  manager1,
  manager2,
  date,
  status,
}) => {
  const navigate = useNavigate();
  const viewProfileReport = async () => {
    navigate(`/action-plan/reports/${id}`);
  };

  let statusName = "";
  let statusClass = "";

  if (status === "accepted") {
    statusClass = "#57EAB5";
    statusName = "قبول شده";
  } else if (status === "unread") {
    statusClass = "#C2C2C2";
    statusName = "خوانده نشده";
  } else if (status === "waiting") {
    statusClass = "#3C77A2";
    statusName = "درحال بررسی";
  }

  return (
    <tr className="bg-caspian-surface duration-300">
      <td
        onClick={viewProfileReport}
        className="whitespace-nowrap text-right border-b border-b-caspian-gray2 px-2 py-4 w-[25%] cursor-pointer">
        <h2 className="text-xs">
          {title?.map((item, index) =>
            <span key={index}>
              {item}
              {index !== title?.length - 1 && '  / '}
            </span>
          )}
        </h2>
      </td>
      <td
        onClick={viewProfileReport}
        className="whitespace-nowrap text-right border-b border-b-caspian-gray2 px-2 py-4 w-[15%] cursor-pointer">
        <h2 className="text-xs">{manager}</h2>
      </td>
      <td
        onClick={viewProfileReport}
        className="whitespace-nowrap text-right border-b border-b-caspian-gray2 px-2 py-4 w-[15%] cursor-pointer"
      >
        <h2 className="text-xs">{manager1}</h2>
      </td>
      <td
        onClick={viewProfileReport}
        className="whitespace-nowrap text-right border-b border-b-caspian-gray2 px-2 py-4 w-[15%] cursor-pointer"
      >
        <h2 className="text-xs">{manager2}</h2>
      </td>
      <td className="whitespace-nowrap text-right border-b border-b-caspian-gray2 w-[15%]">
        <div className="flex items-center justify-between gap-2 pl-2">
          <span style={{ color: `${statusClass}` }} className={`font-semibold text-xs`}>{statusName}</span>
        </div>
      </td>
      <td
        onClick={viewProfileReport}
        className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-2 py-4 w-[15%] cursor-pointer"
      >
        <h2 className="text-xs">{date}</h2>
      </td>
    </tr >
  );
};

export default SingleTableListReport;
