import React, { useContext, useEffect, useState } from 'react';
import API from '../../../../../../api/API';
import { PiPlusBold, PiX } from 'react-icons/pi';
import ListStandard from './ListStandard';
import ListClause from './ListClause';
import { AuthContext } from '../../../../../../context/auth/AuthContext';
import Modal from '../../../../../modal/Modal';

const StandardModal = ({ setShow, list, setList }) => {
    const { token } = useContext(AuthContext);
    const [standard, setStandard] = useState('');
    const [standardSelected, setStandardSelected] = useState(null);
    const [standardList, setStandardList] = useState([]);
    const [s, setS] = useState([]);

    const [c, setC] = useState([]);
    const [clause, setClause] = useState('');
    const [showClause, setShowClause] = useState(false);
    const [clauseSelected, setClauseSelected] = useState([]);
    const [finalSelected, setFinalSelected] = useState(clauseSelected);
    useEffect(() => {
        if (!token) return
        const fetchDataStandard = async () => {
            try {
                const res = await API.get('/api/v1/standard/', { headers: { Authorization: token } });
                setStandardList(res.data);
                setFinalSelected([])
                setClauseSelected([])
            }
            catch (err) {
                console.log(err.message);
            }
        }
        fetchDataStandard();
    }, [token, standard])

    const handleSubmit = () => {
        setList(list => [...list, { s, c }])
        setShow(false)
    }
    console.log(list);
    return (
        <Modal>
            <div className='bg-caspian-surface w-[30%] rounded-md'>
                <div className='flex justify-between items-center px-5 py-2'>
                    <span>افزودن استاندارد و بند مرتبط</span>
                    <PiX className='cursor-pointer' onClick={() => setShow(false)} />
                </div>
                <div className='flex flex-col gap-12 border-t border-caspian-gray2'>
                    <div className='flex flex-col gap-2 px-5 pt-3'>
                        {/* standard */}
                        <ListStandard
                            lable="استاندارد مرتبط"
                            standardList={standardList}
                            standard={standard}
                            setStandard={setStandard}
                            standardSelected={standardSelected}
                            setStandardSelected={setStandardSelected}
                            setS={setS}
                        />
                    </div>
                    <div className='px-5 pb-3'>
                        {/* clause */}
                        <div
                            onClick={() => setShowClause(true)}
                            className='cursor-pointer border border-[#3C77A2] rounded-md py-2 p-3 '>
                            <div className='flex justify-between items-center'>
                                <p className='text-[#3C77A2] flex items-center gap-2 text-sm'>
                                    <PiPlusBold />
                                    <span>انتخاب</span>
                                </p>
                                {finalSelected?.length > 0 ? (
                                    <span className="text-xs">
                                        {finalSelected?.length} بند افزوده شد
                                    </span>
                                ) : (
                                    <span className="text-xs">بندی انتخاب نشده</span>
                                )}
                            </div>
                            {showClause && (
                                <ListClause
                                    finalSelected={finalSelected}
                                    setFinalSelected={setFinalSelected}
                                    selected={clauseSelected}
                                    setSelected={setClauseSelected}
                                    setShowSection={setShowClause}
                                    showSection={showClause}
                                    url={`/api/v1/standard/clause/all/${standard}`}
                                    c={c}
                                    setC={setC}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className='flex gap-10 text-sm 2xl:text-xs px-5 pt-5 pb-2'>
                    {/* button */}
                    <button
                        // onClick={() => handleStandard(idPost)}
                        onClick={handleSubmit}
                        className={`${finalSelected.length < 1 || !standard ? 'bg-caspian-gray4 cursor-not-allowed pointer-events-none text-caspian-gray2' : 'bg-caspian-secondary-500 text-caspian-surface'}  p-1.5 px-3 rounded-md`}>تایید</button>
                    <button className='text-caspian-error2' onClick={() => setShow(false)}>لغو</button>
                </div>
            </div>
        </Modal>
    );
}

export default StandardModal;
