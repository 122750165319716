import React from 'react';

const FoundSingleRow = ({ id, code, type, question, description, requirement, request, setShowProfile }) => {
    return (
        <tr
            onClick={() => setShowProfile(id)}
            className='cursor-pointer h-[100px] text-xs whitespace-nowrap odd:bg-caspian-primary-50 bg-caspian-surface'>
            <td className='py-2 pt-3 w-[10%] text-center align-top text-[#0D358C]'>
                {/* <p className="max-w-[90%] mx-auto truncate text-center"> */}
                {code}
                {/* </p> */}
            </td>
            <td className='py-2 pt-3 w-[5%] text-center align-top text-[#0D358C]'>
                <div className={`${type === 'OK' ? 'text-[#1E7B33] bg-[#D6F5DD] border border-[#1E7B33]' :
                    type === 'OBS' ? 'text-[#638EC0] bg-[#E6EEF6] border border-[#638EC0]' :
                        type === 'NC' ? 'text-[#C38C15] bg-[#FFF5E1] border border-[#C38C15]' :
                            'text-[#686868] bg-[#F2F3F2] border border-[#686868]'
                    } pt-1 px-2 w-[40px] rounded-full text-center mx-auto`}>
                    {type}
                </div>
            </td>
            <td className='py-2 pt-3 text-center w-[10%] align-top'>
                {question?.code}
            </td>
            <td className='py-2  w-[30%] align-top' title={description}>
                <p className='line-clamp-4 text-justify leading-6 w-[100%] whitespace-normal '>
                    {description}
                </p>
            </td>
            <td className='py-2 pt-3 w-[20%] align-top'>
                <div className='flex flex-col items-center gap-2'>
                    {requirement?.map((r, i) => <div className='flex gap-1 w-[80%]' key={i}>
                        <p className='w-[100px] text-[#3C77A2] leading-7'>{r?.standard}</p>
                        <div className='flex items-center gap-1'>
                            {
                                r?.clause?.map((c, i) => <p>{c?.name}
                                    {i + 1 < r?.clause?.length && <span className='text-lg'> ، </span>}
                                </p>)
                            }
                        </div>
                    </div>)}
                </div>
            </td>
            <td className='py-2 pt-3 w-[10%] text-center align-top'>
                {request?.length < 1 ? <p className='text-[#FD5710] mx-auto'>درخواستی ثبت نشده</p>
                    : <div className='flex justify-center'>
                        {
                            request?.map((r, i) => <span>
                                <span className='text-[#3C77A2]'>{r?.name}</span>
                                {i + 1 < request?.length && <span> / </span>}
                            </span>)
                        }
                    </div>}
            </td>
        </tr>
    );
}

export default FoundSingleRow;
