import React, { useState } from "react";
import ListProcess from "./ListProcess";
import ListGoal from "./ListGoal";
import MainPlanning from "../../planning/MainPlanning";

const MainTeamActivity = ({ profileData, isReady }) => {
  console.log(profileData);
  const allTime = profileData?.dayNum;
  const remainedDays = profileData?.remained_days;
  const finaleTime = ((allTime - remainedDays) * 100) / allTime;
  const progressTeam = profileData?.progress?.toFixed(1);
  const [showPlanning, setShowPlanning] = useState(false);
  return (
    <div className="flex flex-col gap-4">
      {showPlanning && isReady && (
        <MainPlanning id={profileData?.id} setShowPlanning={setShowPlanning} />
      )}
      <div className="flex flex-col gap-4 pt-2">
        {/* پیشرفت زمان */}
        <div className="flex justify-between items-center gap-2 w-full px-2">
          <span className="text-xs text-caspian-gray4 basis-1/2 w-full text-right">
            پیشرفت زمانی
          </span>
          <div className="relative h-1 bg-caspian-surface rounded-full w-full basis-1/2 flex items-end justify-end">
            <span className="text-caspian-gray4 text-[10px] absolute right-0 top-1">
              {allTime} روز
            </span>
            <div
              style={{ width: `${finaleTime}%` }}
              className="h-1 bg-caspian-secondary-400 rounded-full relative"
            >
              <span className="text-caspian-secondary-700 text-[10px] absolute left-0 bottom-0">
                {allTime - remainedDays} روز
              </span>
            </div>
          </div>
        </div>
        {/* پیشرفت کل تیم */}
        <div className="flex justify-between items-center gap-2 w-full px-2">
          <span className="text-xs text-caspian-gray4 basis-1/2 w-full text-right">
            درصد پیشرفت کل تیم
          </span>
          <div className="relative h-1 bg-caspian-surface rounded-full w-full basis-1/2 flex items-end justify-end">
            <span className="text-caspian-gray4 text-[10px] absolute right-0 top-1">
              100%
            </span>
            <div
              style={{ width: `${progressTeam}%` }}
              className="h-1 bg-caspian-secondary-400 rounded-full relative"
            >
              <span className="text-caspian-secondary-700 text-[10px] absolute left-0 bottom-0">
                {progressTeam}%
              </span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="flex flex-col items-start gap-2 w-full px-2">
          <span className="text-xs text-caspian-gray4">مهلت</span>
          <div className="flex items-center gap-8">
            <span className="text-xs">
              شروع:{" "}
              {isReady
                ? "-"
                : new Date(profileData?.startDate).toLocaleDateString("fa-IR")}
            </span>
            <span className="text-xs">
              پایان:{" "}
              {isReady
                ? "-"
                : new Date(profileData?.endDate).toLocaleDateString("fa-IR")}
            </span>
          </div>
        </div>
      </div>
      <div>
        <div className="flex flex-col items-start gap-2 w-full px-2">
          <span className="text-xs text-caspian-gray4">وضعیت</span>
          <span
            onClick={() => setShowPlanning(true)}
            className={`text-caspian-secondary-500 text-xs ${
              isReady && "cursor-pointer"
            }`}
          >
            {profileData?.statusType === "selectSubject"
              ? "انتخاب سرفصل"
              : profileData?.statusType === "ready"
              ? "در انتظار برنامه ریزی"
              : profileData?.statusType === "planned"
              ? "در انتظار شروع"
              : profileData?.statusType === "started"
              ? "در حال اجرا"
              : profileData?.statusType === "done"
              ? "انجام شده"
              : profileData?.statusType === "completefinished"
              ? "تکمیل شده"
              : profileData?.statusType === "incompletefinished"
              ? "ناقص به پایان رسیده"
              : profileData?.statusType === "selectMember" && "انتخاب اعضا"}
          </span>
        </div>
      </div>
      <div className="w-full h-full">
        {profileData?.riskTeamSubjectType === "operational" && (
          <div className="flex flex-col items-start gap-2 w-full p-2">
            <span className="text-xs text-caspian-gray4">
              لیست گروه های فرایندی انتخاب شده
            </span>
            <ListProcess
              processList={profileData?.teamSpecializedRiskOperationalSubject}
            />
          </div>
        )}
        {profileData?.riskTeamSubjectType === "strategic" && (
          <div className="flex flex-col items-start gap-2 w-full p-2">
            <span className="text-xs text-caspian-gray4">
              لیست اهداف انتخاب شده
            </span>
            <ListGoal
              goalList={profileData?.teamSpecializedRiskStrategicSubject}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MainTeamActivity;
