// // TreemapChart.js
// import React, { useEffect, useRef } from 'react';
// import * as d3 from 'd3';


// // داده‌ی نمونه به صورت سلسله‌مراتبی
// const data = {
//     name: "root",
//     children: [
//         {
//             name: "واحد مالی",
//             children: [
//                 { name: "iso", value: 100,standard:'iso 9001' },
//                 { name: "iso", value: 50,standard:'iso 9001' },
//             ],
//         },
//         {
//             name: "واحد پشتیبانی",
//             children: [
//                 { name: "iso", value: 75,standard:'iso 9001' },
//                 { name: "iso", value: 125,standard:'iso 9001' },
//             ],
//         },
//     ],
// };


// const TreemapChart = () => {
//     const svgRef = useRef();


//     useEffect(() => {
//         const width = 800;
//         const height = 400;


//         const svg = d3.select(svgRef.current)
//             .attr("viewBox", `0 0 ${width} ${height}`)
//             .style("font", "10px sans-serif");


//         const root = d3.hierarchy(data)
//             .sum(d => d.value || 0)
//             .sort((a, b) => b.value - a.value);


//         // محاسبه‌ی مختصات و اندازه‌ی هر مستطیل در Treemap
//         d3.treemap()
//             .size([width, height])
//             .padding(1)
//             .round(true)(root);


//         const color = d3.scaleOrdinal(d3.schemeCategory10);


//         const cell = svg.selectAll("g")
//             .data(root.leaves())
//             .join("g")
//             .attr("transform", d => `translate(${d.x0},${d.y0})`);


//         cell.append("rect")
//             .attr("id", d => d.data.name)
//             .attr("width", d => d.x1 - d.x0)
//             .attr("height", d => d.y1 - d.y0)
//             .attr("fill", d => color(d.parent.data.name))
//             .attr("fill-opacity", 0.6)
//             .attr("stroke", "#fff");


//         cell.append("text")
//             .selectAll("tspan")
//             .data(d => [d.data.name, `Value:${d.value}`,d.data.standard])
//             .join("tspan")
//             .attr("x", 4)
//             .attr("dy", (d, i) => 10 + i * 10)
//             .attr("font-size","12px")
//             .attr("margin","5px")
//             .text(d => d);
//     }, []);


//     return (
//         <svg ref={svgRef} width="100%" height="400px"></svg>
//     );
// };


// export default TreemapChart;




//این فعلا درستتر از بقیه اس
// import { useRef, useEffect } from 'react';
// import * as d3 from 'd3';

// export default function Treemap({ data, width, height }) {
//     const svgRef = useRef(null);
//     const legendRef = useRef(null);

//     function renderTreemap() {
//         const svg = d3.select(svgRef.current);
//         svg.selectAll('g').remove();

//         const legendContainer = d3.select(legendRef.current);
//         legendContainer.selectAll('g').remove();

//         svg.attr('width', width).attr('height', height);

//         // create root node
//         const root = d3
//             .hierarchy(data)
//             .sum((d) => d.value)
//             .sort((a, b) => b.value - a.value);

//         // create treemap layout
//         const treemapRoot = d3.treemap().size([width, height]).padding(1)(root);

//         // create 'g' element nodes based on data
//         const nodes = svg
//             .selectAll('g')
//             .data(treemapRoot.leaves())
//             .join('g')
//             .attr('transform', (d) => `translate(${d.x0},${d.y0})`);

//         // create color scheme and fader
//         const fader = (color) => d3.interpolateRgb(color, '#fff')(0.3);
//         const colorScale = d3.scaleOrdinal(d3.schemeCategory10.map(fader));

//         // add treemap rects
//         nodes
//             .append('rect')
//             .attr('width', (d) => d.x1 - d.x0)
//             .attr('height', (d) => d.y1 - d.y0)
//             .text((d) => `${d.data.category}`)
//             .attr('fill', (d) => colorScale(d.data.category));

//         const fontSize = 12;

//         // // add text to rects
//         // nodes
//         //     .append('text')
//         //     .text((d) => `${d.data.name}  n:${d.data.value} nc:${d.data.nc} obs:${d.data.obs}`)
//         //     .attr('data-width', (d) => d.x1 - d.x0)
//         //     .attr('font-size', `${fontSize}px`)
//         //     .attr('x', 3)
//         //     .attr('y', fontSize)
//         //     .call(wrapText);


//         // function wrapText(selection) {
//         //     selection.each(function () {
//         //         const node = d3.select(this);
//         //         const rectWidth = +node.attr('data-width');
//         //         let word;
//         //         const words = node.text().split(' ').reverse();
//         //         let line = [];
//         //         let lineNumber = 0;
//         //         const x = node.attr('x');
//         //         const y = node.attr('y');
//         //         let tspan = node.text('').append('tspan').attr('x', x).attr('y', y);
//         //         while (words.length > 1) {
//         //             word = words.pop();
//         //             line.push(word);
//         //             tspan.text(line.join(' '));
//         //             const tspanLength = tspan.node().getComputedTextLength();
//         //             if (tspanLength > rectWidth && line.length !== 1) {
//         //                 line.pop();
//         //                 tspan.text(line.join(' '));
//         //                 line = [word];
//         //                 tspan = addTspan(word);
//         //             }
//         //         }
//         //         addTspan(words.pop());

//         //         function addTspan(text) {
//         //             lineNumber += 1;
//         //             return node
//         //                 .append('tspan')
//         //                 .attr('x', x)
//         //                 .attr('y', y)
//         //                 .attr('dy', `${lineNumber * fontSize}px`)
//         //                 .text(text);
//         //         }
//         //     });
//         // }

//         // نمایش چند خط اطلاعات در هر سلول
//         nodes
//             .append("text")
//             .attr("x", 5)
//             .attr("y", 5)
//             .style("font-size", "12px")
//             .style("pointer-events", "none")
//             .selectAll("tspan")
//             .data(d => [
//                 `${d.data.name}`,
//                 `resualt:${d.data.value}`,
//                 `nc: ${d.data.nc}`,
//                 `obs: ${d.data.obs}`
//             ])
//             .enter()
//             .append("tspan")
//             .attr("x", 2)
//             .attr("dy", "12px")
//             .text(d => d);


//         // pull out hierarchy categories
//         let categories = root.leaves().map((node) => node.data.category);
//         categories = categories.filter(
//             (category, index, self) => self.indexOf(category) === index,
//         );

//         legendContainer.attr('width', width).attr('height', height / 4);

//         // create 'g' elements based on categories
//         const legend = legendContainer.selectAll('g').data(categories).join('g');

//         // create 'rects' for each category
//         legend
//             .append('rect')
//             .attr('width', fontSize)
//             .attr('height', fontSize)
//             .attr('x', fontSize)
//             .attr('y', (_, i) => fontSize * 2 * i)
//             .attr('fill', (d) => colorScale(d));

//         // add text to each category key
//         legend
//             .append('text')
//             .attr('transform', `translate(0, ${fontSize})`)
//             .attr('x', fontSize * 3)
//             .attr('y', (_, i) => fontSize * 2 * i)
//             .style('font-size', fontSize)
//             .text((d) => d);
//     }

//     useEffect(() => {
//         renderTreemap();
//     }, [data]);

//     return (
//         <div style={{overflowY:'auto'}}>
//             <svg ref={svgRef} />
//             <svg ref={legendRef} />
//         </div>
//     );
// }


// این هم خوبه
// import React, { useRef, useEffect } from 'react';
// import * as d3 from 'd3';


// const TreeMapChart = ({ data, colors }) => {
//     const svgRef = useRef();


//     useEffect(() => {
//         const width = 600;
//         const height = 400;


//         // پاک کردن محتوای قبلی SVG
//         d3.select(svgRef.current).selectAll('*').remove();


//         const svg = d3
//             .select(svgRef.current)
//             .attr('width', width)
//             .attr('height', height)
//             .style('font-family', 'Arial');


//         // ساختاردهی داده‌ها به فرم hierachy
//         const root = d3
//             .hierarchy(data)
//             .sum(d => d.value)
//             .sort((a, b) => b.value - a.value);


//         // استفاده از treemap و تنظیم اندازه و فاصله‌ها
//         d3.treemap()
//             .size([width, height])
//             .paddingOuter(10) // فاصله بین بلوک‌های اصلی
//             .paddingInner(3)(root);


//         // مقیاس رنگ برای هر واحد
//         const colorScale = d3.scaleOrdinal()
//             .domain(root.children.map(d => d.data.name))
//             .range(colors);


//         // گروه‌بندی کردن برای نمایش هر واحد به عنوان یک بلوک جدا
//         const groups = svg
//             .selectAll('g')
//             .data(root.children)
//             .enter()
//             .append('g')
//             .attr('transform', d => `translate(${d.x0},${d.y0})`);


//         // رسم مستطیل‌های اصلی برای هر واحد
//         groups
//             .append('rect')
//             .attr('width', d => d.x1 - d.x0)
//             .attr('height', d => d.y1 - d.y0)
//             .attr('fill', (d, i) => colorScale(d.data.name))
//             .attr('stroke', '#ffffff');


//         // رسم مستطیل‌ها برای هر گره داخل هر واحد
//         groups
//             .selectAll('rect')
//             .data(d => d.leaves())
//             .enter()
//             .append('rect')
//             .attr('x', d => d.x0 - d.parent.x0) // تنظیم موقعیت داخلی هر بلوک
//             .attr('y', d => d.y0 - d.parent.y0)
//             .attr('width', d => d.x1 - d.x0)
//             .attr('height', d => d.y1 - d.y0)
//             .attr('fill', d => colorScale(d.parent.data.name)) // رنگ براساس بلوک اصلی
//             .attr('stroke', '#ffffff')
//             .style('opacity', 0.8);


//         // افزودن نام و مقدار هر آیتم داخل مستطیل‌ها
//         groups
//             .selectAll('text')
//             .data(d => d.leaves())
//             .enter()
//             .append('text')
//             .attr('x', d => d.x0 - d.parent.x0 + 5)
//             .attr('y', d => d.y0 - d.parent.y0 + 20)
//             .text(d => `${d.data.name} (${d.data.value})`)
//             .attr('font-size', '12px')
//             .attr('fill', '#000000');


//         // افزودن نام اصلی هر واحد
//         groups
//             .append('text')
//             .attr('x', 5)
//             .attr('y', 15)
//             .text(d => d.data.name)
//             .attr('font-size', '14px')
//             .attr('font-weight', 'bold')
//             .attr('fill', '#000000');

//     }, [data, colors]);


//     return <svg ref={svgRef}></svg>;
// };
// //۳
// export default TreeMapChart;


//این اصلاح شده بالایی که رنگ داخل دیتا قرار داره
// import React, { useRef, useEffect } from 'react';
// import * as d3 from 'd3';


// const TreeMapChart = ({ data }) => {
//     const svgRef = useRef();


//     useEffect(() => {
//         const width = 600;
//         const height = 400;


//         // پاک کردن محتوای قبلی SVG
//         d3.select(svgRef.current).selectAll('*').remove();


//         const svg = d3
//             .select(svgRef.current)
//             .attr('width', width)
//             .attr('height', height)
//             .style('font-family', 'Arial');


//         // ساختاردهی داده‌ها به فرم hierarchy
//         const root = d3
//             .hierarchy(data)
//             .sum(d => d.value)
//             .sort((a, b) => b.value - a.value);


//         // تنظیم treemap با فاصله‌های داخلی و خارجی
//         d3.treemap()
//             .size([width, height])
//             .paddingOuter(10) // فاصله بین بلوک‌های اصلی
//             .paddingInner(3)(root);


//         // گروه‌بندی هر واحد اصلی
//         const groups = svg
//             .selectAll('g')
//             .data(root.children)
//             .enter()
//             .append('g')
//             .attr('transform', d => `translate(${d.x0},${d.y0})`);


//         // رسم مستطیل‌های اصلی برای هر واحد
//         groups
//             .append('rect')
//             .attr('width', d => d.x1 - d.x0)
//             .attr('height', d => d.y1 - d.y0)
//             .attr('fill', d => d.data.colors) // رنگ از داده‌ها خوانده می‌شود
//             .attr('stroke', '#ffffff');


//         // رسم مستطیل‌های داخلی برای هر آیتم در هر واحد
//         groups
//             .selectAll('rect')
//             .data(d => d.children)
//             .enter()
//             .append('rect')
//             .attr('x', d => d.x0 - d.parent.x0)
//             .attr('y', d => d.y0 - d.parent.y0)
//             .attr('width', d => d.x1 - d.x0)
//             .attr('height', d => d.y1 - d.y0)
//             .attr('fill', d => d.parent.data.colors) // رنگ داخلی بر اساس رنگ واحد اصلی
//             .attr('stroke', '#ffffff')
//             .style('opacity', 0.8);


//         // افزودن نام و مقدار هر آیتم داخل مستطیل‌ها
//         groups
//             .selectAll('text')
//             .data(d => d.children)
//             .enter()
//             .append('text')
//             .attr('x', d => d.x0 - d.parent.x0 + 5)
//             .attr('y', d => d.y0 - d.parent.y0 + 20)
//             .text(d => `${d.data.name} (${d.data.value})`)
//             .attr('font-size', '12px')
//             .attr('fill', '#000000');


//         // افزودن نام هر واحد اصلی
//         groups
//             .append('text')
//             .attr('x', 5)
//             .attr('y', 15)
//             .text(d => d.data.name)
//             .attr('font-size', '14px')
//             .attr('font-weight', 'bold')
//             .attr('fill', '#000000');


//     }, [data]);


//     return <svg ref={svgRef}></svg>;
// };


// export default TreeMapChart;



import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';


const TreeMapChart = ({ data }) => {
    const svgRef = useRef();


    useEffect(() => {
        const width = 800;
        const height = 650;


        // پاک کردن محتوای قبلی SVG
        d3.select(svgRef.current).selectAll('*').remove();


        const svg = d3
            .select(svgRef.current)
            .attr('width', width)
            .attr('height', height)
            .style('font-family', 'Arial');


        // ساختاردهی داده‌ها به فرم hierarchy
        const root = d3
            .hierarchy(data)
            .sum(d => d.value)
            .sort((a, b) => b.value - a.value);


        // تنظیم treemap با فاصله‌های داخلی و خارجی
        d3.treemap()
            .size([width, height])
            .paddingOuter(10) // فاصله بین بلوک‌های اصلی
            .paddingInner(3)(root);


        // گروه‌بندی هر واحد اصلی
        const groups = svg
            .selectAll('g')
            .data(root.children)
            .enter()
            .append('g')
            .attr('transform', d => `translate(${d.x0},${d.y0})`);


        // رسم مستطیل‌های اصلی برای هر واحد
        groups
            .append('rect')
            .attr('width', d => d.x1 - d.x0)
            .attr('height', d => d.y1 - d.y0)
            .attr('fill', d => d.data.colors) // رنگ از داده‌ها خوانده می‌شود
            .attr('stroke', '#ffffff');


        // افزودن نام هر واحد اصلی با فاصله بیشتر از آیتم‌ها
        groups
            .append('text')
            .attr('x', 5)
            .attr('y', 12)  // موقعیت y کمی بالاتر تنظیم شده
            .text(d => d.data.name)
            .attr('font-size', '13px')
            .attr('font-weight', 'bold')
            .attr('fill', '#000000');


        // رسم مستطیل‌های داخلی برای هر آیتم در هر واحد با فاصله
        groups
            .selectAll('g')
            .data(d => d.children)
            .enter()
            .append('g')
            .attr('transform', d => `translate(${d.x0 - d.parent.x0},${d.y0 - d.parent.y0 + 8})`) // فاصله عمودی 25px اضافه شده
            .each(function (d) {
                // رسم مستطیل داخلی
                d3.select(this)
                    .append('rect')
                    .attr('width', d.x1 - d.x0)
                    .attr('height', d.y1 - d.y0)
                    .attr('fill', d.parent.data.colors)
                    .attr('stroke', '#ffffff')
                    .style('opacity', 0.8);


                // افزودن متن شامل نام، مقدار، nc و obs
                d3.select(this)
                    .append('text')
                    .selectAll('tspan')
                    .data([
                        `${d.data.name}`,  // نام و مقدار
                        `(${d.data.value})`,
                        `nc: ${d.data.nc}`,                  // مقدار nc
                        `obs: ${d.data.obs}`,                // مقدار obs
                    ])
                    .enter()
                    .append('tspan')
                    .attr('x', 5)
                    .attr('y', (datum, i) => 15 + i * 15) // فاصله بین خطوط
                    .text(datum => datum)
                    .attr('font-size', '12px')
                    .attr('fill', '#000000');
            });


    }, [data]);


    return <svg ref={svgRef}></svg>;
};
//۴
export default TreeMapChart;




