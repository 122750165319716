import React from 'react';
import { PiNotePencil } from 'react-icons/pi';

const ObservationSingleRow = ({ id, standard, clause, question, criteria, status, score }) => {
    return (
        <tr className='cursor-pointer h-[100px] text-xs whitespace-nowrap odd:bg-caspian-primary-50 bg-caspian-surface'>
            <td className='py-2 w-[20%] text-center text-[#0D358C]'>
                {/* <p className="max-w-[90%] mx-auto truncate text-center"> */}
                {standard}
                {/* </p> */}
            </td>
            <td className='py-2 w-[20%] text-center text-[#0D358C]'>
                {/* <p className="max-w-[90%] mx-auto truncate text-center"> */}
                {clause}
                {/* </p> */}
            </td>
            <td className='py-2 w-[30%]'>
                {/* <div className=''> */}
                <div className='mb-1.5 line-clamp-2 w-[100%] whitespace-normal'>
                    موضوع: {question}
                </div>
                <p className='text-[#3C77A2] line-clamp-2 w-[100%] whitespace-normal '>معیار: {criteria}</p>
                {/* </div> */}
            </td>
            <td className='py-2 w-[10%]'>
                <div className={`${status === 'OK' ? 'text-[#1E7B33] bg-[#D6F5DD] border border-[#1E7B33]' :
                    status === 'OBS' ? 'text-[#638EC0] bg-[#E6EEF6] border border-[#638EC0]' :
                        status === 'NC' ? 'text-[#C38C15] bg-[#FFF5E1] border border-[#C38C15]' :
                            'text-[#686868] bg-[#F2F3F2] border border-[#686868]'
                    } pt-1 px-2 w-[40px] rounded-full text-center mx-auto`}>
                    {status}
                </div>
            </td>
            <td className='py-2 w-[8%] text-center'>
                {score || '-'}
            </td>
            <td className='py-2 w-[5%] text-center'>
                <PiNotePencil className='text-lg text-[#3C77A2]' />
            </td>
        </tr>
    );
}

export default ObservationSingleRow;
