import React, { useEffect } from 'react';
import { PiPlusBold } from 'react-icons/pi';
import ListAuditorTeam from './forms/ListAuditorTeam';
import ListAuditorHead from './forms/ListAuditorHead';

const AuditTeamComponent = ({ showAuditor, setShowAuditor, auditorSelected, setAuditorSelected,
    auditorFinalSelected, setAuditorFinalSelected, head, setHead, headList, setHeadList, headSelected, setHeadSelected
}) => {
    useEffect(()=>{
        if(auditorFinalSelected?.length>0){
            setHead('')
        }
    },[auditorFinalSelected,setHead])
    return (
        <div className='flex flex-col gap-4'>
            {/* label */}
            <div className='flex gap-8 items-baseline '>
                <span className='whitespace-nowrap text-[#3C77A2] text-base xl:text-lg'>تیم ممیزی</span>
                <div className='w-full border-t-2 border-caspian-gray2 h-1 relative'>
                    <span className='w-3 h-3 bg-caspian-gray1 rounded-full absolute right-0 -top-2'></span>
                </div>
            </div>
            {/* data entry */}
            <div className='flex items-center gap-4'>
                {/* team auditor */}
                <div className='flex flex-col gap-2 basis-1/2'>
                    <span className='text-xs text-caspian-gray4'>انتخاب افراد تیم ممیزی</span>
                    <div
                        onClick={() => setShowAuditor(true)}
                        className='flex justify-between items-center rounded-lg border border-[#3C77A2] cursor-pointer p-1'>
                        <p className='text-[#3C77A2] flex justify-center items-center gap-1'>
                            <PiPlusBold />
                            <span className=' text-xs 2xl:text-sm'>انتخاب </span>
                        </p>
                        <p>
                            {
                                auditorFinalSelected?.length < 1 ? <span className='text-xs text-caspian-gray4'>موردی افزوده نشده</span>
                                    : <span className='text-xs text-caspian-gray'>{auditorFinalSelected?.length} مورد افزوده شد</span>
                            }
                        </p>
                    </div>
                    {showAuditor && (
                        <ListAuditorTeam
                            finalSelected={auditorFinalSelected}
                            setFinalSelected={setAuditorFinalSelected}
                            selected={auditorSelected}
                            setSelected={setAuditorSelected}
                            setShowSection={setShowAuditor}
                            showSection={showAuditor}
                            title="لیست تیم ممیزی"
                            url={`/api/v1/audit/auditor/`}
                            setHeadList={setHeadList}
                            headList={headList}
                        />
                    )}
                </div>
                {/* auditor head */}
                <div className='flex flex-col gap-2 basis-1/2'>
                    <ListAuditorHead
                        type={head}
                        setType={setHead}
                        typeList={headList}
                        typeSelected={headSelected}
                        setTypeSelected={setHeadSelected}
                        lable='انتخاب سرممیز'
                    />
                </div>
            </div>
        </div>
    );
}

export default AuditTeamComponent;
