import React from 'react'
import { PiCalendar } from 'react-icons/pi'

const MainInfoPlanAudit = () => {
  return (
    <div className='w-[60%] 2xl:w-[30%] flex flex-col gap-4'>
      <span className='text-caspian-gray4 text-sm xl:text-base'>اطلاعات برنامه ممیزی</span>
      <div className='flex flex-col gap-2 bg-caspian-surface shadow-[0px_4px_4px_0px_#00000040] rounded-xl'>
        <div className="px-2 py-4 text-xs xl:text-sm border-b border-caspian-gray2">
          <span>مدیریت بحران و پدافند غیرعامل</span>
        </div>
        <div className="px-2 py-4 flex justify-between items-center">
          <div className='border border-caspian-gray1 p-1 flex gap-2 items-center rounded-lg text-xs xl:text-sm text-caspian-gray4'>
            <PiCalendar className='text-[#45706E] text-base xl:text-lg' />
            <span>دوشنبه </span>
            <span>1403/05/22</span>
          </div>
          <div className='flex gap-2'>
            <div className='border border-caspian-gray1 p-1 flex gap-2 items-center rounded-lg text-xs xl:text-sm text-caspian-gray4'>
              <div className='w-1.5 h-1.5 bg-[#10B981] rounded-full'></div>
              <span>شروع </span>
              <span>9:00</span>
            </div>
            <div className='border border-caspian-gray1 p-1 flex gap-2 items-center rounded-lg text-xs xl:text-sm text-caspian-gray4'>
              <div className='w-1.5 h-1.5 bg-caspian-error2 rounded-full'></div>
              <span>پایان </span>
              <span>10:00</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainInfoPlanAudit