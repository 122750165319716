import React, { useRef } from "react";

const SingleTeamMembers = ({ setIsAbsent,id ,name, job, setIsPresent, isAbsent, isPresent,index }) => {
  const checkedRef = useRef();
  // console.log(isPresent);
  return (
    <div className="flex items-center justify-between w-full border border-caspian-gray2 rounded-md p-2">
      <span className="text-xs w-3/4">{name}</span>
      <span className="text-xs text-caspian-secondary-500 w-1/4">{job}</span>
      <div className="flex items-center gap-2">
        <label
          className={`cursor-pointer flex items-center gap-1 text-xs ${checkedRef?.current?.checked ? "text-[#389b82]" : "text-[#db3d5f]"
            }`}
        >
          <input
            ref={checkedRef}
            type="checkbox"
            defaultChecked={isPresent[index]===id}
            onChange={async (e) => {
              (await e?.target?.checked)
                ? setIsPresent([...isPresent, id])
                :setIsPresent(isPresent.filter((d) => d !== id));
            }}
            className={`cursor-pointer accent-[#389b82]`}
          />
          {checkedRef?.current?.checked ? "حاضر" : "غایب"}
        </label>
        {/* <input type="checkbox" onChange={()=>setIsPresent(!isPresent)} />
        <label className="text-[#01BC8D] text-xs">حاضر</label>
      </div>
      <div className="flex items-center gap-2">
        <input type="checkbox" onChange={()=>setIsAbsent(!isAbsent)} />
        <label className="text-[#FF5B5B] text-xs">غایب</label> */}
      </div>
    </div>
  );
};

export default SingleTeamMembers;
