import React, { useState } from "react";
import { PiPlusCircleBold } from "react-icons/pi";
import FormRiskSubject from "./FormRiskSubject";

const MainRiskTeamSubject = ({
  riskTeamObject,
  profileId,
  setRefreshTeamProfile,
}) => {
  const [showFormRiskSubject, setShowFormRiskSubject] = useState(false);
  return (
    <div className="p-2 pt-0 h-[292px] 2xl:h-[392px] flex items-center justify-center">
      <button
        // onClick={() => setShowListRiskSubject(true)}
        onClick={() => setShowFormRiskSubject(true)}
        className="rounded-md bg-caspian-secondary-400 text-caspian-surface p-2 flex items-center gap-1"
      >
        <PiPlusCircleBold className="text-xl" />
        <span className="text-xs">
          {riskTeamObject === "operational"
            ? "انتخاب گروه فرایندی"
            : "انتخاب اهداف کلان"}
        </span>
      </button>
      {showFormRiskSubject && (
        <FormRiskSubject
          setShowFormRiskSubject={setShowFormRiskSubject}
          riskTeamObject={riskTeamObject}
          profileId={profileId}
          setRefreshTeamProfile={setRefreshTeamProfile}
        />
      )}
    </div>
  );
};

export default MainRiskTeamSubject;
