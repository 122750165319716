import React, { useContext, useEffect, useState } from "react";
import MainAddressAndTime from "./address-time/MainAddressAndTime";
import { useLocation, useOutletContext, useParams } from "react-router-dom";
import { AuthContext } from "../../../../context/auth/AuthContext";
import API from "../../../../api/API";
import MainQuestionList from "./question-list/MainQuestionList";

const MainAuditChecklistProfile = () => {
  const [isAuditer, isChecklist] = useOutletContext();
  const location = useLocation();
  const { token } = useContext(AuthContext);
  const { idProfile, idSection } = useParams();
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [profileData, setProfileData] = useState([-1]);

  useEffect(() => {
    if (!token || !idSection || !idProfile) return;
    const getProfileData = async () => {
      try {
        const res = await API.get(
          `/api/v1/audit/profile/schedule/time/${idProfile}/`,
          {
            headers: { Authorization: token },
          }
        );
        console.log(res?.data);
        if (+idProfile === +res.data.auditProfileRelated) {
          setProfileData(res?.data);
        } else {
          setProfileData([]);
        }
      } catch (error) {
        setProfileData([]);
        console.log(error);
      }
    };
    getProfileData();
  }, [token, idSection, idProfile]);

  return (
    <div className="w-full flex flex-col gap-2 lg:w-[80%] 2xl:w-[70%]">
      <div className="bg-caspian-surface rounded-md shadow-[0_0_10px_#00000020] flex flex-col gap-0 w-full">
        <MainAddressAndTime
          idSection={idSection}
          idProfile={idProfile}
          data={profileData}
          title={location?.state}
          isAuditer={isAuditer}
          isChecklist={isChecklist}
          setShowDeletePopup={setShowDeletePopup}
        />
        <MainQuestionList />
      </div>
    </div>
  );
};

export default MainAuditChecklistProfile;
