import React, { useContext, useEffect, useState } from "react";
import ActionPlanNavbar from "../../../ActionPlanNavbar";
import MainWorkProgressReport from "./work-progress-report/MainWorkProgressReport";
import MainCostReport from "./cost-report/MainCostReport";
import MainMaterialReport from "./material-report/MainMaterialReport";
import { PiCaretLeft, PiChatsCircle, PiCoinsLight, PiScrollLight, PiShoppingCart } from "react-icons/pi";
import { PiChartLineLight } from "react-icons/pi";
import API from "../../../../../api/API";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { AuthContext } from "../../../../../context/auth/AuthContext";
import CommentsMain from "../../../comments/CommentsMain";
import { toast } from "react-toastify";

const MainProfileReportActionPaln = () => {
  const { token, user } = useContext(AuthContext)
  const pmo = useOutletContext();
  const params = useParams();
  const navigate = useNavigate()

  const [valueForm, setValueForm] = useState("");
  const [fullData, setFullData] = useState([])
  const [showComments, setShowComments] = useState(false)
  const [isAccessComment, setIsAccessComment] = useState(false)
  const [accessRead, setAccessRead] = useState(false)
  const [alarm, setAlarm] = useState(false)
  const [refresh, setRefresh] = useState(0)


  useEffect(() => {
    setRefresh(1)
  }, [setRefresh])

  useEffect(() => {
    if (!token || refresh !== 1) return
    const handleData = async () => {
      try {
        const res = await API.get(`/api/v1/actionplan/taskreport/${params?.id}`,
          { headers: { Authorization: token } })
        setFullData(res?.data)
        console.log(res?.data)
        setRefresh(0)
      } catch (error) {
        console.log(error)
      }
    }
    handleData()
  }, [params?.id, token, refresh])


  useEffect(() => {
    if (fullData?.has_comment) {
      setAlarm(true)
    }
    else {
      setAlarm(false)
    }
  }, [fullData?.has_comment])

  useEffect(() => {
    setIsAccessComment(
      (user?.placementProfileRelated?.personalInfoProfileRelated[0]?.id === fullData?.manager?.id)
      || (user?.id === pmo)
      || (user?.placementProfileRelated?.personalInfoProfileRelated[0]?.id === fullData?.taskRelated?.responsible_task?.personalInfoProfileRelated[0]?.id)
      || (user?.placementProfileRelated?.personalInfoProfileRelated[0]?.id === fullData?.taskRelated?.supervisor?.personalInfoProfileRelated[0]?.id)
      || (user?.placementProfileRelated?.personalInfoProfileRelated[0]?.id === fullData?.controller?.personalInfoProfileRelated[0]?.id)
      || (user?.placementProfileRelated?.personalInfoProfileRelated[0]?.id === fullData?.varify?.personalInfoProfileRelated[0]?.id)
      || (user?.placementProfileRelated?.personalInfoProfileRelated[0]?.id === fullData?.approval?.personalInfoProfileRelated[0]?.id)
    )
  }, [fullData, user, pmo])

  useEffect(() => {
    setAccessRead((fullData?.varify?.personalInfoProfileRelated[0]?.id === user?.placementProfileRelated?.personalInfoProfileRelated[0]?.id)
      || (fullData?.approval?.personalInfoProfileRelated[0]?.id === user?.placementProfileRelated?.personalInfoProfileRelated[0]?.id))
  }, [user, fullData])


  const showComment = async () => {
    setShowComments(true)
    setAlarm(false)
  }
  // patch accepted 
  const handleAccepted = async () => {
    if (!token || !params?.id) return
    let formData = {
      is_accepted: true
    }
    try {
      const res = await API.patch(`/api/v1/actionplan/taskreport/${params?.id}/`, formData,
        { headers: { Authorization: token } }
      )
      console.log(res?.data)
      toast.success("گزارش تائید شد")
      setRefresh(1)
    } catch (error) {
      console.log(error)
      toast.error("با خطا روبه رو شد")
    }
  }

  return (
    <div>
      <ActionPlanNavbar buttonActive="actions" />
      <div className="p-5">

        <div className="w-full flex justify-end">
          <button
            onClick={() => navigate(-1)}
            className="flex items-center gap-2 text-sm text-caspian-secondary-600">
            بازگشت
            <PiCaretLeft />
          </button>
        </div>
        <div className="flex flex-col items-center w-full relative p-5 shadow-md lg:w-3/4 bg-caspian-surface">

          <div className="flex flex-col items-center w-full">
            {isAccessComment &&
              <div className="absolute left-5 top-3 cursor-pointer">
                {alarm &&
                  <>
                    <div className="w-3 h-3 rounded-full left-0 top-0 z-30 bg-caspian-error2 absolute"></div>
                    <div className="w-3 h-3 rounded-full animate-ping  left-0 top-0 z-30 bg-caspian-error2 absolute"></div>
                  </>
                }
                <PiChatsCircle
                  className="text-2xl z-40 text-caspian-secondary-500 cursor-pointer"
                  onClick={showComment}>
                </PiChatsCircle>
              </div>
            }

            {showComments &&
              <CommentsMain
                fullData={fullData}
                setShowComments={setShowComments}
                valueForm={valueForm}
                setValueForm={setValueForm}
                accessRead={accessRead}
                urlPost={'/api/v1/actionplan/comment/'}
                urlGet={'/api/v1/actionplan/reports/comment/'}
                formData={{
                  content_type: "actionplan_app.taskreportprofile",
                  object_id: params?.id,
                  text_comment: valueForm,
                  is_parent: true,
                }}
              />
            }

            <div className="flex items-center justify-around w-full gap-5 py-3 ">
              <div className="flex items-center gap-2 text-caspian-secondary-600">
                <PiChartLineLight className="text-2xl" />
                <span>گزارش</span>
              </div>
              <div className="w-full h-[2px] bg-caspian-on_primary/80"></div>
            </div>

            <div className="flex items-center w-full gap-2 py-5">
              <div className="flex flex-col items-start gap-2">
                <span className="text-[#3E615F] text-xs 2xl:text-base">عنوان برنامه اجرایی :</span>
                <span className="text-[#3E615F] text-xs 2xl:text-base">عنوان فعالیت :</span>
              </div>

              <div className="flex flex-col items-start gap-2">
                <span className="text-xs font-bold 2xl:text-base">
                  {fullData?.taskRelated?.actionplanRelated?.title ? fullData?.taskRelated?.actionplanRelated?.title : "-"}

                </span>
                <span className="text-xs font-bold 2xl:text-base">
                  {fullData?.taskRelated?.title ? fullData?.taskRelated?.title : "-"}
                </span>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center w-full py-5">

            {/* پیشرفت کار  */}
            <div className="flex flex-col items-center w-full gap-5">

              <div className='flex items-center w-full gap-2 '>
                <div className='w-1/2 h-[2px] bg-caspian-gray2'></div>
                <PiScrollLight className='text-[#3E615F] text-3xl' />
                <span className='text-xs text-[#3E615F] font-bold 2xl:text-base whitespace-nowrap'>گزارش پیشرفت کار</span>
                <div className='w-1/2 h-[2px] bg-caspian-gray2'></div>
              </div>

              {fullData?.progress_taskreportProfile?.length > 0 ?
                <MainWorkProgressReport fullData={fullData} />
                : <span className="w-full py-5 text-base font-bold text-center">گزارشی برای پیشرفت کار تعریف نشده است.</span>
              }
            </div>

            {/* هزینهّ */}
            <div className="flex flex-col items-center w-full gap-5 py-5">

              <div className='flex items-center w-full gap-2 '>
                <div className='w-1/2 h-[2px] bg-caspian-gray2'></div>
                <PiCoinsLight className='text-[#3E615F] text-3xl' />
                <span className='text-xs text-[#3E615F] font-bold 2xl:text-base whitespace-nowrap'>گزارش هزینه</span>
                <div className='w-1/2 h-[2px] bg-caspian-gray2'></div>
              </div>

              {fullData?.costRelatedreportProfile?.length > 0 ?
                <MainCostReport fullData={fullData} />
                : <span className="w-full py-5 text-base font-bold text-center">گزارشی برای هزینه تعریف نشده است. </span>
              }
            </div>
            {/* اقلام مصرفی */}
            <div className="flex flex-col items-center w-full gap-5">

              <div className='flex items-center w-full gap-2 '>
                <div className='w-1/2 h-[2px] bg-caspian-gray2'></div>
                <PiShoppingCart className='text-[#3E615F] text-3xl' />
                <span className='text-xs text-[#3E615F] font-bold 2xl:text-base whitespace-nowrap'>گزارش اقلام مصرفی</span>
                <div className='w-1/2 h-[2px] bg-caspian-gray2'></div>
              </div>

              {fullData?.reportProfileMaterialRelated?.length > 0 ?
                <MainMaterialReport fullData={fullData} />
                : <span className="w-full py-5 text-base font-bold text-center">گزارشی برای اقلام مصرفی تعریف نشده است. </span>
              }
            </div>
          </div>

          {((user?.placementProfileRelated?.personalInfoProfileRelated[0]?.id === fullData?.manager?.id) &&
            (fullData?.is_accepted !== true)) &&
            <div className="flex flex-col items-start w-full gap-3">
              <div className="flex items-start gap-2 w-full">
                <input type="checkbox" id="accept" />
                <label
                  className="text-xs font-bold "
                  htmlFor="accept">ایا این گزارش را تائید میکنید؟</label>
              </div>

              <button
                onClick={handleAccepted}
                className="w-24 py-2 bg-caspian-secondary-600 text-caspian-surface text-xs rounded">
                تائید گزارش
              </button>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default MainProfileReportActionPaln;
