import React from 'react';
import { PiDotOutlineFill } from 'react-icons/pi';

const StrengthListItem = ({ id, title }) => {
    return (
        <div className='flex items-start border-b border-[#C8C8C8] pb-2 last:border-0'>
            <div className='w-8 text-2xl pt-1'><PiDotOutlineFill /></div>
            <p className='text-xs leading-8 text-justify'>
                {title}
            </p>
        </div>
    );
}

export default StrengthListItem;
