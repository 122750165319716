import React, { useEffect, useState } from "react";
import { PiMessengerLogo } from "react-icons/pi";
import MainContactChat from "./contact-chat/MainContactChat";
import MainHistoryChat from "./history-chat/MainHistoryChat";
import MainContentChat from "./content-chat/MainContentChat";
import MainFavoritesChat from "./favorites-chat/MainFavoritesChat";

const ButtonChat = () => {
  const [showChatBox, setShowChatBox] = useState(false);
  const [showContactChat, setShowContactChat] = useState(false);
  const [showChatContent, setShowChatContent] = useState(false);
  const [memberSelected, setMemberSelected] = useState([]);
  const [isIdConversation, setIsIdConversation] = useState(null);
  const [isIdUser, setIsIdUser] = useState(null);
  const [refreshMyconversation, setRefreshMyconversation] = useState(0);
  const [chatType, setChatType] = useState(null);
  const [groupName, setGroupName] = useState(null);
  const [favoritesShow, setFavoritesShow] = useState(false);
  const [favoritesRefresh, setFavoritesRefresh] = useState(0);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && e.altKey && e.key === "c") {
        e.preventDefault();
        setShowChatBox(!showChatBox);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [showChatBox]);
  return (
    <>
      <button
        onClick={() => setShowChatBox(!showChatBox)}
        type="button"
        className="w-12 h-12 rounded-full bg-caspian-primary-100 flex items-center justify-center fixed bottom-4 left-16 z-[1000] opacity-70 hover:opacity-100 duration-300"
      >
        <PiMessengerLogo className="text-4xl text-caspian-primary-700" />
      </button>
      {favoritesShow && (
        <MainFavoritesChat
          setFavoritesShow={setFavoritesShow}
          isIdConversation={isIdConversation}
          isIdUser={isIdUser}
          setShowChatBox={setShowChatBox}
          setShowContactChat={setShowContactChat}
          showChatContent={showChatContent}
          setShowChatContent={setShowChatContent}
          memberSelected={memberSelected}
          setMemberSelected={setMemberSelected}
          setIsIdConversation={setIsIdConversation}
          setIsIdUser={setIsIdUser}
          setRefreshMyconversation={setRefreshMyconversation}
          chatType={chatType}
          groupName={groupName}
          favoritesRefresh={favoritesRefresh}
          setFavoritesRefresh={setFavoritesRefresh}
        />
      )}
      {showChatBox && (
        <MainHistoryChat
          setFavoritesRefresh={setFavoritesRefresh}
          setFavoritesShow={setFavoritesShow}
          setShowChatBox={setShowChatBox}
          setShowContactChat={setShowContactChat}
          memberSelected={memberSelected}
          setMemberSelected={setMemberSelected}
          setShowChatContent={setShowChatContent}
          setIsIdConversation={setIsIdConversation}
          setIsIdUser={setIsIdUser}
          setRefreshMyconversation={setRefreshMyconversation}
          refreshMyconversation={refreshMyconversation}
          setChatType={setChatType}
          setGroupName={setGroupName}
        />
      )}
      {showContactChat && (
        <MainContactChat
          setFavoritesShow={setFavoritesShow}
          setShowChatBox={setShowChatBox}
          setShowContactChat={setShowContactChat}
          showChatContent={showChatContent}
          setShowChatContent={setShowChatContent}
          memberSelected={memberSelected}
          setMemberSelected={setMemberSelected}
          setRefreshMyconversation={setRefreshMyconversation}
        />
      )}
      {showChatContent && (
        <MainContentChat
          setFavoritesShow={setFavoritesShow}
          isIdConversation={isIdConversation}
          isIdUser={isIdUser}
          setShowChatBox={setShowChatBox}
          setShowContactChat={setShowContactChat}
          showChatContent={showChatContent}
          setShowChatContent={setShowChatContent}
          memberSelected={memberSelected}
          setMemberSelected={setMemberSelected}
          setIsIdConversation={setIsIdConversation}
          setIsIdUser={setIsIdUser}
          setRefreshMyconversation={setRefreshMyconversation}
          chatType={chatType}
          groupName={groupName}
        />
      )}
    </>
  );
};

export default ButtonChat;
