import { PiCheckFat } from "react-icons/pi";

const ListGoal = ({ goalList, member }) => {
  return goalList?.length > 0 ? (
    goalList?.map((p, i) => (
      <div key={i} className="flex flex-col gap-1 px-2">
        {p?.goalsRelatedRiskTeam?.map((g, i) => (
          <div
            className={
              member?.listDone.find((d) => d === g.id)
                ? "text-xs flex items-center justify-between gap-2 bg-caspian-green-100 p-2 rounded-md border border-caspian-green-500"
                : "text-xs flex items-center justify-between gap-2 bg-caspian-surface p-2 rounded-md border border-caspian-gray2/50"
            }
            key={i}
          >
            <div className="flex items-center gap-8">
              <span title={g?.title}>
                {g?.title.length > 20
                  ? g?.title.slice(0, 20) + "..."
                  : g?.title}
              </span>
              <span title={g?.description}>
                {g?.description.length > 30
                  ? g?.description.slice(0, 30) + "..."
                  : g?.description}
              </span>
            </div>
            {member?.listDone.find((d) => d === g.id) && (
              <span>
                <PiCheckFat className="text-caspian-green-700 text-lg" />
              </span>
            )}
          </div>
        ))}
      </div>
    ))
  ) : (
    <div className="flex items-center justify-center gap-2 py-8">
      <span className="text-xs text-caspian-gray4">هدفی موجود نیست</span>
    </div>
  );
};

export default ListGoal;
