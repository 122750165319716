import React from "react";
import { useContext } from "react";
import { PiQuestion } from "react-icons/pi";
import { AuthContext } from "../../context/auth/AuthContext";
import UserDropDown from "./UserDropDown";
import API from "../../api/API";
import { useAppContext } from "../../context/app-context/AppContext";

const UserComponent = ({ showMenu }) => {
  const { user } = useContext(AuthContext);
  const { getAvatar } = useAppContext();
  return (
    <div
      className={
        showMenu
          ? "z-50 fixed bottom-3 left-2 right-2 md:w-[24%] lg:w-[19%] xl:w-[14%] 2xl:w-[14%] "
          : "z-50 w-fit fixed bottom-3 left-2 right-2"
      }
    >
      <div className="pb-3 flex items-center gap-2 p-2 w-full">
        <div className="w-6 h-6 flex items-center justify-center">
          <PiQuestion className="text-2xl" />
        </div>
        {showMenu && (
          <div className="flex items-center justify-between w-full">
            <h2 className="text-sm">راهنما</h2>
          </div>
        )}
      </div>

      <div className="flex items-center gap-4 border-t border-t-caspian-primary-300 pt-3">
        <UserDropDown
          picture={
            getAvatar !== null
              ? getAvatar
              : user?.placementProfileRelated?.personalInfoProfileRelated[0]
                  ?.avatar
              ? API.defaults.baseURL +
                user?.placementProfileRelated?.personalInfoProfileRelated[0]
                  ?.avatar
              : "/assets/images/users/not-picture.svg"
          }
          fullname={
            user.placementProfileRelated &&
            user.placementProfileRelated.firstName +
              " " +
              user?.placementProfileRelated.lastName
          }
        />
        {showMenu && (
          <>
            {user.length < 1 ? (
              <div className="flex flex-col gap-1 justify-between">
                <div className="w-16 h-4 bg-caspian-gray animate-pulse"></div>
                <div className="w-8 h-2 bg-caspian-gray animate-pulse"></div>
              </div>
            ) : (
              <div className="flex flex-col justify-between">
                <h2 className="text-sm">
                  {(user?.placementProfileRelated?.firstName
                    ? user?.placementProfileRelated?.firstName
                    : "") +
                    " " +
                    (user?.placementProfileRelated?.lastName
                      ? user?.placementProfileRelated?.lastName
                      : "")}
                </h2>
                <span className="text-xs text-caspian-gray">
                  {user.placementPositionRelated?.title}
                </span>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default UserComponent;
