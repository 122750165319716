import ListQuestion from "./ListQuestion";
import { DragDropContext } from "react-beautiful-dnd";

const MainDragDropQuestion = ({
  allQuestionList,
  questionSelectedList,
  setQuestionSelectedList,
  refs,
  numberSelected,
}) => {
  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    // اگر آیتم از لیست اول به لیست دوم درگ شد
    if (
      source?.droppableId === "allQuestion" &&
      destination?.droppableId === "questionSelected"
    ) {
      const itemToMove = allQuestionList[source.index];

      // بررسی اینکه آیا آیتم از قبل در لیست دوم وجود دارد یا خیر
      const alreadyExists = questionSelectedList.some(
        (item) => item?.id === itemToMove?.id
      );

      if (!alreadyExists) {
        await setQuestionSelectedList([...questionSelectedList, itemToMove]); // کپی به لیست دوم
        await refs?.current[
          questionSelectedList[source.index]?.id
        ]?.scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  };


  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className="flex items-start gap-4">
        <ListQuestion
          numberSelected={numberSelected}
          title="بانک سوالات"
          items={allQuestionList}
          questionSelectedList={questionSelectedList}
          setQuestionSelectedList={setQuestionSelectedList}
          droppableId="allQuestion"
          isDraggable={true}
          refs={refs}
        />
        <ListQuestion
          title="سوالات منتخب چک لیست"
          items={questionSelectedList}
          setQuestionSelectedList={setQuestionSelectedList}
          droppableId="questionSelected"
          isDraggable={false}
          refsS={refs}
        />
      </div>
    </DragDropContext>
  );
};

export default MainDragDropQuestion;
