import React from "react";
// import Login from "../../components/auth/Login";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NewLogin from "../../components/auth/NewLogin";

const LoginLayoutNew = () => {
  const navigate = useNavigate();
  const _appSignging = localStorage.getItem("_appSignging");
  useEffect(() => {
    if (_appSignging) {
      navigate("/");
    }
  }, [navigate, _appSignging]);
  return (
    <div className="flex items-center justify-center w-full h-screen bg-caspian-gray3 bg-login-bg bg-no-repeat bg-cover bg-center">
      <div className="w-[80%] sm:w-[50%] md:w-[35%] lg:w-[30%] xl:w-[25%] 2xl:w-[20%] border border-1 border-caspian-gray2 rounded-lg bg-caspian-surface">
        <div className="flex flex-col items-center gap-10 p-4 w-full">
          <div className="flex flex-col items-center gap-4">
            <img
              src="/assets/images/logo/logo-hamgara.svg"
              alt="طرح و اجرا گروه مدیریت توسعه همگرا"
              className="w-[130px] h-auto"
            />
            <h2 className="text-xl md:text-lg lg:text-xl font-bold text-[#333333]">
              سامانه مدیریت یکپارچه{" "}
              <span className="text-caspian-primary-400">همگرا</span>
            </h2>
          </div>
          <NewLogin />
          <h2 className="text-xs text-[#333333] text-center">
            طرح و اجرا{" "}
            <span className="text-caspian-primary-400">
              {" "}
              راهکارهای هوشمند همگرا
            </span>
          </h2>
        </div>
      </div>
    </div>
  );
};

export default LoginLayoutNew;
