import React, { useState } from 'react';
import { PiX } from 'react-icons/pi';
import Modal from '../../../../../modal/Modal';
import ListSection from '../observation-details/ListSection';

const NaDetails = ({ setShowNa }) => {
    const [desc, setDesc] = useState('');
    const [section, setSection] = useState('');
    const [sectionSelected, setSectionSelected] = useState(null);
    const [sectionList, setSectionList] = useState([]);
    return (
        <Modal>
            <div className='w-[36%] h-fit 2xl:h-[70vh] bg-caspian-surface rounded-lg flex flex-col'>
                <div className='px-3 2xl:px-12 py-4 text-base flex justify-between items-center border-b border-caspian-darkgray-100'>
                    <span>N/A</span>
                    <PiX className='cursor-pointer' onClick={() => setShowNa(false)} />
                </div>
                <div className='px-3 2xl:px-12 py-2 flex flex-col gap-4 h-full'>
                    <div className='flex flex-col gap-2'>
                        <span className='text-xs 2xl:text-sm text-caspian-gray4'>توضیحات</span>
                        <textarea
                            onChange={(e) => setDesc(e?.target?.value)}
                            className='border border-[#E5E7EB] resize-none text-caspian-gray text-sm placeholder:text-caspian-gray placeholder:text-xs 2xl:placeholder:text-sm rounded-lg py-2 px-4'
                            placeholder='توضیحات خود را یادداشت کنید.'
                            cols="8" rows="8"></textarea>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <span className='text-xs 2xl:text-sm text-caspian-gray4'>ارجاع سوال به واحد</span>
                        <ListSection
                            setSection={setSection}
                            sectionSelected={sectionSelected}
                            setSectionSelected={setSectionSelected}
                            sectionList={sectionList}
                        />
                    </div>
                    <button 
                    className='text-caspian-surface bg-[#3C77A2] rounded-lg text-xs 2xl:text-sm w-[70px] 2xl:w-[100px] flex justify-center items-center p-1.5 2xl:py-2 mt-auto'>
                        <span>ثبت </span>
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default NaDetails;
