import React, { useEffect, useRef, useState } from "react";
import MainStandardBox from "./standard-box/MainStandardBox";
import MainNumberBox from "./number-box/MainNumberBox";
import MainAcceptChecklist from "./accept-checklist/MainAcceptChecklist";
import MainFilterProcessActivity from "./filter-process-activity/MainFilterProcessActivity";
import MainDragDropQuestion from "./drag-drop-question/MainDragDropQuestion";

const MainRightbarChecklistForm = ({
  questionData,
  standardList,
  clauseList,
  indexQuestion,
  setIndexQuestion,
  processFilter,
}) => {
  const refs = useRef({});
  const [questionSelectedList, setQuestionSelectedList] = useState([]);

  // const [standardList, setStandardList] = useState(standards);
  const [standardSelected, setStandardSelected] = useState(null);
  const [activitySelected, setActivitySelected] = useState(null);

  const [numberList, setNumberList] = useState(clauseList);
  const [numberSelected, setNumberSelected] = useState(null);

  const [isSelectedAnime, setIsSelectedAnime] = useState(null);

  const [allQuestionSelected, setAllQuestionSelected] = useState([]);

  const [processSelected, setProcessSelected] = useState([]);

  const [filterFinal, setFilterFinal] = useState([]);

  console.log(
    questionData,
    standardSelected,
    standardSelected
      ? questionData.filter(
          (q) =>
            q?.questionClauseRelatedQuestion[0]?.standardRelated?.id ===
            standardSelected?.standardRelated?.id
        )
      : questionData
  );

  // let filterList = [];
  // filterList = numberSelected
  //   ? questionData.filter((q) => q.id === numberSelected.questionClauseRelated)
  //   : standardSelected
  //   ? questionData.filter(
  //       (q) =>
  //         q?.questionClauseRelatedQuestion[0]?.standardRelated?.id ===
  //         standardSelected?.standardRelated?.id
  //     )
  //   : questionData;

  console.log(activitySelected)

  useEffect(() => {
    let finalProcess = [];
    let finalActivities = questionData;
    // finalActivities =
    //   activitySelected?.length !== 0
    //     ? questionData.filter((p) => p.questionRelatedQuestionProcess[0].processActivityRelated === activitySelected?.questionRelated)
    //     : questionData;

    finalProcess =
      processSelected?.length !== 0
        ? finalActivities.filter(
            (p) => p.id === processSelected?.questionRelated
          )
        : finalActivities;

    setFilterFinal(
      numberSelected
        ? finalProcess.filter(
            (q) => q.id === numberSelected?.questionClauseRelated
          )
        : standardSelected
        ? finalProcess.filter(
            (q) =>
              q?.questionClauseRelatedQuestion[0]?.standardRelated?.id ===
              standardSelected?.standardRelated?.id
          )
        : finalProcess
    );
  }, [
    questionData,
    numberSelected,
    standardSelected,
    processSelected,
    activitySelected
  ]);

  return (
    <div className="w-[80%] h-full flex flex-col gap-4 px-2 py-6 bg-caspian-surface rounded-md">
      <span className="w-full text-center">واحد تستی شماره 7</span>
      <div className="flex flex-col gap-1">
        <MainStandardBox
          standardList={standardList}
          // setStandardList={setStandardList}
          setFilterFinal={setFilterFinal}
          standardSelected={standardSelected}
          setStandardSelected={setStandardSelected}
          indexQuestion={indexQuestion}
          setIndexQuestion={setIndexQuestion}
          setNumberSelected={setNumberSelected}
        />

        <MainNumberBox
          setFilterFinal={setFilterFinal}
          standardSelected={standardSelected}
          numberList={clauseList.filter((c) =>
            c?.standardRelated?.standardNumber?.includes(
              standardSelected?.standardRelated?.standardNumber
            )
          )}
          setNumberList={setNumberList}
          numberSelected={numberSelected}
          setNumberSelected={setNumberSelected}
        />
      </div>
      <div className="bg-caspian-blue-200 border border-[#D0DEFB] rounded-md text-[#0D358C] text-sm p-2 font-bold flex items-center justify-center">
        <span>درک سازمان و فضای آن</span>
      </div>
      <MainFilterProcessActivity
        setFilterFinal={setFilterFinal}
        // questionData={questionData}
        questionData={filterFinal}
        processFilters={processFilter}
        processSelected={processSelected}
        setProcessSelected={setProcessSelected}
        activitySelected={activitySelected}
        setActivitySelected={setActivitySelected}
      />

      <MainDragDropQuestion
        numberSelected={numberSelected}
        allQuestionList={filterFinal}
        // allQuestionList={filterList}
        questionSelectedList={questionSelectedList}
        setQuestionSelectedList={setQuestionSelectedList}
        allQuestionSelected={allQuestionSelected}
        setAllQuestionSelected={setAllQuestionSelected}
        refs={refs}
      />
      <div className="h-[1px] w-full bg-caspian-gray2 my-2"></div>
      <MainAcceptChecklist allQuestionSelected={allQuestionSelected} />
    </div>
  );
};

export default MainRightbarChecklistForm;
