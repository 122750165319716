import React, { useState } from "react";
import SingleTeamMembers from "./SingleMembersSection";

const MainMembersSection = ({ isAbsent, setIsAbsent, isPresent, setIsPresent, sectionMember }) => {

  return (
    <div className="flex items-start gap-2 w-full">
      <div className="flex items-start flex-col gap-2 w-[60%]">
        <label className="">
          <span className="text-xs text-caspian-gray4">افراد حاضر در واحد</span>
        </label>
        <div className="max-h-[280px] overflow-y-auto w-full flex flex-col gap-2">
          {
            sectionMember?.length > 0 && sectionMember?.map((s, i) =>
              <SingleTeamMembers
                key={s?.id}
                index={i}
                {...s}
                isAbsent={isAbsent}
                setIsAbsent={setIsAbsent}
                isPresent={isPresent}
                setIsPresent={setIsPresent}
              />
            )
          }
          {/* <SingleTeamMembers
            team={{
              fullname: "محسن علیپور",
              job: "پاسخگو",
            }}
            isAbsent={isAbsent}
            setIsAbsent={setIsAbsent}
            isPresent={isPresent}
            setIsPresent={setIsPresent}
          /> */}
        </div>
      </div>
      <div className="flex items-start flex-col gap-2 w-[40%]">
        <label className="">
          <span className="text-xs text-caspian-gray4">
            سایر افراد تیم ممیزی
          </span>
        </label>
        <textarea
          placeholder="سایر افراد مورد نظر را یادداشت کنید"
          rows={5}
          className="w-full resize-none text-xs p-2 rounded-md border border-caspian-gray"
        />
      </div>
    </div>
  );
};

export default MainMembersSection;
