import { PiGraduationCap, PiChalkboardTeacher, PiPencilSimpleLine } from "react-icons/pi";
import Accordion from '../accordion/Accordion';
import TableTask from '../accordion/accordion-content/table-task/TableTask';
import { useState } from "react";
import EditResForm from "./forms/EditResForm";
import EditAuthorityForm from "./forms/EditAuthorityForm";
import EditDutyForm from "./forms/EditDutyForm";
import TableRes from "./TableRes";
import TableAuthority from "./TableAuthority";
import EditDesc from "./forms/EditDesc";
const Task = ({ data, id, setRefresh, user }) => {
    const [editRes, setEditRes] = useState(false);
    const [editAuth, setEditAuth] = useState(false);
    const [editDuty, setEditDuty] = useState(false);
    const [editDesc, setEditDesc] = useState(false);

    const publicRes = data.positionRelatedForDuty.filter(item => item.dutyType === 'public');
    const proRes = data.positionRelatedForDuty.filter(item => item.dutyType === 'pro');
    const specialRes = data.positionRelatedForDuty.filter(item => item.dutyType === 'special');

    // const [existRes,setExistRes] = (data?.positionRelatedForResponsible?.length > 0 ? data?.positionRelatedForResponsible?.map(p=>{return p?.describtion}) : [])
    console.log(data?.positionRelatedForResponsible);
    return (
        <>

            <div className="accordion">
                <div className="flex items-baseline gap-2 justify-between w-full relative">
                    <div className='w-[95%]' >
                        <Accordion
                            title='مسئولیت‌ها'
                            icon={<PiGraduationCap />}>
                            <TableRes items={data?.positionRelatedForResponsible} />
                        </Accordion>
                        <div className="absolute left-3 top-8">

                            <div className='text-lg'>
                                <PiPencilSimpleLine className='cursor-pointer' onClick={() => setEditRes(true)} />
                            </div>
                            {
                                editRes && <EditResForm id={id}
                                    // existRes={existRes}
                                    setEditRes={setEditRes} data={data} setRefresh={setRefresh} />
                            }
                        </div>
                    </div>
                </div>
                <div className="flex items-baseline gap-2 justify-between w-full relative">
                    <div
                        className='w-[95%] border-b border-[#F0F3FD]'>
                        <Accordion
                            title='حدود اختیارات'
                            icon={<PiChalkboardTeacher />}>
                            <TableAuthority items={data?.positionRelatedForAuthority} />
                        </Accordion>
                    </div>
                    <div className="absolute left-3 top-8">
                        <div className='text-lg'>
                            <PiPencilSimpleLine className='cursor-pointer' onClick={() => setEditAuth(true)} />
                        </div>
                        {
                            editAuth && <EditAuthorityForm
                                id={id} setEditAuth={setEditAuth} data={data} setRefresh={setRefresh} />
                        }
                    </div>
                </div>
                <div className="flex items-baseline gap-2 justify-between w-full relative">
                    <div className='w-[95%]'>
                        <Accordion
                            title='وظایف سازمانی'
                            icon={<PiChalkboardTeacher />}>

                            <Accordion
                                title=' وظایف عمومی/ سازمانی '
                                icon=''>
                                <TableTask items={publicRes} />
                            </Accordion>

                            <Accordion
                                title=' وظایف تخصصی شغل'
                                icon=''>
                                <TableTask items={proRes} />
                            </Accordion>

                            <Accordion
                                title=' وظایف خاص پست '
                                icon=''>
                                <TableTask items={specialRes} />
                            </Accordion>

                        </Accordion>
                    </div>
                    <div className="absolute left-3 top-8">
                        <div className='text-lg'>
                            <PiPencilSimpleLine className='cursor-pointer' onClick={() => setEditDuty(true)} />
                        </div>
                        {
                            editDuty && <EditDutyForm
                                publicRes={publicRes}
                                proRes={proRes}
                                specialRes={specialRes}
                                id={id} setEditDuty={setEditDuty} data={data} setRefresh={setRefresh} />
                        }
                    </div>
                </div>
            </div>
            <div className='py-3 border-t border-[#F0F3FD] relative'>
                <div className="absolute left-3 top-1/2">
                    <div className='text-lg'>
                        <PiPencilSimpleLine className='cursor-pointer' onClick={() => setEditDesc(true)} />
                    </div>
                    {
                        editDesc && <EditDesc id={id} setEditDesc={setEditDesc}
                            data={data}
                            setRefresh={setRefresh} />
                    }
                </div>
                <span className='text-caspian-gray4'>توضیحات :</span>
                {
                    // data?.positionRelatedForDescribtion?.map(item =>
                    //     <p key={item?.id} className='py-3 pr-3 leading-8 text-sm 2xl:text-sm 2xl:leading-8'>{item?.describtion}</p>
                    // )
                    <p className='py-3 pr-3 leading-8 text-sm 2xl:text-sm 2xl:leading-8'>{data?.positionRelatedForDescribtion[0]?.describtion}</p>

                }
            </div>
        </>
    );
}

export default Task;
