import React from 'react';
import InfoAudit from './info-audit/InfoAudit';
import MainListComponent from './list-audit/MainListComponent';

const MainInputObservationsList = ({showComponent,setShowComponent}) => {
    return (
        <div className='flex flex-col gap-4 xl:gap-6'>
            <InfoAudit />
            <MainListComponent showComponent={showComponent} setShowComponent={setShowComponent} />
        </div>
    );
}

export default MainInputObservationsList;
