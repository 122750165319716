import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppContext } from "../../../../context/app-context/AppContext";
import { PiCheckCircleFill, PiTrash, PiXCircleFill } from "react-icons/pi";
import API from "../../../../api/API";
import { useContext, useState } from "react";
import { AuthContext } from "../../../../context/auth/AuthContext";

const SingleTableListActivites = ({
  id,
  title,
  teamType,
  startDate,
  endDate,
  statusType,
  teamName,
  setRefreshActivitiesList,
  status
}) => {
  const { token } = useContext(AuthContext);

  const [serachParams] = useSearchParams();
  const navigate = useNavigate();
  const { setBradCrump3 } = useAppContext();
  const [showIsDelete, setShowIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const removeHandler = async () => {
    API.delete(`/api/v1/risk/identification/detail/${id}/`, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        setRefreshActivitiesList(1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <tr
      onClick={() =>
        (status === "started" || status === "done") &&
        navigate(`/risk/activities/${id}`)
      }
      className="bg-caspian-surface duration-300 text-sm"
    >
      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2 w-[20%] cursor-pointer">
        <h2>{title}</h2>
      </td>
      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2 w-[13.3%] cursor-pointer">
        <h2>{teamName}</h2>
      </td>
      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2 w-[13.3%] cursor-pointer">
        <h2>
          {teamType === "strategic" ? "گروه اهداف کلان" : "گروه فرایندها"}
        </h2>
      </td>

      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2 w-[13.3%] cursor-pointer">
        <h2>{new Date(startDate).toLocaleDateString("fa-IR")}</h2>
      </td>
      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2 w-[13.3%] cursor-pointer">
        <h2>{new Date(endDate).toLocaleDateString("fa-IR")}</h2>
      </td>
      <td className="border-b border-b-caspian-gray2 px-3 py-2 w-[13.3%] cursor-pointer">
        <h2
          className={`${
            statusType === "done"
              ? "text-[#258E0B]"
              : statusType === "doing"
              ? "text-[#A9A900]"
              : statusType === "outdate" && "text-caspian-error2"
          } w-fit rounded-md px-2 py-0.5 text-xs`}
        >
          {statusType === "doing"
            ? "در انتظار شناسایی"
            : statusType === "done"
            ? "تکمیل شناسایی"
            : statusType === "outdate" && "منقضی شده"}
        </h2>
      </td>
    </tr>
  );
};

export default SingleTableListActivites;
