import React, { useContext, useEffect, useState } from 'react';
import { PiPlusBold, PiX } from 'react-icons/pi';
import { AuthContext } from '../../../../context/auth/AuthContext';
import API from '../../../../api/API';
import ListMS from './ListMs';
// import ListStandard from './ListStandaard';

const ModalMs = ({ setMs, ms, msSelected, setMsSelected, setShowModal, idProfile, list, setList, setRefresh, idPatch, setIdPatch,primarySelected, setPrimarySelected,mainSelected, setMainSelected,secondarySelected, setSecondarySelected,
    systemMangamentSelected, setSystemMangamentSelected
 }) => {
    const { token } = useContext(AuthContext);
    const [msList, setMsList] = useState([]);
    // const [ms, setMs] = useState("");
    // const [msSelected, setMsSelected] = useState(null);
    const [showListms, setShowListms] = useState(false);

    const [primaryStandard, setPrimaryStandard] = useState([]);
    const [mainStandard, setMainStandard] = useState([]);
    const [secondaryStandard, setSecondaryStandard] = useState([]);


    const [managementSystemDataDetails, setManagementSystemDataDetails] =
        useState([]);
    // const [primarySelected, setPrimarySelected] = useState([]);
    // const [mainSelected, setMainSelected] = useState([]);
    // const [secondarySelected, setSecondarySelected] = useState([]);

    // const [systemMangamentSelected, setSystemMangamentSelected] = useState([
        // primarySelected,
        // mainSelected,
        // secondarySelected,
    // ]);
    console.log('idPatch', idPatch);
    useEffect(() => {
        const changeArrays = async () => {
            // if(primarySelected?.length<1 || mainSelected?.length<1 || secondarySelected?.length<1 ){
            //     return
            // }
            const newArray1 = primarySelected;
            const newArray2 = mainSelected;
            const newArray3 = secondarySelected;
            let s = [];
            if (newArray1?.length > 0) {
                s.push(...newArray1)
            }
            if (newArray2?.length > 0) {
                s.push(...newArray2)
            }
            if (newArray3?.length > 0) {
                s.push(...newArray3)
            }
            console.log('s', s);
            // await setSystemMangamentSelected(
            //     [newArray1, newArray2, newArray3].reduce(
            //         (acc, curr) => acc.concat(curr),
            //         []
            //     )
            // );
            setSystemMangamentSelected(s)
        };
        changeArrays();
    }, [primarySelected, mainSelected, secondarySelected]);
    console.log(ms);
    const [isDisabled, setIsDisabled] = useState(false);
    useEffect(() => {
        if (!token) return
        const fetchms = async () => {
            try {
                const res = await API.get('/api/v1/standard/managmentSystem');
                setMsList(res.data);
                // setStandardFinalSelected('');
                // setStandardSelected('')
                setSystemMangamentSelected('')
                setPrimarySelected('');
                setSecondarySelected('');
                setMainSelected('');
                console.log(res.data);
            }
            catch (err) {
                console.log(err.message);
            }
        }
        fetchms();
    }, [token, ms])

    useEffect(() => {
        if (!token || !ms) return
        const fetchms = async () => {
            try {
                const res = await API.get(`/api/v1/standard/managmentSystem/${ms}`);
                if (res?.data) {
                    // setMainStandard([...res?.data?.mainStandard])
                    // setPrimaryStandard(res?.data?.primaryStandard)
                    // setSecondaryStandard([...res?.data?.secondaryStandard])
                    setManagementSystemDataDetails(res?.data)
                }
                console.log(res.data);
            }
            catch (err) {
                console.log(err.message);
            }
        }
        fetchms();
    }, [token, ms, setPrimaryStandard, setMainStandard, setSecondaryStandard])
    // console.log("primaryStandard", primaryStandard);
    // const handlems = async (id) => {
    //     console.log(id);
    //     setShowModal2(false)
    //     if (!id) return
    //     let formData = {
    //         questionRelated: id,
    //         msProfileRelated: ms,
    //         msActivityRelated: activitiesFinalSelected2
    //     };
    //     try {
    //         const res = await API.post('/api/v1/audit/question/ms/', formData, { headers: { Authorization: token } });
    //         if (res.data) {
    //             console.log('res ms', res.data);
    //             setQ(1);
    //             setShowModal2(false);
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }
    // useEffect(() => {
    //     if (activitiesSelected2?.length > 0 && process) {
    //         setIsDisabled(true);
    //     }
    //     else {
    //         setIsDisabled(false);
    //     }
    // }, [process, activitiesSelected2])
    // const handleCLick = (id) => {
    //     if (!standardSelected?.includes(id)) {
    //         setStandardSelected([...standardSelected, id])
    //     }
    //     else {
    //         setStandardSelected(standardSelected?.filter((d) => d !== id));
    //     }
    // }
    console.log('systmeSelected', systemMangamentSelected);
    const handleSubmit = async () => {
        let formData = {
            auditProfileMSRelated: idProfile,
            msRelated: ms,
            standardsRealated: systemMangamentSelected
        }
        console.log(formData);
        setIsDisabled(true);
        let res;
        console.log("idPatch", idPatch);
        try {
            if (idPatch !== -1) {
                res = await API.patch(`/api/v1/audit/profile/ms/${idPatch}/`, formData, { headers: { Authorization: token } })
                setMs('')
            }
            else {
                res = await API.post('/api/v1/audit/profile/ms/', formData, { headers: { Authorization: token } })
            }
            if (res?.data) {
                console.log(res?.data);
                setRefresh(true)
                setShowModal(false);
                setIdPatch(-1)
                setMs('')
            }
        } catch (error) {
            console.log(error);
            setIsDisabled(false);
        }
    }
    return (
        <div className='fixed inset-0 z-[150] bg-caspian-on_primary/30 flex items-center justify-center'>
            <div className='bg-caspian-surface w-[30%] rounded-md'>
                {/* <div className='flex justify-between items-center px-5 py-2'>
                    <span>افزودن فرآیند و فعالیت مرتبط</span>
                    <PiX className='cursor-pointer' onClick={() => setShowModal2(false)} />
                </div> */}
                <div className='flex flex-col gap-5 border-t border-caspian-gray2'>
                    <div className='flex flex-col gap-2 px-5 pt-3'>
                        {/* process */}
                        <ListMS
                            ms={ms}
                            setMs={setMs}
                            msList={msList}
                            setShowListms={setShowListms}
                            showListms={showListms}
                            msSelected={msSelected}
                            setMsSelected={setMsSelected}
                            lable='سیستم مدیریتی'
                        />
                    </div>
                    <div className='px-5 pb-3'>
                        {/* activity */}
                        {/* <div
                            onClick={() => setShowStandard(true)}
                            className='cursor-pointer border border-[#3C77A2] rounded-md py-2 p-3 '>
                            <div className='flex justify-between items-center'>
                                <p className='text-[#3C77A2] flex items-center gap-2 text-sm'>
                                    <PiPlusBold />
                                    <span>انتخاب</span>
                                </p>
                                {standardFinalSelected?.length > 0 ? (
                                    <span className="text-xs">
                                        {standardFinalSelected?.length} مورد افزوده شد
                                    </span>
                                ) : (
                                    <span className="text-xs">موردی افزوده نشده</span>
                                )}
                            </div> */}
                        {/* {showStandard && (
                                <ListStandard
                                    finalSelected={standardFinalSelected}
                                    setFinalSelected={setStandardFinalSelected}
                                    selected={standardSelected}
                                    setSelected={setStandardSelected}
                                    setShowSection={setShowStandard}
                                    showSection={showStandard}
                                    title="لیست استاندازد ها"
                                    url={`/api/v1/standard/managmentSystem/${ms}/`}
                                    list={list}
                                    setList={setList}
                                />
                            )} */}
                        {/* <div className='flex flex-col gap-2'>
                            <span className='text-xs text-caspian-gray4'>استانداردها را انتخاب کنید</span>
                            {ms && (<>
                                <div>
                                    <span className='text-xs text-caspian-gray4'>primary standard</span>
                                    <div className='flex gap-2 items-center text-xs text-caspian-gray4 my-2'>
                                        <input type="checkbox" onChange={()=>handleCLick(primaryStandard?.id)} />
                                        <span>{primaryStandard?.standardNumber}</span>
                                    </div>
                                </div>
                                <div>
                                    <span className='text-xs text-caspian-gray4'>main standard</span>
                                    {
                                        mainStandard?.map(m=><div key={m?.id} className='flex gap-2 items-center text-xs text-caspian-gray4 my-2'>
                                            <input type="checkbox" onChange={()=>handleCLick(m?.id)} />
                                            <span>{m?.standardNumber}</span>
                                        </div>)
                                    }
                                </div>
                                <div>
                                    <span className='text-xs text-caspian-gray4'>secondary standard</span>
                                    {
                                        secondaryStandard?.map(m=><div key={m?.id} className='flex gap-2 items-center text-xs text-caspian-gray4 my-2'>
                                            <input type="checkbox" onChange={()=>handleCLick(m?.id)} />
                                            <span>{m?.standardNumber}</span>
                                        </div>)
                                    }
                                </div>
                            </>)
                            }
                        </div> */}
                        {managementSystemDataDetails.length !== 0 && (
                            <div className="flex flex-col gap-1.5 w-full relative">
                                <label className="text-xs text-caspian-gray">
                                    استاندارد را انتخاب کنید
                                </label>

                                {/* start primary standard */}
                                <div className="flex flex-col gap-2 w-full">
                                    <span className="text-xs">primary standard</span>

                                    <div className="w-full">
                                        <div className="flex items-center gap-1">
                                            <input
                                                checked={primarySelected.includes(
                                                    managementSystemDataDetails?.primaryStandard?.id
                                                )}
                                                onChange={(e) => {
                                                    setPrimarySelected(e.target.checked
                                                        ? [
                                                            ...primarySelected,
                                                            managementSystemDataDetails?.primaryStandard
                                                                ?.id,
                                                        ]
                                                        : primarySelected.filter(
                                                            (f) =>
                                                                f !==
                                                                managementSystemDataDetails?.primaryStandard
                                                                    ?.id
                                                        )
                                                    );
                                                }}
                                                type="checkbox"
                                                id={managementSystemDataDetails?.primaryStandard?.id}
                                                className="accent-caspian-primary-400 cursor-pointer"
                                            />
                                            <label
                                                htmlFor={managementSystemDataDetails?.primaryStandard.id}
                                                className="flex items-center gap-8 w-full text-xs cursor-pointer"
                                            >
                                                <span>
                                                    {
                                                        managementSystemDataDetails?.primaryStandard
                                                            .standardNumber
                                                    }
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {/* end primary standard */}

                                {/* start main standard */}
                                <div className="flex flex-col gap-2">
                                    <span className="text-xs">main standard</span>

                                    <div className="w-full">
                                        <div className="flex flex-col gap-1">
                                            {managementSystemDataDetails?.mainStandard?.map((m, i) => (
                                                <div key={i} className="flex items-center gap-1">
                                                    <input
                                                        checked={mainSelected.includes(m?.id)}
                                                        onChange={(e) => {
                                                            setMainSelected(
                                                                e.target.checked
                                                                    ? [...mainSelected, m?.id]
                                                                    : mainSelected.filter((f) => f !== m?.id)
                                                            );
                                                        }}
                                                        type="checkbox"
                                                        id={m?.id}
                                                        className="accent-caspian-primary-400 cursor-pointer"
                                                    />
                                                    <label
                                                        htmlFor={m?.id}
                                                        className="flex items-center gap-8 w-full text-xs cursor-pointer"
                                                    >
                                                        <span>{m?.standardNumber}</span>
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                {/* end main standard */}

                                {/* start secondary standard */}
                                <div className="flex flex-col gap-2">
                                    <span className="text-xs">secondary standard</span>

                                    <div className="w-full">
                                        <div className="flex flex-col gap-1">
                                            {managementSystemDataDetails?.secondaryStandard?.map(
                                                (m, i) => (
                                                    <div key={i} className="flex items-center gap-1">
                                                        <input
                                                            checked={secondarySelected.includes(m?.id)}
                                                            onChange={(e) => {
                                                                setSecondarySelected(
                                                                    e.target.checked
                                                                        ? [...secondarySelected, m?.id]
                                                                        : secondarySelected.filter(
                                                                            (f) => f !== m?.id
                                                                        )
                                                                );
                                                            }}
                                                            type="checkbox"
                                                            id={m?.id}
                                                            className="accent-caspian-primary-400 cursor-pointer"
                                                        />
                                                        <label
                                                            htmlFor={m?.id}
                                                            className="flex items-center gap-8 w-full text-xs cursor-pointer"
                                                        >
                                                            <span>{m?.standardNumber}</span>
                                                        </label>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {/* end secondary standard */}
                            </div>
                        )}
                    </div>
                </div>

                <div className='flex gap-10 text-sm 2xl:text-xs px-5 pt-5 pb-2'>
                    {/* button */}
                    <button
                        onClick={handleSubmit}
                        className='bg-caspian-secondary-500 text-caspian-surface
                        p-1.5 px-3 rounded-md'
                    >تایید</button>
                    <button className='text-caspian-error2' onClick={() => setShowModal(false)}>لغو</button>
                </div>
            </div>
        </div>
    );
}

export default ModalMs;
