import React from 'react';
import AuditPlannigTable from './AuditPlannigTable';
import { Link, useParams } from 'react-router-dom';

const MainAuditPlannigList = ({ data }) => {
    const {id}=useParams();
    return (
        <div className='w-full 2xl:w-[90%] flex flex-col gap-5 pt-2'>
            {/* profile info */}
            <div className='bg-caspian-surface shadow-[0px_4px_4px_0px_#00000040] px-1.5 py-4 2xl:p-6 rounded-lg flex flex-col gap-2'>
                <div className='flex justify-between items-start gap-4'>
                    {/* info */}
                    <div className='flex items-start gap-4'>
                        <div className='flex flex-col gap-2'>
                            <div className='flex gap-4 text-xs 2xl:text-sm'>
                                <p className='flex gap-2 items-center'>
                                    <span className='text-[#848484] whitespace-nowrap'>نوع : </span>
                                    <span className='whitespace-nowrap'>{data?.auditType === "period" ? 'دوره ای' : 'موردی'}</span>
                                </p>
                                <p className='flex gap-2 items-center'>
                                    <span className='text-[#848484] whitespace-nowrap'>نوبت :</span>
                                    <span className='whitespace-nowrap'>{data?.title}</span>
                                </p>
                                <p className='flex gap-2 items-center'>
                                    <span className='text-[#848484] whitespace-nowrap'>سال : </span>
                                    <span className='whitespace-nowrap'>{new Date(data?.startDate).toLocaleDateString('fa-ir').split('/')[0]}</span>
                                </p>
                            </div>
                            <div>
                                <Link 
                                to={`/audit/${id}`}
                                className='text-xs text-caspian-surface bg-[#3C77A2] p-1.5 rounded-lg text-center'>
                                    مشاهده شناسنامه پروفایل
                                </Link>
                            </div>
                        </div>
                        <div className='flex flex-col gap-4'>
                            <div className='flex gap-2 items-center basis-1/3 text-xs 2xl:text-sm'>
                                <span className='whitespace-nowrap text-[#848484]'>
                                    سر ممیز :
                                </span>
                                <span className='whitespace-nowrap'>{data?.leadAudit?.profileAuditorRelated?.firstName
                                    + ' ' + data?.leadAudit?.profileAuditorRelated?.lastName}</span>
                            </div>
                            <div className='flex gap-1 items-center basis-1/3 text-xs 2xl:text-sm'>
                                <span className='whitespace-nowrap text-[#848484]'>
                                    سیستم های مدیریت:
                                </span>
                                {data?.auditProfileMSRelatedProfile?.map((m, i) => <p key={m?.id}>
                                    <span className='whitespace-nowrap'>{m?.msRelated?.title}</span>
                                    {i + 1 < data?.auditProfileMSRelatedProfile?.length && <span> / </span>}
                                </p>)}
                            </div>
                        </div>
                    </div>
                    {/* status */}
                    <div className='flex items-center gap-2 text-xs 2xl:text-base 2xl:leading-5'>
                        <span className='text-[#3C77A2]'>
                            وضعیت پروفایل :
                        </span>
                        <span>
                            {data?.auditProcess === 'schedule-create' ? 'ایجاد چک لیست' : ''}
                        </span>
                    </div>
                    {/* date */}
                    <div className='flex flex-col gap-4'>
                        <div className='flex gap-2 items-center text-xs 2xl:text-sm'>
                            <span className='text-[#848484] whitespace-nowrap'>تاریخ شروع دوره:</span>
                            <span className='whitespace-nowrap text-[#3C77A2]'>{new Date(data?.startDate).toLocaleDateString('fa-ir')}</span>
                        </div>
                        <div className='flex gap-2 items-center text-xs 2xl:text-sm'>
                            <span className='text-[#848484] whitespace-nowrap'>تاریخ پایان دوره:</span>
                            <span className='whitespace-nowrap text-[#3C77A2]'>{new Date(data?.endDate).toLocaleDateString('fa-ir')}</span>
                        </div>
                    </div>
                </div>
            </div>
            {
                data?.AuditProfileRelatedSchedule?.length < 1 ? <p className='text-caspian-gray4 text-center text-sm'>برنامه ممیزی ایجاد نشده.</p>
                    : <AuditPlannigTable profile={data?.AuditProfileRelatedSchedule} />
            }
        </div>
    );
}

export default MainAuditPlannigList;
