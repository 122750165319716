import React from "react";
import SingleNumberBox from "./SingleNumberBox";

const MainNumberBox = ({
  numberList,
  setNumberList,
  numberSelected,
  setNumberSelected,
  standardSelected,
}) => {
  const numberHandler = (input) => {
    setNumberSelected(input);
    console.log(input);
  };

  const allNumbers = () => {
    setNumberSelected(null);
    setNumberSelected(null);
  };

  return (
    <div className="w-full rounded-md flex items-center justify-center">
      <div className="flex items-center gap-0">
        {numberList?.map((n, i) => (
          <SingleNumberBox
            key={i}
            numberTitle={n.clauseRelated[0].clauseNumber}
            onClick={() => numberHandler(n)}
            isSelected={
              numberSelected?.clauseRelated[0]?.clauseNumber ===
              n?.clauseRelated[0]?.clauseNumber
                ? true
                : false
            }
          />
        ))}
        {standardSelected && (
          <button
            onClick={allNumbers}
            className={`min-w-[32px] py-1 px-4 text-caspian-secondary-600 border border-caspian-secondary-600 flex items-center gap-4 text-xs font-bold ${
              numberSelected ? "bg-caspian-surface" : "bg-caspian-blue-200"
            }`}
          >
            <span>همه</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default MainNumberBox;
