import React from "react";
import SingleQuestionList from "./SingleQuestionList";

const MainQuestionList = () => {
  return (
    <div className="p-4 pt-0">
      <div className="flex flex-col gap-0 w-full">
        <SingleQuestionList />
      </div>
    </div>
  );
};

export default MainQuestionList;
