import React from 'react';
import { useSearchParams } from 'react-router-dom';

const TabBtn = ({btn,activeBtn,setActiveBtn,text,icon,setSearch}) => {
    const [searchParams,setSearchParams]=useSearchParams();
    function handleSearch(key, value) {
        setSearchParams((prevParams) => {
            if (value === null || value === "") {
                prevParams.delete(key);
            } else {
                prevParams.set(key, value);
            }
            return prevParams;
        });
    }

    const handleClick=()=>{
        setActiveBtn(btn);
        if(activeBtn==='list'){
            handleSearch('search','');
            setSearch('');
        }
    }
    return (
        <button
        className={`${activeBtn===btn?'font-bold border border-b-0 rounded-tr rounded-tl border-[#9CA3AF] text-[#4B5563]':'bg-caspian-surface border-b-2 border-dashed border-[#9CA3AF] text-[#BEBEBE]'} text-xs lg:text-sm p-2 flex gap-2 items-center whitespace-nowrap`}
        onClick={handleClick}
        >
            {icon}
            {text}
        </button>
    );
}

export default TabBtn;
