import React from 'react';

const Process = () => {
    return (
        <>
            {/* <div className='border border-caspian-darkgray-200 rounded-lg overflow-hidden'>
            <div className="header flex basis-full divide-x-2 divide-x-reverse divide-caspian-darkgray-200 text-caspian-darkgray-600 bg-caspian-darkgray-100">
                <div className='basis-1/12 p-2 text-center text-xs 2xl:text-sm whitespace-nowrap'>ردیف</div>
                <div className='basis-5/12 p-2 text-xs 2xl:text-sm whitespace-nowrap'>عنوان فرایند</div>
                <div className='basis-1/12 p-2 text-xs 2xl:text-sm text-center whitespace-nowrap'>کد فرایند</div>
                <div className='basis-5/12 p-2 text-xs 2xl:text-sm whitespace-nowrap'>فعالیت‌های مرتبط</div>
            </div>
            <div className="body flex basis-full divide-x-2 divide-x-reverse divide-caspian-darkgray-200 border-t border-caspian-darkgray-200">
                <div className='basis-1/12 p-2 text-xs 2xl:text-sm text-center'>1</div>
                <div className='basis-5/12 p-2 text-xs 2xl:text-sm'></div>
                <div className='basis-1/12 p-2 text-xs 2xl:text-sm text-center'> </div>
                <div className='basis-5/12 p-2 text-xs 2xl:text-sm'> </div>
            </div>
            <div className="body flex basis-full divide-x-2 divide-x-reverse divide-caspian-darkgray-200 border-t border-caspian-darkgray-200">
                <div className='basis-1/12 p-2 text-xs 2xl:text-sm text-center'>2</div>
                <div className='basis-5/12 p-2 text-xs 2xl:text-sm'></div>
                <div className='basis-1/12 p-2 text-xs 2xl:text-sm text-center'> </div>
                <div className='basis-5/12 p-2 text-xs 2xl:text-sm'> </div>
            </div>
        </div> */}
            <div className="w-full overflow-x-auto">
                <table className='min-w-[992px] w-full table-fixed text-xs xl:text-sm rounded-tr-xl rounded-tl-xl bg-caspian-surface'>
                    <thead>
                        <tr>
                            <th className='bg-caspian-primary-500 text-caspian-surface font-normal w-[10%] p-1.5 rounded-tr-xl'>
                                ردیف
                            </th>
                            <th className='bg-caspian-primary-500 text-caspian-surface font-normal w-[40%] p-1.5'>
                                عنوان فرآیند
                            </th>
                            <th className='bg-caspian-primary-500 text-caspian-surface font-normal w-[10%] p-1.5'>
                                کد فرآیند
                            </th>
                            <th className='bg-caspian-primary-500 text-caspian-surface font-normal w-[40%] p-1.5 rounded-tl-xl'>
                                فعالیت‌های مرتبط
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='cursor-pointer border-b text-xs 2xl:text-sm border-b-caspian-gray2'>
                            {/* <th className='w-[10%] text-center font-normal text-xs'>1</th>
                            <th className='w-[40%] text-center font-normal text-xs'> */}
                            {/* مدیریت استراتژیک */}
                            {/* 
                            </th>
                            <th className='w-[10%] text-center font-normal text-xs'> */}
                            {/* 80-92-60 */}
                            {/* </th>
                            <th className='w-[40%] text-center font-normal text-xs' title=''>
                                <span className='line-clamp-2 leading-6'> */}
                            {/* لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است */}
                            {/* </span>
                            </th> */}
                            <td colSpan={4} className='bg-caspian-gray3'>
                                <div className='py-5 flex flex-col gap-2 items-center justify-center'>
                                    <div className='w-[220px] h-[220px] flex justify-center'>
                                        <img src="/assets/images/library/not-found.svg" alt="موردی یافت نشد..." />
                                    </div>
                                    <h2 className='text-center text-sm'> موردی یافت نشد...</h2>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default Process;
