import React, { useContext, useState } from "react";
import API from "../../../../api/API";
import { AuthContext } from "../../../../context/auth/AuthContext";
import { useParams } from "react-router-dom";

const MainAcceptAudit = ({ auditProcess, setRefreshProfile,sectionList }) => {
  const { id } = useParams();
  const { token } = useContext(AuthContext);
  const [isAccept, setIsAccept] = useState(false);
  console.log(auditProcess);
  const scheduleReview = async () => {
    try {
      if (!token && !id) return;
      console.log(sectionList);
      // const res = await API.patch(
      //   `/api/v1/audit/profile/detial/${id}/`,
      //   {
      //     auditProcess: "schedule-review",
      //   },
      //   {
      //     headers: { Authorization: token },
      //   }
      // );
      // console.log(res.data);
      // setRefreshProfile(1);
      // setIsAccept(false);
    } catch (error) {
      console.log(error);
    }
  };
  const scheduleConfirm = async () => {
    try {
      if (!token && !id) return;
      const res = await API.patch(
        `/api/v1/audit/profile/detial/${id}/`,
        {
          auditProcess: "schedule-confirm",
        },
        {
          headers: { Authorization: token },
        }
      );
      console.log(res.data);
      setRefreshProfile(1);
      setIsAccept(false);
    } catch (error) {
      console.log(error);
    }
  };
  const scheduleApprove = async () => {
    try {
      if (!token && !id) return;
      const res = await API.patch(
        `/api/v1/audit/profile/detial/${id}/`,
        {
          auditProcess: "schedule-approve",
        },
        {
          headers: { Authorization: token },
        }
      );
      console.log(res.data);
      setRefreshProfile(1);
      setIsAccept(false);
    } catch (error) {
      console.log(error);
    }
  };
  const checklistCreate = async () => {
    try {
      if (!token && !id) return;
      const res = await API.patch(
        `/api/v1/audit/profile/detial/${id}/`,
        {
          auditProcess: "checklist-create",
        },
        {
          headers: { Authorization: token },
        }
      );
      console.log(res.data);
      setRefreshProfile(1);
      setIsAccept(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="min-h-[10vh] w-full rounded-md bg-caspian-surface border border-caspian-gray2 p-2 flex items-center">
      {auditProcess === "schedule-create" && (
        <div className="flex items-center gap-8">
          <div className="flex items-center gap-2">
            <input
              onChange={() => setIsAccept(!isAccept)}
              id="accept"
              type="checkbox"
              className=" cursor-pointer accent-caspian-secondary-400"
            />
            <label
              htmlFor="accept"
              className="text-xs cursor-pointer font-bold"
            >
              آیا از تکمیل مرحله برنامه ریزی اولیه اطمینان دارید؟
            </label>
          </div>
          <div className="flex items-center gap-2">
            <button
              disabled={isAccept ? false : true}
              onClick={scheduleReview}
              className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-secondary-500 text-caspian-surface text-xs p-1.5 rounded-md"
            >
              ثبت و ارسال
            </button>
          </div>
        </div>
      )}
      {auditProcess === "schedule-review" && (
        <div className="flex items-center gap-8">
          <div className="flex items-center gap-2">
            <input
              onChange={() => setIsAccept(!isAccept)}
              id="accept"
              type="checkbox"
              className=" cursor-pointer accent-caspian-secondary-400"
            />
            <label
              htmlFor="accept"
              className="text-xs cursor-pointer font-bold"
            >
              آیا از بررسی مرحله برنامه ریزی اولیه اطمینان دارید؟
            </label>
          </div>
          <div className="flex items-center gap-2">
            <button
              disabled={isAccept ? false : true}
              onClick={scheduleConfirm}
              className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-secondary-500 text-caspian-surface text-xs p-1.5 rounded-md"
            >
              ثبت و ارسال
            </button>
          </div>
        </div>
      )}
      {auditProcess === "schedule-confirm" && (
        <div className="flex items-center gap-8">
          <div className="flex items-center gap-2">
            <input
              onChange={() => setIsAccept(!isAccept)}
              id="accept"
              type="checkbox"
              className=" cursor-pointer accent-caspian-secondary-400"
            />
            <label
              htmlFor="accept"
              className="text-xs cursor-pointer font-bold"
            >
              آیا از تایید مرحله برنامه ریزی اولیه اطمینان دارید؟
            </label>
          </div>
          <div className="flex items-center gap-2">
            <button
              disabled={isAccept ? false : true}
              onClick={scheduleApprove}
              className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-secondary-500 text-caspian-surface text-xs p-1.5 rounded-md"
            >
              ثبت و ارسال
            </button>
          </div>
        </div>
      )}
      {auditProcess === "schedule-approve" && (
        <div className="flex items-center gap-8">
          <div className="flex items-center gap-2">
            <input
              onChange={() => setIsAccept(!isAccept)}
              id="accept"
              type="checkbox"
              className=" cursor-pointer accent-caspian-secondary-400"
            />
            <label
              htmlFor="accept"
              className="text-xs cursor-pointer font-bold"
            >
              آیا از تصویب مرحله برنامه ریزی اولیه اطمینان دارید؟
            </label>
          </div>
          <div className="flex items-center gap-2">
            <button
              disabled={isAccept ? false : true}
              onClick={checklistCreate}
              className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-secondary-500 text-caspian-surface text-xs p-1.5 rounded-md"
            >
              ثبت و ارسال
            </button>
          </div>
        </div>
      )}
      {auditProcess === "checklist-create" && (
        <div className="flex items-center gap-2">
          <label htmlFor="accept" className="text-xs font-bold">
            لطفا مرحله چک لیست هر برنامه ریزی را انجام دهید.
          </label>
        </div>
      )}
    </div>
  );
};

export default MainAcceptAudit;
