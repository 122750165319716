import React, { useRef } from "react";

const SingleMembersSection = ({ isPresent, setIsPresent, name, status, id, index }) => {
  const checkedRef = useRef();
  // console.log(isPresent);
  return (
    <div className="flex items-center justify-between w-full border border-caspian-gray2 rounded-md p-2">
      <span className="text-xs w-3/4">{name}</span>
      <span className="text-xs text-caspian-secondary-500 w-1/4">{status}</span>
      <div className="flex items-center gap-2">
        <label
          className={`cursor-pointer flex items-center gap-1 text-xs ${checkedRef?.current?.checked ? "text-[#389b82]" : "text-[#db3d5f]"
            }`}
        >
          <input
            ref={checkedRef}
            type="checkbox"
            defaultChecked={isPresent[index] === id}
            onChange={async (e) => {
              (await e?.target?.checked)
                ? setIsPresent([...isPresent, id])
                : setIsPresent(isPresent.filter((d) => d !== id));
            }}
            className={`cursor-pointer accent-[#389b82]`}
          />
          {checkedRef?.current?.checked ? "حاضر" : "غایب"}
        </label>
        {/* <input
            ref={checkedRef}
            type="checkbox"
            onChange={async (e) => {
              setIsAbsent(e?.target?.checked);
            }}
            className={`cursor-pointer accent-[#389b82]`}
          />
          {checkedRef?.current?.checked ? "حاضر" : "غایب"}
        </label> */}
      </div>
    </div>
  );
};

export default SingleMembersSection;
