import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';

const InfoAudit = () => {
    const [status, setStatus] = useState(false);
    const { id } = useParams();
    return (
        <div className='bg-caspian-surface px-2 xl:px-6 py-2 mt-4 rounded-lg shadow-[0px_4px_4px_0px_#00000040] flex flex-col gap-3'>
            <div className='flex justify-between items-center text-xs xl:text-sm'>
                <span>واحد :مدیریت بحران و پدافند غیرعامل</span>
                <Link className='text-[#3C77A2]' to={`/audit/${id}`}>مشاهده کل چک لیست</Link>
            </div>
            <div className='flex justify-between items-center'>
                <div className='flex gap-6 text-xs xl:text-sm'>
                    <p className='flex gap-2'>
                        <span className='text-[#848484]'>نوع : </span>
                        <span>دوره ای</span>
                    </p>
                    <p className='flex gap-2'>
                        <span className='text-[#848484]'>نوبت :  </span>
                        <span> اول</span>
                    </p>
                    <p className='flex gap-2'>
                        <span className='text-[#848484]'>سال : </span>
                        <span> 1403</span>
                    </p>
                </div>
                <div className='flex flex-col gap-1 basis-[40%] xl:basis-[60%]'>
                    <div className='basis-2/4 flex gap-2 text-xs xl:text-sm'>
                        <span>تعداد پاسخ :</span>
                        <span>{0}</span>
                        <span>سوال از</span>
                        <span>{4} سوال </span>
                    </div>
                    <div className={`${status ? 'bg-[#22A900]' : 'bg-caspian-darkgray-300'} w-full rounded-full p-1`}>

                    </div>
                </div>
            </div>
            <div className='flex gap-2 text-xs xl:text-sm'>
                <span className='text-[#848484]'>تاریخ ممیزی :</span>
                <span>1403/05/22</span>
            </div>
        </div>
    );
}

export default InfoAudit;
