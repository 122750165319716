import React from "react";

const OutActivity = ({ thisTitleProcess, thisTitleGoal }) => {
  return (
    <div className="flex flex-col gap-2 p-2">
      {/* {thisTitleProcess && (
        <h2 className="text-xs font-bold text-caspian-gray4 w-fit py-2 px-4">
          گروه فرایندها - {thisTitleProcess}
        </h2>
      )}
      {thisTitleGoal && (
        <h2 className="text-xs font-bold text-caspian-gray4 w-fit py-2 px-4">
          گروه اهداف کلان - {thisTitleGoal}
        </h2>
      )} */}
      <div className="flex flex-col items-center justify-center py-12 gap-2 w-1/2">
        <span className="text-xs text-caspian-gray4">
          این فعالیت در دسترس نمی باشد
        </span>
      </div>
    </div>
  );
};

export default OutActivity;
