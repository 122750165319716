import React, { useState } from "react";
import SingleTeamMembers from "./SingleTeamMembers";

const MainTeamMembersAudit = ({ isAuditAbsent, setIsAuditAbsent, isAuditPresent, setIsAuditPresent, auditMember,
  setDesc
}) => {

  return (
    <div className="flex items-start gap-2 w-full">
      <div className="flex items-start flex-col gap-2 w-[60%]">
        <label className="">
          <span className="text-xs text-caspian-gray4">تیم ممیزی</span>
        </label>
        <div className="max-h-[280px] overflow-y-auto w-full flex flex-col gap-2">
          {
            auditMember?.length > 0 && auditMember?.map((a, i) => <SingleTeamMembers
              index={i}
              key={a?.id}
              {...a}
              isAbsent={isAuditAbsent}
              isPresent={isAuditPresent}
              setIsAbsent={setIsAuditAbsent}
              setIsPresent={setIsAuditPresent}
            />)
          }
          {/* <SingleTeamMembers
            team={{
              fullname: "الهام آرامی پور",
              job: "سرپرست",
            }}
            isAbsent={isAuditAbsent}
            isPresent={isAuditPresent}
            setIsAbsent={setIsAuditAbsent}
            setIsPresent={setIsAuditPresent}
          />
          <SingleTeamMembers
            team={{
              fullname: "محمد امین امیر خلیلی",
              job: "ممیز",
            }}
            isAbsent={isAuditAbsent}
            isPresent={isAuditPresent}
            setIsAbsent={setIsAuditAbsent}
            setIsPresent={setIsAuditPresent}
          />
          <SingleTeamMembers
            team={{
              fullname: "سونیا کاوه",
              job: "ممیز",
            }}
            isAbsent={isAuditAbsent}
            isPresent={isAuditPresent}
            setIsAbsent={setIsAuditAbsent}
            setIsPresent={setIsAuditPresent}
          /> */}
        </div>
      </div>
      <div className="flex items-start flex-col gap-2 w-[40%]">
        <label className="">
          <span className="text-xs text-caspian-gray4">
            سایر افراد تیم ممیزی
          </span>
        </label>
        <textarea
          onChange={(e) => setDesc(e?.target?.value)}
          placeholder="سایر افراد مورد نظر را یادداشت کنید"
          rows={5}
          className="w-full resize-none text-xs p-2 rounded-md border border-caspian-gray"
        />
      </div>
    </div>
  );
};

export default MainTeamMembersAudit;
