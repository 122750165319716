import React, { useState } from "react";
import { PiPlusBold } from "react-icons/pi";
import NaModal from "./NaModal";


const Score = ({ title, score, setScore, i,setCount }) => {
    const [selectedIndex, setSelectedIndex] = useState(-1); // نگه‌داری ایندکس انتخاب‌شده
    const [na, setNa] = useState(false);
    const [showNa, setShowNa] = useState(false);


    // رنگ‌های مختلف بر اساس خانه کلیک‌شده
    const colors = ["bg-[#FF3333]", "bg-[#FFCC33]", "bg-[#FCFF7D]", "bg-[#ADEBBB]", "bg-[#5AD878]"];


    // تابع کلیک
    const handleClick = (index) => {
        setSelectedIndex(index); // تنظیم ایندکس انتخاب‌شده
        const s = (index + 1) * 20;
        score[i] = s;
        setCount(true);
        console.log('score', score);
    };

    const handleNa = () => {
        setNa(!na);
        score[i] = 0;
        setSelectedIndex(-1)
        setCount(true);
    }
    return (
        <div className="flex items-center">
            <div className="text-[11px] lg:text-xs basis-2/3">
                {title}
            </div>
            <div className="flex items-center justify-between basis-1/3">
                <div className="basis-1/3 ml-2 lg:ml-0">
                    <div
                        onClick={handleNa}
                        className={`${na ? 'bg-[#717171]' : ''} w-5 h-5 2xl:w-6 2xl:h-6 flex pt-1 items-center justify-center border-2 text-[#BCBCBC] text-xs border-[#BCBCBC] rounded cursor-pointer`}>
                        <span>NA</span>
                    </div>
                </div>
                {na ? <div
                    onClick={() => setShowNa(true)}
                    className="text-[#3C77A2] text-sm flex gap-2 items-center basis-2/3 cursor-pointer">
                    <span>توضیحات</span>
                    <PiPlusBold />
                </div>
                    : <div className="flex gap-2">
                        {colors.map((color, index) => (
                            <div
                                key={index}
                                onClick={() => handleClick(index)} // کلیک روی هر خانه
                                //w-6 h-6
                                className={`w-5 h-5 2xl:w-6 2xl:h-6 border-2 border-[#BCBCBC] rounded cursor-pointer ${index <= selectedIndex ? colors[selectedIndex] : "bg-transparent"
                                    }`}
                            ></div>
                        ))}
                    </div>
                }
                {/* <div className="mt-6 text-xl font-semibold">
                امتیاز انتخاب‌شده: <span className="text-green-600">{(selectedIndex + 1) * 20}</span>
            </div> */}
            </div>
            {
                showNa && <NaModal setShowNa={setShowNa} />
            }
        </div>
    );
};
//۵
export default Score;

