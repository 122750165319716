import React, { useEffect, useState } from "react";
import RowView from "./RowView";
import Paginate from "./Paginate";
import { useSearchParams } from "react-router-dom";
import { PiCircleNotch } from "react-icons/pi";
import SingleTableListTeams from "./SingleTableListTeams";

const TableListTeams = ({ listTeams, loading, setRefreshTeamsList }) => {
  const [filterByTitle, setFilterByTitle] = useState("");
  const [filterByLeader, setFilterByLeader] = useState("");
  const [filterByCoordinator, setFilterByCoordinator] = useState("");
  const [filterByRiskTeamSubjectType, setFilterByRiskTeamSubjectType] =
    useState("");

  const [searchParams] = useSearchParams();
  const search = searchParams.get("search");
  const page = searchParams.get("page");
  const rows = searchParams.get("rows");

  let filterTitle = [];
  let filterLeader = [];
  let filterCoordinator = [];
  let filterRiskTeamSubjectType = [];

  useEffect(() => {
    if (search) {
      setCurrentPage(1);
    }
  }, [search]);

  let newData = [-1];
  if (listTeams[0] !== -1) {
    newData = search
      ? listTeams?.filter((d) =>
          d?.title?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase())
        )
      : listTeams;
  }

  if (newData[0] !== -1) {
    filterTitle = filterByTitle
      ? newData.filter((d) =>
          d?.title
            .toLocaleLowerCase()
            .includes(filterByTitle.toLocaleLowerCase())
        )
      : newData;

    filterRiskTeamSubjectType = filterByRiskTeamSubjectType
      ? filterTitle.filter((d) =>
          d?.riskTeamSubjectType
            .toLocaleLowerCase()
            .includes(filterByRiskTeamSubjectType.toLocaleLowerCase())
        )
      : filterTitle;

    filterLeader = filterByLeader
      ? filterRiskTeamSubjectType.filter((d) =>
          (d?.leader?.firstName + " " + d?.leader?.lastName)
            .toLocaleLowerCase()
            .includes(filterByLeader.toLocaleLowerCase())
        )
      : filterRiskTeamSubjectType;

    filterCoordinator = filterByCoordinator
      ? filterLeader.filter((d) =>
          (d?.coordinator?.firstName + " " + d?.coordinator?.lastName)
            .toLocaleLowerCase()
            .includes(filterByCoordinator.toLocaleLowerCase())
        )
      : filterLeader;
  }

  const [currentPage, setCurrentPage] = useState(page ? page : 1);
  const [recordsPerPage, setRecordsPerPage] = useState(rows ? rows : 10);
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = filterCoordinator.slice(firstIndex, lastIndex);
  const npage = Math.ceil(filterCoordinator.length / recordsPerPage);

  return (
    <div className="flex flex-col py-10 px-8 gap-4">
      {loading ? (
        <div className="flex items-center justify-center py-40">
          <PiCircleNotch className="text-caspian-secondary-600 text-2xl animate-spin" />
        </div>
      ) : (
        <div className="w-full overflow-x-auto">
          <table className="min-w-[992px] w-full text-left text-sm font-light rounded-tr-xl rounded-tl-xl">
            <thead className="border-b border-b-caspian-gray2 font-medium">
              <tr className="rounded-tr-xl rounded-tl-xl">
                <th
                  scope="col"
                  className="bg-caspian-primary-500 rounded-tr-xl relative p-2 w-[15%]"
                >
                  <input
                    // onClick={() => setCurrentPage(1)}
                    onChange={(e) => {
                      setFilterByTitle(e.target.value);
                    }}
                    type="search"
                    id="t1"
                    className={`${
                      filterByTitle ? "outline-caspian-surface/50 border" : ""
                    }peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50  cursor-pointer bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-16 border border-caspian-gray2`}
                  />
                  <label
                    htmlFor="t1"
                    className={`${
                      filterByTitle
                        ? "absolute left-full right-4 font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 text-caspian-surface"
                        : ""
                    }absolute cursor-pointer font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 peer-focus:left-full text-caspian-surface peer-focus:right-4 whitespace-nowrap`}
                  >
                    عنوان تیم
                  </label>
                </th>
                <th scope="col" className="bg-caspian-primary-500 p-2 w-[15%]">
                  <select
                    onChange={(e) =>
                      setFilterByRiskTeamSubjectType(e.target.value)
                    }
                    className="rounded-[4px] outline outline-1 outline-caspian-gray2 p-1 font-light text-sm w-full cursor-pointer bg-caspian-primary-500 text-caspian-surface"
                  >
                    <option value="">همه سرفصل‌ها</option>
                    <option value="strategic">ریسک‌های راهبردی</option>
                    <option value="operational">ریسک‌‌های عملیاتی</option>
                  </select>
                </th>
                <th
                  scope="col"
                  className="bg-caspian-primary-500 relative p-2 w-[12.5%]"
                >
                  <input
                    onChange={(e) => setFilterByLeader(e.target.value)}
                    type="search"
                    id="t2"
                    className={`${
                      filterByLeader ? "outline-caspian-surface/50 border" : ""
                    }peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50  cursor-pointer bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-[72px] border border-caspian-gray2`}
                  />
                  <label
                    htmlFor="t2"
                    className={`${
                      filterByLeader
                        ? "absolute left-full right-4 font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 text-caspian-surface"
                        : ""
                    }absolute cursor-pointer font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 peer-focus:left-full text-caspian-surface peer-focus:right-4 whitespace-nowrap`}
                  >
                    مسئول تیم
                  </label>
                </th>

                <th
                  scope="col"
                  className="bg-caspian-primary-500 relative p-2 w-[12.5%]"
                >
                  <input
                    onChange={(e) => setFilterByCoordinator(e.target.value)}
                    type="search"
                    id="t3"
                    className={`${
                      filterByCoordinator
                        ? "outline-caspian-surface/50 border"
                        : ""
                    }peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50  cursor-pointer bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-28 border border-caspian-gray2`}
                  />
                  <label
                    htmlFor="t3"
                    className={`${
                      filterByCoordinator
                        ? "absolute left-full right-4 font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 text-caspian-surface"
                        : ""
                    }absolute cursor-pointer font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 peer-focus:left-full text-caspian-surface peer-focus:right-4 whitespace-nowrap`}
                  >
                    هماهنگ کننده تیم
                  </label>
                </th>
                <th
                  scope="col"
                  className="bg-caspian-primary-500 relative p-2 w-[10%] text-center"
                >
                  <span className="text-caspian-surface text-sm font-normal whitespace-nowrap">
                    تاریخ شروع
                  </span>
                </th>
                <th
                  scope="col"
                  className="bg-caspian-primary-500 relative p-2 w-[10%] text-center"
                >
                  <span className="text-caspian-surface text-sm font-normal whitespace-nowrap">
                    تاریخ پایان
                  </span>
                </th>
                <th
                  scope="col"
                  className="bg-caspian-primary-500 relative p-2 w-[10%] text-center"
                >
                  <span className="text-caspian-surface text-sm font-normal whitespace-nowrap">
                    وضعیت
                  </span>
                </th>
                <th
                  scope="col"
                  className="bg-caspian-primary-500 relative p-2 w-[10%] text-center"
                >
                  <span className="text-caspian-surface text-sm font-normal whitespace-nowrap">
                    اقدام
                  </span>
                </th>
                <th
                  scope="col"
                  className="bg-caspian-primary-500 relative rounded-tl-xl p-2 w-[5%] text-center"
                ></th>
              </tr>
            </thead>
            {records.length > 0 ? (
              <tbody>
                {records.map((d, i) => (
                  <SingleTableListTeams
                    key={i}
                    id={d.id}
                    //عنوان تیم
                    title={d.title}
                    //مسئول تیم
                    leader={d?.leader?.firstName + " " + d?.leader?.lastName}
                    //سرفصل
                    riskTeamSubjectType={d?.riskTeamSubjectType}
                    // هماهنگ کننده
                    coordinator={
                      d?.coordinator?.firstName + " " + d?.coordinator?.lastName
                    }
                    // تاریخ شروع
                    startDate={
                      d?.startDate
                        ? new Date(d?.startDate).toLocaleDateString("fa-IR")
                        : "-"
                    }
                    // تاریخ پایان
                    endDate={
                      d?.endDate
                        ? new Date(d?.endDate).toLocaleDateString("fa-IR")
                        : "-"
                    }
                    // وضعیت
                    statusType={d?.statusType}
                    setRefreshTeamsList={setRefreshTeamsList}
                  />
                ))}
              </tbody>
            ) : (
              <tbody className="py-24">
                <tr>
                  <td className="py-24">
                    <div className="flex items-center justify-center w-full py-10">
                      <div className="z-10 absolute left-1/2 -translate-x-1/2 mx-auto">
                        <div className="flex flex-col gap-2 items-center justify-center w-full">
                          <img
                            src="/assets/images/library/not-found.svg"
                            alt="گروه یافت نشد..."
                          />
                          <h2>گروه یافت نشد...</h2>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      )}
      {records.length > 0 && (
        <div className="flex items-center justify-between py-2">
          <RowView
            setRecordsPerPage={setRecordsPerPage}
            setCurrentPage={setCurrentPage}
          />
          <Paginate
            pageCount={npage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
          <div className="text-sm flex items-center gap-4">
            <span>نمایش {firstIndex + 1}</span>
            <span>تا {lastIndex - (recordsPerPage - records.length)}</span>
            <span>از کل {filterCoordinator.length}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableListTeams;
