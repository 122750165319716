import React, { useEffect, useRef, useState } from 'react';
import { PiFileDocDuotone, PiFileImage, PiLinkSimpleLight, PiPlusBold, PiTrash } from "react-icons/pi";
import Score from './Score';
import ToggleSwitch from './ToggleSwitch';
import ListSection from './ListSection';
import StandardModal from './StandardModal';
import Modal from '../../../../../modal/Modal';
import convertFileSize from '../../../../../../helper/convertFileSize';

const scoreQuestion = [
    'در سیستم، طرح‌ریزی و برنامه‌ریزی درخصوص این موضوع صورت گرفته است',
    'این موضوع پیاده‌سازی و اجرا شده است',
    'نتایج اقدامات انجام‌شده مورد پایش و کنترل قرار گرفته است',
    'اثربخشی نتایج پایش و کنترل ‌مورد بررسی قرار گرفته و اقدامات بهبود مورد نیاز تعریف و اجرا شده است',
]
const ObservationDetails = ({ setShow, index, title, cri }) => {
    // شواهد
    const [evidence, setEvidence] = useState('');
    const [files, setFiles] = useState('');
    // یافته
    const [found, setFound] = useState('');

    const [score, setScore] = useState([0, 0, 0, 0]);
    const [number, setNumber] = useState('');
    const [count, setCount] = useState(true);
    // واحد پیگیری کننده
    const [section, setSection] = useState('');
    const [sectionSelected, setSectionSelected] = useState(null);
    const [sectionList, setSectionList] = useState([]);

    const [showModal, setShowModal] = useState(false);
    // پیگیری
    const [followUp, setFollowUp] = useState(false);

    // const [standard, setStandard] = useState('');
    // const [standardSelected, setStandardSelected] = useState(null);
    // const [standardList, setStandardList] = useState([]);
    // const [s,setS]=useState([]);

    // const [clause,setClause]=useState('');
    // const [clauseSelected,setClauseSelected]=useState([]);
    // const [finalSelected,setFinalSelected]=useState(clauseSelected);
    // const [c,setC]=useState([]);
    const [list, setList] = useState([]);

    const ref = useRef(null);
    // const [isUploadFile,setIsUploadFile]=useState(false);

    const handleUpload = (event) => {
        event.preventDefault();
        setFiles(event.target.files[0]);
        // const uploadFiles = event.target.files;
        // if (uploadFiles.length > 0) {
        //     const newFiles = Array.from(uploadFiles);
        //     setFiles((prevFiles) => [...prevFiles, ...newFiles]);
        // }
    };
    const handleRemoveFile = () => {
        setFiles(null);
        ref.current.value = null;
    }
    console.log(list);
    const handleRemoveStandard = (index) => {
        const rmv = list?.filter((l, i) => i !== index);
        setList(rmv);
    }
    useEffect(() => {
        if (count) {
            const n = score?.reduce((prev, next) => prev + next, 0);
            setNumber((n / 4));
            setCount(false);
        }
        console.log(number);
    }, [score, number, count])
    return (
        <Modal>
            <div className='bg-caspian-surface w-[95%] h-fit 2xl:h-fit 2xl:w-[90%] rounded-lg p-1.5 xl:py-4 xl:px-6 flex gap-8'>
                <div className='basis-[40%] lg:basis-1/2 flex flex-col gap-2'>
                    {/* iso and ms ... */}
                    <div className='flex items-center gap-6'>
                        <div className='flex gap-4 text-[#0D358C] text-xs'>
                            <span>OH&S</span>
                            <span>ISO 45001:2015</span>
                        </div>
                        <div className='flex gap-4 text-[#0D358C] text-xs'>
                            <span>6-1</span>
                            <span className='text-[#3CA29C]'>موقعیت و شرایط کاری</span>
                        </div>
                    </div>
                    <div className='border border-[#3C77A2] p-4 rounded-lg flex items-start gap-3 min-h-[140px]'>
                        <div className='w-[25px] h-[25px] flex items-center justify-center text-xs border text-[#3CA29C] border-[#3CA29C] rounded-lg'>
                            {/* {index+1} */} 1
                        </div>
                        <div className='text-xs text-justify'>
                            <p>
                                {/* {title} */}
                                سوال :سازمان شما عوامل داخلی و خارجی موثر بر سیستم مدیریت ایمنی و بهداشت شغلی را شناسایی و ارزیابی میکند ؟
                            </p>
                            <p className='text-[#3C77A2] mt-2'>
                                {/* {cri} */}
                                معیار : شناسایی عوامل داخلی و خارجی ، درنظر گرفتن نیاز های ذینفعان و ارزیابی منظم برای بهبود سیستم ایمنی و بهداشت شغلی است.
                            </p>
                        </div>
                    </div>
                    {/* شواهد عینی */}
                    <div className='flex flex-col text-xs gap-2'>
                        <span className='text-caspian-gray4'>شواهد عینی</span>
                        <textarea
                            onChange={(e) => setEvidence(e?.target?.value)}
                            placeholder='شواهد خود را یادداشت کنید'
                            className='border border-[#E5E7EB] rounded-lg resize-none p-2 placeholder:text-xs placeholder:text-caspian-gray4'
                            cols={10}
                            rows={10}
                        ></textarea>
                    </div>
                    {/* انتخاب فایل */}
                    <div className='flex justify-between items-center'>
                        <div>
                            <label htmlFor='file-upload'
                                className='text-[#3C77A2] text-xs flex gap-2 items-center cursor-pointer'>
                                <PiLinkSimpleLight className='text-base' />
                                <span>انتخاب فایل</span>
                            </label>
                            <input type="file" id="file-upload"
                                ref={ref}
                                onChange={handleUpload}
                                accept='.jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx,.svg,.ppt,.pptx,.zip,.rar'
                                className='hidden'
                            />
                        </div>
                        {
                            files && (
                                <div className='flex gap-3 items-center basis-2/3'>
                                    <div className='flex px-3 pb-1 items-center justify-between w-full text-sm border-b border-caspian-gray'>
                                        <div className='flex gap-3 items-center'>
                                            <p className='text-xl text-[#3C77A2]'>
                                                {
                                                    files?.type?.includes('jpg') || files?.type?.includes('jpeg') ||
                                                        files?.type?.includes('png') || files?.type?.includes('svg') ?
                                                        <PiFileImage /> :
                                                        <PiFileDocDuotone />
                                                }
                                            </p>
                                            <p className='text-xs'> {files?.name} </p>
                                        </div>
                                        <p className='text-xs rtl text-right'>{convertFileSize(files?.size)}</p>
                                    </div>
                                    <PiTrash className='cursor-pointer text-lg text-[#3C77A2]' onClick={handleRemoveFile} />
                                </div>
                            )
                        }

                    </div>
                    <div className='mt-auto flex gap-4'>
                        <button className='bg-[#3C77A2] text-caspian-surface rounded-lg flex justify-center items-center gap-2 py-1.5 2xl:py-2 w-[70px] 2xl:w-[100px] text-xs 2xl:text-sm'>
                            <span>تکمیل</span>
                        </button>
                        <button
                            onClick={() => setShow(false)}
                            className='border border-[#3C77A2] text-[#3C77A2] rounded-lg flex justify-center items-center gap-2 py-1.5 2xl:py-2 w-[50px] 2xl:w-[70px] text-xs 2xl:text-sm'>
                            <span>انصراف</span>
                        </button>
                    </div>
                </div>
                <div className='basis-[60%] lg:basis-1/2 flex flex-col gap-2'>
                    <div className='flex flex-col gap-2 border-b border-[#D9D9D9] pb-4'>
                        <span className='text-caspian-gray4 text-xs 2xl:text-sm'>براساس شواهد ممیزی، وضعیت موضوع پرسش و معیار مربوطه را از لیست ذیل انتخاب کنید</span>
                        {/* امتیازات */}
                        <div className='flex flex-col gap-4 text-xs'>
                            {
                                scoreQuestion?.map((t, i) => <Score
                                    key={i} i={i} title={t}
                                    setCount={setCount}
                                    setScore={setScore} score={score} />)
                            }
                        </div>
                        <div className='flex justify-end items-center text-xs 2xl:text-sm text-[#727272] gap-1.5'>
                            <span>امتیاز :</span>
                            <div className='w-[100px] border-2 border-[#EFEFEF] flex justify-center items-center h-6 2xl:h-10 rounded-lg'>
                                {/* {score?.reduce((prev,next)=>prev+next,0)} */}
                                {number}
                            </div>
                        </div>
                        {/* پیگیری  و واحد*/}
                        <div className='flex justify-between gap-10 items-center'>
                            <div className='basis-1/3 flex items-center gap-4'>
                                <span className='text-caspian-coffee-700 text-xs whitespace-nowrap'>نیاز به پیگیری بیشتر</span>
                                <ToggleSwitch setFollowUp={setFollowUp} followUp={followUp} />
                            </div>
                            <div className='basis-2/3 flex items-center gap-4'>
                                <span className='text-caspian-gray4 text-xs whitespace-nowrap'>واحد پیگیری کننده :</span>
                                <ListSection
                                    setSection={setSection}
                                    sectionSelected={sectionSelected}
                                    setSectionSelected={setSectionSelected}
                                    sectionList={sectionList}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col gap-2'>
                        {/* یافته */}
                        <div className='flex flex-col gap-2.5'>
                            <div className='flex justify-between items-center'>
                                <span className='text-xs 2xl:text-sm text-caspian-gray4'>یافته </span>
                                <div className='flex justify-between gap-6'>
                                    <div className='flex gap-2 items-center 2xl:items-baseline'>
                                        <input type="radio" name="t1" value='ok' onChange={(e) => setFound(e?.target?.value)} />
                                        <label htmlFor="t1" className='text-[#007F1E] text-xs 2xl:text-base font-semibold'>OK</label>
                                    </div>
                                    <div className='flex gap-2 items-center 2xl:items-baseline'>
                                        <input type="radio" name="t1" value='obs' onChange={(e) => setFound(e?.target?.value)} />
                                        <label htmlFor="t1" className='text-[#3C77A2] text-xs 2xl:text-base font-semibold'>OBS</label>
                                    </div>
                                    <div className='flex gap-2 items-center 2xl:items-baseline'>
                                        <input type="radio" name="t1" value='nc' onChange={(e) => setFound(e?.target?.value)} />
                                        <label htmlFor="t1" className='text-[#E07B28] text-xs 2xl:text-base font-semibold'>NC</label>
                                    </div>
                                </div>
                            </div>
                            <textarea
                                className='border border-[#E5E7EB] resize-none text-xs xl:text-sm placeholder:text-xs  placeholder:text-caspian-gray p-2 px-4 rounded-lg'
                                placeholder='شرح یافته خود را یادداشت کنید.'
                                cols="5" rows="5"></textarea>
                        </div>
                        {/* استاندارد و بند */}
                        <div className='border border-[#D9D9D9] rounded-lg flex flex-col gap-2'>
                            <div
                                onClick={() => setShowModal(true)}
                                className='flex items-center gap-2 text-[#3C77A2] text-xs 2xl:text-sm cursor-pointer p-1 2xl:p-2 px-4'>
                                <span>افزودن استاندارد و بند های مرتبط</span>
                                <PiPlusBold />
                            </div>
                            <div className='h-[80px] lg:h-[100px] overflow-y-auto pb-2 px-4'>
                                {
                                    list?.length > 0 && list?.map((l, index) => <div
                                        className='flex justify-between items-center' key={index}>
                                        <div className='w-full border-b border-[#CCCACA] p-2 text-xs flex gap-4'>
                                            <span className='w-[80px] whitespace-nowrap'>{l?.s?.standardNumber}</span>
                                            <div className='flex gap-2 w-full truncate'>
                                                {
                                                    l?.c?.map(d => <span key={d?.id}>{d?.clauseNumber}</span>)
                                                }
                                            </div>
                                        </div>
                                        <div>
                                            <PiTrash className='text-[#3C77A2] cursor-pointer'
                                                onClick={() => handleRemoveStandard(index)} />
                                        </div>
                                    </div>)
                                }
                            </div>
                        </div>
                        {
                            showModal && <StandardModal
                                setList={setList} list={list}
                                // s={s} setS={setS} c={c} setC={setC}
                                setShow={setShowModal} />
                        }
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default ObservationDetails;
