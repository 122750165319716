import React, { useContext, useState } from "react";
import StartTimePicker from "./times/StartTimePicker";
import EndTimePicker from "./times/EndTimePicker";
import StartDateTimePicker from "./times/StartDateTimePicker";
import { PiCalendar, PiCaretLeftLight, PiCircleNotch, PiPlusBold, PiTrash } from "react-icons/pi";
import MainTime from "./times/MainTime";
import MainTeamMembersAudit from "./team-members-audit/MainTeamMembersAudit";
import MainMembersSection from "./members-section/MainMembersSection";
import StrengthModal from "./strength-modal/StrengthModal";
import ImproveModal from "./improve-modal/ImproveModal";
import API from "../../../../../api/API";
import { AuthContext } from "../../../../../context/auth/AuthContext";

const auditMember = [
  {
    id: 1,
    name: 'الهام آرامی پور',
    isAbsent: false,
    job: 'سرپرست'
  },
  {
    id: 2,
    name: 'محمد امین امیر خلیلی',
    isAbsent: false,
    job: 'ممیز'
  },
  {
    id: 3,
    name: 'سونیا کاوه',
    isAbsent: false,
    job: 'ممیز'
  },
]
const sectionMember = [
  {
    id: 1,
    name: 'محسن علیپور',
    isAbsent: false,
    status: 'پاسخگو'
  },
  {
    id: 2,
    name: 'کاوه راد',
    isAbsent: false,
    status: 'پاسخگو'
  },
]

const MainInfoSectionAudit = ({show,setShow}) => {
  const { token } = useContext(AuthContext);
  const [isLoading,setIsLoading]=useState(false);

  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const [isAuditAbsent, setIsAuditAbsent] = useState(false);
  let a = auditMember.map(m => {
    if (!m.isAbsent)
      return m.id
  })
  const [isAuditPresent, setIsAuditPresent] = useState(a);
  // console.log(isAuditPresent);
  const [desc1, setDesc1] = useState('')
  const [desc2, setDesc2] = useState('')
  let b = sectionMember.map(m => {
    if (!m.isAbsent)
      return m.id
  })
  const [isAbsent, setIsAbsent] = useState(false);
  const [isPresent, setIsPresent] = useState(b);

  const [showStrength, setShowStrength] = useState(false);
  const [strengthList, setStrengthList] = useState([]);
  const [idStrength, setIdStrength] = useState('');

  const removeStrength = (index) => {
    const rmv = strengthList?.filter((s, i) => i !== index);
    setStrengthList(rmv)
  }
  const showDetailsstrength = (index) => {
    setIdStrength(index);
    setShowStrength(true);
  }

  const [showImprove, setShowImprove] = useState(false);
  const [improveList, setImproveList] = useState([]);
  const [idImprove, setIdImprove] = useState('');

  const removeImprove = (index) => {
    const rmv = improveList?.filter((s, i) => i !== index);
    setImproveList(rmv)
  }
  const showDetailsImprove = (index) => {
    setIdImprove(index);
    setShowImprove(true);
  }

  const handleSubmit = () => {
    let strength = strengthList?.map(s => {
      return s?.id
    })
    let improve = improveList?.map(s => {
      return s?.id
    })
    const formData = {
      startDate,
      startTime,
      endTime,
      isAuditPresent,
      isPresent,
      strength,
      improve,
      desc1,
      desc2
    }
    console.log('formData', formData);
    setIsLoading(true);
    // setShow(true);
    setShow('observation');
    try {
      // const res = API.post('link', formData, { headers: { Authorization: token } })
      // if(res?.data){
      //   setIsLoading(false)
      // }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }
  return (
    <div className="flex flex-col gap-4 w-[80%] 2xl:w-[70%] bg-caspian-surface rounded-[26px] p-2 2xl:p-4 shadow-[0px_4px_4px_0px_#00000040]">
      <span className="text-sm font-normal">اطلاعات ممیزی واحد</span>
      <p className="text-xs text-caspian-gray4">
        برای شروع مراحل ثبت مشاهدات ممیزی این واحد اطلاعات زیر را تکمیل کنید.
      </p>
      <MainTime
        setStartDate={setStartDate}
        setStartTime={setStartTime}
        setEndTime={setEndTime}
      />
      <MainTeamMembersAudit
        setDesc={setDesc1}
        auditMember={auditMember}
        isAuditAbsent={isAuditAbsent} setIsAuditAbsent={setIsAuditAbsent}
        isAuditPresent={isAuditPresent} setIsAuditPresent={setIsAuditPresent}
      />
      <MainMembersSection
        setDesc={setDesc2}
        sectionMember={sectionMember}
        isAbsent={isAbsent} setIsAbsent={setIsAbsent}
        isPresent={isPresent} setIsPresent={setIsPresent}
      />
      <div className="flex gap-4 items-center justify-between">
        <div className="basis-1/2 flex flex-col gap-2">
          <span className="text-caspian-gray4 text-xs">
            نقاط قوت مورد نظر خود را وارد کنید
          </span>
          <div className="flex flex-col gap-2">
            <p
              onClick={() => setShowStrength(true)}
              className="text-[#3C77A2] text-xs flex gap-2 font-semibold cursor-pointer">
              <span>نقاط قوت</span>
              <PiPlusBold />
            </p>
            {
              showStrength && <StrengthModal setShowStrength={setShowStrength} strengthList={strengthList}
                setStrengthList={setStrengthList} id={idStrength} setId={setIdStrength} />
            }
            <div className="max-h-[98px] h-[98px] overflow-y-auto">
              {
                strengthList.length > 0 && strengthList?.map((s, i) => <div className="flex justify-between items-center"
                  key={i}>
                  <div className="flex justify-between items-center w-full border-b border-[#CCCACA] p-2">
                    <span className="text-xs">{s?.title}</span>
                    <div
                      onClick={() => showDetailsstrength(s?.id)}
                      className="flex gap-1 items-center text-[#3C77A2] text-[10px] font-medium cursor-pointer">
                      <span>بیشتر</span>
                      <PiCaretLeftLight className="text-base" />
                    </div>
                  </div>
                  <div className="text-[#3C77A2]">
                    <PiTrash className="cursor-pointer" onClick={() => removeStrength(i)} />
                  </div>
                </div>)
              }
            </div>
          </div>
        </div>
        <div className="basis-1/2 flex flex-col gap-2">
          <span className="text-caspian-gray4 text-xs">
            فرصت بهبود مورد نظر خود را وارد کنید
          </span>
          <div className="flex flex-col gap-2">
            <p className="text-[#3C77A2] text-xs flex gap-2 font-semibold cursor-pointer"
              onClick={() => setShowImprove(true)}>
              <span>فرصت بهبود </span>
              <PiPlusBold />
            </p>
            {
              showImprove && <ImproveModal setShowImprove={setShowImprove} improveList={improveList}
                setImproveList={setImproveList} id={idImprove} setId={setIdImprove} />
            }
            <div className="max-h-[98px] h-[98px] overflow-y-auto">
              {
                improveList?.length > 0 && improveList?.map((s, i) => <div className="flex justify-between items-center"
                  key={i}>
                  <div className="flex justify-between items-center w-full border-b border-[#CCCACA] p-2">
                    <span className="text-xs">{s?.title}</span>
                    <div
                      onClick={() => showDetailsImprove(s?.id)}
                      className="flex gap-1 items-center text-[#3C77A2] text-[10px] font-medium cursor-pointer">
                      <span>بیشتر</span>
                      <PiCaretLeftLight className="text-base" />
                    </div>
                  </div>
                  <div className="text-[#3C77A2]">
                    <PiTrash className="cursor-pointer" onClick={() => removeImprove(i)} />
                  </div>
                </div>)
              }
            </div>
          </div>
        </div>
      </div>
      {/* start button */}
      <button
        onClick={handleSubmit}
        className="bg-[#3C77A2] text-caspian-surface text-sm text-center flex justify-center gap-2 w-[150px] rounded-lg p-1.5">
        <span>شروع</span>
        {isLoading && <span>
          <PiCircleNotch className='animate-spin' />
        </span>}
      </button>
    </div>
  );
};

export default MainInfoSectionAudit;
