import React, { useContext, useEffect, useState } from 'react'
import AuditTimeComponent from './AuditTimeComponent'
import AuditScopComponent from './AuditScopComponent';
import AuditTeamComponent from './AuditTeamComponent';
import { PiCircleNotch } from 'react-icons/pi';
import API from '../../../api/API';
import { AuthContext } from '../../../context/auth/AuthContext';
import ViewComponent from './ViewComponent';
import { useNavigate } from 'react-router-dom';

const MainAuditCreate = () => {
  const { token } = useContext(AuthContext);
  const navigate=useNavigate();
  const [idProfile, setIdProfile] = useState(-1);
  const [isLoading, setIsLoadig] = useState(false);
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    setRefresh(true)
  }, [])
  useEffect(() => {
    if(!token) return;
    if (idProfile === -1 || !refresh) return
    setRefresh(false);
    const fetchData = async () => {
      try {
        const res = await API.get(`/api/v1/audit/profile/detial/${idProfile}/`, { headers: { Authorization: token } })
        if (res?.data) {
          console.log(res?.data);
          setData(res?.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [idProfile, refresh,setRefresh,setData,token])
  //audit time variables

  // let d = new Date().toLocaleDateString('fa-ir').split('/')[0];

  // let y = [];
  // for (let i = 0; i < 11; i++) {
  //   y.push(d);
  // }
  // console.log(d);
  // console.log(y);
  const [year, setYear] = useState('');
  const [yearList, setYearList] = useState([]);
  const [yearSelected, setYearSelected] = useState(null);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [type, setType] = useState('period');
  const [typeList, setTypeList] = useState([{ title: 'دوره ای', val: 'period' }, { title: 'موردی', val: 'case' }]);
  const [typeSelected, setTypeSelected] = useState({ title: 'دوره ای', val: 'period' });

  const [round, setRound] = useState('')

  //audit scop variables
  const [scopeBtn, setScopeBtn] = useState(false);
  const [purpose, setPurpose] = useState('');
  const [purposeList, setPurposeList] = useState([{ title: 'واحد', val: 'section' }]);
  const [purposeSelected, setPurposeSelected] = useState(null);

  const [showSection, setShowSection] = useState(false);
  const [sectionSelected, setSectionSelected] = useState([]);
  const [sectionFinalSelected, setSectionFinalSelected] =
    useState(sectionSelected);

  const [showStandard, setShowStandard] = useState(false);
  const [standardSelected, setStandardSelected] = useState([]);
  const [standardFinalSelected, setStandardFinalSelected] =
    useState(standardSelected);

  const [scope, setScope] = useState('');
  //audit team variables
  const [teamBtn, setTeamBtn] = useState(false);
  const [showAuditor, setShowAuditor] = useState(false);
  const [auditorSelected, setAuditorSelected] = useState([]);
  const [auditorFinalSelected, setAuditorFinalSelected] =
    useState(auditorSelected);

  const [head, setHead] = useState('');
  const [headList, setHeadList] = useState([]);
  const [headSelected, setHeadSelected] = useState(null);

  const [showView, setShowView] = useState(false);

  const handleSubmit = async () => {
    // setShowView(true);
    let formData = {
      leadAudit: head,
      auditorRelated: auditorFinalSelected
    }
    console.log('formData', formData);
    try {
      const res = await API.patch(`/api/v1/audit/profile/detial/${idProfile}/`, formData, { headers: { Authorization: token } })
      if (res?.data) {
        console.log(res?.data);
        // setShowView(true);
        navigate(`/audit/create/${idProfile}`)
      }
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <>
      {/* {
        !showView ?  */}
        <div className='bg-caspian-surface w-[75%] rounded-md p-4 mb-10 flex flex-col gap-8'>
          <span className='text-xs text-caspian-gray4'>پروفایل ممیزی مورد نظر را با تکمیل فیلد های زیر ایجاد کنید.</span>
          <div className='flex flex-col gap-6'>
            <AuditTimeComponent
              year={year} setYear={setYear}
              yearList={yearList} yearSelected={yearSelected} setYearSelected={setYearSelected}
              startDate={startDate} setStartDate={setStartDate}
              endDate={endDate} setEndDate={setEndDate}
              type={type} setType={setType}
              typeList={typeList} typeSelected={typeSelected} setTypeSelected={setTypeSelected}
              round={round} setRound={setRound}
              scopeBtn={scopeBtn} setScopeBtn={setScopeBtn} token={token} setIdProfile={setIdProfile}
            />

            {
              scopeBtn && <AuditScopComponent
                purpose={purpose} setPurpose={setPurpose}
                purposeList={purposeList} setPurposeList={setPurposeList}
                purposeSelected={purposeSelected} setPurposeSelected={setPurposeSelected}
                showSection={showSection} setShowSection={setShowSection}
                sectionSelected={sectionSelected} setSectionSelected={setSectionSelected}
                sectionFinalSelected={sectionFinalSelected} setSectionFinalSelected={setSectionFinalSelected}
                showStandard={showStandard} setShowStandard={setShowStandard}
                standardSelected={standardSelected} setStandardSelected={setStandardSelected}
                standardFinalSelected={standardFinalSelected} setStandardFinalSelected={setStandardFinalSelected}
                scope={scope} setScope={setScope} idProfile={idProfile} setIdProfile={setIdProfile} token={token}
                teamBtn={teamBtn} setTeamBtn={setTeamBtn}
                setRefresh={setRefresh} refresh={refresh} data={data}
              />
            }

            {
              teamBtn && <AuditTeamComponent
                showAuditor={showAuditor} setShowAuditor={setShowAuditor}
                auditorSelected={auditorSelected} setAuditorSelected={setAuditorSelected}
                auditorFinalSelected={auditorFinalSelected} setAuditorFinalSelected={setAuditorFinalSelected}
                head={head} setHead={setHead} headList={headList} setHeadList={setHeadList}
                headSelected={headSelected} setHeadSelected={setHeadSelected}
              />
            }
          </div>
          {
            teamBtn && <button
              onClick={handleSubmit}
              className='bg-[#3C77A2] text-xs xl:text-sm flex justify-center items-center gap-2 text-caspian-surface rounded-lg py-2 px-4 w-fit'>
              <span>ثبت پروفایل</span>
              <span className='animate-spin'>{isLoading && <PiCircleNotch />}</span>
            </button>
          }
        </div> 
        {/* : 
        <ViewComponent
          setShowView={setShowView} idProfile={idProfile}
          round={round} setRound={setRound}
          startDate={startDate} endDate={endDate} scope={scope} year={year} head={head}
          auditor={auditorSelected}
        /> */}
      {/* // } */}
    </>
  )
}

export default MainAuditCreate