import React, { useContext, useState } from 'react';
import { PiCaretLeft, PiPencilSimpleLineLight, PiTrash } from 'react-icons/pi';
import API from '../../../api/API';
import { AuthContext } from '../../../context/auth/AuthContext';

const Accordion2 = ({ id, ms, standardsRealated, setMsSelected,msRelated,setRefresh,setMs,idMs,setShowModal,setIdPatch
    ,idPatch,setSystemMangamentSelected
 }) => {
    console.log('id', id);
    const { token } = useContext(AuthContext)
    const [open, setOpen] = useState(false);
    const handleRemove = async () => {
        try {
            const res = await API.delete(`/api/v1/audit/profile/ms/${id}/`, { headers: { Authorization: token } })
            setRefresh(true);
        } catch (error) {
            console.log(error);
        }
    }
    const editMs=()=>{
        console.log('idMs',idMs);
        // let s=standardsRealated?.map(s=>{return s?.id})
        // console.log('s is',s);
        // setSystemMangamentSelected(s);
        setIdPatch(id);
        setMs(idMs);
        setMsSelected(msRelated)
        setShowModal(true);
    }
    console.log(idPatch);
    return (
        <div className='rounded px-4 py-2.5 bg-caspian-surface text-xs xl:text-sm shadow-[0px_1px_4px_0px_#00000040]
mb-1.5'>
            <div className='flex gap-2 items-center cursor-pointer
            text-[#326791]' onClick={() => setOpen(!open)}>
                <div className='w-full justify-between flex'>
                    <span>{ms}</span>
                    <span className={`${open ? '-rotate-90' : ''} transition-all`}><PiCaretLeft /></span>
                </div>
                <div className='flex gap-2'>
                    <span onClick={handleRemove}><PiTrash /></span>
                    <span onClick={editMs}><PiPencilSimpleLineLight /></span>
                </div>
            </div>
            <div>
                {
                    open && standardsRealated?.map(s => <p className='mb-1' key={s?.id}>{s?.standardNumber}</p>)
                }
            </div>
        </div>
    );
}

export default Accordion2;
