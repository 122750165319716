import { useContext, useEffect, useRef, useState } from "react";
import {
    PiCheckSquareBold,
    PiMagnifyingGlass,
    PiSquare,
    PiX,
} from "react-icons/pi";
import { AuthContext } from "../../../../../../context/auth/AuthContext";
import API from "../../../../../../api/API";
import Modal from "../../../../../modal/Modal";

const ListClause = ({
    url,
    setShowSection,
    setFinalSelected,
    finalSelected,
    selected,
    setSelected,
    c,
    setC
}) => {
    const searchRef = useRef();
    const { token } = useContext(AuthContext);
    const [search, setSearch] = useState("");

    const [selectedData, setSelectedData] = useState([]);
    const [loading, setLoading] = useState(false);
    console.log(selected);
    useEffect(() => {
        const getList = async () => {
            if (!token || !url) return;

            setLoading(true);
            try {
                const res = await API.get(url, {
                    headers: { Authorization: token },
                });
                console.log(res?.data);
                setSelectedData(res?.data);
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
            }
        };

        getList();
    }, [token, url]);

    const finalHandler = (e) => {
        e.stopPropagation()
        if (selected?.length > 0) {
            setFinalSelected(selected);
            setShowSection(false);
        }
    };
    let serachData = [];
    if (selectedData) {
        serachData = search
            ? selectedData?.filter((s) => s?.title?.includes(search))
            : selectedData;
    }

    const clearSearch = () => {
        searchRef.current.value = "";
        setSearch("");
    };
console.log('clause',c);
    return (
        <Modal>
            <div className="flex flex-col gap-0 min-w-[400px] max-w-[400px] z-[1000] bg-caspian-surface rounded-md shadow-[0_0_5px_#00000050] overflow-hidden">
                <div className="flex items-center justify-between w-full p-2 border-b border-b-caspian-gray2">
                    {/* <span className="text-xs text-caspian-gray font-bold">{title}</span> */}
                    <div className="flex items-center gap-2 w-1/2 relative">
                        {search?.length > 0 && (
                            <PiX
                                onClick={clearSearch}
                                className="absolute top-1/2 -translate-y-1/2 left-2 text-sm text-caspian-gray cursor-pointer"
                            />
                        )}
                        <input
                            ref={searchRef}
                            onChange={(e) => { setSearch(e.target.value) }}
                            type="search"
                            placeholder="جستجو..."
                            className="bg-caspian-transparent text-xs outline-none pr-6"
                        />
                        <PiMagnifyingGlass className="absolute top-1/2 -translate-y-1/2 right-0 text-xl text-caspian-gray" />
                    </div>
                    <PiX
                        onClick={(e) => {
                            e.stopPropagation()
                            setShowSection(false);
                            setSelected(finalSelected);
                        }}
                        className="cursor-pointer"
                    />
                </div>
                <div className="max-h-[360px] min-h-[360px] overflow-y-scroll">
                    {loading ? (
                        <div className="flex items-center justify-center w-full h-full py-12">
                            <span className="text-xs text-caspian-gray4">
                                در حال بارگزاری...
                            </span>
                        </div>
                    ) : serachData?.length < 1 ? (
                        <div className="flex items-center justify-center w-full py-12">
                            <span className="text-xs text-caspian-gray4">
                                موردی یافت نشد...
                            </span>
                        </div>
                    ) : (
                        serachData?.map((s, i) => (
                            <div
                                onClick={() => {
                                    if (!selected?.includes(s?.id)) {
                                        setSelected([...selected, s?.id])
                                        setC([...c, s]);
                                    }
                                    else {
                                        setSelected(selected?.filter((d) => d !== s?.id))
                                        setC(c?.filter(d => d?.id !== s?.id))
                                    };
                                }}
                                key={i}
                                className="flex items-center gap-4 justify-between w-full even:bg-caspian-gray2/30 p-2 cursor-pointer"
                            >
                                <div className="flex items-center gap-2">
                                    {selected?.includes(s?.id) ? (
                                        <PiCheckSquareBold className="text-xl text-caspian-primary-500" />
                                    ) : (
                                        <PiSquare className="text-xl text-caspian-primary-500" />
                                    )}
                                    <span className="text-xs text-caspian-gray4">{s?.clauseNumber}</span>
                                </div>
                                <span className="text-xs text-caspian-gray4">
                                    {s?.groupCode ? s?.groupCode : s?.id}
                                </span>
                            </div>
                        ))
                    )}
                </div>
                <div className="p-2 border-t border-t-caspian-gray2">
                    <button
                        onClick={(e) => finalHandler(e)}
                        disabled={selected?.length < 1 ? true : false}
                        className="bg-caspian-secondary-500 text-xs rounded-md text-caspian-surface py-1.5 px-4 disabled:bg-caspian-gray2 disabled:text-caspian-gray4"
                    >
                        تایید
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default ListClause;
