// import React, { useEffect, useRef } from "react";
// import * as d3 from "d3";


// const NestedPieChart = () => {
//     const svgRef = useRef();


//     // دادهٔ نمونه برای تست نمودار
//     const sampleData = {
//         name: "Root",
//         children: [
//             {
//                 name: "ایزو 9001",
//                 children: [
//                     { name: "nc", value: 5,clause:[{name:'7-1-5'}] },
//                     { name: "obc", value: 10 }
//                 ]
//             },
//             {
//                 name: "ایزو 26001",
//                 children: [
//                     { name: "nc", value: 8 },
//                     { name: "obc", value: 12 },
//                 ]
//             },
//             {
//                 name: "ایزو 5001",
//                 children: [
//                     { name: "nc", value: 12 },
//                     { name: "obc", value: 5 },
//                 ]
//             }
//         ]
//     };


//     useEffect(() => {
//         const width = 400;
//         const height = 400;
//         const radius = Math.min(width, height) / 2;


//         // پاک‌سازی نمودار قبلی (در صورت وجود)
//         const svg = d3.select(svgRef.current);
//         svg.selectAll("*").remove();


//         // افزودن گروه اصلی به SVG و تنظیم موقعیت آن در مرکز
//         const g = svg
//             .attr("width", width)
//             .attr("height", height)
//             .append("g")
//             .attr("transform", `translate(${width / 2}, ${height / 2})`);


//         // محاسبه ساختار سلسله‌مراتبی داده‌ها
//         const root = d3.hierarchy(sampleData).sum(d => d.value);


//         // استفاده از دایره برای ایجاد نمودار حلقوی تو در تو
//         const partition = d3.partition().size([2 * Math.PI, radius]);
//         partition(root);


//         // رنگ‌بندی‌ها
//         const colorScale = d3.scaleOrdinal()
//             .domain(root.descendants().map(d => d.data.name))
//             .range(d3.schemeCategory10);


//         // رسم بخش‌های نمودار
//         g.selectAll("path")
//             .data(root.descendants())
//             .enter()
//             .append("path")
//             .attr("display", d => (d.depth ? null : "none"))
//             .attr("d", d3
//                 .arc()
//                 .startAngle(d => d.x0)
//                 .endAngle(d => d.x1)
//                 .innerRadius(d => d.y0)
//                 .outerRadius(d => d.y1))
//             .style("stroke", "#fff")
//             .style("fill", d => colorScale(d.data.name));


//         // افزودن برچسب‌ها
//         g.selectAll("text")
//             .data(root.descendants().filter(d => d.depth))
//             .enter()
//             .append("text")
//             .attr("transform", d => {
//                 // محاسبه زاویه میانگین برای هر بخش
//                 const angle = (d.x0 + d.x1) / 2 * (180 / Math.PI) - 90;


//                 // چرخاندن متن به شکلی که هم‌پوشانی نکند
//                 const rotate = angle < 180 ? angle :angle;


//                 // انتقال متن به سمت خارج دایره
//                 const xPos = (d.y0 + d.y1) / 2;
//                 const yPos = (d.x0 + d.x1) / 2;


//                 return `rotate(${rotate}) translate(${xPos + 10}, ${yPos + 10})`; // فاصله از مرکز و چرخش
//             })
//             .attr("dx", "-20") // فاصله متن از محور
//             .attr("dy", "0.35em")
//             .text(d => d.data.name)
//             .style("font-size", "10px")
//             .style("text-anchor", "middle")
//             .style("fill", "#000"); // رنگ متن
//     }, []); // فقط یک بار اجرا می‌شود


//     return (
//         <div style={{ display: "flex", justifyContent: "center", alignItems: "center",direction:'ltr' }}>
//             <svg ref={svgRef}></svg>
//         </div>
//     );
// };


// export default NestedPieChart;




import React, { useEffect, useRef } from "react";
import * as d3 from "d3";


const NestedPieChart = ({ setShow, setMyData }) => {
    const svgRef = useRef();
    // const sampleData = {
    //     name: "Root",
    //     children: [
    //         {
    //             name: " 9001",
    //             children: [
    //                 {
    //                     name: "nc", // عدم انطباق
    //                     count: 16,
    //                     children: [
    //                         { name: "1.1", value: 10 },
    //                         { name: "1.5.7", value: 10 },
    //                         { name: "1.8", value: 10 },
    //                     ]
    //                 },
    //                 {
    //                     name: "obs", // مشاهده
    //                     count: 4,
    //                     children: [
    //                         { name: "1.3", value: 10 },
    //                         { name: "1.4", value: 10 }
    //                     ]
    //                 }
    //             ]
    //         },
    //         {
    //             name: " 26001",
    //             children: [
    //                 {
    //                     name: "nc",
    //                     count: 5,
    //                     children: [
    //                         {
    //                             name: "2.1", value: 10, children: [
    //                                 { name: '2.1.5', value: 10 }
    //                             ]
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     name: "obs",
    //                     count: 20,
    //                     children: [
    //                         { name: "2.2", value: 10 }
    //                     ]
    //                 }
    //             ]
    //         },
    //         {
    //             name: " 45001",
    //             children: [
    //                 {
    //                     name: "nc",
    //                     count: 8,
    //                     children: [
    //                         { name: "3.1", value: 10 }
    //                     ]
    //                 },
    //                 {
    //                     name: "obs",
    //                     count: 2,
    //                     children: [
    //                         { name: "3.2", value: 10 },
    //                         { name: "3.3", value: 10 }
    //                     ]
    //                 }
    //             ]
    //         }
    //         // سایر استانداردها را به همین شکل اضافه کنید
    //     ]
    // };

    const sampleData = {
        name: "Root",
        children: [
            {
                name: "9001",
                children: [
                    {
                        name: "nc", // عدم انطباق
                        count: 16,
                        clauseRelated: [
                            { name: "1.1", value: 10 },
                            { name: "1.5.7", value: 10 },
                            { name: "1.8", value: 10 },
                        ]
                    },
                    {
                        name: "obs", // مشاهده
                        count: 4
                    }
                ]
            },
            {
                name: "26001",
                children: [
                    {
                        name: "nc",
                        count: 5
                    },
                    {
                        name: "obs",
                        count: 20
                    }
                ]
            },
            {
                name: "45001",
                children: [
                    {
                        name: "nc",
                        count: 8
                    },
                    {
                        name: "obs",
                        count: 2
                    }
                ]
            }
            // سایر استانداردها را به همین شکل اضافه کنید
        ]
    };
    const handleClik = (name) => {
        const filterData = sampleData?.children.filter(s => s?.name === name);
        if (filterData?.length > 0) {
            setMyData(filterData[0]?.name);
            setShow(true);
        }
        console.log(filterData);
    }
    useEffect(() => {
        const width = 350;
        const height = 350;
        const radius = Math.min(width, height) / 2;


        // پاک‌سازی نمودار قبلی (در صورت وجود)
        const svg = d3.select(svgRef.current);
        svg.selectAll("*").remove();


        // افزودن گروه اصلی به SVG و تنظیم موقعیت آن در مرکز
        const g = svg
            .attr("width", width)
            .attr("height", height)
            .append("g")
            .attr("transform", `translate(${width / 2}, ${height / 2})`);


        // محاسبه ساختار سلسله‌مراتبی داده‌ها
        const root = d3.hierarchy(sampleData)
            .sum(d => d.value || d.count) // از count برای سطح دوم استفاده می‌کند، value برای بندها
            .sort((a, b) => b.value - a.value); // مرتب‌سازی بر اساس مقدار


        // استفاده از پارتیشن‌بندی برای نمودار حلقوی تو در تو
        const partition = d3.partition().size([2 * Math.PI, radius]);
        partition(root);


        // رنگ‌بندی‌ها
        const colorScale = d3.scaleOrdinal()
            .domain(root.descendants().map(d => d.data.name))
            .range(d3.schemeCategory10);


        // رسم بخش‌های نمودار
        g.selectAll("path")
            .data(root.descendants())
            .enter()
            .append("path")
            .attr("display", d => (d.depth ? null : "none"))
            .attr("d", d3
                .arc()
                .startAngle(d => d.x0)
                .endAngle(d => d.x1)
                .innerRadius(d => d.y0)
                .outerRadius(d => d.y1)
                // .padAngle(0.02) // فاصله بین هر بخش
            )
            .style("stroke", "#fff")
            .style("fill", d => colorScale(d.data.name))
            .on('click', (event, d) => handleClik(d.data.name))


        // افزودن برچسب‌ها
        g.selectAll("text")
            .data(root.descendants().filter(d => d.depth > 0)) // فقط بخش‌هایی که عمق بیشتر از صفر دارند
            .enter()
            .append("text")
            .attr("transform", d => {
                const angle = (d.x0 + d.x1) / 2;
                const angleDeg = (angle * 180) / Math.PI - 90;
                const xPos = (d.y0 + d.y1) / 2;
                return `rotate(${angleDeg}) translate(${xPos + 10}, 0)`;
            })
            .attr("dx", "-10")
            .attr("dy", "0.35em")
            .text(d => d.data.name)
            .style("font-size", "12px")
            .style("text-anchor", "middle")
            .style("fill", "#000")
            // .attr('class','font-bold')
            .style("pointer-events", "none"); // جلوگیری از تعاملات موس با متن
    }, []); // فقط یک بار اجرا می‌شود


    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <svg ref={svgRef}></svg>
        </div>
    );
};


export default NestedPieChart;



