import React, { useEffect, useRef, useState } from "react";
import { PiCaretDownBold, PiMagnifyingGlassBold } from "react-icons/pi";

const TypeAuditList = ({
    type,
    setType,
    typeSelected,
    setTypeSelected,
    showListtype,
    setShowListtype,
    typeList,
    lable,
    scopeBtn
}) => {
    let menuRef = useRef();

    const [open, setOpen] = useState(false);
console.log('type',type);
    return (
        <div ref={menuRef} className='flex flex-col gap-2 w-full relative'>
            <div className="flex justify-between items-center">
                <label className="text-xs text-caspian-gray4">{lable}</label>
            </div>
            <div
                onClick={() => {
                    // setShowListtype(!showListtype);
                    setOpen(!open);
                }}
                className={`${scopeBtn?'pointer-events-none bg-[#FAFAFA]':''} text-xs outline outline-1 outline-caspian-gray1 p-2 rounded-md cursor-pointer flex items-center justify-between`}
            >
                {type !== '' && type !== undefined ? (
                    <span className="text-xs">
                        {typeSelected?.title}
                    </span>
                ) : (
                    <span className="text-caspian-gray1 text-xs whitespace-nowrap">انتخاب کنید</span>
                )}
                <PiCaretDownBold className="text-caspian-gray" />
            </div>
            {
                <div
                    className={
                        open
                            ? "rounded-md shadow-[0_0_5px_#00000050] w-full flex flex-col gap-3 py-3 absolute left-0 right-0 top-[58px] bg-caspian-surface z-50 "
                            : "hidden"
                    }
                >
                    <div className="flex flex-col gap-2 w-full max-h-[200px] overflow-y-scroll">
                        {typeList?.length > 0 &&
                            typeList.map((m, i) => (
                                <div
                                    key={i}
                                    onClick={() => {
                                        setTypeSelected(m);
                                        setType(m?.val);
                                        setOpen(false);
                                    }}
                                    className="flex items-center justify-start gap-2 text-xs hover:bg-caspian-secondary-100 py-1 px-2 w-full cursor-pointer duration-300"
                                >
                                    <span className="">{m?.title}</span>
                                </div>
                            ))}
                    </div>
                </div>
            }
        </div>
    );
};

export default TypeAuditList;
