import React, { useContext, useEffect, useState } from 'react';
import { PiTrashBold } from 'react-icons/pi';
import API from '../../../api/API';
import { AuthContext } from '../../../context/auth/AuthContext';
import Loading from '../../../Loading';
import Accordion from './Accordion';
import { useNavigate, useParams } from 'react-router-dom';

const ViewComponent = () => {
    const { token } = useContext(AuthContext);
    const {idCreate}=useParams();
    const [data, setData] = useState({});
    // const [refresh, setRefresh] = useState(false);
    const [err,setErr]=useState('')
    const [isDisabled, setIsDisabled] = useState(true);

    const navigate = useNavigate();
    console.log(idCreate);
    // useEffect(() => {
    //     setRefresh(true)
    // }, [])
    useEffect(() => {
        const fetchData = async () => {
            if (!token || !idCreate) return
            // setRefresh(false);
            try {
                const res = await API.get(`/api/v1/audit/profile/detial/${idCreate}/`, { headers: { Authorization: token } })
                if (res?.data) {
                    console.log(res?.data);
                    setData(res?.data);
                }
            } catch (error) {
                console.log(error);
                if(error.response.status===404){
                    setErr('404');
                }
            }
        }
        fetchData();
    }, [token,idCreate])
    // console.log('lead', data?.leadAudit);
    const handleConfirm = async () => {
        let auditStatus;
        if (data?.auditStatus === 'scheduleTime') {
            auditStatus = 'scheduleResponsible'
        }
        else if (data?.auditStatus === 'scheduleResponsible') {
            // auditStatus = 'audit-confirm'
            auditStatus='review'
        }
        else if (data?.auditStatus === 'review') {
            // auditStatus = 'audit-approve'
            auditStatus='confirm'
        }
        else {
            auditStatus = 'approve'
        }
        let formData = {
            auditStatus
        }
        try {
            const res = await API.patch(`/api/v1/audit/profile/detial/${idCreate}/`, formData, { headers: { Authorization: token } })
            console.log(res?.data);
            if (res?.data) {
                // setRefresh(true);
                // setShowView(false);
                navigate('/audit/chart');

            }
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <>
            {
                err==='404' ? <p className='text-center text-caspian-gray4 pt-20 text-sm'>پروفایل مورد نظر یافت نشد.</p>:
                data?.length < 1 ? <Loading /> : <div className='flex flex-col gap-2 w-[90%] xl:w-[75%]'>
                    <div className='bg-caspian-surface w-full rounded-md mb-0 flex flex-col gap-6'>
                        <div className="header border-b border-[#B4B4B4] p-4 flex justify-between items-center">
                            {/* year and round and date */}
                            <div className='flex items-center gap-8'>
                                <div className='flex gap-4'>
                                    {
                                        data?.auditType === 'period' ? <span className='text-[#3C77A2] text-base xl:text-lg'> ممیزی دوره {data?.title}</span> : <span className='text-[#3C77A2] text-base xl:text-lg'> ممیزی مورد {data?.title}</span>
                                    }
                                    <span> سال {new Date(data?.startDate).toLocaleDateString("fa-IR").split('/')[0]}</span>
                                </div>
                                <div className='flex gap-2 text-[#656565] text-base xl:text-lg'>
                                    <span>{new Date(data?.startDate).toLocaleDateString("fa-IR")}</span>
                                    <span>تا</span>
                                    <span>{new Date(data?.endDate).toLocaleDateString("fa-IR")}</span>
                                </div>
                            </div>
                            {/* code and edit and trash */}
                            <div className='flex items-center gap-4'>
                                {/* <div className='flex gap-2 items-center text-[#3C77A2]'>
                                    <button onClick={}>
                                        <PiTrashBold />
                                    </button>
                                </div> */}
                                <div className='flex items-center gap-2 text-xs text-caspian-gray4'>
                                    <span>کد پروفایل:</span>
                                    <span>B-015-03</span>
                                </div>
                            </div>
                        </div>
                        {/* system and standard */}
                        <div className='flex gap-4 p-6 py-0'>
                            <div className='flex flex-col gap-1 basis-1/2'>
                                <span className='text-base text-caspian-gray4'>سیستم ها و استانداردها</span>
                                <div className='h-[234px] overflow-y-auto'>
                                    {data?.auditProfileMSRelatedProfile?.length > 0 &&
                                        data?.auditProfileMSRelatedProfile?.map(a => <Accordion key={a?.id}
                                            ms={a?.msRelated?.title}
                                            standardsRealated={a?.standardsRealated}
                                        />)}
                                </div>
                            </div>
                            <div className='flex flex-col gap-1 basis-1/2'>
                                <span className='text-base text-caspian-gray4'>واحدها</span>
                                <div className='h-[234px] overflow-y-auto'>
                                    {
                                        data?.auditRelatedSections?.length > 0 && data?.auditRelatedSections?.map(a => <div
                                            className='rounded px-4 py-2.5 bg-caspian-surface text-xs xl:text-sm shadow-[0px_1px_4px_0px_#00000040] mb-1.5'
                                            key={a?.id}>
                                            {a?.sectionRelated?.title}
                                        </div>)
                                    }
                                </div>
                            </div>
                        </div>
                        {/* description */}
                        <div className='flex flex-col gap-4 p-4 py-0'>
                            <span className='text-base text-caspian-gray4'>توضیحات</span>
                            {
                                data?.scope !== '' && <p className='text-sm'>{data?.scope}</p>
                            }
                        </div>
                        {/* team */}
                        <div className='flex flex-col gap-4 p-4 py-0 mb-2'>
                            <span className='text-base text-caspian-gray4'>تیم ممیزی</span>
                            <div className='flex flex-col gap-1'>
                                {
                                    data?.leadAudit !== null && <div className='bg-[#EAEAEA] flex gap-4 p-1 items-center'>
                                        <span className='text-[#01BC8D] text-[13px]'>سرممیز:</span>
                                        <span className='text-sm'>{data?.leadAudit?.profileAuditorRelated?.firstName + ' ' + data?.leadAudit?.profileAuditorRelated?.lastName}</span>
                                    </div>
                                }
                                {
                                    data?.auditorRelated?.length > 0 && <div className='bg-[#EAEAEA] flex gap-4 p-1 items-center'>
                                        <span className='text-[#3C77A2] text-[13px]'>ممیز:</span>
                                        <div className='flex gap-2'>
                                            {data?.auditorRelated?.map((a, i) => <p className='text-sm' key={a?.id}>
                                                <span>
                                                    {a?.profileAuditorRelated?.firstName + ' ' + a?.profileAuditorRelated?.lastName}
                                                </span>
                                                {
                                                    i + 1 !== data?.auditorRelated?.length && <span>/</span>
                                                }
                                            </p>)}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    {/* confirm and approve */}
                    <div className='bg-caspian-surface w-full flex items-center gap-4 rounded-md p-2 px-5'>
                        <input type="checkbox" onChange={() => setIsDisabled(!isDisabled)} />
                        {
                            data?.auditStatus === 'scheduleTime' ? <span className={`${isDisabled?'text-caspian-gray':''} text-sm`}>با پروفایل ایجاد شده موافقت می کنید</span> : 
                            data?.auditStatus==='scheduleResponsible' ? <span className={`${isDisabled?'text-caspian-gray':''} text-sm`}>اطلاعات پروفایل مورد بررسی شما قرار گرفت ؟</span> 
                            : data?.auditStatus === 'review' ?
                                <span className={`${isDisabled?'text-caspian-gray':''} text-sm`}>آیا پروفایل ایجاد شده مورد تایید شما قرار گرفته است</span>
                                : data?.auditStatus === 'confirm' ? <span className={`${isDisabled?'text-caspian-gray':''} text-sm`}>آیا پروفایل ایجاد شده را تصویب می کنید ؟</span> : ''
                        }
                        <button
                            disabled={isDisabled}
                            onClick={handleConfirm}
                            className='disabled:bg-caspian-gray4 bg-[#3C77A2] rounded-lg p-1.5 px-3 text-center text-caspian-surface'>
                            {data?.auditStatus === 'scheduleTime' ? <span className='text-sm'> ارسال به بررسی کننده</span> : data?.auditStatus === 'scheduleResponsible' ? <span className='text-sm'>ارسال به تایید کننده </span> : data?.auditStatus === 'review' ?
                                <span className='text-sm'>ارسال به تصویب کننده </span>
                                : data?.auditStatus === 'confirm' ? <span className='text-sm'>
                                    تصویب پروفایل
                                </span> : ''}
                        </button>
                    </div>
                </div>
            }
        </>
    )
}

export default ViewComponent;
