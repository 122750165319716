import React from "react";
import InfoBox from "./InfoBox";

const DetailsSectionInternalContact = ({ heightHandler, fullData }) => {
  console.log(fullData)
  return (
    <div className="w-full flex flex-col gap-2">
      <InfoBox fullData={fullData} heightHandler={heightHandler} />
    </div>
  );
};

export default DetailsSectionInternalContact;
