
const ListProcess = ({ processList }) => {
  return processList?.length > 0 ? (
    processList?.map((p, i) => (
      <div key={i} className="flex flex-col gap-2">
        {p?.groupProcessRelatedRiskTeam?.map((g, i) => (
          <div
            className={
              "text-xs flex items-center justify-between gap-2"
            }
            key={i}
          >
            <div className="flex items-center gap-8">
            <span title={g?.groupCode}>
                {g?.groupCode.length > 20
                  ? g?.groupCode.slice(0, 20) + "..."
                  : g?.groupCode}
              </span>
              <span title={g?.title}>
                {g?.title.length > 30
                  ? g?.title.slice(0, 30) + "..."
                  : g?.title}
              </span>
            </div>
          </div>
        ))}
      </div>
    ))
  ) : (
    <div className="flex items-center justify-center gap-2 py-8">
      <span className="text-xs text-caspian-gray4">فرایندی موجود نیست</span>
    </div>
  );
};

export default ListProcess;
