import React from "react";
import { PiCalendar } from "react-icons/pi";
import StartDateTimePicker from "./StartDateTimePicker";
import StartTimePicker from "./StartTimePicker";
import EndTimePicker from "./EndTimePicker";

const MainTime = ({ setStartDate, setStartTime, setEndTime }) => {
  return (
    <div className="flex items-center justify-between w-full">
      <div className="flex items-start flex-col gap-4">
        <label className="flex items-center gap-2">
          <PiCalendar className="text-lg text-caspian-gray4" />
          <span className="text-xs text-caspian-gray4">تاریخ ممیزی</span>
        </label>
        <StartDateTimePicker setStartDate={setStartDate} />
      </div>
      <div className="flex items-center gap-4">
        <div className="flex items-start flex-col gap-2">
          <label className="">
            <span className="text-xs text-caspian-gray4">ساعت شروع</span>
          </label>
          <div className="w-[100px] lg:w-full">
            <StartTimePicker setStartTime={setStartTime} />
          </div>
        </div>
        <div className="flex items-start flex-col gap-2">
          <label className="">
            <span className="text-xs text-caspian-gray4">ساعت خاتمه</span>
          </label>
          <div className="w-[100px] lg:w-full">
            <EndTimePicker setEndTime={setEndTime} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainTime;
