import { PiMapPin } from "react-icons/pi";

const InfoBox = ({ fullData, heightHandler }) => {

  const x = (fullData?.locationRelated?.geoX)
  const y = (fullData?.locationRelated?.geoY)

  const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${x},${y}`;


  const filterNumberDirect = fullData?.telePhoneRelated?.filter((item) => item?.TelePhoneType === 'direct')
  const filterNumberInDirect = fullData?.telePhoneRelated?.filter((item) => item?.TelePhoneType === 'inDirect')
  const filterNumberMobile = fullData?.telePhoneRelated?.filter((item) => item?.TelePhoneType === 'Mobile')
  const filterNumberFax = fullData?.telePhoneRelated?.filter((item) => item?.TelePhoneType === 'fax')

  const filterEmailPersonal = fullData?.emailRelated?.filter((item) => item?.emailType === 'personal')
  const filterEmailCompany = fullData?.emailRelated?.filter((item) => item?.emailType === 'company')

  return (
    <div className="flex items-start flex-col gap-0">

      <div className="flex flex-col items-center w-full border-b border-caspian-gray2/30 px-1">
        <div className="flex items-center w-full px-3 py-1">
          <div className="basis-1/2 flex flex-col  justify-center gap-2">
            <span className="text-xs text-caspian-gray4">واحد سازمانی </span>
          </div>
          <div className="basis-1/2">
            <span className="text-xs">{fullData?.sectionRelated?.categoryRelated?.title}</span>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center w-full border-b border-caspian-gray2/30 px-1">
        {filterNumberInDirect?.length > 0 ? (
          filterNumberInDirect?.map((item, index) => (
            <div key={index} className={`flex items-center w-full px-3 py-1  `}>
              <div className="basis-1/2 flex flex-col justify-center gap-2">
                <span className="text-xs text-caspian-gray4"> شماره تماس داخلی </span>
              </div>
              <div className={`basis-1/2 flex flex-col justify-center `}>
                <span className="text-xs">{item?.preCode ? ("0" + item?.preCode + "-") : ("")}{item?.number}</span>
                <span className="text-xs">{item?.secondNumber ? (" داخلی " + item?.secondNumber) : (" ")}</span>
              </div>
            </div>
          ))
        ) : (<div className="flex items-center pt-1 w-full px-3 py-1 ">
          <div className="basis-1/2 flex flex-col justify-center">
            <span className="text-xs text-caspian-gray4"> شماره تماس داخلی </span>
          </div>
          <div className="basis-1/2">
            <span className="text-xs">-</span>
          </div>
        </div>
        )}
      </div>

      <div className="flex flex-col items-center w-full border-b border-caspian-gray2/30 px-1 ">
        {filterNumberDirect?.length > 0 ?
          (filterNumberDirect?.map((item, index) => (
            <div key={index} className="flex items-center w-full px-3 py-1 ">
              <div className="basis-1/2 flex flex-col justify-center gap-2">
                <span className="text-xs text-caspian-gray4">شماره تماس داخلی </span>
              </div>
              <div className="basis-1/2">
                <span className="text-xs">{item?.number}</span>
              </div>
            </div>
          ))
          ) : (
            <div className="flex items-center w-full px-3 py-1 ">
              <div className="basis-1/2 flex flex-col justify-center gap-2">
                <span className="text-xs text-caspian-gray4">شماره تماس داخلی </span>
              </div>
              <div className="basis-1/2">
                <span className="text-xs">-</span>
              </div>
            </div>
          )}
      </div>

      <div className="flex flex-col items-center w-full border-b border-caspian-gray2/30 px-1">
        {filterNumberMobile?.length > 0 ?
          (filterNumberMobile?.map((item, index) => (
            <div key={index} className="flex items-center w-full px-3 py-1">
              <div className="basis-1/2 flex flex-col  justify-center gap-2">
                <span className="text-xs text-caspian-gray4">شماره همراه </span>
              </div>
              <div className="basis-1/2">
                <span className="text-xs">{item?.number}</span>
              </div>
            </div>
          ))
          ) : (
            <div className="flex items-center w-full px-3 py-1">
              <div className="basis-1/2 flex flex-col  justify-center gap-2">
                <span className="text-xs text-caspian-gray4">شماره همراه </span>
              </div>
              <div className="basis-1/2">
                <span className="text-xs">-</span>
              </div>
            </div>
          )}
      </div>

      <div className="flex flex-col items-center w-full border-b border-caspian-gray2/30 px-1">
        {filterNumberFax?.length > 0 ?
          (filterNumberFax?.map((item, index) => (
            <div key={index} className="flex items-center w-full px-3 py-1">
              <div className="basis-1/2 flex flex-col  justify-center gap-2">
                <span className="text-xs text-caspian-gray4">فکس </span>
              </div>
              <div className="basis-1/2">
                <span className="text-xs">{item?.number}</span>
              </div>
            </div>
          ))
          ) : (
            <div className="flex items-center w-full px-3 py-1">
              <div className="basis-1/2 flex flex-col  justify-center gap-2">
                <span className="text-xs text-caspian-gray4">فکس </span>
              </div>
              <div className="basis-1/2">
                <span className="text-xs">-</span>
              </div>
            </div>
          )}
      </div>


      <div className="flex flex-col items-center w-full border-b border-caspian-gray2/30 px-1">
        {filterEmailCompany?.length > 0 ?
          (filterEmailCompany?.map((item, index) => (
            <div key={index} className="flex items-center w-full px-3 py-1">
              <div className="basis-1/2 flex flex-col  justify-center gap-2">
                <span className="text-xs text-caspian-gray4">ایمیل سازمانی </span>
              </div>
              <div className="basis-1/2">
                <span className="text-xs">{item?.email}</span>
              </div>
            </div>
          ))
          ) : (<div className="flex items-center w-full px-3 py-1">
            <div className="basis-1/2 flex flex-col  justify-center gap-2">
              <span className="text-xs text-caspian-gray4">ایمیل سازمانی </span>
            </div>
            <div className="basis-1/2">
              <span className="text-xs">-</span>
            </div>
          </div>)
        }
      </div>

      <div className="flex flex-col items-center w-full border-b border-caspian-gray2/30 px-1">
        {filterEmailPersonal?.length > 0 ?
          (filterEmailPersonal?.map((item, index) => (
            <div key={index} className="flex items-center w-full px-3 py-1">
              <div className="basis-1/2 flex flex-col  justify-center gap-2">
                <span className="text-xs text-caspian-gray4">ایمیل شخصی </span>
              </div>
              <div className="basis-1/2">
                <span className="text-xs">{item?.email}</span>
              </div>
            </div>
          ))
          ) : (<div className="flex items-center w-full px-3 py-1">
            <div className="basis-1/2 flex flex-col  justify-center gap-2">
              <span className="text-xs text-caspian-gray4">ایمیل شخصی </span>
            </div>
            <div className="basis-1/2">
              <span className="text-xs">-</span>
            </div>
          </div>)
        }
      </div>


      {heightHandler &&
        <div className="w-full flex flex-col items-center">
          <div className="flex flex-col items-center w-full border-b border-caspian-gray2/30 px-1">
            <div className="flex items-center w-full px-3 py-1">
              <div className="basis-1/2 flex flex-col  justify-center gap-2">
                <span className="text-xs text-caspian-gray4">سایر نقش‌ها </span>
              </div>
              <div className="basis-1/2">
                <span className="text-xs">-</span>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center w-full border-b border-caspian-gray2/30 px-1">
            <div className="flex items-center w-full px-3 py-1">
              <div className="basis-1/2  flex flex-col justify-stretch ">
                <span className="text-xs text-caspian-gray4">محل خدمت </span>
              </div>
              <div className="basis-1/2">
                <span className="text-xs">{fullData?.locationRelated?.address ? fullData?.locationRelated?.address : "-"}</span>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center w-full border-b border-caspian-gray2/30 px-1">
            <div className="flex items-center w-full px-3 py-1">
              <div className="basis-1/2 flex flex-col justify-center gap-2">
                <span className="text-xs text-caspian-gray4">لوکیشن </span>
              </div>
              <div className="basis-1/2 w-full">
                <div className="flex items-center gap-2 text-caspian-secondary-500">
                  <PiMapPin />
                  <a
                    href={googleMapsLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-xs">
                    {fullData?.locationRelated?.address ? fullData?.locationRelated?.address : "-"}
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center w-full">s</div>
        </div>
      }
    </div >
  );
};

export default InfoBox;
