import React, { useEffect, useState } from 'react'
import HistorySingle from './HistorySingle'
import { useContext } from 'react'
import { AuthContext } from '../../../../../../context/auth/AuthContext'
import API from '../../../../../../api/API'
import { useParams } from 'react-router-dom'

const HistoryMain = () => {
  const { token } = useContext(AuthContext)
  const params = useParams()
  const [fullData, setFullData] = useState([])

  useEffect(() => {
    if (!token) return;
    const handleData = async () => {
      try {
        const res = await API.get(`/api/v1/actionplan/task/logs/${params?.id}`,
          { headers: { Authorization: token } }
        )
        console.log(res?.data)
        setFullData(res?.data)
      } catch (error) {
        console.log(error)
      }
    }
    handleData()
  }, [token, params?.id])

  return (
    <div className='flex flex-col gap-2 '>
      {fullData?.map((item, index) => (
        <HistorySingle key={index} fullData={item} />
      ))}
    </div>
  )
}

export default HistoryMain