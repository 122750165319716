import React, { useState } from 'react';
import { PiCircleNotch, PiPlusBold } from 'react-icons/pi';
import StatusFilter from './StatusFilter';
import SingleListAudit from './SingleListAudit';
import Loading from '../../../../Loading';
import { Link } from 'react-router-dom';

const MainListProfile = ({ data }) => {
    const [status, setStatus] = useState('');
    const [statusList, setStatusList] = useState([{ title: ' همه', val: '' }, { title: 'برنامه ریزی زمان', val: 'scheduleTime' },
    { title: ' برنامه ریزی مسئولان ', val: 'scheduleResponsible' }, { title: 'تایید', val: 'confirmation' }]);
    const [statusSelected, setStatusSelected] = useState({ title: ' همه', val: '' });

    let newData = [];
    if (data[0] !== -1) {
        newData = status ? data?.filter(d => d?.auditStatus?.toString()?.includes(status?.toString())) : data
    }
    return (
        <div className='border border-[#B5B5B5] h-[72vh] rounded-lg basis-[25%] overflow-hidden flex flex-col gap-5 py-4'>
            <div className='flex justify-between items-center px-2 2xl:px-4'>
                <span className='text-[#3C77A2] text-xs xl:text-base font-semibold'>پروفایل‌ها</span>
                <Link
                to='/audit/create'
                className='bg-[#3C77A2] text-caspian-surface rounded-lg p-2 flex items-center gap-2'>
                    <PiPlusBold />
                    <span className='text-xs 2xl:text-sm'>ایجاد پروفایل ممیزی</span>
                </Link>
            </div>
            <div className='flex flex-col gap-4'>
                <div className='w-[190px] mr-auto px-2 2xl:px-4'>
                    <StatusFilter
                        status={status}
                        setStatus={setStatus}
                        statusList={statusList}
                        statusSelected={statusSelected}
                        setStatusSelected={setStatusSelected}
                        lable='وضعیت'
                    />
                </div>
                <div className='h-[50vh] overflow-y-auto flex flex-col gap-4'>
                    {/* list */}
                    {data[0] === -1 ? <p className='pt-10 flex items-center justify-center text-xs text-caspian-gray4'>
                        در حال بارگزاری ...
                    </p> :
                        newData?.length > 0 ? newData?.map(d => <SingleListAudit key={d?.id} {...d} />) :
                            <p className="text-caspian-gray4 text-xs text-center pt-20">داده‌ای یافت نشد. </p>
                    }
                </div>
            </div>
        </div>
    );
}

export default MainListProfile;
