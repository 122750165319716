import React, { useContext, useEffect, useRef, useState } from 'react'
import { PiCaretLeftBold } from "react-icons/pi";
import ListUploadFile from './upload-file-work-progress/ListUploadFile'
import API from '../../../../../../api/API'
import { AuthContext } from '../../../../../../context/auth/AuthContext';


const MainFormWorkProgress = ({ setActiveTab, reportId, valuePercent, dataProgress, setDataProgress, succesData, setSuccesData, idProgress, setIdProgress }) => {
    const { token } = useContext(AuthContext);
    let percentRef = useRef()
    let descRef = useRef()
    const [description, setDescription] = useState(dataProgress?.description ? dataProgress?.description : "")
    const [progressPercent, setProgressPercent] = useState(dataProgress?.progress_percent ? dataProgress?.progress_percent : "")
    const [emptyPercent, setEmptyPercent] = useState(false)
    const [listDoc, setListDoc] = useState(dataProgress?.document ? dataProgress?.document : [])
    const [refreshList, setRefreshList] = useState(0)
    const [checked, setChecked] = useState(true)
    const [totlaValuePercent, setTotlaValuePercent] = useState(0)
    const [deleteData, setDeleteData] = useState(false)
    const [matchDataList, setMatchDataList] = useState(false)
    const [addDoc, setAddDoc] = useState(false)
    const [errorPercent, setErrorPercent] = useState(false)
    const [errorPercentText, setErrorPercentText] = useState("")
    useEffect(() => {
        const sumValuePercent = (valuePercent + Number(progressPercent ? progressPercent : 0))
        setTotlaValuePercent(sumValuePercent)
    }, [valuePercent, progressPercent]
    )
    //post پیشرفت کار
    const handleDocument = async () => {
        if (!token || !reportId) return;
        let hasError = false;
        if (!progressPercent) {
            setEmptyPercent(true);
            hasError = true;
        } else {
            setEmptyPercent(false)
            hasError = false
        }
        if (hasError) return
        let formData = {
            progress_percent: progressPercent,
            description: description,
            reportProfileRelated: reportId,
            documents: listDoc?.map((item) => item?.id)
        }
        try {
            const res = await API.post('/api/v1/actionplan/reportProgress/', formData,
                {
                    headers: { Authorization: token }
                }
            )
            console.log(res?.data)
            setIdProgress(res?.data?.id)
            setSuccesData(true)
            setAddDoc(false)
            setDeleteData(false)
        } catch (error) {
            console.log(error?.response?.data[0])
            setErrorPercent(true)
            setErrorPercentText(error?.response?.data[0])
        }
    }
    //get برای جابه جایی بین تب ها
    useEffect(() => {
        if (!token || !idProgress) return;
        const handleDataProgress = async () => {
            try {
                const res = await API.get(`/api/v1/actionplan/reportProgress/${idProgress}/`)
                console.log(res?.data)
                setDataProgress(res?.data)
            } catch (error) {
                console.log(error)
            }
        }
        handleDataProgress()
    }, [token, idProgress, setDataProgress])
    //patch پیشرفت کار
    const editDataProgress = async () => {
        if (!token || !idProgress || !succesData) return;
        let formData = {
            progress_percent: progressPercent,
            description: description,
            documents: listDoc?.map((item) => item?.id)
        }
        try {
            if (succesData) {
                const res = await API.patch(`/api/v1/actionplan/reportProgress/${idProgress}/`, formData,
                    { headers: { Authorization: token } }
                )
                console.log(res?.data)
                setMatchDataList(true)
                setAddDoc(false)
                setDataProgress(res?.data);
            }
        } catch (error) {
            console.log(error)
        }
    }
    // delete پیشرفت کار 
    const deleteReport = async () => {
        if (!token || !reportId || !idProgress) return;
        try {

            const res = await API.delete(`/api/v1/actionplan/reportProgress/${idProgress}/`,
                { headers: { Authorization: token } }
            )
            console.log(res?.data)
            setSuccesData(false)
            setDeleteData(true)
        } catch (error) {
            console.log(error)
        }
    }
    const clearData = async () => {
        setDataProgress({})
        setListDoc([])
        percentRef.current.value = ""
        descRef.current.value = ""
        setDescription("")
        setDeleteData(true)
        setProgressPercent(0)
        setIdProgress(null)
        setRefreshList(0)
        deleteReport()
    }

    return (
        <div className='flex h-full flex-col items-start justify-between'>
            <div className='flex flex-col items-center w-full px-5 '>
                <div className='flex border-b border-b-caspian-secondary-600 flex-col items-start h-full w-full gap-3 pb-3'>

                    <div className='flex flex-col items-center gap-2 w-full'>
                        <div className='flex items-center justify-start w-full border-2 border-caspian-gray1/80 py-1.5 px-5 rounded-lg gap-5'>
                            <input type='checkbox' id='pogress'

                                onClick={() => setChecked(!checked)} />

                            <label htmlFor='pogress'
                                className={`text-xs 2xl:text-sm font-semibold ${checked ? "text-caspian-gray1" : "text-caspian-on_primary"}`}>
                                گزارشی ندارم
                            </label>
                        </div>
                        <div className='flex items-center w-full gap-5'>
                            <span className={`text-xs 2xl:text-sm whitespace-nowrap ${checked ? "text-caspian-gray4" : "text-caspian-gray4/30"} `}>
                                درصد پیشرفت
                            </span>
                            <div className='w-full h-[1px] bg-caspian-graygreen-200'></div>
                        </div>
                        <div className='flex flex-col items-center gap-2 w-full h-full'>
                            <div className='flex items-start w-full gap-10'>
                                <div className='flex flex-col items-start justify-around gap-3 h-[90px]'>
                                    <span className={`text-xs font-semibold whitespace-normal ${checked ? "text-caspian-gray" : "text-caspian-gray/30"}`}>درصد پیشرفت پیش از این مرحله:  </span>
                                    <span className={`text-xs font-semibold whitespace-normal ${checked ? "text-caspian-on_primary" : "text-caspian-on_primary/30"}`}> درصد پیشرفت این مرحله را وارد کنید :</span>
                                    <span className={`text-xs font-semibold whitespace-normal ${checked ? "text-caspian-on_primary" : "text-caspian-on_primary/30"}`}>درصد پیشرفت تجمیعی: </span>
                                </div>

                                <div className='flex flex-col items-start justify-around gap-2 pt-1 h-[90px]'>
                                    <span className='text-xs 2xl:text-sm text-caspian-gray'>{valuePercent}%</span>

                                    <div className='flex items-center gap-5'>
                                        <input
                                            disabled={checked ? false : true}
                                            defaultValue={dataProgress?.progress_percent}
                                            type='text' ref={percentRef} onChange={(e) => setProgressPercent(e.target.value)}
                                            className='border-2 text-center w-7 h-7 text-xs  rounded-lg outline-none border-caspian-gray2/50' />
                                        {(errorPercent) &&
                                            <span className='text-xs text-caspian-error2'>{errorPercentText}</span>
                                        }
                                    </div>

                                    <span className={`text-xs font-semibold 2xl:text-sm
                                 ${checked ? "text-caspian-on_primary" : "text-caspian-on_primary/30"}`}>
                                        {totlaValuePercent}%
                                    </span>
                                </div>
                            </div>
                            <div className='flex flex-col items-start gap-1'>
                                <span className={`${checked ? "text-caspian-gray4" : "text-caspian-gray4/30"} text-xs 2xl:text-sm`}>توضیحات</span>
                                <textarea
                                    defaultValue={dataProgress?.description}
                                    disabled={checked ? false : true}
                                    onChange={(e) => setDescription(e.target.value)}
                                    ref={descRef}
                                    className={`resize-none px-5 py-3 w-full font-semibold placeholder:font-normal text-xs outline-none border-2 border-caspian-gray2 rounded-lg

                                     ${checked ? "placeholder:text-caspian-gray4" : "placeholder:text-caspian-gray4/30 text-caspian-on_primary/50"} `}


                                    placeholder='توضیحات برنامه اجرایی را وارد کنید'
                                    cols={80}
                                    rows={4}
                                />
                            </div>

                        </div>

                    </div>
                </div>
                <ListUploadFile setAddDoc={setAddDoc} reportId={reportId} listDoc={listDoc} setListDoc={setListDoc} progressPercent={progressPercent} dataProgress={dataProgress}
                    setRefreshList={setRefreshList} refreshList={refreshList} checked={checked} />
            </div>

            <div className='w-full flex flex-col items-start gap-3 pb-2'>
                <div className='px-5'>

                    {checked ?
                        (!succesData || deleteData || !progressPercent) ?
                            <button
                                disabled={(progressPercent?.length > 0) || (!checked) ? false : true}
                                onClick={handleDocument}
                                className={`text-xs 2xl:text-sm  text-caspian-surface duration-500 w-[80px] h-8 rounded-[4px] 
                                    ${(progressPercent?.length > 0) || (!checked) ? "bg-caspian-primary-500" : "bg-caspian-gray"}`}>
                                ثبت
                            </button>
                            :
                            <button
                                disabled={(
                                    ((Number(progressPercent) !== dataProgress?.progress_percent))
                                    || (description?.length !== dataProgress?.description?.length)
                                    || addDoc

                                ) ? false : true}

                                onClick={editDataProgress}
                                className={`text-xs 2xl:text-sm  text-caspian-surface duration-500 w-[80px] h-8 rounded-[4px] 
                                ${(
                                        (Number(progressPercent) !== dataProgress?.progress_percent)
                                        || (description?.length !== dataProgress?.description?.length)
                                        || addDoc
                                    ) ? "bg-caspian-primary-500" : "bg-caspian-gray"}`}>
                                ویرایش
                            </button>
                        :
                        <button
                            disabled={(!checked) && (!deleteData) ? false : true}
                            onClick={clearData}
                            className={`text-xs 2xl:text-sm  text-caspian-surface duration-500 w-[80px] h-8 rounded-[4px] 
                                    ${(!checked) && (!deleteData) ? "bg-caspian-primary-500" : "bg-caspian-gray"}`}>
                            ثبت
                        </button>
                    }
                </div>

                <div className='w-full flex items-center border-t border-caspian-gray2 pt-3 px-5'>
                    <button
                        disabled={
                            (!checked && (deleteData))
                                || (checked && (((Number(progressPercent) === dataProgress?.progress_percent)) && (description?.length === dataProgress?.description?.length)) && !addDoc)
                                ? false
                                : true
                        }

                        onClick={() => setActiveTab("cost")}
                        className={`text-xs 2xl:text-sm flex items-center justify-center duration-500 text-caspian-surface w-[80px] h-8 rounded-[4px] 
                                ${(!checked && (deleteData))
                                || (checked && (((Number(progressPercent) === dataProgress?.progress_percent)) && (description?.length === dataProgress?.description?.length)) && !addDoc)
                                ? "bg-caspian-secondary-600"
                                : "bg-caspian-gray2"}`
                        }>

                        مرحله بعد
                        <PiCaretLeftBold className='font-black' />
                    </button>
                </div>
            </div >
        </div >
    )
}

export default MainFormWorkProgress