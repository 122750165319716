import React, { useEffect, useState } from "react";
import RowView from "./RowView";
import Paginate from "./Paginate";
import { useSearchParams } from "react-router-dom";
import { PiCircleNotch } from "react-icons/pi";
import SingleTableDraftProfileMeeting from "./SingleTableDraftProfileMeeting";

const TableListDraftProfileMeeting = ({
  data,
  loading,
  setShowDraftMeetingForm,
  setDraftId,
  setDarftTitle,
  setRefresh,
}) => {
  const [filterByTitle, setFilterByTitle] = useState("");
  const [filterByType, setFilterByType] = useState("");
  const [filterByPeriod, setFilterByPeriod] = useState("");
  const [filterByDate, setFilterByDate] = useState("");

  const [searchParams] = useSearchParams();
  const search = searchParams.get("search");
  const page = searchParams.get("page");
  const rows = searchParams.get("rows");

  let searchProfile = [];
  let filterTitle = [];
  let filterType = [];
  let filterPeriod = [];
  let filterDate = [];

  useEffect(() => {
    if (search) {
      setCurrentPage(1);
    }
  }, [search]);

  let newData = [-1];
  if (data[0] !== -1) {
    newData = search
      ? data.filter((d) =>
        d?.title.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      )
      : data;
  }

  if (newData[0] !== -1) {
    filterTitle = filterByTitle
      ? newData.filter((d) =>
        d.title
          .toLocaleLowerCase()
          .includes(filterByTitle.toLocaleLowerCase())
      )
      : newData;
    filterType = filterByType
      ? filterTitle?.filter((d) =>
        d?.meetingTypeRelated.title
          .toLocaleLowerCase()
          .includes(filterByType.toLocaleLowerCase())
      )
      : filterTitle;

    filterPeriod = filterByPeriod
      ? filterTitle?.filter((d) =>
        d?.holdingTime
          .toLocaleLowerCase()
          .includes(filterByPeriod.toLocaleLowerCase())
      )
      : filterType;

    filterDate = filterByDate
      ? filterPeriod.filter((d) =>
        "1402/02/02"
          .toLocaleLowerCase()
          .includes(filterByDate.toLocaleLowerCase())
      )
      : filterPeriod;
  }
  const [currentPage, setCurrentPage] = useState(page ? page : 1);
  const [recordsPerPage, setRecordsPerPage] = useState(rows ? rows : 10);
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = filterDate.slice(firstIndex, lastIndex);
  const npage = Math.ceil(filterDate.length / recordsPerPage);

  return (
    <div className="flex flex-col gap-4 py-6 px-8 xl:px-16">
      {loading ? (
        <div className="flex items-center justify-center py-40">
          <PiCircleNotch className="text-caspian-secondary-600 text-2xl animate-spin" />
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-[992px] w-full text-left text-sm font-light">
            <thead className="border-b border-b-caspian-gray2 font-medium">
              <tr>
                <th
                  scope="col"
                  className="rounded-tr-xl bg-caspian-primary-500 relative p-2 w-[20%]"
                >
                  <input
                    // onClick={() => setCurrentPage(1)}
                    onChange={(e) => {
                      setFilterByTitle(e.target.value);
                      setCurrentPage(1);
                    }}
                    type="search"
                    id="t1"
                    className={`${filterByTitle
                      ? "border border-caspian-surface/50 "
                      : "border border-caspian-transparent"
                      } text-[#FFF7CB] bg-caspian-transparent p-1.5 rounded-md outline-none  w-full focus:border focus:border-caspian-surface/50 pr-11 font-light peer cursor-pointer`}
                  />
                  <label htmlFor="t1"
                    className={`${filterByTitle ? "right-0 left-auto" : ""
                      } text-caspian-surface absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 p-0 bg-caspian-primary-500 rounded-md border border-caspian-primary-500 z-[30] whitespace-nowrap cursor-pointer font-light
                            peer-focus:left-auto peer-focus:right-0`}
                  >عنوان</label>
                </th>
                <th
                  scope="col"
                  className="bg-caspian-primary-500 relative p-2 w-[20%]"
                >
                  <input
                    onChange={(e) => {
                      setFilterByType(e.target.value);
                      setCurrentPage(1);
                    }}
                    type="search"
                    id="t2"
                    className={`${filterByType
                      ? "border border-caspian-surface/50 "
                      : "border border-caspian-transparent"
                      } text-[#FFF7CB] bg-caspian-transparent p-1.5 rounded-md outline-none  w-full focus:border focus:border-caspian-surface/50 pr-9 font-light peer cursor-pointer`}
                  />
                  <label htmlFor="t2"
                    className={`${filterByType ? "right-2 left-auto" : ""
                      } text-caspian-surface absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 p-0 bg-caspian-primary-500 rounded-md border border-caspian-primary-500 z-[30] whitespace-nowrap cursor-pointer font-light
                            peer-focus:left-auto peer-focus:right-2`}
                  >نوع</label>
                </th>
                <th
                  scope="col"
                  className="bg-caspian-primary-500 relative p-2 w-[20%]"
                >
                  <input
                    onChange={(e) => setFilterByPeriod(e.target.value)}
                    type="search"
                    id="t3"
                    className={`${filterByPeriod
                      ? "border border-caspian-surface/50 "
                      : "border border-caspian-transparent"
                      } text-[#FFF7CB] bg-caspian-transparent p-1.5 rounded-md outline-none  w-full focus:border focus:border-caspian-surface/50 pr-[78px] font-light peer cursor-pointer`}
                  />
                  <label htmlFor="t3"
                    className={`${filterByPeriod ? "right-4 left-full -translate-x-0" : ""
                      } text-caspian-surface absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 p-0 whitespace-nowrap rounded-md border border-caspian-primary-500 z-[30] cursor-pointer font-light
                            peer-focus:left-full peer-focus:right-4 focus:-translate-x-0`}
                  >دوره برگزاری</label>
                </th>
                <th
                  scope="col"
                  className="bg-caspian-primary-500 relative p-2 w-[20%]"
                >
                  <input
                    onChange={(e) => setFilterByDate(e.target.value)}
                    type="search"
                    id="t4"
                    className={`${filterByDate
                      ? "border border-caspian-surface/50 "
                      : "border border-caspian-transparent"
                      } text-[#FFF7CB] bg-caspian-transparent p-1.5 rounded-md outline-none  w-full focus:border focus:border-caspian-surface/50 pr-[76px] font-light peer cursor-pointer`}
                  />
                  <label htmlFor="t4"
                    className={`${filterByDate ? "right-4 left-full -translate-x-0" : ""
                      } text-caspian-surface absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 p-0 whitespace-nowrap rounded-md border border-caspian-primary-500 z-[30] cursor-pointer font-light
                            peer-focus:left-full peer-focus:right-4 focus:-translate-x-0`}
                  >تاریخ ایجاد</label>
                </th>
                <th
                  scope="col"
                  className="bg-caspian-primary-500 rounded-tl-xl p-2 relative w-[20%]"
                ></th>
              </tr>
            </thead>
            {records.length > 0 ? (
              <tbody>
                {records.map((d, i) => (
                  <SingleTableDraftProfileMeeting
                    setRefresh={setRefresh}
                    setShowDraftMeetingForm={setShowDraftMeetingForm}
                    key={i}
                    id={d.id}
                    //عنوان
                    title={d?.title}
                    //نوع
                    type={d?.meetingTypeRelated}
                    //دوره برگزاری
                    period={d?.holdingTime}
                    // تاریخ ذخیره
                    date={d.created_at}
                    setDraftId={setDraftId}
                    setDarftTitle={setDarftTitle}
                  />
                ))}
              </tbody>
            ) : (
              <tbody className="py-24">
                <tr>
                  <td className="py-24">
                    <div className="flex items-center justify-center w-full py-10">
                      <div className="z-10 absolute left-1/2 -translate-x-1/2 mx-auto">
                        <div className="flex flex-col gap-2 items-center justify-center w-full">
                          <img
                            src="/assets/images/library/not-found.svg"
                            alt="پروفایل جلسه ای یافت نشد..."
                          />
                          <h2>پروفایل جلسه ای یافت نشد...</h2>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      )}
      {records.length > 0 && (
        <div className="flex items-center justify-between py-2">
          <RowView
            setRecordsPerPage={setRecordsPerPage}
            setCurrentPage={setCurrentPage}
          />
          <Paginate
            pageCount={npage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
          <div className="text-sm flex items-center gap-4">
            <span>نمایش {firstIndex + 1}</span>
            <span>تا {lastIndex - (recordsPerPage - records.length)}</span>
            <span>از کل {filterDate.length}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableListDraftProfileMeeting;
