import React, { useState } from "react";
import { PiPlusCircle } from "react-icons/pi";
import RiskNavbar from "../RiskNavbar";
import MainListTeams from "./list-teams/MainListTeams";
import MainCreateForm from "./form/create/MainCreateForm";
import MainPlanning from "./planning/MainPlanning";
import NewRiskNavbar from "../NewRiskNavbar";

const MainTeams = () => {
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showPlanning, setShowPlanning] = useState(false);
  const [refreshTeamsList, setRefreshTeamsList] = useState(0);
  return (
    <div>
      {showCreateForm && (
        <MainCreateForm
          setShowCreateForm={setShowCreateForm}
          setRefreshTeamsList={setRefreshTeamsList}
        />
      )}
      {showPlanning && (
        <MainPlanning
          setShowPlanning={setShowPlanning}
          setRefreshTeamsList={setRefreshTeamsList}
        />
      )}
      {/* <RiskNavbar buttonActive="teams" /> */}
      <NewRiskNavbar buttonActive="teams" />

      <div className="flex flex-col gap-2 p-2">
        <div className="flex items-center gap-2 p-2">
          <button
            onClick={() => setShowCreateForm(true)}
            className="w-fit flex items-center gap-1 rounded-md bg-caspian-secondary-500 text-caspian-surface text-sm p-2"
          >
            <PiPlusCircle className="text-xl" />
            <span className="text-sm">ایجاد تیم جدید</span>
          </button>
          {/* <button
            onClick={() => setShowPlanning(true)}
            className="w-fit flex items-center gap-1 rounded-md bg-caspian-secondary-500 text-caspian-surface text-sm p-2"
          >
            <PiPlusCircle className="text-xl" />
            <span className="text-sm">برنامه ریزی ریسک</span>
          </button> */}
        </div>
        <MainListTeams
          refreshTeamsList={refreshTeamsList}
          setRefreshTeamsList={setRefreshTeamsList}
        />
      </div>
    </div>
  );
};

export default MainTeams;
