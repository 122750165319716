import React, { useEffect, useRef, useState } from "react";
import { PiCaretDownBold, PiFunnelThin, PiMagnifyingGlassBold } from "react-icons/pi";

const StatusFilter = ({
    status,
    setStatus,
    statusSelected,
    setStatusSelected,
    showListstatus,
    setShowListstatus,
    statusList,
    lable
}) => {
    let menuRef = useRef();

    const [open, setOpen] = useState(false);

    return (
        <div ref={menuRef} className='flex flex-col gap-1.5 w-[150px] relative'>
            <div className="flex justify-between items-center">
                <label className="text-xs text-caspian-gray4">{lable}</label>
            </div>
            <div
                onClick={() => {
                    // setShowListstatus(!showListstatus);
                    setOpen(!open);
                }}
                className='text-xs border border-caspian-gray2 p-1 2xl:p-2 rounded-md cursor-pointer flex items-center gap-2 bg-caspian-surface'
            >
                <PiFunnelThin className="text-lg text-caspian-gray" />
                {statusSelected !== '' && status !== undefined ? (
                    <span className="text-xs">
                        {statusSelected?.title}
                    </span>
                ) : (
                    <span className="text-caspian-gray1 text-xs whitespace-nowrap">انتخاب کنید</span>
                )}
                <PiCaretDownBold className="text-caspian-gray mr-auto" />
            </div>
            {
                <div
                    className={
                        open
                            ? "rounded-md shadow-[0_0_5px_#00000050] w-full flex flex-col gap-3 py-3 absolute left-0 right-0 top-[58px] bg-caspian-surface z-50"
                            : "hidden"
                    }
                >
                    <div className="flex flex-col gap-2 w-full max-h-[200px] overflow-y-scroll">
                        {statusList?.length > 0 &&
                            statusList.map((m, i) => (
                                <div
                                    key={i}
                                    onClick={() => {
                                        setStatusSelected(m);
                                        setStatus(m?.val);
                                        setOpen(false);
                                    }}
                                    className="flex items-center justify-start gap-2 text-xs hover:bg-caspian-secondary-100 py-1 px-2 w-full cursor-pointer duration-300"
                                >
                                    <span className="">{m?.title}</span>
                                </div>
                            ))}
                    </div>
                </div>
            }
        </div>
    );
};

export default StatusFilter;
