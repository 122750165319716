import React, { useContext, useEffect, useState } from "react";
import MainAuditChecklistForm from "./audit-checklist-form/MainAuditChecklistForm";
import API from "../../../api/API";
import { AuthContext } from "../../../context/auth/AuthContext";
import { Outlet, useLocation, useParams } from "react-router-dom";

const MainAuditChecklist = () => {
  const { token } = useContext(AuthContext);
  const [questionData, setQuestionData] = useState([-1]);
  const [questionFilterClause, setQuestionFilterClause] = useState([-1]);
  const [questionFilterProcess, setQuestionFilterProcess] = useState([-1]);
  const [standardUniqList, setStandardUniqList] = useState([-1]);
  const location = useLocation();
  const { idSection } = useParams();

  useEffect(() => {
    if (!token || !idSection) return;
    const getQuestion = async () => {
      try {
        const res = await API.get(
          `/api/v1/audit/question/filter/${idSection}`,
          {
            headers: { Authorization: token },
          }
        );
        console.log(res.data);
        console.log(res.data?.allQuestion);
        console.log(res.data?.questionClause);
        console.log(res.data?.questionProcess);
        setQuestionData(res?.data?.allQuestion);
        setQuestionFilterClause(res?.data?.questionClause);
        setQuestionFilterProcess(res?.data?.questionProcess);

        const uniqueArray = res?.data?.questionClause.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (obj) =>
                obj.standardRelated.standardNumber ===
                item.standardRelated.standardNumber
            )
        );
        setStandardUniqList(uniqueArray);
        console.log(uniqueArray);
      } catch (error) {
        console.log(error);
      }
    };
    getQuestion();
  }, [token, idSection]);

  return location?.pathname?.includes(`/${idSection}/profile`) ? (
    <Outlet />
  ) : (
    <MainAuditChecklistForm
      questionData={questionData}
      questionFilterClause={questionFilterClause}
      processFilter={questionFilterProcess}
      standardUniqList={standardUniqList}
    />
  );
};

export default MainAuditChecklist;
