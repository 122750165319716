import NewListItemSingle from './NewListItemSingle'
import { PiPlayCircleLight, PiPulse, PiUsers } from 'react-icons/pi'

const NewRiskNavbar = ({ buttonActive }) => {
    let ba = "تیم‌ها"
    if (buttonActive === "teams") {
        ba = "تیم‌ها"
    } else if (buttonActive === "activities") {
        ba = "فعالیت‌ها"
    } else if (buttonActive === "risks") {
        ba = "ریسک‌ها"
    }
    return (
        <div className='w-full flex items-center justify-start gap-3 pt-3 pr-5'>
            <div className='flex items-center w-fit rounded-xl p-2 shadow-[0px_4px_4px_0px_#00000040] bg-[#F9FAFB]'>
                <div className='flex items-center border border-caspian-secondary-600 rounded-full p-1 gap-8'>
                    <NewListItemSingle
                        title={"تیم‌ها"}
                        icon={<PiUsers />}
                        active={"teams"}
                        link={"/risk/teams"}
                        buttonActive={buttonActive}
                    />

                    <NewListItemSingle
                        title={"فعالیت‌ها"}
                        icon={<PiPlayCircleLight />}
                        active={"activities"}
                        link={"/risk/activities"}
                        buttonActive={buttonActive}
                    />

                    <NewListItemSingle
                        title={"ریسک‌ها"}
                        icon={<PiPulse />}
                        active={"risks"}
                        link={"/risk/risks"}
                        buttonActive={buttonActive}
                    />
                </div>
            </div>
            <div className='flex items-center justify-center w-[65%] xl:w-[80%]'>
                <div className='bg-caspian-primary-900 border-[1.5px] w-full border-caspian-secondary-600'></div>
                <span className='w-[200px] xl:w-[300px] py-3 text-sm text-center 2xl:text-xl text-caspian-secondary-600'>{ba}</span>
                <div className='bg-caspian-primary-900 border-[1.5px] w-full border-caspian-secondary-600'></div>
            </div>
        </div>
    )
}

export default NewRiskNavbar