
const TopProfile = ({ data }) => {
    return (
        <div className="bg-caspian-surface rounded-lg border border-caspian-gray2 p-5 text-sm 2xl:text-base">

            <div className='flex justify-between items-center'>
                <h2 className='font-medium text-sm xl:text-lg 2xl:text-xl'> {data?.title} </h2>
                <p> <span className='text-caspian-gray4'>کد پست: </span>
                    {/* W-015-02 */}
                    {data?.code}</p>
            </div>

            <div className='flex py-7 border-b border-[#F0F3FD]'>
                <div className='basis-1/2 space-y-4'>
                    <div>
                        <span className='text-caspian-gray4'> شغل مرتبط: </span>
                        <span>
                            {/* کارشناس توسعه مدیریت */}

                            {data?.positionJobRelated?.title}
                        </span>
                    </div>
                    <div>
                        <span className='text-caspian-gray4'> رده پست سازمانی: </span>
                        {/* <span>کارشناسی</span> */}
                        <span>
                            {/* {data?.positionLevelRelated?.title} */}
                            {data?.jobLevelRelated?.title}
                        </span>
                    </div>
                    <div>
                        <span className='text-caspian-gray4'>واحد سازمانی: </span>
                        <span>
                            {/* دفتر توسعه مدیریت و تحقیقات */}
                            {data?.positionSectionRelated?.title}
                        </span>
                    </div>
                </div>
                <div className='basis-1/2 space-y-4'>
                    <div>
                        <span className='text-caspian-gray4'>کد شغل: </span>
                        <span>
                            {/* W-015-02 */}
                            {data?.positionJobRelated?.code}
                        </span>
                    </div>
                    <div>
                        <span className='text-caspian-gray4'> نوع پست سازمانی: </span>
                        {/* <span>کارشناسی</span> */}
                        <span>
                            {/* {data?.positionTypeRelated?.title} */}
                            {data?.jobTypeRelated?.title}
                        </span>
                    </div>
                    <div>
                        <span className='text-caspian-gray4'>مافوق پست سازمانی: </span>
                        <span>
                            {/* مدیر دفتر توسعه مدیریت و تحقیقات */}
                            {data?.positionHead?.title}
                        </span>
                    </div>
                </div>
            </div>

            <div className='py-4'>
                <h5 className='text-sm 2xl:text-base text-caspian-gray4 font-medium mb-3'>افراد مورد تصدی در این پست:</h5>
                <div className='flex flex-wrap gap-2'>
                    {/* <p> */}
                    {
                        data?.AllPlacements?.map(a => <span
                            key={a?.id}
                            className='text-caspian-secondary-600 text-sm 2xl:text-base'>
                            {a?.placementProfileRelated?.firstName + ' ' + a?.placementProfileRelated?.lastName}
                        </span>)
                    }
                    {/* </p> */}
                </div>
            </div>
        </div>
    );
}

export default TopProfile;
