// import React, { useRef, useEffect } from "react";
// import * as d3 from "d3";


// const StackedBarChart2 = ({ data }) => {
//     const svgRef = useRef();


//     useEffect(() => {
//         const width = 800;
//         const height = 400;
//         const margin = { top: 20, right: 30, bottom: 30, left: 40 };


//         // گرفتن داده‌های clause برای استفاده در نمودار
//         const clauseData = data[0].clause;


//         // تنظیمات محور X و Y
//         const x = d3.scaleBand()
//             .domain(clauseData.map(d => d.name))
//             .range([margin.left, width - margin.right])
//             .padding(0.1);


//         const y = d3.scaleLinear()
//             .domain([0, d3.max(clauseData, d => d.nc + d.obs)])
//             .nice()
//             .range([height - margin.bottom, margin.top]);


//         // تنظیم رنگ‌ها برای nc و obs
//         const color = d3.scaleOrdinal()
//             .domain(["nc", "obs"])
//             .range(["#1f77b4", "#ff7f0e"]);


//         // آماده‌سازی داده‌ها به صورت stacked
//         const stackedData = d3.stack()
//             .keys(["nc", "obs"])
//             .value((d, key) => d[key])(clauseData);


//         const svg = d3.select(svgRef.current)
//             .attr("width", width)
//             .attr("height", height);


//         // محور X
//         svg.append("g")
//             .attr("transform", `translate(0,${height - margin.bottom})`)
//             .call(d3.axisBottom(x));


//         // محور Y
//         svg.append("g")
//             .attr("transform", `translate(${margin.left},0)`)
//             .call(d3.axisLeft(y));


//         // ایجاد barهای stacked
//         svg.append("g")
//             .selectAll("g")
//             .data(stackedData)
//             .enter()
//             .append("g")
//             .attr("fill", d => color(d.key))
//             .selectAll("rect")
//             .data(d => d)
//             .enter()
//             .append("rect")
//             .attr("x", d => x(d.data.name))
//             .attr("y", d => y(d[1]))
//             .attr("height", d => y(d[0]) - y(d[1]))
//             .attr("width", x.bandwidth());


//         // اضافه کردن لِگِند
//         const legend = svg.append("g")
//             .attr("transform", `translate(${width - margin.right - 80},${margin.top})`);


//         ["nc", "obs"].forEach((key, i) => {
//             legend.append("rect")
//                 .attr("x", 0)
//                 .attr("y", i * 20)
//                 .attr("width", 15)
//                 .attr("height", 15)
//                 .attr("fill", color(key));


//             legend.append("text")
//                 .attr("x", 20)
//                 .attr("y", i * 20 + 12)
//                 .text(key)
//                 .style("font-size", "12px")
//                 .attr("alignment-baseline", "middle");
//         });


//     }, [data]);


//     return <svg ref={svgRef}></svg>;
// };


// export default StackedBarChart2;



// import React, { useRef, useEffect } from "react";
// import * as d3 from "d3";


// const StackedBarChart = ({ data, filter }) => {
//   const svgRef = useRef();


//   useEffect(() => {
//     const width = 800;
//     const height = 400;
//     const margin = { top: 20, right: 30, bottom: 30, left: 40 };


//     // بررسی داده‌ها در کنسول
//     console.log("Received data:", data);
//     console.log("Filter:", filter);


//     const clauseData = data[0].clause.map(d => ({
//       ...d,
//       nc: d.nc || 0,
//       obs: d.obs || 0,
//     }));


//     const maxYValue = data[0].count || 0;


//     // مقیاس محور x و y
//     const x = d3.scaleBand()
//       .domain(clauseData.map(d => d.name))
//       .range([margin.left, width - margin.right])
//       .padding(0.1);


//     const y = d3.scaleLinear()
//       .domain([0, maxYValue])
//       .nice()
//       .range([height - margin.bottom, margin.top]);


//     const color = d3.scaleOrdinal()
//       .domain(["nc", "obs"])
//       .range(["#1f77b4", "#ff7f0e"]);


//     // داده‌های انباشته‌شده با فیلتر
//     const stackedData = d3.stack()
//       .keys(filter)
//       .value((d, key) => d[key] || 0)(clauseData);


//     console.log("Stacked Data:", stackedData);


//     const svg = d3.select(svgRef.current)
//       .attr("width", width)
//       .attr("height", height);


//     svg.selectAll("*").remove();


//     // رسم محور x
//     svg.append("g")
//       .attr("transform", `translate(0,${height - margin.bottom})`)
//       .call(d3.axisBottom(x));


//     // رسم محور y
//     svg.append("g")
//       .attr("transform", `translate(${margin.left},0)`)
//       .call(d3.axisLeft(y));


//     // رسم بارهای انباشته
//     svg.append("g")
//       .selectAll("g")
//       .data(stackedData)
//       .enter()
//       .append("g")
//       .attr("fill", d => color(d.key))
//       .selectAll("rect")
//       .data(d => d)
//       .enter()
//       .append("rect")
//       .attr("x", d => x(d.data.name))
//       .attr("y", d => y(d[1])) 
//       .attr("height", d => y(d[0]) - y(d[1])) 
//       .attr("width", x.bandwidth());


//     // اضافه کردن لگند
//     const legend = svg.append("g")
//       .attr("transform", `translate(${width - margin.right - 80},${margin.top})`);


//     filter.forEach((key, i) => {
//       legend.append("rect")
//         .attr("x", 0)
//         .attr("y", i * 20)
//         .attr("width", 15)
//         .attr("height", 15)
//         .attr("fill", color(key));


//       legend.append("text")
//         .attr("x", 20)
//         .attr("y", i * 20 + 12)
//         .text(key)
//         .style("font-size", "12px")
//         .attr("alignment-baseline", "middle");
//     });


//   }, [data, filter]);


//   return <svg ref={svgRef}></svg>;
// };


// export default StackedBarChart;




import React, { useRef, useEffect } from "react";
import * as d3 from "d3";


const StackedBarChart = ({ data, filter, setShowBar, setType, setMainClause }) => {
    const svgRef = useRef();


    useEffect(() => {
        const width = 800;
        const height = 400;
        const margin = { top: 20, right: 30, bottom: 30, left: 40 };


        // تنظیم داده‌ها بر اساس ساختار جدید
        const clauseData = data[0].clause.map(clause => {
            const ncValue = data[0].nc.find(nc => nc.name === clause.name)?.value || 0;
            const obsValue = data[0].obs.find(obs => obs.name === clause.name)?.value || 0;
            return {
                ...clause,
                nc: ncValue,
                obs: obsValue
            };
        });


        // محاسبه مقدار حداکثری محور Y
        const maxYValue = d3.max(clauseData, d => d.nc + d.obs);


        // مقیاس محور x و y
        const x = d3.scaleBand()
            .domain(clauseData.map(d => d.name))
            .range([margin.left, width - margin.right])
            .padding(0.1);


        const y = d3.scaleLinear()
            .domain([0, maxYValue])
            .nice()
            .range([height - margin.bottom, margin.top]);


        const color = d3.scaleOrdinal()
            .domain(["nc", "obs"])
            .range(["#1f77b4", "#ff7f0e"]);


        // داده‌های انباشته‌شده با فیلتر
        const stackedData = d3.stack()
            .keys(filter)
            .value((d, key) => d[key] || 0)(clauseData);


        const svg = d3.select(svgRef.current)
            .attr("width", width)
            .attr("height", height);


        svg.selectAll("*").remove();


        // رسم محور x
        svg.append("g")
            .attr("transform", `translate(0,${height - margin.bottom})`)
            .call(d3.axisBottom(x));


        // رسم محور y
        svg.append("g")
            .attr("transform", `translate(${margin.left},0)`)
            .call(d3.axisLeft(y));


        // رسم بارهای انباشته
        svg.append("g")
            .selectAll("g")
            .data(stackedData)
            .enter()
            .append("g")
            .attr("fill", d => color(d.key))
            .selectAll("rect")
            .data(d => d)
            .enter()
            .append("rect")
            .attr("x", d => x(d.data.name))
            .attr("y", d => y(d[1]))
            .attr("height", d => y(d[0]) - y(d[1]))
            .attr("width", x.bandwidth())
            // .on("click", d => console.log(d?.srcElement?. __data__?.data));
            .on("click", (event, d) => {
                const type = d3.select(event.target.parentNode).datum().key;
                setMainClause(d?.data?.name) //event?.srcElement?.__data__?.data?.name
                setType(type);
                setShowBar(true);
            });


        // اضافه کردن لگند
        const legend = svg.append("g")
            .attr("transform", `translate(${width - margin.right - 80},${margin.top})`);


        filter.forEach((key, i) => {
            legend.append("rect")
                .attr("x", 0)
                .attr("y", i * 20)
                .attr("width", 15)
                .attr("height", 15)
                .attr("fill", color(key));


            legend.append("text")
                .attr("x", 20)
                .attr("y", i * 20 + 12)
                .text(key)
                .style("font-size", "12px")
                .attr("alignment-baseline", "middle");
        });


    }, [data, filter]);


    return <svg ref={svgRef}></svg>;
};


export default StackedBarChart;

