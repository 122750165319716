import { Outlet, useLocation, useParams } from "react-router-dom";
import { useAppContext } from "../../context/app-context/AppContext";
import { useContext, useEffect } from "react";
import AuditNavNew from "./audit-nav/AuditNavNew";
import { AuthContext } from "../../context/auth/AuthContext";

const AuditLayout = ({ showMenu }) => {
  const location = useLocation();
  const { id, idProfile, idChecklist, idSection } = useParams();
  const { user } = useContext(AuthContext);
  // const isAuditer = user?.placementProfileRelated?.id === 1 ? true : false;
  const { setBradCrump1, setBradCrump2, setBrcLink2 } = useAppContext();
  useEffect(() => {
    if (location.pathname.includes("audit/planning")) {
      setBradCrump1("ممیزی");
      setBradCrump2("برنامه ریزی ممیزی");
    } else if (location.pathname.includes(`audit/chart`)) {
      setBradCrump1("ممیزی");
      setBradCrump2("نمودارها");
    } else if (location.pathname.includes(`audit/${id}`)) {
      setBradCrump1("شناسنامه ممیزی");
      setBradCrump2(id);
    }
  }, [id, location, setBradCrump1, setBradCrump2, setBrcLink2]);
  return (
    <div
      className={
        showMenu
          ? `pl-[50px] mr-auto w-full md:w-[75%] lg:w-[80%] xl:w-[85%] 2xl:w-[85%] bg-caspian-gray3 p-1 overflow-y-auto`
          : `pl-[50px] mr-[54px] w-full bg-caspian-gray3 p-1 overflow-y-auto`
      }
    >
      {
        <div className="flex flex-col gap-3 2xl:gap-6 w-full px-4">
          {!idSection && !idChecklist && (
            <div className="flex items-center gap-2 w-full pt-2 2xl:pt-4">
              <AuditNavNew />
              <div className="w-full border-b border-b-caspian-secondary-500"></div>
            </div>
          )}
          {/* <AuditNav /> */}
          <div className="w-full h-min-screen">
            {/* {user.id ? ( */}
            <Outlet />
            {/* ) : (
              <h2>در حال بارگزاری</h2>
            )} */}
          </div>
        </div>
      }
    </div>
  );
};

export default AuditLayout;
