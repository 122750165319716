import React, { useState } from "react";
import MainLeftbarChecklistForm from "./leftbar-checklist-form/MainLeftbarChecklistForm";
import MainRightbarChecklistForm from "./rightbar-checklist-form/MainRightbarChecklistForm";

const MainAuditChecklistForm = ({
  questionData,
  questionFilterClause,
  processFilter,
  standardUniqList,
}) => {
  const [indexQuestion, setIndexQuestion] = useState(null);

  return (
    <div className="flex items-start gap-0 w-full min-h-screen py-4">
      <MainRightbarChecklistForm
        indexQuestion={indexQuestion}
        setIndexQuestion={setIndexQuestion}
        questionData={questionData}
        clauseList={questionFilterClause}
        standardList={standardUniqList}
        processFilter={processFilter}
      />
      <MainLeftbarChecklistForm />
    </div>
  );
};

export default MainAuditChecklistForm;
