import React from "react";

const SingleQuestionList = ({ question }) => {
  return (
    <div className="flex flex-col gap-0">
      <div className="flex items-center gap-8 w-full border-b border-b-caspian-gray2 py-4">
        <div className="flex items-center gap-3">
          <span className="text-xs font-medium text-caspian-secondary-500">
            QMS
          </span>
          <span className="text-xs font-medium text-caspian-secondary-500">
            ISO9001-2015
          </span>
          <span className="text-xs font-medium text-caspian-secondary-500">
            5-1-2
          </span>
        </div>
        <span className="text-xs font-medium text-caspian-primary-500">
          لورم ایپسوم
        </span>
      </div>
      {/* سوال ها و معیارها */}
      <div className="flex items-start gap-2 w-full pt-4">
        <span className="flex items-center justify-center p-2 rounded-md border border-caspian-primary-400 text-caspian-primary-400 text-sm w-6 h-6">
          1
        </span>
        <div className="flex flex-col gap-2 items-start">
          <div className="flex items-start gap-2">
            <span className="text-xs">سوال:</span>
            <p className="text-xs">
              سوال تستی برای این معیار تستی برای این سوال لورم ایپسوم متن ساختگی
              با تولید سادگی نامفهوم از صنعت چاپ،است لورم ایپسوم متن ساختگی با
              تولید سادگی نامفهوم از صنعت
            </p>
          </div>
          <div className="flex items-start gap-2 text-caspian-secondary-500">
            <span className="text-xs">معیار:</span>
            <p className="text-xs">
              معیار تستی برای این معیار تستی برای این سوال لورم
            </p>
          </div>
        </div>
      </div>
      {/* سوال ها و معیارها */}
      <div className="flex items-start gap-2 w-full py-2">
        <span className="flex items-center justify-center p-2 rounded-md border border-caspian-primary-400 text-caspian-primary-400 text-sm w-6 h-6">
          2
        </span>
        <div className="flex flex-col gap-2 items-start">
          <div className="flex items-start gap-2">
            <span className="text-xs">سوال:</span>
            <p className="text-xs">
              سوال تستی برای این معیار تستی برای این سوال لورم ایپسوم متن ساختگی
              با تولید سادگی نامفهوم از صنعت چاپ،است لورم ایپسوم متن ساختگی با
              تولید سادگی نامفهوم از صنعت
            </p>
          </div>
          <div className="flex items-start gap-2 text-caspian-secondary-500">
            <span className="text-xs">معیار:</span>
            <p className="text-xs">
              معیار تستی برای این معیار تستی برای این سوال لورم
            </p>
          </div>
        </div>
      </div>
      {/* سوال ها و معیارها */}
      <div className="flex items-start gap-2 w-full py-2">
        <span className="flex items-center justify-center p-2 rounded-md border border-caspian-primary-400 text-caspian-primary-400 text-sm w-6 h-6">
          3
        </span>
        <div className="flex flex-col gap-2 items-start">
          <div className="flex items-start gap-2">
            <span className="text-xs">سوال:</span>
            <p className="text-xs">
              سوال تستی برای این معیار تستی برای این سوال لورم ایپسوم متن ساختگی
              با تولید سادگی نامفهوم از صنعت چاپ،است لورم ایپسوم متن ساختگی با
              تولید سادگی نامفهوم از صنعت
            </p>
          </div>
          <div className="flex items-start gap-2 text-caspian-secondary-500">
            <span className="text-xs">معیار:</span>
            <p className="text-xs">
              معیار تستی برای این معیار تستی برای این سوال لورم
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleQuestionList;
