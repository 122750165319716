import React, { useContext, useEffect, useState } from "react";
import ProcressTopProfile from "./ProcressTopProfile";
import InfoTaskProfile from "../info-task-profile/InfoTaskProfile";
import MainCreateFormReport from "../../../report/form-report-action-plan/create-form-report/MainCreateFormReport";
import { PiChatsCircle } from "react-icons/pi";
import CommentsMain from "../../../comments/CommentsMain";
import { AuthContext } from "../../../../../context/auth/AuthContext";
import { useParams } from "react-router-dom";
import API from "../../../../../api/API";

const MainTopProfile = ({ reportId, taskData, showModalFrom, setShowModalFrom, setCostListObject, costListObject }) => {
  const { token, user } = useContext(AuthContext)

  const params = useParams()

  const [showInfoProfile, setShowInfoProfile] = useState(false);
  const [showComments, setShowComments] = useState(false)
  const [isAccessComment, setIsAccessComment] = useState(false)
  const [alarm, setAlarm] = useState(false)
  const [valueForm, setValueForm] = useState("");

  const inDay = taskData?.durationDate - taskData?.remained_days;
  const valuePercentTime = inDay < 1 ? 0 : inDay;
  const maxValuePercentTime = taskData?.durationDate;

  const valuePercentProgress = taskData?.progress;
  const maxValuePercentprogress = 100;

  const valuePercentCost = taskData?.costProgress;
  const maxValuePercentCost = taskData?.cost;

  const valuePercent = ((valuePercentProgress / maxValuePercentprogress) * 100)

  let status = taskData?.status;

  let statusName = "";
  let statusClass = "";

  if (status === "Working") {
    statusName = "در حال انجام";
    statusClass = "text-[#3C77A2] bg-[#3C77A210] border-[#3C77A2]";
  } else if (status === "NotStarted") {
    statusName = "شروع نشده";
    statusClass = "text-[#99999999] bg-[#99999910] border-[#99999999]";
  } else if (status === "Done") {
    statusName = "انجام شده";
    statusClass = "text-[#01BC8D] bg-[#01BC8D10] border-[#01BC8D]";
  } else if (status === "PrePlaning") {
    statusName = "درحال برنامه‌ریزی";
    statusClass = "text-[#D6A834] bg-[#D6A83410] border-[#D6A834]";
  } else if (status === "Closed") {
    statusName = "بسته شده";
    statusClass = "text-[#FF440A] bg-[#FF440A10] border-[#FF440A]";
  } else if (status === "Delayed") {
    statusName = "با تاخیر";
    statusClass = "text-[#000000] bg-[#FFFFFF] border-[#000000]";
  }

  useEffect(() => {
    setIsAccessComment(
      (user?.placementProfileRelated?.id === taskData?.actionplan_responsibles?.manager?.id) ||
        (user?.placementProfileRelated?.id === taskData?.actionplan_responsibles?.accounting_observer?.placementProfileRelated?.id) ||
        (user?.placementProfileRelated?.id === taskData?.actionplan_responsibles?.budget_observer?.placementProfileRelated?.id) ||
        (user?.placementProfileRelated?.id === taskData?.actionplan_responsibles?.general_observer?.placementProfileRelated?.id) ||
        (user?.placementProfileRelated?.id === taskData?.actionplan_responsibles?.hse_observer?.placementProfileRelated?.id) ||
        (user?.placementProfileRelated?.id === taskData?.actionplan_responsibles?.legal_observer?.placementProfileRelated?.id) ||
        (user?.placementProfileRelated?.id === taskData?.actionplan_responsibles?.tech_observer?.placementProfileRelated?.id)
        ? true
        : false
    );
  }, [user, taskData])

  useEffect(() => {
    setIsAccessComment((taskData?.actionplanRelated?.statusAction === "PMOConfirm" &&
      (taskData?.userRole?.includes("MANAGER") || taskData?.userRole?.includes("PMO"))
    ) || (taskData?.actionplanRelated?.statusAction === "ObseversCommentsApproval" &&
      (taskData?.userRole?.includes("OBSERVER") || taskData?.userRole?.includes("MANAGER") || taskData?.userRole?.includes("PMO"))
      ) || (taskData?.actionplanRelated?.statusAction === "VarifyConfirm" &&
        (taskData?.userRole?.includes("VARIFY_PLACEMENT") || taskData?.userRole?.includes("MANAGER") || taskData?.userRole?.includes("PMO"))
      ) || (taskData?.actionplanRelated?.statusAction === "ApprovalConfirm" &&
        (taskData?.userRole?.includes("APPROVAL_PLACEMENT") || taskData?.userRole?.includes("MANAGER") || taskData?.userRole?.includes("PMO"))
      ))
  }, [taskData])
  

  useEffect(() => {
    if (!token || !params?.id) return;
    const haasComment = async () => {
      let formData = {
        task_id: params?.id,
        has_comment: false
      }
      try {
        const res = await API.post(`/api/v1/actionplan/taskprofilehascomment`, formData,
          { headers: { Authorization: token } }
        )
        console.log(res?.data)
        setAlarm(res?.data?.has_comment)
      } catch (error) {
        console.log(error)
      }
    }
    haasComment()
  })
  const showComment = async () => {
    setShowComments(true)
    setAlarm(false)
  }

  return (
    <div className="w-full p-3">
      {showInfoProfile && (
        <InfoTaskProfile setShowInfoProfile={setShowInfoProfile} />
      )}
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center gap-4">
          <h2 className="text-sm font-bold">{taskData?.title}</h2>
          <span className={`border p-1.5 rounded-lg text-xs ${statusClass}`}>
            {statusName}
          </span>
        </div>
        <div className="flex items-center gap-16">
          <span
            className="text-xs font-bold pt-2"
          // onClick={() => setShowInfoProfile(true)}
          >
            کد وظیفه{" "}
            <span>
              {"T-" + taskData?.id + "-" + taskData?.actionplanRelated?.id}
            </span>
          </span>

          <div className="flex relative items-center pt-2 justify-end  gap-2  text-caspian-secondary-600">
            {isAccessComment &&
              (
                <div className="absolute left-5 cursor-pointer">
                  {alarm &&
                    <>
                      <div className="w-3 h-3 rounded-full left-0 top-0 z-30 bg-caspian-error2 absolute"></div>
                      <div className="w-3 h-3 rounded-full animate-ping  left-0 top-0 z-30 bg-caspian-error2 absolute"></div>
                    </>
                  }
                  <PiChatsCircle
                    className="text-2xl z-40 text-caspian-secondary-500 cursor-pointer"
                    onClick={showComment}>
                  </PiChatsCircle>
                </div>
              )
            }
          </div>

          {showComments &&
            <CommentsMain
              fullData={taskData}
              setShowComments={setShowComments}
              valueForm={valueForm}
              setValueForm={setValueForm}
              urlPost={'/api/v1/actionplan/comment/'}
              urlGet={'/api/v1/actionplan/task/comments/'}
              formData={{
                content_type: "actionplan_app.taskprofile",
                object_id: params?.id,
                text_comment: valueForm,
                is_parent: true,
              }}
            />
          }
        </div>
      </div>

      <div className="flex flex-col gap-8 w-[50%] pt-10">
        <div className="flex items-center">
          <span className="basis-1/2 text-xs">پیشرفت زمانی</span>
          <ProcressTopProfile
            value={(valuePercentTime / maxValuePercentTime) * 100}
            valuePercent={valuePercentTime}
            valueType={"روز"}
            // minValue={0}
            maxValue={maxValuePercentTime}
          />
        </div>

        <div className="flex items-center ">
          <span className="basis-1/2 text-xs"> درصد پیشرفت </span>
          <ProcressTopProfile
            value={(valuePercentProgress / maxValuePercentprogress) * 100}
            // timeSubmit={"13:01:00"}
            // dateSubmit={"1402/01/01"}
            valuePercent={valuePercentProgress}
            valueType={"%"}
            // minValue={0}
            maxValue={maxValuePercentprogress}
          />
        </div>

        <div className="flex items-center">
          <span className="basis-1/2 text-xs">بودجه استفاده شده</span>
          <ProcressTopProfile
            value={(valuePercentCost / maxValuePercentCost) * 100}
            // timeSubmit={"13:01:00"}
            // dateSubmit={"1402/01/01"}
            valuePercent={valuePercentCost}
            valueType={"ریال"}
            // minValue={0}
            maxValue={maxValuePercentCost}
          />
        </div>
      </div>
      {showModalFrom &&
        <MainCreateFormReport valuePercent={valuePercent} setCostListObject={setCostListObject} costListObject={costListObject} reportId={reportId} setShowModalFrom={setShowModalFrom} />
      }
    </div>
  );
};

export default MainTopProfile;
