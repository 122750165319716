import React from "react";
import { useSearchParams } from "react-router-dom";

const TabChartStatic = ({ setActiveChart, showChartSHM }) => {
  // const [showLine, setShowLine] = useState(null)
  const [serachParams, setSearchParams] = useSearchParams();

  function handleChart(key, value) {
    setSearchParams((prevParams) => {
      if (value === null || value === "") {
        prevParams.delete(key);
      } else {
        prevParams.set(key, value);
      }
      return prevParams;
    });
  }

  const handleButton = (name) => {
    setActiveChart(name);
    !showChartSHM && handleChart("c", name);
  };

  let buttons = [
    {
      chart: 2,
      text: "مدیرعامل",
    },
    {
      chart: 3,
      text: "معاون مهندسی و توسعه (1)",
    },
    {
      chart: 4,
      text: "معاون بهره برداری (1)",
    },
    {
      chart: 5,
      text: "معاون خدمات مشترکین و درآمد (1)",
    },
    {
      chart: 6,
      text: "معاون برنامه ریزی و منابع انسانی (1)",
    },
    {
      chart: 7,
      text: "معاون مالی و پشتیبانی (1)",
    },
    {
      chart: 8,
      text: "مدیریت ناحیه یک (1)",
    },
    {
      chart: 9,
      text: "مدیریت ناحیه دو منطقه یک",
    },
    {
      chart: 10,
      text: "مدیریت ناحیه سه منطقه یک",
    },

  ]
  const totalButtons = buttons.length;


  let radius;
  if (window.innerWidth > 1024) {
    radius = 200
  } else {
    radius = 180
  }

  return (
    <div className=" flex items-center justify-center h-[85vh] bg-caspian-surface shadow-[0_5px_5px_#00000050] rounded-md ">

      <div className="flex items-center justify-center relative">
        <button
          className="absolute w-32 h-32 lg:w-40 lg:h-40 z-50 flex items-center justify-center text-xs 2xl:text-lg border-4 border-caspian-secondary-900 bg-caspian-secondary-700 text-caspian-surface rounded-full"
          onClick={() => handleButton("chart1")}
        // onMouseMove={() => setShowLine("chart1")}
        // onMouseLeave={() => setShowLine(null)}
        >
          مدیرعامل
        </button>

        <div className="absolute w-full flex items-center justify-center">

          {buttons?.map((item, index) => {
            const angle = (index * 360) / totalButtons;
            const x = radius * Math.cos((angle * Math.PI) / 180);
            const y = radius * Math.sin((angle * Math.PI) / 180);

            return (
              <button
                key={index}
                className={`absolute w-24 h-24 lg:w-28 lg:h-28 flex top-[${item?.chart}] items-center justify-center text-xs 2xl:text-sm px-2 rounded-full border-4 border-caspian-secondary-500 bg-caspian-secondary-300 text-caspian-surface`}
                style={{ transform: `translate(${x}px, ${y}px)` }}
                onClick={() => handleButton(`chart${item?.chart}`)}
              // onMouseMove={() => setShowLine(`chart${item?.chart}`)}
              // onMouseLeave={() => setShowLine(null)}
              >
                {item?.text}
                {/* {showLine === `chart${item.chart}` && (
                  <div
                    className="absolute -z-50 border-b-caspian-gray4 border-b-2"
                    style={{
                      width: "100px",
                      left: "-50%",
                      top: "50%",
                      transform: `translate(-50%, -50%) rotate(${angle}deg)`,
                    }}
                  ></div>
                )} */}
              </button>
            )
          })}
        </div>
      </div>
    </div>
  );
};

export default TabChartStatic;
