import React, { useState } from 'react';
import AuditPlannigRow from './AuditPlannigRow';
import StatusFilter from './StatusFilter';
import OrderList from './OrderList';

const AuditPlannigTable = ({ profile }) => {
    const [code, setCode] = useState('');
    const [type, setType] = useState('');
    const [question, setQuestion] = useState('');
    const [description, setDescription] = useState('');
    const [requirement, setRequirement] = useState('');
    const [request, setRequest] = useState('');

    console.log('profile',profile);
    return (
        <div className='flex flex-col gap-2'>
            {/* filter */}
            <div className='flex gap-2 justify-end'>
                <StatusFilter 
                lable={'وضعیت'}
                />
                <OrderList 
                lable={'مرتب‌سازی'}
                />
            </div>
            {/* table */}
            <div className='overflow-x-auto'>
                <table className='min-w-[992px] bg-caspian-surface w-full overflow-x-auto rounded-tr-xl rounded-tl-xl relative'>
                    <thead className='sticky top-0'>
                        <tr>
                            <th className='bg-caspian-primary-500 w-[20%] p-2 relative rounded-tr-xl'>
                                <input id='t1' type="text"
                                    className={`${code ? "outline-caspian-surface/50 border" : ""}peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50 cursor-pointer bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-14 border border-caspian-gray2`}
                                    onChange={(e) => { setCode(e.target.value) }}
                                />
                                <label htmlFor="t1"
                                    className={`${code ? 'absolute left-full right-3 font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 text-caspian-surface' : ''}absolute cursor-pointer font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 peer-focus:left-full text-caspian-surface whitespace-nowrap peer-focus:right-3`}>
                                    واحد
                                </label>
                            </th>
                            <th className='bg-caspian-primary-500 w-[5%] p-2 relative'>
                                <input id='t2' type="text"
                                    className={`${type ? "outline-caspian-surface/50 border" : ""}peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50 cursor-pointer bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-7 border border-caspian-gray2`}
                                    onChange={(e) => { setType(e.target.value) }}
                                />
                                <label htmlFor="t2"
                                    className={`${type ? 'absolute left-full right-3 font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 text-caspian-surface' : ''}absolute cursor-pointer font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 peer-focus:left-full text-caspian-surface whitespace-nowrap peer-focus:right-3`}>
                                    روز
                                </label>
                            </th>
                            <th className='bg-caspian-primary-500 w-[5%] p-2 relative'>
                                <input id='t3' type="text"
                                    className={`${question ? "outline-caspian-surface/50 border" : ""}peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50 cursor-pointer bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-10 border border-caspian-gray2`}
                                    onChange={(e) => { setQuestion(e.target.value) }}
                                />
                                <label htmlFor="t3"
                                    className={`${question ? 'absolute left-full right-3 font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 text-caspian-surface' : ''}absolute cursor-pointer font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 peer-focus:left-full text-caspian-surface whitespace-nowrap peer-focus:right-3`}>
                                    تاریخ
                                </label>
                            </th>
                            <th className='bg-caspian-primary-500 w-[10%] p-2 relative'>
                                <input id='t4' type="text"
                                    className={`${description ? "outline-caspian-surface/50 border" : ""}peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50 cursor-pointer bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-16 border border-caspian-gray2`}
                                    onChange={(e) => { setDescription(e.target.value) }}
                                />
                                <label htmlFor="t4"
                                    className={`${description ? 'absolute left-full right-3 font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 text-caspian-surface' : ''}absolute cursor-pointer font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 peer-focus:left-full text-caspian-surface whitespace-nowrap peer-focus:right-3`}>
                                    سیستم های مدیریت
                                </label>
                            </th>
                            <th className='bg-caspian-primary-500 w-[10%] p-2 relative'>
                                <input id='t5' type="text"
                                    className={`${requirement ? "outline-caspian-surface/50 border" : ""}peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50 cursor-pointer bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-20 border border-caspian-gray2`}
                                    onChange={(e) => { setRequirement(e.target.value) }}
                                />
                                <label htmlFor="t5"
                                    className={`${requirement ? 'absolute left-full right-3 font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 text-caspian-surface' : ''}absolute cursor-pointer font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 peer-focus:left-full text-caspian-surface whitespace-nowrap peer-focus:right-3`}>
                                    ساعت (برنامه ریزی)
                                </label>
                            </th>
                            <th className='bg-caspian-primary-500 w-[10%] p-2 relative'>
                                <input id='t6' type="text"
                                    className={`${request ? "outline-caspian-surface/50 border" : ""}peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50 cursor-pointer bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-16 border border-caspian-gray2`}
                                    onChange={(e) => { setRequest(e.target.value) }}
                                />
                                <label htmlFor="t6"
                                    className={`${request ? 'absolute left-full right-3 font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 text-caspian-surface' : ''}absolute cursor-pointer font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 peer-focus:left-full text-caspian-surface whitespace-nowrap peer-focus:right-3`}>
                                    ساعت واقعی
                                </label>
                            </th>
                            <th className='bg-caspian-primary-500 w-[10%] p-2 relative'>
                                <input id='t7' type="text"
                                    className={`${request ? "outline-caspian-surface/50 border" : ""}peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50 cursor-pointer bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-16 border border-caspian-gray2`}
                                    onChange={(e) => { setRequest(e.target.value) }}
                                />
                                <label htmlFor="t7"
                                    className={`${request ? 'absolute left-full right-3 font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 text-caspian-surface' : ''}absolute cursor-pointer font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 peer-focus:left-full text-caspian-surface whitespace-nowrap peer-focus:right-3`}>
                                    وضعیت
                                </label>
                            </th>
                            <th className='bg-caspian-primary-500 w-[5%] rounded-tl-xl p-2 relative'>
                                
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            profile?.map(p=><AuditPlannigRow key={p?.id} {...p} />)
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default AuditPlannigTable;
