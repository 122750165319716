import React, { useContext, useState } from "react";
import Modal from "../../../modal/Modal";
import { PiX } from "react-icons/pi";
import SingleInput from "./SingleInput";
import StartDateTimePicker from "./StartDateTimePicker";
import EndDateTimePicker from "./EndDateTimePicker";
import API from "../../../../api/API";
import { AuthContext } from "../../../../context/auth/AuthContext";
import LoadingPlanning from "./LoadingPlanning";
import { useNavigate } from "react-router-dom";

const MainPlanning = ({
  setShowPlanning,
  id,
  riskTeamSubjectType,
  riskTeamType,
}) => {
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [type, setType] = useState("operational");
  const [loading, setLoading] = useState(false);
  const planningHandler = async () => {
    if (!token) return;
    let formData = {
      startDate: startDate,
      endDate: endDate,
      // riskType: type,
      team_id: +id,
    };
    console.log(formData);
    setLoading(true);
    try {
      const res = await API.post(
        `/api/v1/risk/identification/creator`,
        formData,
        {
          headers: { Authorization: token },
        }
      );
      console.log(res?.data);
      statusUpdateHandler();
      setShowPlanning(false);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      navigate("/risk/activities");
    }
  };

  const statusUpdateHandler = async () => {
    if (!id) return;
    let formData = {
      // riskTeamSubjectType: riskTeamSubjectType,
      // riskTeamType: riskTeamType,
      statusType: "planned",
      is_planned: true,
    };

    try {
      const res = API.patch(`/api/v1/risk/team/detail/${id}/`, formData, {
        headers: { Authorization: token },
      });
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  return loading ? (
    <LoadingPlanning />
  ) : (
    <Modal>
      <div className="flex flex-col gap-0 min-w-[200px] z-50 bg-caspian-surface rounded-md shadow-[0_0_5px_#00000050] overflow-hidden">
        <div className="flex items-center justify-between w-full p-2 border-b border-b-caspian-gray2">
          <span className="text-xs text-caspian-gray font-bold">
            برنامه ریزی ریسک
          </span>
          <PiX
            onClick={() => setShowPlanning(false)}
            className="cursor-pointer"
          />
        </div>
        <div className="p-2 flex flex-col gap-3 w-full">
          <SingleInput lable="تاریخ شروع">
            <StartDateTimePicker setStartDate={setStartDate} />
          </SingleInput>
          <SingleInput lable="تاریخ پایان">
            <EndDateTimePicker setEndDate={setEndDate} />
          </SingleInput>
          {/* <SingleInput lable="سرفصل">
            <select
              onChange={(e) => setType(e.target.value)}
              className="text-xs outline outline-1 outline-caspian-gray1 p-1.5 rounded-md bg-caspian-transparent cursor-pointer w-full"
            >
              <option value={"operational"}>عملیاتی</option>
              <option value={"strategic"}>راهبردی</option>
            </select>
          </SingleInput> */}
          <button
            onClick={planningHandler}
            className="rounded-md bg-caspian-secondary-400 text-caspian-surface py-2 px-6 flex items-center gap-1 w-fit"
          >
            <span className="text-xs">ثبت</span>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default MainPlanning;
