import React, { useState } from 'react';
import { PiCircleNotch, PiPlusBold, PiTrash } from 'react-icons/pi';
import ListSection2 from './forms/ListSection2';
// import ListStandard from './forms/ListStandaard';
import ListPurpose from './forms/ListPurpose';
import API from '../../../api/API';
import ModalMs from './forms/ModalMs';
// import Accordion from './Accordion';
import Accordion2 from './Accordion2';

const AuditScopComponent = ({ showSection, setShowSection, sectionSelected, setSectionSelected, sectionFinalSelected,
    setSectionFinalSelected, showStandard, setShowStandard, standardSelected, setStandardSelected,
    standardFinalSelected, setStandardFinalSelected, purpose, setPurpose, purposeList, setPurposeList,
    purposeSelected, setPurposeSelected, scope, setScope, idProfile, setIdProfile, token, teamBtn, setTeamBtn,
    refresh, setRefresh, data
}) => {
    const [list, setList] = useState([]);
    const [isLoading, setIsLoadig] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [ms, setMs] = useState("");
    const [msSelected, setMsSelected] = useState(null);
    const [idPatch, setIdPatch] = useState(-1);
    const [primarySelected, setPrimarySelected] = useState([]);
    const [mainSelected, setMainSelected] = useState([]);
    const [secondarySelected, setSecondarySelected] = useState([]);

    const [systemMangamentSelected, setSystemMangamentSelected] = useState([
        // primarySelected,
        // mainSelected,
        // secondarySelected,
    ]);
    console.log(list);
    const handleSubmit = async () => {
        let formData = {
            scope,
            auditPurpose: purpose
        }
        console.log('formData', formData);
        setIsLoadig(true);
        try {
            const res = await API.patch(`/api/v1/audit/profile/detial/${idProfile}/`, formData, { headers: { Authorization: token } })
            if (res?.data) {
                handleSection();
                console.log(res?.data);
                // setIsLoadig(false);
                // setTeamBtn(true);
            }
        } catch (error) {
            console.log(error);
            setIsLoadig(false);
        }
    }
    const handleSection = async () => {
        let formData;
        for (let i = 0; i < sectionFinalSelected?.length; i++) {
            formData = {
                sectionRelated: sectionFinalSelected[i],
                auditProfileRelated: idProfile
            }
            console.log(formData);
            try {
                const res = await API.post('/api/v1/audit/profile/section/', formData, { headers: { Authorization: token } })
                console.log(res?.data);
                if (res?.data) {
                    setIsLoadig(false);
                    setTeamBtn(true);
                }
            } catch (error) {
                console.log(error);
            }
        }
    }
    return (
        <div className='flex flex-col gap-4'>
            {/* label */}
            <div className='flex gap-8 items-baseline '>
                <span className='whitespace-nowrap text-[#3C77A2] text-base xl:text-lg'>دامنه ممیزی</span>
                <div className='w-full border-t-2 border-caspian-gray2 h-1 relative'>
                    <span className='w-3 h-3 bg-caspian-gray1 rounded-full absolute right-0 -top-2'></span>
                </div>
            </div>
            {/* data entry */}
            <div className='flex flex-col gap-2'>
                <div className='flex gap-4'>
                    {/* ممیزی بر اساس */}
                    <div className='flex flex-col gap-2 basis-1/2'>
                        <ListPurpose
                            type={purpose}
                            setType={setPurpose}
                            typeList={purposeList}
                            typeSelected={purposeSelected}
                            setTypeSelected={setPurposeSelected}
                            lable='ممیزی براساس'
                            teamBtn={teamBtn}
                        />
                    </div>
                    {/* audit section */}
                    <div className='basis-1/2 flex flex-col gap-2'>
                        <span className='text-xs text-caspian-gray4'>واحد های مورد نظر</span>
                        <div
                            onClick={() => setShowSection(true)}
                            className={`${teamBtn ? 'pointer-events-none bg-[#FAFAFA]' : ''} flex justify-between items-center rounded-lg border border-[#3C77A2] cursor-pointer p-1`}>
                            <p className='text-[#3C77A2] flex justify-center items-center gap-1'>
                                <PiPlusBold />
                                <span className='text-xs 2xl:text-sm'>انتخاب </span>
                            </p>
                            <p>
                                {
                                    sectionFinalSelected?.length < 1 ? <span className='text-xs text-caspian-gray4'>موردی افزوده نشده</span>
                                        : <span className='text-xs text-caspian-gray'>{sectionFinalSelected?.length} مورد افزوده شد</span>
                                }
                            </p>
                        </div>
                        {showSection && (
                            <ListSection2
                                finalSelected={sectionFinalSelected}
                                setFinalSelected={setSectionFinalSelected}
                                selected={sectionSelected}
                                setSelected={setSectionSelected}
                                setShowSection={setShowSection}
                                showSection={showSection}
                                scope="لیست واحد ها"
                                url={`/api/v1/organization/structure/section/`}
                                idProfile={idProfile}
                            />
                        )}
                    </div>
                </div>
                {/* standard and description */}
                <div className='flex items-start gap-4'>
                    <div className='basis-1/2 flex flex-col gap-2'>
                        <p className={`${teamBtn ? 'pointer-events-none bg-[#FAFAFA]' : ''} text-[#3C77A2] flex justify-start items-center gap-2 cursor-pointer`}
                            onClick={() => setShowModal(true)}>
                            <PiPlusBold />
                            <span className=' text-xs 2xl:text-sm'>افزودن استاندارد </span>
                        </p>
                        <div className='h-24 overflow-y-auto px-2'>
                            {/* {
                                list?.map(i => <p
                                    key={i?.id}
                                    className='p-4 border-b border-caspian-gray2 text-xs xl:text-sm flex justify-between items-center
                                    text-caspian-secondary-700'>
                                    <span>{i?.symbol}</span>
                                    <span>{i?.standardNumber}</span>
                                </p>)
                            } */}
                            {
                                data?.auditProfileMSRelatedProfile?.length > 0
                                && data?.auditProfileMSRelatedProfile?.map(a => <Accordion2 key={a?.id}
                                    setRefresh={setRefresh}
                                    id={a?.id}
                                    setIdPatch={setIdPatch}
                                    idPatch={idPatch}
                                    ms={a?.msRelated?.title}
                                    setMs={setMs}
                                    idMs={a?.msRelated?.id}
                                    standardsRealated={a?.standardsRealated}
                                    setShowModal={setShowModal}
                                    msRelated={a?.msRelated}
                                    setMsSelected={setMsSelected}
                                    setSystemMangamentSelected={setSystemMangamentSelected}
                                />)
                            }
                        </div>
                        {showModal && (
                            <ModalMs
                                primarySelected={primarySelected}
                                setPrimarySelected={setPrimarySelected}
                                secondarySelected={secondarySelected}
                                setSecondarySelected={setSecondarySelected}
                                mainSelected={mainSelected}
                                setMainSelected={setMainSelected}
                                systemMangamentSelected={systemMangamentSelected}
                                setSystemMangamentSelected={setSystemMangamentSelected}
                                setMs={setMs}
                                ms={ms}
                                setShowModal={setShowModal}
                                idProfile={idProfile}
                                list={list}
                                setList={setList}
                                setRefresh={setRefresh}
                                msSelected={msSelected}
                                setMsSelected={setMsSelected}
                                idPatch={idPatch}
                                setIdPatch={setIdPatch}
                            />
                        )}
                    </div>
                    <div className='basis-1/2 flex flex-col gap-2'>
                        <span className='text-xs text-caspian-gray4'>توضیحات</span>
                        <textarea
                            disabled={teamBtn}
                            onChange={(e) => setScope(e.target.value)}
                            className='border border-caspian-gray2 rounded-lg p-2 xl:px-4 text-xs placeholder:text-xs
                            placeholder:text-caspian-gray resize-none'
                            placeholder='توضیحات مورد نظر را یادداشت کنید '
                            rows={5}
                            cols={5}
                        ></textarea>
                    </div>
                </div>
            </div>
            {
                !teamBtn && <button
                    onClick={handleSubmit}
                    className='bg-[#3C77A2] text-xs xl:text-sm flex justify-center items-center gap-2 text-caspian-surface rounded-lg py-2 px-4 w-fit'>
                    <span>انتخاب تیم</span>
                    <span className='animate-spin'>{isLoading && <PiCircleNotch />}</span>
                </button>
            }
        </div>
    );
}

export default AuditScopComponent;
