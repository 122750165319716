import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import ToolTip from './ToolTip';

const ButtonNavNew = ({ icon, url, active, buttonActvie, onClick, title, bg, setActiveTab }) => {
    const [show, setShow] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    function handleSearch(key, value) {
        setSearchParams((prevParams) => {
            if (value === null || value === "") {
                prevParams.delete(key);
            } else {
                prevParams.set(key, value);
            }
            return prevParams;
        });
    }

    return (
        <div className='relative'>
            {/* {showView && <p>hi</p>} */}
            {
                active === 'view' ? (<>
                    <button
                        onMouseEnter={() => setShow(true)}
                        onMouseLeave={() => setShow(false)}
                        onClick={() => {
                            setActiveTab("view");
                            handleSearch('view', 'list');
                        }}
                        className={`${active === buttonActvie ? 'text-caspian-primary-500' : ""
                            } flex items-center justify-center w-10 h-10 rounded-full`}
                    >
                        <div className={`${active === buttonActvie ? 'text-[#3C77A2] border-2 border-[#3C77A2] rounded-full' : "bg-caspian-surface"
                            } text-2xl rounded-full p-1.5`}
                            style={{ backgroundColor: buttonActvie === active ? bg : '' }}
                        >{icon}</div>
                    </button>
                    {
                        show && <ToolTip title={title} />
                    }
                </>
                ) : (
                    <>
                        <Link
                            onMouseEnter={() => setShow(true)}
                            onMouseLeave={() => setShow(false)}
                            onClick={() => {
                                onClick();
                                handleSearch('view', '');
                            }}
                            to={url}
                            className={`${active === buttonActvie ? 'text-caspian-primary-500' : ""
                                } flex items-center justify-center w-10 h-10 rounded-full`}
                        >
                            <div className={`${active === buttonActvie ? 'text-[#3C77A2] border-2 border-[#3C77A2] rounded-full' : "bg-caspian-surface"
                                } text-2xl rounded-full p-1.5`}
                                style={{ backgroundColor: buttonActvie === active ? bg : '' }}
                            >{icon}</div>
                        </Link>
                        {
                            show && <ToolTip title={title} />
                        }
                    </>
                )

            }
        </div>
    );
}

export default ButtonNavNew;
