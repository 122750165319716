import React from 'react'
import API from '../../../../../../api/API'

const HistorySingle = ({ fullData }) => {
    return (
        <div className='border-b-2 border-caspian-gray3 last:border-none '>
            <div className='flex items-center py-3 w-[70%] cursor-pointer'>
                <div className='flex items-center gap-2 basis-1/3'>
                    <img src={fullData?.modifier?.personalInfoProfileRelated[0]?.avatar
                        ? API.defaults.baseURL + fullData?.modifier?.personalInfoProfileRelated[0]?.avatar
                        : "/assets/images/users/not-picture.svg"}
                        alt='' className='w-8 h-8'
                    />
                    <div className='flex items-end gap-2'>
                        <span className='text-sm '>{fullData?.modifier?.firstName} {fullData?.modifier?.lastName} </span>
                    </div>
                </div>

                <div className='flex items-center gap-2 basis-1/3'>
                    <span className='text-caspian-gray text-sm'>
                        {fullData?.text?.split(" ")?.slice(0, 5)?.join(" ")}
                    </span>
                </div>

                <div className='flex gap-2 basis-1/3'>
                    <span className='text-caspian-gray text-sm'>{new Date(fullData?.created).toLocaleTimeString('fa-IR')}</span>
                    <span className='text-caspian-gray text-sm'>{new Date(fullData?.created).toLocaleDateString('fa-IR')}</span>
                </div>
            </div>
        </div>
    )
}

export default HistorySingle