import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppContext } from "../../../../context/app-context/AppContext";
import { PiCheckCircleFill, PiTrash, PiXCircleFill } from "react-icons/pi";
import API from "../../../../api/API";
import { useContext, useState } from "react";
import { AuthContext } from "../../../../context/auth/AuthContext";

const SingleTableListRisks = ({
  id,
  title,
  teamType,
  date,
  endDate,
  statusRisk,
  teamName,
  setRefreshRisksList,
  riskCount,
}) => {
  const { token } = useContext(AuthContext);

  const [serachParams] = useSearchParams();
  const navigate = useNavigate();
  const { setBradCrump3 } = useAppContext();
  const [showIsDelete, setShowIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const removeHandler = async () => {
    API.delete(`/api/v1/risk/identification/detail/${id}/`, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        setRefreshRisksList(1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <tr
      onClick={() => navigate(`/risk/risks/${id}`)}
      className="bg-caspian-surface duration-300"
    >
      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2 w-[20%] cursor-pointer">
        <h2>{title}</h2>
      </td>
      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2 w-[20%] cursor-pointer">
        <h2>{riskCount}</h2>
      </td>
      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2 w-[20%] cursor-pointer">
        <h2>
          {teamType === "strategic" ? "گروه اهداف کلان" : "گروه فرایندها"}
        </h2>
      </td>

      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2 w-[20%] cursor-pointer">
        <h2>{new Date(date).toLocaleDateString("fa-IR")}</h2>
      </td>

      <td className="border-b border-b-caspian-gray2 px-3 py-2 w-[20%] cursor-pointer text-center">
        <h2 className="text-[#258E0B] w-fit rounded-md px-2 py-0.5 text-xs text-center w-full">
          {statusRisk}
        </h2>
      </td>
    </tr>
  );
};

export default SingleTableListRisks;
