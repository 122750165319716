import React, { useEffect, useState } from 'react';
import Modal from '../../../../modal/Modal';
import { PiX } from "react-icons/pi";
import StackedBarChart from './StackedBarChartD3';
import StackedBarChart2 from './StackedBarChart2';

const Test = ({ myData, setShow }) => {
    const [standard,setStandard]=useState(myData);
    // const [n, setN] = useState('')
    // useEffect(() => {
    //     if (myData) {
    //         setN(myData)
    //     }
    // }, [myData, setN])
    // console.log(n);
    // const [data, setData] = useState([{
    //     standard:'26001',
    //     clause:[{name:'1',nc:0,obs:0},{name:'2',nc:0,obs:0},{name:'3',nc:4,obs:5},{name:'4',nc:5,obs:10},
    //         {name:'5',nc:0,obs:0}],
    // }]);
    const [data, setData] = useState([{
        standard: '26001',
        clause: [{ name: '1' }, { name: '2' }, { name: '3' }, { name: '4' },
        { name: '5' }],
        nc: [{ name: '1', value: 2 }, { name: '3', value: 5 }, { name: '4', value: 4 },],
        obs: [{ name: '1', value: 5 }, { name: '2', value: 3 }, { name: '3', value: 1 }, { name: '4', value: 1 },]
    }, {
        standard: '9001',
        clause: [{ name: '5' }, { name: '6' }, { name: '7' }],
        nc: [{ name: '5', value: 1 }, { name: '6', value: 5 }, { name: '7', value: 4 },],
        obs: [{ name: '5', value: 2 }, { name: '6', value: 1 }]
    }, {
        standard: '45001',
        clause: [{ name: '7' }, { name: '8' }, { name: '9' }],
        nc: [{ name: '7', value: 3 }, { name: '9', value: 2 }],
        obs: [{ name: '7', value: 2 }, { name: '9', value: 2 }]
    }]);
    const [filterData, setFilterData] = useState(f=>data?.filter(d=>d?.standard===myData));
    console.log(filterData);
    const [filter, setFilter] = useState(["nc", "obs"]); // نمایش هر دو به صورت پیش‌فرض
    //     useEffect(()=>{
    //         if(!myData){
    //             console.log('no data');
    //             return};
    //         console.log('n',n);
    //         let l=data?.filter(d=>d.standard===n);
    //         setFilterData(l)
    //         console.log('filterData',filterData);
    // },[myData,setFilterData])
    const handleFilterChange = (event) => {
        const value = event.target.value;
        if (value === "both") {
            setFilter(["nc", "obs"]); // نمایش همه
            // setFilterData(data);
        } else {
            setFilter([value]); // نمایش فقط nc یا obs
            // setFilterData(s=>data?.find(d=>d?.clause[value]===value));
        }
    };
    // console.log("filter data",filterData);

    const [type,setType]=useState('');
    const [mainClause,setMainClause]=useState('');
    const [showBar,setShowBar]=useState(false);


    return (
        <Modal>
            <div className='bg-caspian-surface w-[70%] p-3 rounded-md'>
                <PiX onClick={() => setShow(false)} className='mr-auto cursor-pointer' />
                <h1 className='text-caspian-gray4 text-sm mb-2 text-center'>نمایش یافته‌ها براساس بندهای اصلی استادارد {myData}</h1>
                <div className='flex justify-between'>
                    <select name="" id="" className='w-[200px] border rounded text-caspian-gray4 border-caspian-gray1' onChange={handleFilterChange}>
                        <option value='both'>همه</option>
                        <option value='nc'>nc</option>
                        <option value='obs'>obs</option>
                    </select>
                    {/* legend */}
                    {/* <div className='flex gap-2'>
                        <div className='flex gap-2 flex-row-reverse'>
                            <div className='w-4 h-4 rounded-full bg-[#6b6ecf]'></div>
                            <span>nc</span>
                        </div>
                        <div className='flex gap-2 flex-row-reverse'>
                            <div className='w-4 h-4 rounded-full bg-[#b5a6d6]'></div>
                            <span>obs</span>
                        </div>
                    </div> */}
                </div>

                <div className='ltr overflow-y-auto'>
                    {/* <StackedBarChart data={myData} filter={filter} /> */}
                    <StackedBarChart2 data={filterData} filter={filter} 
                    setShowBar={setShowBar} setType={setType} setMainClause={setMainClause}
                    />
                </div>
                {showBar && <p>{type} {mainClause} {standard}</p>}
            </div>
        </Modal>
    );
}

export default Test;
