import React, { useState } from 'react';
import ObservationTable from './ObservationTable';

const MainObservationListTab = ({ statusFilter, search }) => {
    const [data, setData] = useState([
        {
            id: 1,
            standard: 'ISO 45001:2018',
            clause: '6-1',
            question: 'موضوع:سازمان چگونه دامنه و زمینه سیستم مدیریت کیفیت خود را تعیین و مستند می  کند تا اطمینان حاصل کند.',
            criteria: 'شناسایی عوامل داخلی و خارجی ، درنظر گرفتن نیاز های ذینفعان و ارزیابی منظم برای بهبود سیستم ایمنی و بهداشت شغلی است.',
            status: 'OK',
            score: '6.7'
        },
        {
            id: 2,
            standard: 'ISO 9001:2018',
            clause: '5-1',
            question: 'سازمان شما عوامل داخلی و خارجی موثر بر سیستم مدیریت ایمنی و بهداشت شغلی را شناسایی و ارزیابی میکند ؟',
            criteria: 'شناسایی عوامل داخلی و خارجی ، درنظر گرفتن نیاز های ذینفعان و ارزیابی منظم برای بهبود سیستم ایمنی و بهداشت شغلی است.',
            status: 'OBS',
            score: '7.2'
        },
        {
            id: 3,
            standard: 'ISO 9001:2018',
            clause: '5-1-2',
            question: 'سازمان شما عوامل داخلی و خارجی موثر بر سیستم مدیریت ایمنی و بهداشت شغلی را شناسایی و ارزیابی میکند ؟',
            criteria: 'شناسایی عوامل داخلی و خارجی ، درنظر گرفتن نیاز های ذینفعان و ارزیابی منظم برای بهبود سیستم ایمنی و بهداشت شغلی است.',
            status: 'NC',
            score: '5.5'
        },
        {
            id: 4,
            standard: 'ISO 9001:2018',
            clause: '5-1-2',
            question: 'سازمان شما عوامل داخلی و خارجی موثر بر سیستم مدیریت ایمنی و بهداشت شغلی را شناسایی و ارزیابی میکند ؟',
            criteria: 'شناسایی عوامل داخلی و خارجی ، درنظر گرفتن نیاز های ذینفعان و ارزیابی منظم برای بهبود سیستم ایمنی و بهداشت شغلی است.',
            status: 'N/A',
            score: ''
        },
        {
            id: 5,
            standard: 'ISO 9001:2018',
            clause: '5-1-2',
            question: 'لورم ایپسوم یک متن ساختگی',
            criteria: 'تست یک',
            status: 'OBS',
            score: '10'
        },
        {
            id: 6,
            standard: 'ISO 9001:2018',
            clause: '5-1-2',
            question: 'لورم ایپسوم یک متن ساختگی',
            criteria: 'تست یک',
            status: 'NC',
            score: '10'
        },
        {
            id: 7,
            standard: 'ISO 9001:2018',
            clause: '5-1-2',
            question: 'لورم ایپسوم یک متن ساختگی',
            criteria: 'تست یک',
            status: 'OK',
            score: '10'
        },
    ])
    return (
        <div className='w-full 2xl:w-[95%] h-full flex flex-col gap-8 justify-between'>
            {/* table */}
            <ObservationTable
                data={data}
                statusFilter={statusFilter}
                search={search}
                />
            {/* // self-end mt-auto */}
            {/* <div className='bg-caspian-surface fixed bottom-0 w-[70%] p-4 shadow-[0px_-4px_12px_0px_#00000040] flex gap-2'>
                <input type="checkbox" id="check" className='cursor-pointer peer' />
                <label htmlFor="check" className='peer-checked:text-[#000] text-xs text-caspian-gray cursor-pointer'>
                    در صورت اطمینان از صحت همه مشاهدات آن را تایید کنید
                </label>
            </div> */}
        </div>
    );
}

export default MainObservationListTab;
