import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ToolTip from "./ToolTip";

const ButtonSection = ({
  id,
  icon,
  sectionId,
  title,
  alignToolTip,
  typeAudit,
  disabled,
}) => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  return (
    <button
      disabled={disabled}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      onClick={() => navigate(`/audit/${id}/${typeAudit}/${sectionId}`)}
      className="text-xl relative bg-caspian-surface border border-caspian-transparent hover:border-caspian-gray duration-300 rounded-md text-caspian-gray4 p-0.5 shadow-[0_0_5px_#00000050] disabled:bg-caspian-gray2 disabled:border-caspian-transparent disabled:text-caspian-surface disabled:shadow-none"
    >
      {icon}
      {show && <ToolTip alignToolTip={alignToolTip} title={title} />}
    </button>
  );
};

export default ButtonSection;
