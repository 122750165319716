// import React, { useRef, useEffect, useState } from 'react';
// import * as d3 from 'd3';


// const RadarChart = () => {
//     const svgRef = useRef();
//     const [data,setData] = useState([
//         { name: 'A', value: 5 },
//         { name: 'B', value: 4 },
//         { name: 'C', value: 3 },
//         { name: 'D', value: 2 },
//         { name: 'E', value: 4 }
//     ]);


//     useEffect(() => {
//         const width = 300;
//         const height = 300;
//         const margin = 20;
//         const radius = Math.min(width, height) / 2 - margin;
//         const numAxes = data.length;
//         const angleSlice = Math.PI * 2 / numAxes;


//         const scale = d3.scaleLinear().domain([0, d3.max(data, d => d.value)]).range([0, radius]);


//         const svg = d3.select(svgRef.current)
//             .attr('width', width)
//             .attr('height', height)
//             .append('g')
//             .attr('transform', `translate(${width / 2}, ${height / 2})`);


//         // دایره‌های مرزی
//         const axisGrid = svg.append('g').attr('class', 'axisGrid');
//         axisGrid.selectAll('.gridCircle')
//             .data(d3.range(1, 6))
//             .enter().append('circle')
//             .attr('class', 'gridCircle')
//             .attr('r', d => scale(d))
//             .style('fill', 'none')
//             .style('stroke', '#ccc')
//             .style('stroke-width', 0.5);


//         // رسم محورهای اصلی
//         const axes = svg.append('g').attr('class', 'axes');
//         axes.selectAll('.axis')
//             .data(data)
//             .enter().append('line')
//             .attr('class', 'axis')
//             .attr('x1', 0)
//             .attr('y1', 0)
//             .attr('x2', (d, i) => scale(d.value) * Math.cos(i * angleSlice - Math.PI / 2))
//             .attr('y2', (d, i) => scale(d.value) * Math.sin(i * angleSlice - Math.PI / 2))
//             .style('stroke', 'black')
//             .style('stroke-width', 2);


//         // رسم داده‌ها
//         const dataPoints = svg.append('g').attr('class', 'dataPoints');
//         dataPoints.selectAll('.dataPoint')
//             .data(data)
//             .enter().append('circle')
//             .attr('class', 'dataPoint')
//             .attr('cx', (d, i) => scale(d.value) * Math.cos(i * angleSlice - Math.PI / 2))
//             .attr('cy', (d, i) => scale(d.value) * Math.sin(i * angleSlice - Math.PI / 2))
//             .attr('r', 5)
//             .style('fill', 'red');


//         // اضافه کردن نام‌ها به هر محور
//         const labels = svg.append('g').attr('class', 'labels');
//         labels.selectAll('.label')
//             .data(data)
//             .enter().append('text')
//             .attr('class', 'label')
//             .attr('x', (d, i) => (scale(d.value) + 10) * Math.cos(i * angleSlice - Math.PI / 2))
//             .attr('y', (d, i) => (scale(d.value) + 10) * Math.sin(i * angleSlice - Math.PI / 2))
//             .text(d => d.name)
//             .style('text-anchor', 'middle');
//     }, [data]);


//     return (
//         <svg ref={svgRef}></svg>
//     );
// };


// export default RadarChart;



// RadarChart.js
import React, { useRef, useEffect } from "react";
import * as d3 from "d3";


const RadarChart = ({ data, width, height }) => {
    const svgRef = useRef();


    useEffect(() => {
        const svg = d3.select(svgRef.current);
        svg.selectAll("*").remove(); // پاکسازی SVG


        const radius = Math.min(width, height) / 2 - 50;
        const angleSlice = (Math.PI * 2) / data[0].length;
        const maxValue = 1; // مقدار حداکثر برای نرمال‌سازی


        // تنظیم SVG
        svg.attr("width", width).attr("height", height);


        // گروه مرکزی
        const g = svg
            .append("g")
            .attr("transform", `translate(${width / 2}, ${height / 2})`);


        // رسم سطوح شبکه‌ای و اضافه کردن شماره‌های مقیاس
        const levels = 5;
        for (let i = 0; i < levels; i++) {
            const levelFactor = radius * ((i + 1) / levels);
            const percentValue = ((i + 1) * 100) / levels; // درصد محاسبه می‌شود


            g.selectAll(".levels")
                .data(data[0])
                .enter()
                .append("line")
                .attr("x1", (d, j) => levelFactor * Math.cos(angleSlice * j - Math.PI / 2))
                .attr("y1", (d, j) => levelFactor * Math.sin(angleSlice * j - Math.PI / 2))
                .attr("x2", (d, j) => levelFactor * Math.cos(angleSlice * (j + 1) - Math.PI / 2))
                .attr("y2", (d, j) => levelFactor * Math.sin(angleSlice * (j + 1) - Math.PI / 2))
                .attr("class", "grid")
                .style("stroke", "#aaa")
                .style("stroke-opacity", 0.75);


            // اضافه کردن درصد در هر سطح
            g.append("text")
                .attr("x", levelFactor * Math.cos(-Math.PI / 2)) // تنظیم مکان متن روی محور y
                .attr("y", levelFactor * Math.sin(-Math.PI / 2))
                .attr("dy", "0.35em")
                .style("font-size", "10px")
                .style("fill", "#555")
                .style("text-anchor", "middle")
                .text(`${percentValue}%`);
        }


        // رسم محورها
        g.selectAll(".axis")
            .data(data[0])
            .enter()
            .append("line")
            .attr("x1", 0)
            .attr("y1", 0)
            .attr("x2", (d, i) => radius * Math.cos(angleSlice * i - Math.PI / 2))
            .attr("y2", (d, i) => radius * Math.sin(angleSlice * i - Math.PI / 2))
            .attr("class", "axis")
            .style("stroke", "#999");


        // رسم ناحیه داده‌ها
        data.forEach((d, i) => {
            const radarLine = d3
                .lineRadial()
                .radius(d => d.value * radius)
                .angle((_, i) => i * angleSlice);


            g.append("path")
                .datum(d)
                .attr("d", radarLine)
                .attr("class", "radar-area")
                .style("stroke", i === 0 ? "blue" : "red")
                .style("fill", i === 0 ? "blue" : "red")
                .style("fill-opacity", 0.3);
        });
    }, [data, width, height]);


    return <svg ref={svgRef}></svg>;
};


export default RadarChart;

