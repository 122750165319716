import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppContext } from "../../../../context/app-context/AppContext";
import { PiCheckCircleFill, PiTrash, PiXCircleFill } from "react-icons/pi";
import API from "../../../../api/API";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../context/auth/AuthContext";
import MainProfile from "../profile/MainProfile";
import MainPlanning from "../planning/MainPlanning";

const SingleTableListTeams = ({
  id,
  title,
  leader,
  coordinator,
  riskTeamSubjectType,
  setRefreshTeamsList,
  statusType,
  startDate,
  endDate,
}) => {
  const { token } = useContext(AuthContext);
  const [showPlanning, setShowPlanning] = useState(false);
  const [serachParams] = useSearchParams();
  const navigate = useNavigate();
  const { setBradCrump3 } = useAppContext();
  // const [showAction, setShowAction] = useState(true);
  const [showProfile, setShowProfile] = useState(false);
  const [showIsDelete, setShowIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const removeHandler = async () => {
    API.delete(`/api/v1/risk/team/detail/${id}/`, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        setRefreshTeamsList(1);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const viewTeam = async () => {
    setShowProfile(true);
  };

  // const mouseEnter = () => {
  //   setShowAction(true);
  // };
  // const mouseLeave = () => {
  //   setShowAction(false);
  //   setDeleteId(null);
  //   setShowIsDelete(false);
  // };

  // useEffect(() => {
  //   if (showProfile) setShowAction(false);
  // }, [showProfile]);
  return (
    <tr
      // onMouseEnter={mouseEnter}
      // onMouseLeave={mouseLeave}
      className="bg-caspian-surface duration-300"
    >
      <td
        onClick={viewTeam}
        className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2 w-[15%] cursor-pointer"
      >
        <h2 className="text-xs">{title}</h2>
      </td>
      <td
        onClick={viewTeam}
        className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2 w-[15%] cursor-pointer"
      >
        <h2 className="text-xs">
          {riskTeamSubjectType === "strategic" ? "راهبردی" : "عملیاتی"}
        </h2>
      </td>
      <td
        onClick={viewTeam}
        className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2 w-[12.5%] cursor-pointer"
      >
        <h2 className="text-xs">{leader}</h2>
      </td>
      <td
        onClick={viewTeam}
        className="whitespace-nowrap text-center border-b border-b-caspian-gray2 w-[12.5%] cursor-pointer"
      >
        <h2 className="text-xs">{coordinator}</h2>
      </td>
      <td
        onClick={viewTeam}
        className="whitespace-nowrap text-center border-b border-b-caspian-gray2 w-[10%] cursor-pointer"
      >
        <h2 className="text-xs">{startDate}</h2>
      </td>
      <td
        onClick={viewTeam}
        className="whitespace-nowrap text-center border-b border-b-caspian-gray2 w-[10%] cursor-pointer"
      >
        <h2 className="text-xs">{endDate}</h2>
      </td>
      <td
        onClick={viewTeam}
        className="whitespace-nowrap text-center border-b border-b-caspian-gray2 w-[10%] cursor-pointer"
      >
        <div className="">
          <span
            className={`${
              statusType === "selectSubject"
                ? "text-caspian-secondary-500"
                : statusType === "ready"
                ? "text-caspian-primary-500"
                : statusType === "selectMember" && "text-caspian-secondary-500"
            }  py-0.5 px-1 rounded-md text-xs`}
          >
            {statusType === "selectSubject"
              ? "انتخاب سرفصل"
              : statusType === "ready"
              ? "در انتظار برنامه ریزی"
              : statusType === "planned"
              ? "در انتظار شروع"
              : statusType === "started"
              ? "در حال اجرا"
              : statusType === "done"
              ? "انجام شده"
              : statusType === "completefinished"
              ? "تکمیل شده"
              : statusType === "incompletefinished"
              ? "ناقص به پایان رسیده"
              : statusType === "selectMember" && "انتخاب اعضا"}
          </span>
        </div>
      </td>
      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 w-[10%]">
        {statusType === "ready" ? (
          <button
            onClick={() => setShowPlanning(true)}
            className="text-xs bg-caspian-secondary-500 rounded-md p-1 text-caspian-surface"
          >
            زمان بندی
          </button>
        ) : (
          <span
            onClick={viewTeam}
            className="text-xs text-caspian-secondary-500 cursor-pointer"
          >
            مشاهده
          </span>
        )}
      </td>
      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 w-[5%]">
        {
          <div className="flex items-center gap-1">
            {/* حذف تیم موقت غیر فعال است */}
            {/* <div className="relative flex items-center justify-center">
              <button
                onClick={async () => {
                  await setDeleteId(id);
                  await setShowIsDelete(true);
                }}
                className="text-caspian-error2 text-lg"
              >
                <PiTrash />
              </button>
              <div
                className={
                  id === deleteId && showIsDelete
                    ? "absolute left-0 top-0 bottom-0 flex items-center gap-0.5 w-fit bg-caspian-surface shadow-[0_0_5px_#00000050] p-0.5 rounded-md duration-500"
                    : "hidden duration-500"
                }
              >
                <PiCheckCircleFill
                  onClick={() => {
                    removeHandler(id);
                    setShowIsDelete(false);
                  }}
                  className="text-caspian-primary-600 text-lg cursor-pointer"
                />
                <PiXCircleFill
                  onClick={() => {
                    setShowIsDelete(false);
                    setDeleteId(null);
                  }}
                  className="text-caspian-gray text-lg cursor-pointer"
                />
              </div>
            </div> */}
            {showPlanning && statusType === "ready" && (
              <MainPlanning id={id} setShowPlanning={setShowPlanning} />
            )}
            {showProfile && (
              <MainProfile
                showProfile={showProfile}
                profileId={id}
                profileTitle={title}
                setShowProfile={setShowProfile}
                setRefreshTeamsList={setRefreshTeamsList}
              />
            )}
          </div>
        }
      </td>
    </tr>
  );
};

export default SingleTableListTeams;
