import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../context/auth/AuthContext";
import API from "../../../../api/API";
import { useOutletContext } from "react-router-dom";
import LoadingData from "../../LoadingData";
import NotfoundData from "../../NotfoundData";
import OutRisks from "./form/create/OutRisks";
import DoneRisks from "./form/create/DoneRisks";
import MainCreateForm from "./form/create/MainCreateForm";

const MainRisksProfile = () => {
  const { token } = useContext(AuthContext);
  const [fullData, setfullData] = useState([-1]);
  const [risksId] = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [refreshRiskRisks, setRefreshRiskRisks] = useState(0);
  useEffect(() => {
    setRefreshRiskRisks(1);
  }, []);
  useEffect(() => {
    if (!token || !risksId || refreshRiskRisks === 0) return;
    setLoading(true);
    const getIdentification = async () => {
      try {
        const res = await API.get(`/api/v1/risk/profile/${risksId}/`, {
          headers: { Authorization: token },
        });
        setfullData(res.data);
        setRefreshRiskRisks(0);
        console.log(res.data);
      } catch (error) {
        console.log(error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    getIdentification();
  }, [token, risksId, refreshRiskRisks]);
  console.log(fullData);
  return (
    <div className="flex flex-col gap-0 pb-4">
      {loading ? (
        <LoadingData />
      ) : error ? (
        <NotfoundData />
      ) : (
        // ) : fullData?.statusType === "outdate" ? (
        //   <OutRisks
        //     thisTitleProcess={fullData?.groupProcessSelected?.title}
        //     thisTitleGoal={fullData?.goalObjectiveSelected?.title}
        //   />
        // ) : fullData?.statusType === "done" ? (
        <DoneRisks
          title={fullData?.title}
          thisTitleProcess={fullData?.groupProcessSelected?.title}
          thisTitleGoal={fullData?.goalObjectiveSelected?.title}
          riskForm={fullData?.RiskIdentificationRelated}
        />
        // ) : (
        //   <MainCreateForm
        //     isDoing={fullData?.statusType === "doing"}
        //     setRefreshRiskRisks={setRefreshRiskRisks}
        //     fullData={fullData}
        //     thisProfileId={risksId}
        //     thisIdProcess={fullData?.groupProcessSelected?.id}
        //     thisTitleProcess={fullData?.groupProcessSelected?.title}
        //     thisIdGoal={fullData?.goalObjectiveSelected?.id}
        //     thisTitleGoal={fullData?.goalObjectiveSelected?.title}
        //     riskForm={fullData?.RiskIdentificationRelated}
        //   />
      )}
    </div>
  );
};

export default MainRisksProfile;
