import React, { useContext, useState } from 'react';
import TabBtn from './tab-button/TabBtn';
import { PiCircleNotch, PiClipboardText, PiNoteDuotone } from 'react-icons/pi';
import MainInfoTab from './audit-info-tab/MainInfoTab';
import MainObservationListTab from './observation-list-tab/MainObservationListTab';
import StatusFilter from './observation-list-tab/StatusFilter';
import InfoAuditPlan from './observation-list-tab/InfoAuditPlan';
import SearchList from './observation-list-tab/SearchList';
import API from '../../../../api/API';
import { AuthContext } from '../../../../context/auth/AuthContext';

const MainObservationDetailsTabs = ({ show, setShow }) => {
    const { token } = useContext(AuthContext);
    const [activeBtn, setActiveBtn] = useState('info');
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [statusSelected, setStatusSelected] = useState({});
    const [statusShow, setStatusShow] = useState(false);
    const [statusList, setStatusList] = useState([
        {
            id: 1,
            title: 'همه',
            value: 'all'
        },
        {
            id: 2,
            title: 'ok',
            value: 'ok'
        },
        {
            id: 3,
            title: 'obs',
            value: 'obs'
        },
        {
            id: 4,
            title: 'nc',
            value: 'nc'
        },
        {
            id: 5,
            title: 'n/a',
            value: 'n/a'
        },
    ]);
    const [isAgree, setIsAgree] = useState(false);
    const handleSubmit = async () => {
        setShow('found');
        // const formData={
        // status
        // }
        // console.log(formData);
        // setIsLoading(true);
        // try {
        //     const res=await API.patch('link',formData,{headers:{Authorization:token}})
        //     if(res?.data){
        //         console.log(res?.data);
        //         setIsLoading(false);
        //     }
        // } catch (error) {
        //     console.log(error);
        // }
        // finally{
        //     setIsLoading(false);
        // }
    }
    return (
        //min-h-screen h-full
        <div className='flex flex-col gap-4 justify-between min-h-screen h-full'>
            <div className='flex flex-col gap-4'>
                <div className='flex flex-col w-full 2xl:w-[95%] gap-2'>
                    <div className='flex gap-0'>
                        <TabBtn
                            activeBtn={activeBtn}
                            setActiveBtn={setActiveBtn}
                            btn={'info'}
                            icon={<PiClipboardText className='text-lg lg:text-2xl' />}
                            text={'اطلاعات ممیزی'}
                            setSearch={setSearch}
                        />
                        <TabBtn
                            activeBtn={activeBtn}
                            setActiveBtn={setActiveBtn}
                            btn={'list'}
                            icon={<PiNoteDuotone className='text-lg lg:text-2xl' />} //size={24}
                            text={'مشاهدات ممیزی'}
                            setSearch={setSearch}
                        />
                    </div>
                    {
                        activeBtn === 'list' && <div className='flex justify-between items-end'>
                            <div className='flex gap-1'>
                                {/* search */}
                                <div className='relative 2xl:w-[250px] self-end'>
                                    <SearchList search={search} setSearch={setSearch}
                                        setCurrenPage={setCurrentPage}
                                    // setOffset={setOffset}
                                    />
                                </div>
                                {/* status filter */}
                                <div className='w-[150px] self-start'>
                                    <StatusFilter
                                        status={statusFilter}
                                        setstatus={setStatusFilter}
                                        statusList={statusList}
                                        statusSelected={statusSelected}
                                        setstatusSelected={setStatusSelected}
                                        lable={'مرتب‌سازی'}
                                    />
                                </div>
                            </div>

                            {/* info */}
                            <InfoAuditPlan />
                        </div>
                    }
                </div>
                <div>
                    {
                        activeBtn === 'info' ? <MainInfoTab /> :
                            <MainObservationListTab
                                statusFilter={statusFilter}
                                search={search}
                            />
                    }
                </div>
            </div>
            {
                activeBtn === 'list' && (
                    // self-end mt-auto
                    <div className='bg-caspian-surface p-2 w-[100%] border border-caspian-gray1 rounded-lg flex items-center gap-2'>
                        <input
                            onClick={() => setIsAgree(!isAgree)}
                            type="checkbox" id="check" className='cursor-pointer peer' />
                        <label htmlFor="check" className='peer-checked:text-[#000] text-caspian-gray4 text-sm cursor-pointer'>
                            در صورت اطمینان از صحت همه مشاهدات آن را تایید کنید
                        </label>
                        <button
                            disabled={!isAgree || isLoading}
                            onClick={handleSubmit}
                            className='rounded-lg p-1.5 text-caspian-surface bg-caspian-gray4 peer-checked:bg-[#3C77A2] w-[100px] mr-5 text-sm flex justify-center items-center gap-1'>
                            <span>تایید</span>
                            {isLoading && <PiCircleNotch className='animate-spin' />}
                        </button>
                    </div>
                )
            }
        </div>
    );
}

export default MainObservationDetailsTabs;
