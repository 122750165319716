import React, { useContext, useEffect, useRef, useState } from "react";
import StepThreeFirstForm from "./StepThreeFirstForm";
import { AuthContext } from "../../../../../../../../context/auth/AuthContext";
import { toast } from "react-toastify";
import API from "../../../../../../../../api/API";

const MainStepThreeFirst = ({
  setActiveTab,
  actionplanIdRelated,
  setShowCreateOfficeForm,
}) => {
  const { token } = useContext(AuthContext);
  const [objectiveRelated, setObjectiveRelated] = useState("");
  const [riskRelated, setRiskRelated] = useState("");
  const [correctiveactionRelated, setCorrectiveactionRelated] = useState("");
  const [mosavabBaznegari, setMosavabBaznegari] = useState("");
  const [other, setOther] = useState("");
  const [actionPlanData, setActionPlanData] = useState([]);
  const [isDisable, setIsDisable] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!token || !actionplanIdRelated) return;
    const getActionPlanData = async () => {
      setLoading(true);
      try {
        const res = await API.get(
          `/api/v1/actionplan/origin/actionplan/${+actionplanIdRelated}`,
          {
            headers: { Authorization: token },
          }
        );
        console.log(res?.data[0]);
        setActionPlanData(res?.data[0]);
        setRiskRelated(res?.data[0].riskRelated);
        setCorrectiveactionRelated(res?.data[0].correctiveactionRelated);
        setMosavabBaznegari(res?.data[0].mosavabBaznegari);
        setOther(res?.data[0].other);
        setObjectiveRelated(res?.data[0]?.objectiveRelated?.id);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    getActionPlanData();
  }, [token, actionplanIdRelated]);

  console.log(+actionPlanData?.objectiveRelated?.id);
  const submitHandler = async () => {
    let formData = {
      objectiveRelated: +objectiveRelated,
      riskRelated: riskRelated,
      correctiveactionRelated: correctiveactionRelated,
      mosavabBaznegari: mosavabBaznegari,
      other: other,
      actionplanRelated: actionplanIdRelated,
    };
    if (
      actionplanIdRelated &&
      +objectiveRelated === +actionPlanData?.objectiveRelated?.id &&
      riskRelated === actionPlanData?.riskRelated &&
      correctiveactionRelated === actionPlanData?.correctiveactionRelated &&
      mosavabBaznegari === actionPlanData?.mosavabBaznegari &&
      other === actionPlanData?.other
    ) {
      setActiveTab("StepThree");
    } else if (
      actionplanIdRelated &&
      actionPlanData &&
      (+objectiveRelated !== +actionPlanData?.objectiveRelated?.id ||
        riskRelated !== actionPlanData?.riskRelated ||
        correctiveactionRelated !== actionPlanData?.correctiveactionRelated ||
        mosavabBaznegari !== actionPlanData?.mosavabBaznegari ||
        other !== actionPlanData?.other)
    ) {
      try {
        console.log(formData);
        const res = await API.patch(
          `/api/v1/actionplan/ActionPlanOrigin/${+actionPlanData?.id}/`,
          formData,
          {
            headers: { Authorization: token },
          }
        );
        console.log(res?.data);
        await setActiveTab("StepThree");
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        console.log(formData);
        const res = await API.post(
          `/api/v1/actionplan/ActionPlanOrigin/`,
          formData,
          {
            headers: { Authorization: token },
          }
        );
        console.log(res?.data);
        await setActiveTab("StepThree");
      } catch (err) {
        console.log(err);
        if (err?.message === "Network Error") {
          toast.error("اتصال با سرور برقرار نشد", {
            className: "toast-faild",
            bodyClassName: "toast-faild",
          });
        } else if (err?.response?.data?.actionplanRelated[0]) {
          toast.error(err?.response?.data?.actionplanRelated[0], {
            className: "toast-faild",
            bodyClassName: "toast-faild",
          });
        } else {
          toast.error("مشکل ناشناخته ای به وجود آمد.", {
            className: "toast-faild",
            bodyClassName: "toast-faild",
          });
        }
      }
    }
  };

  useEffect(() => {
    if (+objectiveRelated === -1) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [objectiveRelated]);

  return (
    <div className="flex flex-col justify-between h-full gap-4 p-2 min-h-[370px]">
      {loading ? (
        <div className="flex items-center justify-center w-full py-12">
          <span className="text-xs text-caspian-gray4">در حال بارگزاری...</span>
        </div>
      ) : (
        <StepThreeFirstForm
          objectiveRelated={objectiveRelated}
          setObjectiveRelated={setObjectiveRelated}
          riskRelated={riskRelated}
          setRiskRelated={setRiskRelated}
          correctiveactionRelated={correctiveactionRelated}
          setCorrectiveactionRelated={setCorrectiveactionRelated}
          mosavabBaznegari={mosavabBaznegari}
          setMosavabBaznegari={setMosavabBaznegari}
          other={other}
          setOther={setOther}
        />
      )}
      <div className="flex items-center gap-2">
        <button
          onClick={async () => {
            await setActiveTab("StepTwo");
          }}
          className="min-w-[6rem] outline outline-1 outline-caspian-secondary-500 text-caspian-secondary-500 text-xs p-1.5 rounded-md"
        >
          مرحله قبل
        </button>
        <button
          disabled={isDisable ? true : false}
          onClick={submitHandler}
          className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-secondary-500 text-caspian-surface text-xs p-1.5 rounded-md"
        >
          مرحله بعد
        </button>
      </div>
    </div>
  );
};

export default MainStepThreeFirst;
