import React from "react";
import MainInfoSectionAudit from "./info-section-audit/MainInfoSectionAudit";
import MainInfoPlanAudit from "./info-plan-audit/MainInfoPlanAudit";

const MainInputObservations = ({show,setShow}) => {
  return (
    //flex-col-reverse 2xl:flex-row
    <div className="flex items-start gap-2 py-4">
      <MainInfoSectionAudit show={show} setShow={setShow} />
      <MainInfoPlanAudit />
    </div>
  );
};

export default MainInputObservations;
