import React, { useEffect, useState } from "react";
import {
  PiHouseSimple,
  PiCalendar,
  PiQuestion,
  PiUsers,
  PiFolderPlus,
  PiGearSix,
} from "react-icons/pi";
import ButtonNavNew from "./ButtonNavNew";
import { useLocation, useParams, useSearchParams } from "react-router-dom";

const AuditNavNew = () => {
  const { id } = useParams();

  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState("chart");
  const [queryView, setQueryView] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get("view");
  useEffect(() => {
    if (!query) return;
    if (query === "list") {
      setActiveTab("view")
    }
  }, [query, activeTab])

  useEffect(() => {
    if (query) {
      setQueryView(query)
    }
  }, [query, setQueryView])

  useEffect(() => {
    if (query) return
    if (pathname === "/audit/chart") {
      setActiveTab("chart");
    } else if (pathname.includes(`audit/${id}`)) {
      setActiveTab("schedule");
    } else if (pathname === "/audit/note") {
      setActiveTab("note");
    } else if (pathname === "/audit/question") {
      setActiveTab("question");
    } else if (pathname === "/audit/user") {
      setActiveTab("user");
    } else if (pathname === `/audit/${id}?view=list`) {
      setActiveTab("view");
    } else if (pathname === "/audit/setting") {
      setActiveTab("setting");
    }
  }, [pathname, setActiveTab, id, query]);

  return (
    <div className="flex items-center gap-6 py-2 bg-caspian-background px-2 rounded-[13px] w-fit shadow-[0px_4px_4px_0px_#00000040]">
      {activeTab === "schedule" || activeTab === "view" ? (
        <>
          <ButtonNavNew
            url="/audit/chart"
            icon={<PiHouseSimple />}
            active="chart"
            buttonActvie={activeTab}
            onClick={() => setActiveTab("chart")}
            title="خانه"
          />
          <div className="flex gap-4 items-center border border-[#3C77A2] rounded-full p-1">
            <ButtonNavNew
              url={`/audit/${id}`}
              icon={<PiCalendar />}
              active="schedule"
              buttonActvie={activeTab}
              onClick={() => setActiveTab("schedule")}
              title="برنامه ریزی"
              bg="#cee1f3"
            />
            <ButtonNavNew
              // url="/audit/folder"
              icon={<PiFolderPlus />}
              active="view"
              buttonActvie={activeTab}
              setActiveTab={setActiveTab}
              title="لیست برنامه ممیزی"
              bg="#cee1f3"
            />
          </div>
        </>
      ) : (
        <>
          <ButtonNavNew
            url="/audit/chart"
            icon={<PiHouseSimple />}
            active="chart"
            buttonActvie={activeTab}
            onClick={() => setActiveTab("chart")}
            title="خانه"
          />
          {/* <ButtonNavNew
        url="/audit/1"

        icon={<PiCalendar />}
        active="schedule"
        buttonActvie={activeTab}
        onClick={() => setActiveTab("schedule")}
        title="برنامه ریزی"
      /> */}
          {/* <ButtonNavNew
        url="/audit/note"
        icon={<PiNoteDuotone />}
        active="note"
        buttonActvie={activeTab}
        onClick={() => setActiveTab("note")}
        title="note"
      /> */}

          <ButtonNavNew
            url="/audit/question"
            icon={<PiQuestion />}
            active="question"
            buttonActvie={activeTab}
            onClick={() => setActiveTab("question")}
            title="سوالات ممیزی"
          />

          <ButtonNavNew
            url="/audit/user"
            icon={<PiUsers />}
            active="user"
            buttonActvie={activeTab}
            onClick={() => setActiveTab("user")}
            title="تیم ممیزی"
          />

          {/* <ButtonNavNew
        url="/audit/folder"
        icon={<PiFolderPlus />}
        active="folder"
        buttonActvie={activeTab}
        onClick={() => setActiveTab("folder")}
        title="folder"
      /> */}

          <ButtonNavNew
            url="/audit/setting"
            icon={<PiGearSix />}
            active="setting"
            buttonActvie={activeTab}
            onClick={() => setActiveTab("setting")}
            title="تنظیمات"
          />
        </>
      )}
    </div>
  );
};

export default AuditNavNew;
