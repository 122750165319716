import React from 'react'

const TooltipReplay = ({ children, index, showJob, reply }) => {
    return (
        <div className={`flex items-center justify-center w-[150px] rounded-md z-50 absolute text-center text-[10px] bg-caspian-on_primary text-caspian-surface px-1 py-2
        ${index === 0 && !reply ? 'top-8' : '-top-14'}
        ${(showJob && index === 0) ? "right-0 " : "-right-1/2 left-0 "}
        ${(showJob && index !== 0) ? "right-0 -top-10" : "-right-1/2 left-0 "}`}>

            <div className={`w-0 h-0 absolute left-20 
                     ${index === 0 && !reply
                    ? 'top-2 border-x-[16px] border-x-caspian-transparent '
                    : '-bottom-[16px] border-x-[16px] border-x-caspian-transparent '}`}>

                <div className={`w-0 h-0 absolute right-[15px] -z-10 border-x-[8px]         
                     ${index === 0 && !reply
                        ? '-top-[16px] border-b-[8px] border-y-caspian-transparent border-y-caspian-on_primary bg-caspian-on_primary'
                        : '-top-[16px] border-t-[8px] border-x-caspian-transparent border-t-caspian-on_primary'}`}>
                </div>
            </div>
            {children}
        </div>
    )
}

export default TooltipReplay