import React from "react";
import MessageNavbar from "../MessageNavbar";
import NewMessage from "../NewMessage";
import SearchInput from "./SearchInput";
import { PiCaretLeft, PiDotsThreeVertical } from "react-icons/pi";
import ChatListSingle from "./ChatListSingle";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppContext } from "../../../context/app-context/AppContext";
import { useEffect } from "react";
import ChatDetils from "./ChatDetils";

const ChatList = ({ showMenu }) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [ids, setIds] = useState("");
  const showMessage = searchParams.get(`id`) === ids;

  const { setBradCrump1, setBradCrump2, setBradCrump3, setBrcLink2 } =
    useAppContext();
  useEffect(() => {
    setBradCrump1("پیام ها");
    setBradCrump2("پیام های دریافتی");
    if (showMessage) {
      setBrcLink2("/message/chat");
      setBradCrump3(ids);
    } else {
      setBradCrump3("");
    }
  }, [
    setBradCrump1,
    setBradCrump2,
    setBradCrump3,
    setBrcLink2,
    ids,
    showMessage,
  ]);

  return (
    <div
      className={
        showMenu
          ? `pl-[60px] mr-auto w-full md:w-[75%] lg:w-[80%] xl:w-[85%] 2xl:w-[85%] bg-caspian-gray3 p-4 overflow-y-scroll min-h-screen`
          : `pl-[60px] mr-[54px] w-full bg-caspian-gray3 p-4 overflow-y-scroll`
      }
    >
      <div className="flex flex-col gap-4 h-full">
        <div className="flex items-center gap-2">
          {/* <SearchInput /> */}
          {/* <NewMessage /> */}
          {showMessage && (
            <button
              className="mr-auto flex items-center gap-2 text-caspian-secondary-700"
              onClick={() => {
                setSearchParams({});
              }}
            >
              <span className="">بازگشت</span>
              <PiCaretLeft />
            </button>
          )}
        </div>
        <div className="flex flex-col gap-0.5 h-full">
          {showMessage ? (
            <ChatDetils />
          ) : (
            <>
              <div className="flex items-center gap-4 px-5 py-2 bg-caspian-surface">
                <input type="checkbox" className="w-4 h-4" />
                <button>
                  <PiDotsThreeVertical className="text-2xl" />
                </button>
              </div>
              <MessageNavbar buttonActive="chat" />
              {/* <div className="bg-caspian-surface h-full">
                <table className="w-full">
                  <tbody>
                    <tr>
                      <ChatListSingle
                        onClick={() => {
                          setIds("1234");
                          setSearchParams({ id: "1234" });
                        }}
                        fullName="امیر احمدی"
                        subject="فایل پیوست جلسه"
                        desc="سلام خدمت شما فایل ارائه جلسه خدمت شما"
                        time="10:41 PM"
                        read={false}
                      />
                      <ChatListSingle
                        onClick={() => {
                          setIds("1235");
                          setSearchParams({ id: "1235" });
                        }}
                        fullName="فاطمه کریمی"
                        subject="نامه ارجاع"
                        desc="لطفا این نامه را مشاهده و پاسخ دهید"
                        time="10:41 PM"
                        read={true}
                        save={true}
                      />
                      <ChatListSingle
                        onClick={() => {
                          setIds("1236");
                          setSearchParams({ id: "1236" });
                        }}
                        fullName="علی حقیقی"
                        subject="شماره پرونده"
                        desc="لطفا شماره پرونده را بررسی کنید و در اسرع وقت رسیدگی کنید لطفا شماره پرونده را بررسی کنید و در اسرع وقت رسیدگی کنید لطفا شماره پرونده را بررسی کنید و در اسرع وقت رسیدگی کنید"
                        time="10:41 PM"
                        read={true}
                        save={false}
                      />
                    </tr>
                  </tbody>
                </table>
              </div> */}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatList;
