import React, { useEffect, useRef, useState } from "react";
import { PiCaretDownBold, PiCaretLeftBold, PiFunnel } from "react-icons/pi";

const FilterDropdown = ({
  filterPersonal,
  filterSection,
  setFilterPersonal,
  setFilterSection,
}) => {
  const [open, setOpen] = useState(false);
  let menuRef = useRef();
  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });
  return (
    <div ref={menuRef} className="relative">
      <div
        onClick={() => setOpen(!open)}
        className="w-[80px] flex items-center justify-between border border-caspian-gray2 p-2 rounded-md cursor-pointer"
      >
        <div className="flex items-center gap-0">
          <PiFunnel className="text-caspian-gray4" />
          <span className="text-xs text-caspian-gray4">
            {filterPersonal ? "فرد" : filterSection ? "سازمان" : "همه"}
          </span>
        </div>
        {open ? (
          <PiCaretDownBold className="text-caspian-gray4 text-sm" />
        ) : (
          <PiCaretLeftBold className="text-caspian-gray4 text-sm" />
        )}
      </div>
      <div
        className={
          open
            ? "w-[80px] rounded-md shadow-[0_0_10px_#00000050] bg-caspian-surface absolute top-10 right-0 z-50"
            : "hidden"
        }
      >
        <div className="flex flex-col gap-0">
          <span
            onClick={() => {
              setFilterPersonal(true);
              setFilterSection(false);
              setOpen(false);
            }}
            className="text-xs px-2 py-1 hover:bg-caspian-secondary-50 cursor-pointer"
          >
            فرد
          </span>
          <span
            onClick={() => {
              setFilterPersonal(false);
              setFilterSection(true);
              setOpen(false);
            }}
            className="text-xs px-2 py-1 hover:bg-caspian-secondary-50 rounded-b-md cursor-pointer"
          >
            سازمان
          </span>
        </div>
      </div>
    </div>
  );
};

export default FilterDropdown;
