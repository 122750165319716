import React from "react";

const ToolTip = ({ title, alignToolTip }) => {
  return alignToolTip === "right" ? (
    <div className="absolute z-[1000] pointer-events-none text-xs bg-caspian-gray4 min-w-[100px]  rounded-md -top-8 right-9 whitespace-nowrap py-2 text-center text-caspian-surface">
      <p>{title}</p>
      <div
        className="border-b-caspian-gray4 -right-[10px] border-x-[16px] border-x-caspian-transparent border-b-[16px] absolute  rotate-0
        bottom-0"
      ></div>
    </div>
  ) : alignToolTip === "left" ? (
    <div className="absolute z-[1000] pointer-events-none text-xs bg-caspian-gray4 min-w-[100px]  rounded-md -top-8  left-9 whitespace-nowrap py-2 text-center text-caspian-surface">
      <p>{title}</p>
      <div
        className="border-b-caspian-gray4 -left-[10px] border-x-[16px] border-x-caspian-transparent border-b-[16px] absolute  rotate-0
        bottom-0"
      ></div>
    </div>
  ) : (
    <div className="absolute z-[1000] pointer-events-none text-xs bg-caspian-gray4 min-w-[100px] rounded-md left-1/2 -translate-x-1/2 bottom-8 whitespace-nowrap py-2 text-center text-caspian-surface">
      <div
        className="border-t-caspian-gray4 -bottom-[8px] border-x-[16px] border-x-caspian-transparent border-t-[16px] absolute -translate-x-1/2
        left-1/2"
        ></div>
        <p>{title}</p>
    </div>
  );
};

export default ToolTip;
