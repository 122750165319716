import React, { useState } from 'react';
import Loading from '../../../../../Loading';
import ObservationSingleRow from './ObservationSingleRow';

const ObservationTable = ({ data, statusFilter, search }) => {
    const [standard, setStandard] = useState('');
    const [clause, setClause] = useState('');
    const [question, setQuestion] = useState('');
    const [status, setStatus] = useState('');
    const [score, setScore] = useState('');

    let filterBySearch = [];
    // let filterByStatusFilter=[];
    let filterByStandard = [];
    let filterByClause = [];
    let filterByQuestion = [];
    let filterByStatus = [];
    let filterByScore = [];
    if (data[0] !== -1) {
        filterBySearch = search ? data?.filter(d => (d?.question + ' ' + d?.criteria)?.toString()?.toLocaleLowerCase()?.includes(
            search?.toString()?.toLocaleLowerCase()
        )) : data;

        filterByStandard = standard ? filterBySearch?.filter(d => d?.standard?.toString()?.toLocaleLowerCase()?.includes(
            standard?.toString()?.toLocaleLowerCase()
        )) : filterBySearch;

        filterByClause = clause ? filterByStandard?.filter(d => d?.clause?.toString()?.toLocaleLowerCase()?.includes(
            clause?.toString()?.toLocaleLowerCase()
        )) : filterByStandard;

        filterByQuestion = question ? filterByClause?.filter(d => (d?.question + ' ' + d?.criteria)?.toString()?.toLocaleLowerCase()?.includes(
            question?.toString()?.toLocaleLowerCase()
        )) : filterByClause;

        filterByStatus = status ? filterByQuestion?.filter(d => d?.status?.toString()?.toLocaleLowerCase()?.includes(
            status?.toString()?.toLocaleLowerCase()
        )) : filterByQuestion;

        filterByStatus = status ? filterByQuestion?.filter(d => d?.status?.toString()?.toLocaleLowerCase()?.includes(
            status?.toString()?.toLocaleLowerCase()
        )) : filterByQuestion;

        filterByScore = score ? filterByStatus?.filter(d => d?.score?.toString()?.toLocaleLowerCase()?.includes(
            score?.toString()?.toLocaleLowerCase()
        )) : filterByStatus;

    }
    return (
        <div className="w-full h-[70vh] overflow-auto">
            <table className='min-w-[992px] w-full overflow-x-auto rounded-tr-xl rounded-tl-xl relative'>
                <thead className='sticky top-0'>
                    <tr>
                        <th className='bg-caspian-primary-500 w-[20%] p-2 relative rounded-tr-xl'>
                            <input id='t1' type="text"
                                className={`${standard ? "outline-caspian-surface/50 border" : ""}peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50 cursor-pointer bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-16 border border-caspian-gray2`}
                                onChange={(e) => { setStandard(e.target.value) }}
                            />
                            <label htmlFor="t1"
                                className={`${standard ? 'absolute left-full right-3 font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 text-caspian-surface' : ''}absolute cursor-pointer font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 peer-focus:left-full text-caspian-surface whitespace-nowrap peer-focus:right-3`}>
                                استاندارد
                            </label>
                        </th>
                        <th className='bg-caspian-primary-500 w-[20%] p-2 relative'>
                            <input id='t2' type="text"
                                className={`${clause ? "outline-caspian-surface/50 border" : ""}peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50 cursor-pointer bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-7 border border-caspian-gray2`}
                                onChange={(e) => { setClause(e.target.value) }}
                            />
                            <label htmlFor="t2"
                                className={`${clause ? 'absolute left-full right-3 font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 text-caspian-surface' : ''}absolute cursor-pointer font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 peer-focus:left-full text-caspian-surface whitespace-nowrap peer-focus:right-3`}>
                                بند
                            </label>
                        </th>
                        <th className='bg-caspian-primary-500 w-[30%] p-2 relative'>
                            <input id='t3' type="text"
                                className={`${question ? "outline-caspian-surface/50 border" : ""}peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50 cursor-pointer bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-10 border border-caspian-gray2`}
                                onChange={(e) => { setQuestion(e.target.value) }}
                            />
                            <label htmlFor="t3"
                                className={`${question ? 'absolute left-full right-3 font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 text-caspian-surface' : ''}absolute cursor-pointer font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 peer-focus:left-full text-caspian-surface whitespace-nowrap peer-focus:right-3`}>
                                سوال
                            </label>
                        </th>
                        <th className='bg-caspian-primary-500 w-[10%] p-2 relative'>
                            <input id='t4' type="text"
                                className={`${status ? "outline-caspian-surface/50 border" : ""}peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50 cursor-pointer bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-14 border border-caspian-gray2`}
                                onChange={(e) => { setStatus(e.target.value) }}
                            />
                            <label htmlFor="t4"
                                className={`${status ? 'absolute left-full right-3 font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 text-caspian-surface' : ''}absolute cursor-pointer font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 peer-focus:left-full text-caspian-surface whitespace-nowrap peer-focus:right-3`}>
                                وضعیت
                            </label>
                        </th>
                        <th className='bg-caspian-primary-500 w-[8%] p-2 relative'>
                            <input id='t5' type="text"
                                className={`${score ? "outline-caspian-surface/50 border" : ""}peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50 cursor-pointer bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-10 border border-caspian-gray2`}
                                onChange={(e) => { setScore(e.target.value) }}
                            />
                            <label htmlFor="t5"
                                className={`${score ? 'absolute left-full right-3 font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 text-caspian-surface' : ''}absolute cursor-pointer font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 peer-focus:left-full text-caspian-surface whitespace-nowrap peer-focus:right-3`}>
                                امتیاز
                            </label>
                        </th>
                        <th className='bg-caspian-primary-500 w-[5%] rounded-tl-xl p-2'>

                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data[0] === -1 ? (
                            <tr><td><Loading /></td></tr>
                        ) :
                        filterByScore?.length > 0 ? (filterByScore?.map(item => <ObservationSingleRow {...item} key={item?.id} />)) : 
                        (<tr><td colSpan={6} className='bg-caspian-surface'>
                                <div className='py-5 flex flex-col gap-3 items-center justify-center'>
                                    <div className='w-[220px] h-[220px] flex justify-center'>
                                        <img src="/assets/images/library/not-found.svg" alt="موردی یافت نشد..." />
                                    </div>
                                    <h2 className='text-center text-sm'> موردی یافت نشد...</h2>
                                </div>
                            </td></tr>)
                    }
                </tbody>
            </table>
        </div>
    );
}

export default ObservationTable;
