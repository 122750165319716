import React from 'react';


const Legend = ({ labels, colors }) => (
    <div className='p-2 text-sm'>
        {labels.map((label, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', marginRight: '15px' }}>
                <div
                    style={{
                        width: '15px',
                        height: '15px',
                        borderRadius:'50%',
                        backgroundColor: colors[index],
                        marginLeft: '5px'
                    }}
                ></div>
                <span>{label}</span>
            </div>
        ))}
    </div>
);


export default Legend;

