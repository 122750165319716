import React, { useEffect, useState } from "react";
import RowView from "./RowView";
import Paginate from "./Paginate";
import { useSearchParams } from "react-router-dom";
import { PiCircleNotch } from "react-icons/pi";
import SingleTableActivities from "./SingleTableListActivites";

const TableListActivites = ({ listActivites, loading }) => {
  const [filterByTitle, setFilterByTitle] = useState("");
  const [filterBySender, setFilterBySender] = useState("");
  const [filterByReceiver, setFilterByReceiver] = useState("");
  const [filterByText, setFilterByText] = useState("");

  const [searchParams] = useSearchParams();
  const search = searchParams.get("search");
  const page = searchParams.get("page");
  const rows = searchParams.get("rows");

  let filterTitle = [];
  let filterSender = [];
  let filterText = [];
  let filterReceiver = [];

  useEffect(() => {
    if (search) {
      setCurrentPage(1);
    }
  }, [search]);

  let newData = [-1];
  if (listActivites[0] !== -1) {
    newData = search
      ? listActivites?.filter((d) =>
          d?.title?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase())
        )
      : listActivites;
  }

  if (newData[0] !== -1) {
    filterSender = filterBySender
      ? newData?.filter((d) =>
          (d?.sender?.firstName + " " + d?.sender?.lastName)
            ?.toLocaleLowerCase()
            ?.includes(filterBySender?.toLocaleLowerCase())
        )
      : newData;

    filterReceiver = filterByReceiver
      ? filterSender.filter((d) =>
          (d?.receiver?.firstName + " " + d?.receiver?.lastName)
            .toLocaleLowerCase()
            .includes(filterByReceiver?.toLocaleLowerCase())
        )
      : filterSender;

    filterText = filterByText
      ? filterReceiver.filter((d) =>
          (d?.text)
            .toLocaleLowerCase()
            .includes(filterByText?.toLocaleLowerCase())
        )
      : filterReceiver;

    filterTitle = filterByTitle
      ? filterText.filter((d) =>
          (d?.text)
            .toLocaleLowerCase()
            .includes(filterByTitle?.toLocaleLowerCase())
        )
      : filterText;
  }

  const [currentPage, setCurrentPage] = useState(page ? page : 1);
  const [recordsPerPage, setRecordsPerPage] = useState(rows ? rows : 10);
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = filterTitle.slice(firstIndex, lastIndex);
  const npage = Math.ceil(filterTitle.length / recordsPerPage);

  return (
    <div className="flex flex-col gap-4 py-10 px-8 xl:px-16">
      {loading ? (
        <div className="flex items-center justify-center py-40">
          <PiCircleNotch className="text-caspian-secondary-600 text-2xl animate-spin" />
        </div>
      ) : (
        <div className="flex flex-col overflow-x-auto">
              <div className="overflow-x-auto">
                <table className="min-w-[992px] w-full text-left text-sm font-light">
                  <thead className="border-b border-b-caspian-gray2 font-medium">
                    <tr className="">
                      <th
                        scope="col"
                        className="relative bg-caspian-primary-500 rounded-tr-xl text-right p-2 w-[20%]"
                      >

                        <input
                          id="sender"
                          // onClick={() => setCurrentPage(1)}
                          onChange={(e) => {
                            setFilterBySender(e.target.value);
                          }}
                          type="search"
                          // placeholder="فرستنده"
                          className={`${
                            filterBySender
                              ? "border border-caspian-surface/50"
                              : "border border-caspian-transparent"
                          } text-[#FFF7CB] bg-caspian-transparent peer p-1.5 rounded-md outline-none text-xs w-full focus:border focus:border-caspian-surface/50 pr-[66px] font-light cursor-pointer`}
                        />
                        {
                          <label
                            htmlFor="sender"
                            className={`${
                              filterBySender ? "right-0 left-auto" : ""
                            } font-light text-caspian-surface absolute text-center top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 p-0 bg-caspian-primary-500  rounded-md  cursor-pointer z-[30]
                            peer-focus:right-0 peer-focus:left-auto`}
                          >
                            فرستنده
                          </label>
                        }
                      </th>
                      <th
                        scope="col"
                        className="relative bg-caspian-primary-500 text-right px-2 py-1 w-[20%]"
                      >
                        
                        <input
                          id="receiver"
                          onChange={(e) => setFilterByReceiver(e.target.value)}
                          type="search"
                          // placeholder="گیرنده"
                          className={`${
                            filterByReceiver
                              ? "border border-caspian-surface/50 "
                              : "border border-caspian-transparent"
                          } text-[#FFF7CB] bg-caspian-transparent p-1.5 rounded-md outline-none text-xs w-full focus:border focus:border-caspian-surface/50 pr-12 font-light peer cursor-pointer`}
                        />
                        {
                          <label
                            htmlFor="receiver"
                            className={`${
                              filterByReceiver ? "right-0 left-auto" : ""
                            } font-light text-caspian-surface absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 p-0 text-center bg-caspian-primary-500 rounded-md  cursor-pointer z-[30]
                            peer-focus:right-0 peer-focus:left-auto`}
                          >
                            گیرنده
                          </label>
                        }
                      </th>
                      <th
                        scope="col"
                        className="relative bg-caspian-primary-500 px-2 py-1 w-[25%]"
                      >
                        
                        <input
                          id="subject"
                          onChange={(e) => setFilterByText(e.target.value)}
                          type="search"
                          // placeholder="موضوع "
                          className={`${
                            filterByText
                              ? "border border-caspian-surface/50 "
                              : "border border-caspian-transparent"
                          } text-[#FFF7CB] bg-caspian-transparent p-1.5 rounded-md outline-none text-xs w-full focus:border focus:border-caspian-surface/50 pr-14 font-light peer cursor-pointer`}
                        />
                        {
                          <label
                            htmlFor="subject"
                            className={`${
                              filterByText ? "right-0 left-auto" : ""
                            } text-caspian-surface absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 p-0 bg-caspian-primary-500 rounded-md  cursor-pointer font-light z-[30]
                            peer-focus:left-auto peer-focus:right-0`}
                          >
                            موضوع
                          </label>
                        }
                      </th>
                      <th
                        scope="col"
                        className="relative bg-caspian-primary-500 px-2 py-1 w-[25%]"
                      >
                        
                        <input
                          id="title"
                          onChange={(e) => setFilterByTitle(e.target.value)}
                          type="search"
                          // placeholder="عنوان برنامه اجرایی"
                          className={`${
                            filterByTitle
                              ? "border border-caspian-surface/50 "
                              : "border border-caspian-transparent"
                          } text-[#FFF7CB] bg-caspian-transparent p-1.5 rounded-md outline-none text-xs w-full focus:border focus:border-caspian-surface/50 pr-[114px] font-light peer cursor-pointer`}
                        />
                        {
                          <label
                            htmlFor="title"
                            className={`${
                              filterByTitle ? "right-0 left-auto -translate-x-3" : ""
                            } text-caspian-surface absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 p-0 bg-caspian-primary-500 rounded-md  cursor-pointer font-light z-[30]
                            peer-focus:left-auto peer-focus:right-0 peer-focus:-translate-x-3`}
                          >
                            عنوان برنامه اجرایی
                          </label>
                        }
                      </th>
                      <th
                        scope="col"
                        className="bg-caspian-primary-500 rounded-tl-xl text-center px-2 py-1 w-[10%]"
                      >
                        <input
                          disabled
                          type="search"
                          placeholder="تاریخ"
                          className="p-1.5 text-sm w-full bg-caspian-transparent placeholder:text-caspian-surface font-light text-center"
                        />
                      </th>
                    </tr>
                  </thead>
                  {records.length > 0 ? (
                    <tbody>
                      {records.map((d, i) => (
                        <SingleTableActivities
                          key={i}
                          id={d.id}
                          sender={
                            d?.sender?.firstName + " " + d?.sender?.lastName
                          }
                          receiver={
                            d?.receiver?.firstName + " " + d?.receiver?.lastName
                          }
                          title={d.title}
                          text={d?.text}
                          date={d?.created_at}
                          link={d?.link}
                          status={d?.status}
                        />
                      ))}
                    </tbody>
                  ) : (
                    <tbody className="py-24">
                      <tr>
                        <td className="py-24">
                          <div className="flex items-center justify-center w-full py-10">
                            <div className="z-10 absolute left-1/2 -translate-x-1/2 mx-auto">
                              <div className="flex flex-col gap-2 items-center justify-center w-full">
                                <img
                                  src="/assets/images/library/not-found.svg"
                                  alt="برنامه سازمانی یافت نشد..."
                                />
                                <h2>برنامه سازمانی یافت نشد...</h2>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>
      )}
      {records.length > 0 && (
        <div className="flex items-center justify-between py-2">
          <RowView
            setRecordsPerPage={setRecordsPerPage}
            setCurrentPage={setCurrentPage}
          />
          <Paginate
            pageCount={npage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
          <div className="text-xs flex items-center gap-4">
            <span>نمایش {firstIndex + 1}</span>
            <span>تا {lastIndex - (recordsPerPage - records.length)}</span>
            <span>از کل {filterText.length}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableListActivites;
