import React, { useState } from 'react';
import { PiCalendar } from 'react-icons/pi';
import StrengthListItem from './StrengthListItem';
import ImproveListItem from './ImproveListItem';

const MainInfoTab = () => {
    const [strengthList, setStrengthList] = useState([
        {
            id: 1,
            title: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است'
        },
        {
            id: 2,
            title: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد. '
        },
        {
            id: 3,
            title: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است'
        },
        {
            id: 4,
            title: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است'
        },
    ])
    const [improveList, setImproveList] = useState([
        {
            id: 1,
            title: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است'
        }
    ])
    return (
        <div className='w-[90%] 2xl:w-[82%] bg-caspian-surface p-4 pb-6 mb-4 rounded-3xl flex flex-col gap-4 shadow-[0px_4px_4px_0px_#00000040]'>
            {/* info */}
            <div className='flex justify-between items-start gap-6'>
                {/* time and date */}
                <div className='flex flex-col gap-4 basis-[40%]'>
                    <div className='flex gap-2 items-center'>
                        <PiCalendar className='text-[#45706E] text-xs lg:text-sm 2xl:text-base' size={20} />
                        <span className='text-[#45706E] text-xs lg:text-sm 2xl:text-base'> تاریخ ممیزی : </span>
                        <span className='font-bold text-xs lg:text-sm 2xl:text-base'>دوشنبه </span>
                        <span className='font-bold text-xs lg:text-sm 2xl:text-base'>1403/03/18 </span>
                    </div>
                    <div className='flex justify-between items-center'>
                        {/* time plan */}
                        <div className='flex flex-col gap-2 text-[#66666659]  text-xs lg:text-sm basis-[30%]'>
                            <div className='border-b border-[#66666659] text-center w-full'>
                                <span>ساعت برنامه</span>
                            </div>
                            <div className='flex items-center gap-2'>
                                <span className='whitespace-nowrap'>ساعت شروع :</span>
                                <span>14:30</span>
                            </div>
                            <div className='flex items-center gap-2'>
                                <span className='whitespace-nowrap'>ساعت خاتمه :</span>
                                <span>16:30</span>
                            </div>
                        </div>
                        {/* time audit */}
                        <div className='flex flex-col gap-2 text-xs lg:text-sm basis-[30%]'>
                            <div className='border-b text-caspian-gray4 text-center w-full'>
                                <span>ساعت ممیزی</span>
                            </div>
                            <div className='flex justify-center items-center gap-2'>
                                <span className='text-caspian-gray4 whitespace-nowrap'>ساعت شروع :</span>
                                <span>14:30</span>
                            </div>
                            <div className='flex justify-center items-center gap-2'>
                                <span className='text-caspian-gray4 whitespace-nowrap'>ساعت خاتمه :</span>
                                <span>16:30</span>
                            </div>
                        </div>
                    </div>
                </div>
                {/* audit team */}
                <div className='flex flex-col gap-1 basis-[60%]'>
                    <span className='text-caspian-primary-700 mb-2 font-semibold text-xs lg:text-sm 2xl:text-base'>تیم ممیزی</span>
                    <div className='flex gap-4 w-full bg-[#EAEAEA] p-1 text-xs lg:text-sm'>
                        <span className='text-[#01BC8D] '>سرپرست تیم ممیزی :</span>
                        <span>محمد صادق بیگی</span>
                    </div>
                    <div className='flex gap-4 w-full bg-caspian-green-200 p-1 text-xs lg:text-sm'>
                        <span className='text-[#3C77A2] '> ممیز :</span>
                        <span>محمد صادق بیگی/ نازنین صادقی</span>
                    </div>
                    <div className='flex gap-4 w-full bg-caspian-green-200 p-1 text-xs lg:text-sm'>
                        <span className='text-[#3C77A2] '> سایر افراد تیم ممیزی :</span>
                        <span>محمد صادق بیگی/ نازنین صادقی</span>
                    </div>
                    <div className='flex gap-4 w-full bg-caspian-blue-50 p-1 text-xs lg:text-sm'>
                        <span className='text-[#3C77A2] '> افراد حاضر در واحد :</span>
                        <span>محسن علیپور</span>
                    </div>
                    <div className='flex gap-4 w-full bg-caspian-blue-50 p-1 text-xs lg:text-sm'>
                        <span className='text-[#3C77A2] '> سایر افراد واحد :</span>
                        <span>محمد رضا رسول زاده</span>
                    </div>
                </div>
            </div>

            {/* strength and improve */}
            <div className='flex items-start justify-between'>
                {/* strength */}
                <div className='flex flex-col basis-[45%] gap-4'>
                    <div className='flex items-center gap-4'>
                        <span className='whitespace-nowrap text-caspian-primary-800 font-medium'>نقاط قوت</span>
                        <div className='w-full border-b border-caspian-primary-800'></div>
                    </div>
                    {/* strength list */}
                    <div className='flex flex-col gap-2'>
                        {
                            strengthList?.length > 0 ?
                                strengthList?.map(s => <StrengthListItem key={s?.id} {...s} />)
                                : <p className='text-sm text-center w-full'>نقاط قوت وجود ندارد.</p>
                        }
                    </div>
                </div>
                {/* improve */}
                <div className='flex flex-col basis-[45%] gap-4'>
                <div className='flex items-center gap-4'>
                        <span className='whitespace-nowrap text-caspian-primary-800 font-medium'>فرصت بهبود</span>
                        <div className='w-full border-b border-caspian-primary-800'></div>
                    </div>
                    {/* strength list */}
                    <div className='flex flex-col gap-2'>
                        {
                            improveList?.length > 0 ?
                                improveList?.map(s => <ImproveListItem key={s?.id} {...s} />)
                                : <p className='text-sm text-center w-full'>فرصت بهبود وجود ندارد.</p>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainInfoTab;
