import React from 'react'
import { useNavigate } from 'react-router-dom';

const SingleListReportActionPlan = ({ item }) => {
    const navigate = useNavigate()

    let colorStatus;
    let textStatus;
    if (item?.status === "accepted") {
        colorStatus = "#57EAB5";
        textStatus = "قبول شده";
    } else if (item?.status === "unread") {
        colorStatus = "#C2C2C2";
        textStatus = "خوانده نشده";
    } else if (item?.status === "waiting") {
        colorStatus = "#3C77A2";
        textStatus = "درحال بررسی";
    }
    
    return (
        <tr className='w-full bg-caspian-surface cursor-pointer hover:bg-caspian-blue-100 duration-300 rounded-md flex items-center p-4 pl-0 justify-between'
            onClick={() => navigate('/action-plan/reports/' + item?.id)}
        >
            <td className='w-[20%] flex items-center justify-start text-xs 2xl:text-[15px]'>
                {item?.reports?.map((i, index) => (
                    <div key={index}>
                        {i}
                        {index !== item?.reports?.length - 1 && '  / '}
                        &nbsp;
                    </div>
                ))}
            </td>

            <td className='w-[10%] flex items-center justify-start text-xs 2xl:text-[15px]'>
                {item?.taskRelated?.actionplanRelated?.title}
            </td>

            <td className='w-[10%] flex items-center justify-start text-xs 2xl:text-[15px]'>
                {item?.taskRelated?.title}
            </td>

            <td className='w-[10%] flex items-center justify-start text-xs 2xl:text-sm'>
                {item?.taskRelated?.responsible_task?.firstName} {item?.taskRelated?.responsible_task?.lastName}
            </td>

            <td className='w-[10%] flex items-center justify-start text-xs 2xl:text-[15px]'>
                {item?.taskRelated?.supervisor?.firstName} {item?.taskRelated?.supervisor?.lastName}
            </td>

            <td className='w-[10%] flex items-center justify-start text-xs 2xl:text-[15px] font-semibold'
                style={{ color: `${colorStatus}` }}>
                {textStatus}
            </td>

            <td className='w-[10%] flex items-center justify-start text-xs 2xl:text-[15px]'>
                {new Date(item?.created_at).toLocaleDateString("fa-IR")}
            </td>
        </tr>
    )
}

export default SingleListReportActionPlan