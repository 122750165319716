import React, { useState } from 'react';
import Loading from '../../../../../Loading';
import FoundSingleRow from './FoundSingleRow';
import Paginate from './Paginate';
import RowView from './RowView';
import MainProfileFound from '../profile-found/MainProfileFound';

const FoundTable = ({ data, search, statusFilter, currentPage, setCurrentPage, limit, setLimit }) => {
    const [code, setCode] = useState('');
    const [type, setType] = useState('');
    const [question, setQuestion] = useState('');
    const [description, setDescription] = useState('');
    const [requirement, setRequirement] = useState('');
    const [request, setRequest] = useState('');

    const [showProfile, setShowProfile] = useState(-1);

    let searchData = [];
    let filterByCode = [];
    let filterByType = [];
    let filterByQuestion = [];
    let filterByDesc = [];
    let filterByRequire = [];
    let filterByRequest = [];

    if (data[0] !== -1) {
        searchData = search ? data?.filter(d => d?.description?.toString()?.toLocaleLowerCase()?.includes(search?.toString()?.toLocaleLowerCase())) : data;

        filterByCode = code ? searchData?.filter(d => d?.code?.toString()?.toLocaleLowerCase()?.includes(code?.toString()?.toLocaleLowerCase())) : searchData;

        filterByType = type ? filterByCode?.filter(d => d?.type?.toString()?.toLocaleLowerCase()?.includes(type?.toString()?.toLocaleLowerCase())) : filterByCode;

        filterByQuestion = question ? filterByType?.filter(d => d?.question?.code?.toString()?.toLocaleLowerCase()?.includes(question?.toString()?.toLocaleLowerCase())) : filterByType;

        filterByDesc = description ? filterByQuestion?.filter(d => d?.description?.toString()?.toLocaleLowerCase()?.includes(description?.toString()?.toLocaleLowerCase())) : filterByQuestion;

        filterByRequire = requirement ? filterByDesc?.filter(d => (d?.requirement?.find(r => r?.standard?.includes(requirement))) || (d?.requirement?.find(r => r?.clause?.find(c => c?.name?.includes(requirement))))) : filterByDesc;

        filterByRequest = request ? filterByRequire?.filter(d => {
            if (('درخواستی ثبت نشده').includes(request)) {
                if (d?.request?.length < 1) {
                    return d
                }
            }
            else {
                return d?.request?.find(r=>r?.name?.toString()?.toLocaleLowerCase()?.includes(request?.toString()?.toLocaleLowerCase()))
            }
        }) : filterByRequire;
    }
    const lastIndex = currentPage * limit;
    const firstIndex = lastIndex - limit;
    const records = filterByRequest?.slice(firstIndex, lastIndex);
    const npage = Math.ceil(filterByRequest?.length / limit);
    return (
        <div className='flex flex-col gap-4'>
            {showProfile !== -1 && <MainProfileFound showProfile={showProfile} setShowProfile={setShowProfile} />}
            <div className='overflow-x-auto'>
                <table className='min-w-[992px] bg-caspian-surface w-full overflow-x-auto rounded-tr-xl rounded-tl-xl relative'>
                    <thead className='sticky top-0'>
                        <tr>
                            <th className='bg-caspian-primary-500 w-[10%] p-2 relative rounded-tr-xl'>
                                <input id='t1' type="text"
                                    className={`${code ? "outline-caspian-surface/50 border" : ""}peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50 cursor-pointer bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-14 border border-caspian-gray2`}
                                    onChange={(e) => { setCode(e.target.value) }}
                                />
                                <label htmlFor="t1"
                                    className={`${code ? 'absolute left-full right-3 font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 text-caspian-surface' : ''}absolute cursor-pointer font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 peer-focus:left-full text-caspian-surface whitespace-nowrap peer-focus:right-3`}>
                                    کد یافته
                                </label>
                            </th>
                            <th className='bg-caspian-primary-500 w-[5%] p-2 relative'>
                                <input id='t2' type="text"
                                    className={`${type ? "outline-caspian-surface/50 border" : ""}peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50 cursor-pointer bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-7 border border-caspian-gray2`}
                                    onChange={(e) => { setType(e.target.value) }}
                                />
                                <label htmlFor="t2"
                                    className={`${type ? 'absolute left-full right-3 font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 text-caspian-surface' : ''}absolute cursor-pointer font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 peer-focus:left-full text-caspian-surface whitespace-nowrap peer-focus:right-3`}>
                                    نوع
                                </label>
                            </th>
                            <th className='bg-caspian-primary-500 w-[10%] p-2 relative'>
                                <input id='t3' type="text"
                                    className={`${question ? "outline-caspian-surface/50 border" : ""}peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50 cursor-pointer bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-10 border border-caspian-gray2`}
                                    onChange={(e) => { setQuestion(e.target.value) }}
                                />
                                <label htmlFor="t3"
                                    className={`${question ? 'absolute left-full right-3 font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 text-caspian-surface' : ''}absolute cursor-pointer font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 peer-focus:left-full text-caspian-surface whitespace-nowrap peer-focus:right-3`}>
                                    سوال
                                </label>
                            </th>
                            <th className='bg-caspian-primary-500 w-[30%] p-2 relative'>
                                <input id='t4' type="text"
                                    className={`${description ? "outline-caspian-surface/50 border" : ""}peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50 cursor-pointer bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-16 border border-caspian-gray2`}
                                    onChange={(e) => { setDescription(e.target.value) }}
                                />
                                <label htmlFor="t4"
                                    className={`${description ? 'absolute left-full right-3 font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 text-caspian-surface' : ''}absolute cursor-pointer font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 peer-focus:left-full text-caspian-surface whitespace-nowrap peer-focus:right-3`}>
                                    شرح یافته
                                </label>
                            </th>
                            <th className='bg-caspian-primary-500 w-[20%] p-2 relative'>
                                <input id='t5' type="text"
                                    className={`${requirement ? "outline-caspian-surface/50 border" : ""}peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50 cursor-pointer bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-20 border border-caspian-gray2`}
                                    onChange={(e) => { setRequirement(e.target.value) }}
                                />
                                <label htmlFor="t5"
                                    className={`${requirement ? 'absolute left-full right-3 font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 text-caspian-surface' : ''}absolute cursor-pointer font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 peer-focus:left-full text-caspian-surface whitespace-nowrap peer-focus:right-3`}>
                                    الزامات مرتبط
                                </label>
                            </th>
                            <th className='bg-caspian-primary-500 w-[10%] rounded-tl-xl p-2 relative'>
                                <input id='t6' type="text"
                                    className={`${request ? "outline-caspian-surface/50 border" : ""}peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50 cursor-pointer bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-16 border border-caspian-gray2`}
                                    onChange={(e) => { setRequest(e.target.value) }}
                                />
                                <label htmlFor="t6"
                                    className={`${request ? 'absolute left-full right-3 font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 text-caspian-surface' : ''}absolute cursor-pointer font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 peer-focus:left-full text-caspian-surface whitespace-nowrap peer-focus:right-3`}>
                                    درخواست
                                </label>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data[0] === -1 ? (
                                <tr><td><Loading /></td></tr>
                            ) :
                                records?.length > 0 ? (records?.map(item => <FoundSingleRow
                                    {...item} key={item?.id} setShowProfile={setShowProfile} />)) :
                                    (<tr><td colSpan={6} className='bg-caspian-surface'>
                                        <div className='py-5 flex flex-col gap-3 items-center justify-center'>
                                            <div className='w-[220px] h-[220px] flex justify-center'>
                                                <img src="/assets/images/library/not-found.svg" alt="موردی یافت نشد..." />
                                            </div>
                                            <h2 className='text-center text-sm'> موردی یافت نشد...</h2>
                                        </div>
                                    </td></tr>)
                        }
                    </tbody>
                </table>
            </div>
            {records?.length > 0 &&
                (<div className='flex justify-between items-center'>
                    <RowView
                        setLimit={setLimit}
                        setCurrentPage={setCurrentPage}
                    />

                    <Paginate
                        pageCount={npage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                    <div className="text-sm flex items-center gap-4">
                        <span>نمایش {firstIndex + 1}</span>
                        <span>تا {lastIndex - (limit - records?.length)}</span>
                        <span>از کل {filterByRequest?.length}</span>
                    </div>
                </div>)

            }
        </div>
    );
}

export default FoundTable;
