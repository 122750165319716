import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// import BasicInformation from "./layout/BasicInformation";
import Library from "./layout/Library";
import Header from "./components/header/Header";
import Sidebar from "./components/sidebar/Sidebar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect, useContext } from "react";
import { AuthContext } from "./context/auth/AuthContext";
import Cookies from "js-cookie";
import Toolbar from "./components/toolbar/Toolbar";
import ManagmentOfMeetings from "./layout/ManagmentOfMeetings";
import ModelIdProcess from "./components/process-management-components/model-process/ModelIdProcess";
import MapIdProcess from "./components/process-management-components/map-process/MapIdProcess";
import ProcessManagement from "./layout/ProcessManagement";
import MainListProcess from "./components/process-management-components/list-processes/MainListProcess";
import ScrollToTop from "./components/scroll-to-top/ScrollToTop";
import ScrollFixed from "./components/scroll-fixed/ScrollFixed";
import LoginLayoutNew from "./layout/auth/LoginLayoutNew";
import NotificationLayout from "./layout/NotificationLayout";
import ProfileLayout from "./layout/auth/ProfileLayout";
import PersonalInfo from "./components/auth/person-info/PersonalInfo.js";
import JobInfo from "./components/auth/job-info/JobInfo";
import ForgetPassword from "./components/auth/forget-password/ForgetPassword";
import MessageLayout from "./layout/MessageLayout";
import SavedMessage from "./components/message/saved-message/SavedMessage";
import CalendarMain from "./components/calendar/CalendarMain.js";
import API from "./api/API.js";
import MainDocuments from "./components/documents-records/documents/MainDocuments.js";
import MainListRecords from "./components/documents-records/list-records/MainListRecords.js";
import DocumentsLayout from "./components/documents-records/documents/DocumentsLayout.js";
import MainInternalDocuments from "./components/documents-records/documents/internal-documents/MainInternalDocuments.js";
import MainExternalDocuments from "./components/documents-records/documents/external-documents/MainExternalDocuments.js";
import MainObsoleteDocuments from "./components/documents-records/documents/obsolete-documents/MainObsoleteDocuments.js";
import MainFavoriteDocuments from "./components/documents-records/documents/favorite-documents/MainFavoritesDocuments.js";
import MainProfileDocuments from "./components/documents-records/profile-documents/MainProfileDocuments.js";
// import ReportLayout from "./components/report-components/ReportLayout.js";
// import DetailsReceviedReport from "./components/report-components/recevied/DetailsReceviedReport.js";
// import MainReceviedReport from "./components/report-components/recevied/MainReceviedReport.js";
// import DetailsSentReport from "./components/report-components/sent/DetailsSentReport.js";
// import MainSentReport from "./components/report-components/sent/MainSentReport.js";
import MainExternalProfile from "./components/documents-records/documents/external-documents/profile/MainExternalProfile.js";
// import MainReferReport from "./components/report-components/refer/MainReferReport.js";
// import DetailsReferReport from "./components/report-components/refer/DetailsReferReport.js";
import MeetingLayout from "./components/meeting/MeetingLayout.js";
import MainProfileMeeting from "./components/meeting/profile-meeting/MainProfileMeeting.js";
import MainRecordsMeeting from "./components/meeting/records-meeting/MainRecordsMeeting.js";
import MainAllProfiles from "./components/meeting/profile-meeting/all-profiles/MainAllProfiles.js";
import MainMyProfiles from "./components/meeting/profile-meeting/my-profiles/MainMyProfiles.js";
import MainDraftProfile from "./components/meeting/profile-meeting/draft-profile/MainDraftProfile.js";
import MainDetailsProfileMeeting from "./components/meeting/profile-meeting/all-profiles/details-profile-meeting/MainDetailsProfileMeeting.js";
import ChartLayout from "./components/chart-components/ChartLayout.js";
import MainTreeChart from "./components/chart-components/tree-chart/MainTreeChart.js";
import MainListChart from "./components/chart-components/list-chart/MainListChart.js";
import MainListMeeting from "./components/meeting/list-meeting/MainListMeeting.js";
import MainAllMeeting from "./components/meeting/list-meeting/all-meeting/MainAllMeeting.js";
import MainMyMeeting from "./components/meeting/list-meeting/my-meeting/MainMyMeeting.js";
import MainDetailsListMeeting from "./components/meeting/list-meeting/details-list-meeting/MainDetailsListMeeting.js";
import MainListPersonal from "./components/chart-components/list-personal/MainListPersonal.js";
import ChatList from "./components/message/chat-message/ChatList.js";
import HomeLayout from "./components/home/HomeLayout.js";
import NoteLayout from "./components/note/NoteLayout.js";
import MainNote from "./components/note/note/MainNote.js";
import MainNotebook from "./components/notebook/MainNotebook.js";
import ButtonChat from "./components/chat/ButtonChat.js";
import RezaOutLayout from "./components/reza/RezaOutLayout.js";
import RezaInLayout from "./components/reza/RezaInLayout.js";
import RequestLayout from "./components/request-components/RequestLayout.js";
import MainSentRequest from "./components/request-components/sent/MainSentRequest.js";
import DetailsSentRequest from "./components/request-components/sent/DetailsSentRequest.js";
import MainReceviedRequest from "./components/request-components/recevied/MainReceviedRequest.js";
import DetailsReceviedRequest from "./components/request-components/recevied/DetailsReceviedRequest.js";
import MainReferRequest from "./components/request-components/refer/MainReferRequest.js";
import DetailsReferRequest from "./components/request-components/refer/DetailsReferRequest.js";
import RiskLayout from "./components/risk/RiskLayout.js";
import MainRisks from "./components/risk/risks/MainRisks.js";
import MainTeams from "./components/risk/teams/MainTeams.js";
import MainActivites from "./components/risk/activity/MainActivites.js";
import MainActivityProfile from "./components/risk/activity/profile/MainActivityProfile.js";
import ActionPlanLayout from "./components/action-plan/ActionPlanLayout.js";
import MainDashboard from "./components/action-plan/dashboard/MainDashboard.js";
// import MainPmo from "./components/action-plan/pmo/MainPmo.js";
import Loading from "./Loading.js";
// import MainPmoProfile from "./components/action-plan/pmo/profile/MainPmoProfile.js";
import MainProfileActionplan from "./components/action-plan/my-actionplan/profile-actionplan/MainProfileActionplan.js";
import MainMyActionplan from "./components/action-plan/my-actionplan/MainMyActionplan.js";
import IdActionPlan from "./components/action-plan/my-actionplan/IdActionPlan.js";
import MainFormActionplan from "./components/action-plan/my-actionplan/form-actionplan/MainFormActionplan.js";
import MainPlans from "./components/action-plan/plans/MainPlans.js";
import MainActions from "./components/action-plan/actions/MainActions.js";
import MainPmDashboard from "./components/action-plan/plans/pm/MainPmDashboard.js";
import MainPmoDashboard from "./components/action-plan/plans/pmo/MainPmoDashboard.js";
import MainProfileTask from "./components/action-plan/plans/task-profile/MainProfileTask.js";
import MainPmoProfile from "./components/action-plan/plans/pmo/profile/MainPmoProfile.js";
import StackholdersLayout from "./components/stackholders/StackholdersLayout.js";
import MainListStackholders from "./components/stackholders/list-stackholders/MainListStackholders.js";
import MainModelStackholders from "./components/stackholders/model-stackholders/MainModelStackholders.js";
import MainProfileStackholders from "./components/stackholders/profile-stackholders/MainProfileStackholders.js";
import BasicInformationLayout from "./components/basic-information/BasicInformationLayout.js";
import GoalsLayout from "./components/goals/GoalsLayout.js";
import MainListGoals from "./components/goals/list-goals/MainListGoals.js";
import MainModelGoals from "./components/goals/model-goals/MainModelGoals.js";
import MainProfileGoals from "./components/goals/profile-goals/MainProfileGoals.js";
import MainProfilePost from "./components/chart-components/profile-post/MainProfilePost.js";
import AuditLayout from "./components/audit/AuditLayout.js";
import MainAuditPlanning from "./components/audit/audit-planning/MainAuditPlanning.js";
import MainAuditProfile from "./components/audit/audit-profile/MainAuditProfile.js";
import MainAuditChart from "./components/audit/audit-chart/MainAuditChart.js";
import BlogLayout from "./components/blog/BlogLayout.js";
import MainProfileBlog from "./components/blog/profile-blog/MainProfileBlog.js";
import MainListBlog from "./components/blog/list-blog/MainListBlog.js";
import Error from "./Error.js";
import MainAuditQuestion from "./components/audit/audit-question/MainAuditQuestion.js";
import MainAuditProfileQuestion from "./components/audit/audit-question/profile-question/MainAuditProfileQuestion.js";
import MainProfileReportActionPaln from "./components/action-plan/actions/reports/profile-report-action-plan/MainProfileReportActionPaln.js";
import SizeScreen from "./components/size-screen/SizeScreen.js";
import MainAuditChecklist from "./components/audit/audit-cheklist/MainAuditChecklist.js";
import MainAuditChecklistProfile from "./components/audit/audit-cheklist/audit-checklist-profile/MainAuditChecklistProfile.js";
import MainAuditCreate from "./components/audit/audit-create/MainAuditCreate.js";
import MainAuditObservations from "./components/audit/audit-observations/MainAuditObservations.js";
import ViewComponent from "./components/audit/audit-create/ViewComponent.js";
import MainAuditRequest from "./components/audit/audit-request/MainAuditRequest.js";
import MainRisksProfile from "./components/risk/risks/profile/MainRisksProfile.js";

function App() {
  const [showMenu, setShowMenu] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("hide_sidebar")) {
      setShowMenu(false);
    } else {
      setShowMenu(true);
    }
  }, [showMenu]);

  const { dispatch, token, isLoggedIn, user } = useContext(AuthContext);
  const _appSignging = localStorage.getItem("_appSignging");
  const refresh = Cookies.get("refresh");

  useEffect(() => {
    if (_appSignging && refresh) {
      const getToken = async () => {
        await API.post("/auth/jwt/refresh/", {
          refresh: refresh,
        })
          .then((res) => {
            dispatch({ type: "GET_TOKEN", payload: res?.data?.access });
          })
          .catch((err) => {
            console.log(err);
            Cookies.remove("refresh");
            dispatch({ type: "SIGNOUT" });
          });
      };
      getToken();
    }
  }, [dispatch, _appSignging, refresh, isLoggedIn]);

  useEffect(() => {
    if (token) {
      const getUser = async () => {
        await API.get("/api/v1/profile/me", {
          headers: {
            Authorization: `${token}`,
          },
        })
          .then((res) => {
            console.log(res?.data);
            dispatch({ type: "GET_USER", payload: res?.data });
          })
          .catch((err) => console.log(err));
      };
      getUser();
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (!refresh) {
      localStorage.removeItem("_appSignging");
      dispatch({ type: "SIGNOUT" });
    }
  }, [dispatch, refresh]);

  useEffect(() => {
    if (!_appSignging) {
      Cookies.remove("refresh");
      dispatch({ type: "SIGNOUT" });
    }
  }, [_appSignging, dispatch]);

  const [showMainNotebook, setShowMainNotebook] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);

  const [pmo, setPmo] = useState(null);
  useEffect(() => {
    if (!user || !token) return;
    const pmoHandler = async () => {
      try {
        const res = await API.get("/api/v1/actionplan/Settings/", {
          headers: {
            Authorization: `${token}`,
          },
        });
        setPmo(res.data[0]?.PMO);
      } catch (error) {
        console.log(error);
      }
    };
    pmoHandler();
  }, [token, user]);

  return (
    <BrowserRouter>
      <ToastContainer
        autoClose={1500}
        hideProgressBar
        position="top-center"
        rtl={true}
        theme="colored"
        draggable={false}
      />
      {/* {showMainNotebook && (
        <MainNotebook
          setShowMainNotebook={setShowMainNotebook}
          setShowSidebar={setShowSidebar}
        />
      )} */}
      <ScrollToTop />
      <ScrollFixed />
      {_appSignging ? (
        <div className="flex flex-col">
          <Header />
          <SizeScreen />
          <ButtonChat />
          {/* <ChatButton /> */}
          <div className="relative flex justify-stretch min-h-screen">
            <Sidebar showMenu={showMenu} setShowMenu={setShowMenu} />
            <Routes>
              <Route
                path={`/`}
                errorElement={<Error />}
                element={<HomeLayout showMenu={showMenu} />}
              />
              <Route
                path={`notebook`}
                element={<NoteLayout showMenu={showMenu} />}
              >
                <Route path=":notebook" element={<MainNotebook />}>
                  <Route path=":note" element={<MainNote />}></Route>
                </Route>
              </Route>

              <Route
                path={`/basic-information`}
                element={<BasicInformationLayout showMenu={showMenu} />}
              />

              {/* Start risk routing */}
              <Route path="risk" element={<RiskLayout showMenu={showMenu} />}>
                {/* <Route
                  path="groups"
                  element={<MainGroups showMenu={showMenu} />}
                /> */}
                <Route
                  path="teams"
                  element={<MainTeams showMenu={showMenu} />}
                />
                <Route path="risks" element={<MainRisks showMenu={showMenu} />}>
                  <Route
                    path=":id"
                    element={<MainRisksProfile showMenu={showMenu} />}
                  />
                </Route>
                <Route
                  path="activities"
                  element={<MainActivites showMenu={showMenu} />}
                >
                  <Route
                    path=":id"
                    element={<MainActivityProfile showMenu={showMenu} />}
                  />
                </Route>
              </Route>
              {/* End risk Routing */}

              {/* Start audit routing */}
              <Route path="audit" element={<AuditLayout showMenu={showMenu} />}>
                <Route
                  path="chart"
                  element={<MainAuditChart showMenu={showMenu} />}
                />
                <Route
                  path="create"
                  element={<MainAuditCreate showMenu={showMenu} />}
                />
                <Route
                  path="create/:idCreate"
                  element={<ViewComponent showMenu={showMenu} />}
                />
                {/* <Route
                  path=":id"
                  element={<Navigate to={"/audit/chart"} />}
                ></Route> */}
                <Route
                  path=":id"
                  element={<MainAuditPlanning showMenu={showMenu} />}
                >
                  <Route
                    path="schedule"
                    element={<Navigate to={"/audit/5"} />}
                  ></Route>

                  {/* <Route
                    path="schedule/:idProfile"
                    element={<MainAuditProfile showMenu={showMenu} />}
                  ></Route> */}
                  <Route
                    path="schedule/:idSection"
                    element={<MainAuditProfile showMenu={showMenu} />}
                  ></Route>
                  <Route
                    path="checklist/:idSection"
                    element={<MainAuditChecklist showMenu={showMenu} />}
                  ></Route>
                  <Route
                    path="observeration/:idSection"
                    element={<MainAuditObservations showMenu={showMenu} />}
                  ></Route>
                  <Route
                    path="request/:idSection"
                    element={<MainAuditRequest showMenu={showMenu} />}
                  ></Route>
                  <Route
                    path="checklist/:idSection/profile"
                    element={<MainAuditChecklistProfile showMenu={showMenu} />}
                  ></Route>
                </Route>
                <Route
                  path=":id/observations"
                  element={<MainAuditObservations showMenu={showMenu} />}
                ></Route>

                <Route
                  path="question"
                  element={<MainAuditQuestion showMenu={showMenu} />}
                ></Route>
                <Route
                  path="question/:questionId"
                  element={<MainAuditProfileQuestion showMenu={showMenu} />}
                ></Route>
              </Route>
              {/* End audit Routing */}

              {/* Start stackholders routing */}
              <Route
                path="stackholders"
                element={<StackholdersLayout showMenu={showMenu} />}
              >
                <Route
                  path="list"
                  element={<MainListStackholders showMenu={showMenu} />}
                />
                <Route
                  path="model"
                  element={<MainModelStackholders showMenu={showMenu} />}
                />
                <Route
                  path=":id"
                  element={<MainProfileStackholders showMenu={showMenu} />}
                ></Route>
              </Route>
              {/* End stackholders Routing */}

              {/* Start goals routing */}
              <Route path="goals" element={<GoalsLayout showMenu={showMenu} />}>
                <Route
                  path="list"
                  element={<MainListGoals showMenu={showMenu} />}
                />
                <Route
                  path="model"
                  element={<MainModelGoals showMenu={showMenu} />}
                />
                <Route
                  path=":id"
                  element={<MainProfileGoals showMenu={showMenu} />}
                ></Route>
              </Route>
              {/* End goals Routing */}

              {/* Start project routing */}
              <Route
                path="action-plan"
                element={<ActionPlanLayout pmo={pmo} showMenu={showMenu} />}
              >
                <Route
                  path="plans"
                  element={<MainPlans showMenu={showMenu} />}
                ></Route>
                <Route
                  path="plans/:id"
                  element={<MainProfileTask showMenu={showMenu} />}
                ></Route>
                <Route
                  path="plans/pm"
                  element={<MainPmDashboard showMenu={showMenu} />}
                ></Route>
                <Route
                  path="plans/pmo"
                  element={
                    user?.id === undefined || pmo === null ? (
                      <Loading />
                    ) : user?.id === pmo ? (
                      <MainPmoDashboard showMenu={showMenu} />
                    ) : (
                      <Navigate to="/action-plan/plans" />
                    )
                  }
                ></Route>
                <Route
                  path="plans/pmo/:id"
                  element={
                    user?.id === undefined || pmo === null ? (
                      <Loading />
                    ) : user?.id === pmo ? (
                      <MainPmoProfile showMenu={showMenu} />
                    ) : (
                      <Navigate to="/action-plan/my-actionplan" />
                    )
                  }
                />
                <Route
                  path="reports"
                  element={
                    <Navigate to="/action-plan/actions?action=reports" />
                  }
                ></Route>
                <Route
                  path="reports/:id"
                  element={<MainProfileReportActionPaln showMenu={showMenu} />}
                ></Route>

                <Route
                  path="actions"
                  element={<MainActions showMenu={showMenu} />}
                ></Route>
                <Route
                  path="my-actionplan"
                  element={<MainMyActionplan showMenu={showMenu} />}
                >
                  <Route
                    path=":id"
                    element={<IdActionPlan showMenu={showMenu} />}
                  >
                    <Route
                      path="profile"
                      element={<MainProfileActionplan showMenu={showMenu} />}
                    />
                    <Route
                      path="action"
                      element={<MainFormActionplan showMenu={showMenu} />}
                    />
                  </Route>
                </Route>

                {/* <Route
                  path="pmo"
                  element={
                    user?.id === undefined || pmo === null ? (
                      <Loading />
                    ) : user?.id === pmo ? (
                      <MainPmo showMenu={showMenu} />
                    ) : (
                      <Navigate to="/action-plan/my-actionplan" />
                    )
                  }
                >
                  <Route
                    path=":id"
                    element={
                      user?.id === undefined || pmo === null ? (
                        <Loading />
                      ) : user?.id === pmo ? (
                        <MainPmoProfile showMenu={showMenu} />
                      ) : (
                        <Navigate to="/action-plan/my-actionplan" />
                      )
                    }
                  />
                </Route> */}
                <Route
                  path="dashboard"
                  element={<MainDashboard showMenu={showMenu} />}
                />
              </Route>
              {/* End project Routing */}

              {/* Start blog routing */}
              {/* <Route path="blog" element={<BlogLayout showMenu={showMenu} />}>
                <Route index element={<MainListBlog showMenu={showMenu} />} />
                <Route
                  path=":id"
                  element={<MainProfileBlog showMenu={showMenu} />}
                />
              </Route> */}
              {/* End blog Routing */}

              {/* Start requests routing */}
              <Route
                path="requests"
                element={<RequestLayout showMenu={showMenu} />}
              >
                <Route
                  path="sent"
                  element={<MainSentRequest showMenu={showMenu} />}
                >
                  <Route
                    path=":id"
                    element={<DetailsSentRequest showMenu={showMenu} />}
                  />
                </Route>
                <Route
                  path="received"
                  element={<MainReceviedRequest showMenu={showMenu} />}
                >
                  <Route
                    path=":id"
                    element={<DetailsReceviedRequest showMenu={showMenu} />}
                  />
                </Route>
                <Route
                  path="refer"
                  element={<MainReferRequest showMenu={showMenu} />}
                >
                  <Route
                    path=":id"
                    element={<DetailsReferRequest showMenu={showMenu} />}
                  />
                </Route>
              </Route>
              {/* End reports Routing */}
              {/* Start reports routing */}
              {/* <Route
                path="reports"
                element={<ReportLayout showMenu={showMenu} />}
              >
                <Route
                  path="sent"
                  element={<MainSentReport showMenu={showMenu} />}
                >
                  <Route
                    path=":id"
                    element={<DetailsSentReport showMenu={showMenu} />}
                  />
                </Route>
                <Route
                  path="received"
                  element={<MainReceviedReport showMenu={showMenu} />}
                >
                  <Route
                    path=":id"
                    element={<DetailsReceviedReport showMenu={showMenu} />}
                  />
                </Route>
                <Route
                  path="refer"
                  element={<MainReferReport showMenu={showMenu} />}
                >
                  <Route
                    path=":id"
                    element={<DetailsReferReport showMenu={showMenu} />}
                  />
                </Route>
              </Route> */}
              {/* End reports Routing */}

              {/* Start chart routing */}
              <Route path="chart" element={<ChartLayout showMenu={showMenu} />}>
                <Route
                  path="tree-chart"
                  element={<MainTreeChart showMenu={showMenu} />}
                ></Route>
                <Route
                  path="list-chart"
                  element={<MainListChart showMenu={showMenu} />}
                >
                  <Route
                    path=":id"
                    element={<MainProfilePost showMenu={showMenu} />}
                  ></Route>
                </Route>
                <Route
                  path="list-personal-chart"
                  element={<MainListPersonal showMenu={showMenu} />}
                ></Route>
              </Route>
              {/* End chart Routing */}

              {/* Start meeting routing */}
              <Route
                path="managment-of-meetings"
                element={<MeetingLayout showMenu={showMenu} />}
              >
                <Route
                  path="profile-meeting"
                  element={<MainProfileMeeting showMenu={showMenu} />}
                >
                  <Route
                    path="all-profiles"
                    element={<MainAllProfiles showMenu={showMenu} />}
                  >
                    <Route
                      path=":id"
                      element={
                        <MainDetailsProfileMeeting showMenu={showMenu} />
                      }
                    />
                  </Route>
                  <Route
                    path="my-profiles"
                    element={<MainMyProfiles showMenu={showMenu} />}
                  ></Route>
                  <Route
                    path="draft-profile"
                    element={<MainDraftProfile showMenu={showMenu} />}
                  ></Route>
                </Route>
                {/* <Route
                  path="inprogress-meeting"
                  element={<MainInprogressMeeting showMenu={showMenu} />}
                >
                  <Route
                    path="all-meeting"
                    element={<MainAllMeeting showMenu={showMenu} />}
                  >
                    <Route
                      path=":id"
                      element={
                        <MainDetailsInprogressMeeting showMenu={showMenu} />
                      }
                    />
                  </Route>
                  <Route
                    path="my-meeting"
                    element={<MainMyMeeting showMenu={showMenu} />}
                  ></Route>
                </Route> */}
                {/* List Meeting Tab */}
                <Route
                  path="list-meeting"
                  element={<MainListMeeting showMenu={showMenu} />}
                >
                  <Route
                    path="all-meeting"
                    element={<MainAllMeeting showMenu={showMenu} />}
                  >
                    <Route
                      path=":id"
                      element={<MainDetailsListMeeting showMenu={showMenu} />}
                    />
                  </Route>
                  <Route
                    path="my-meeting"
                    element={<MainMyMeeting showMenu={showMenu} />}
                  ></Route>
                </Route>

                <Route
                  path="records-meeting"
                  element={<MainRecordsMeeting showMenu={showMenu} />}
                >
                  <Route
                    path=":id"
                    element={<MainDetailsListMeeting showMenu={showMenu} />}
                  />
                </Route>
              </Route>
              {/* End meeting Routing */}

              {/* Start documents and records routing */}
              <Route
                path="/documents-and-records"
                element={<DocumentsLayout showMenu={showMenu} />}
              >
                <Route
                  path="documents"
                  element={<MainDocuments showMenu={showMenu} />}
                >
                  <Route
                    path="internal-documents"
                    element={<MainInternalDocuments showMenu={showMenu} />}
                  />
                  <Route
                    path="external-documents"
                    element={<MainExternalDocuments showMenu={showMenu} />}
                  />
                  <Route
                    path="obsolete-documents"
                    element={<MainObsoleteDocuments showMenu={showMenu} />}
                  />
                  <Route
                    path="favorite-documents"
                    element={<MainFavoriteDocuments showMenu={showMenu} />}
                  />
                </Route>
                <Route
                  path="documents/internal-documents/:code"
                  element={<MainProfileDocuments showMenu={showMenu} />}
                />
                <Route
                  path="documents/external-documents/:id"
                  element={<MainExternalProfile showMenu={showMenu} />}
                />
                <Route
                  path="documents/obsolete-documents/:code"
                  element={<MainProfileDocuments showMenu={showMenu} />}
                />
                <Route
                  path="list-records"
                  element={<MainListRecords showMenu={showMenu} />}
                />
              </Route>
              {/* End documents and records routing */}

              {/* <Route path={`/chart`} element={<Chart showMenu={showMenu} />} /> */}
              <Route
                path={`/process-management`}
                element={<ProcessManagement showMenu={showMenu} />}
              />
              <Route
                path={`/process-management/model`}
                element={<ModelIdProcess showMenu={showMenu} />}
              />
              <Route
                path={`/process-management/map`}
                element={<MapIdProcess showMenu={showMenu} />}
              />
              <Route
                path={`/process-management/list-processes`}
                element={<MainListProcess showMenu={showMenu} />}
              />
              <Route
                path={`/process-management/list-processes/:code`}
                element={<MainListProcess showMenu={showMenu} />}
              />
              <Route
                path={`/managment-of-meetings`}
                element={<ManagmentOfMeetings showMenu={showMenu} />}
              />
              <Route
                path={`/library`}
                element={<Library showMenu={showMenu} />}
              />
              <Route
                path={`/profile`}
                element={<ProfileLayout showMenu={showMenu} />}
              />
              <Route
                path={`/profile/personal-info`}
                element={<PersonalInfo showMenu={showMenu} />}
              />
              <Route
                path={`/profile/job-info`}
                element={<JobInfo showMenu={showMenu} />}
              />
              <Route
                path={`/profile/forget-password`}
                element={<ForgetPassword showMenu={showMenu} />}
              />
              <Route
                path={`/notification`}
                element={<NotificationLayout showMenu={showMenu} />}
              />
              <Route
                path={`/message`}
                element={<MessageLayout showMenu={showMenu} />}
              />
              <Route
                path={`/message/chat`}
                element={<ChatList showMenu={showMenu} />}
              />
              <Route
                path={`/message/saved`}
                element={<SavedMessage showMenu={showMenu} />}
              />
              <Route
                path={`/calendar`}
                element={<CalendarMain showMenu={showMenu} />}
              />
              <Route
                path={`/reza-in`}
                element={<RezaInLayout showMenu={showMenu} />}
              />
              <Route path="/*" element={<Navigate to={"/"} />} />
            </Routes>
            <Toolbar
              showMainNotebook={showMainNotebook}
              setShowMainNotebook={setShowMainNotebook}
              showSidebar={showSidebar}
              setShowSidebar={setShowSidebar}
            />
          </div>
        </div>
      ) : (
        <Routes>
          <Route path={`/login`} element={<LoginLayoutNew />} />
          <Route path={`/reza-out`} element={<RezaOutLayout />} />
          <Route path={`/*`} element={<Navigate to={`/login`} />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}

export default App;
