import { useContext, useEffect, useState } from "react";
import Details from "./tab-profile/details/Details";
import ReportMain from "./tab-profile/report/ReportMain";
import ButtonTab from "./ButtonTab";
import HistoryMain from "./tab-profile/history/HistoryMain";
import MainTopProfile from "./top-profile/MainTopProfile";
import { useNavigate, useParams } from "react-router-dom";
import { PiCaretLeft, PiChartLineDown, PiChartLineUp } from "react-icons/pi";
import { AuthContext } from "../../../../context/auth/AuthContext";
import API from "../../../../api/API";
import Loading from "../../../../Loading";

const MainProfileTask = () => {
  const { token, user } = useContext(AuthContext);
  const navigate = useNavigate();

  const params = useParams();
  const [activeTab, setActiveTab] = useState("details");
  const [taskData, setTaskData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModalFrom, setShowModalFrom] = useState(false)
  const [costListObject, setCostListObject] = useState([])
  const [reportId, setReportId] = useState()

  useEffect(() => {
    const getTaskData = async () => {
      if (!token || !params?.id) return;
      try {
        setLoading(true);
        const res = await API.get(
          `/api/v1/actionplan/tasksWithRole/${params?.id}/`,
          {
            headers: { Authorization: token },
          }
        );
        setTaskData(res?.data);
        console.log(res?.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    getTaskData();
  }, [params?.id, token]);


  // post گزارش دهی 
  const handleData = async () => {
    if (!token || !params.id) return;

    try {
      const res = await API.post('/api/v1/actionplan/taskreport/', {
        taskRelated: params?.id
      }, { headers: { Authorization: token } }
      )
      console.log(res?.data)
      console.log(res?.data?.id)
      setReportId(res?.data?.id)
      setShowModalFrom(true)

    } catch (error) {
      console.log(error)
    }
  }

  return loading ? (
    <Loading />
  ) : (
    <div className="w-full">
      <div className="flex items-center justify-between w-full pt-2 pr-2">
        <div className="flex items-center justify-center ">
          {taskData?.actionplanRelated?.statusAction === "StartTasks" &&
           (taskData?.responsible_task?.personalInfoProfileRelated[0]?.id === user?.placementProfileRelated?.personalInfoProfileRelated[0]?.id) &&
            <button
              className="flex items-center gap-2 text-caspian-secondary-700 p-2 border rounded-md">
              <PiChartLineUp />
              <span className="text-sm"
                onClick={handleData}>
                گزارش دهی
              </span>
            </button>
          }
        </div>
        <button
          onClick={() => navigate(-1)}
          className="flex items-center gap-2 text-caspian-secondary-700 p-2">
          <span className="text-sm">بازگشت</span>
          <PiCaretLeft />
        </button>
      </div>
      <div className="flex flex-col gap-2 w-full md:w-[90%] lg:w-85%] xl:w-[75%] p-2">
        <div className="w-full p-2 rounded-md shadow-[0_0_10px_#00000050] bg-caspian-surface flex flex-col gap-4">
          <div className="flex items-center gap-4 py-1.5 px-2 rounded-md">
            <MainTopProfile setCostListObject={setCostListObject} costListObject={costListObject} reportId={reportId} setShowModalFrom={setShowModalFrom} showModalFrom={showModalFrom} taskData={taskData} />
          </div>
        </div>

        <div className="w-full p-2 rounded-md shadow-[0_0_10px_#00000050] min-h-screen bg-caspian-surface flex flex-col gap-4">
          <div className="flex items-center gap-4 py-1.5 px-2 rounded-md"></div>
          <div className="flex items-center gap-4 py-1.5 px-2 rounded-md">
            <ButtonTab
              activeTab={activeTab}
              active="details"
              onClick={() => setActiveTab("details")}
              title="جزیات اقدام اجرایی"
            />
            <ButtonTab
              activeTab={activeTab}
              active="history"
              onClick={() => setActiveTab("history")}
              title="تاریخچه"
            />
            <ButtonTab
              activeTab={activeTab}
              active="report"
              onClick={() => setActiveTab("report")}
              title="لیست گزارش ها"
            />
          </div>

          {activeTab === "details" ? (
            <Details taskData={taskData} />
          ) : activeTab === "history" ? (
            <HistoryMain />
          ) : (
            <ReportMain />
          )}
        </div>
      </div>
    </div>
  );
};

export default MainProfileTask;
