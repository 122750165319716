import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PiCaretLeft, PiPencilSimpleLine } from "react-icons/pi";
import { AuthContext } from "../../../../../context/auth/AuthContext";
import API from "../../../../../api/API";
import PmoProfile from "./PmoProfile";
import MainEditOfficeForm from "../form/edit-office/MainEditOfficeForm";

const MainPmoProfile = () => {
  const params = useParams();
  const { token } = useContext(AuthContext);

  const navigate = useNavigate();
  const [showEditOfficeForm, setShowEditOfficeForm] = useState(false);
  const [actionPlanData, setActionPlanData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);


  useEffect(() => {
    setRefresh(1);
  }, []);
  useEffect(() => {
    if (!token || !params?.id || refresh !== 1) return;

    const getActionPlanData = async () => {
      setLoading(true);
      try {
        const res = await API.get(
          `/api/v1/actionplan/actionplanWithRole/${params?.id}/`,
          {
            headers: { Authorization: token },
          }
        );
        console.log(res?.data);
        setActionPlanData(res?.data);
        setRefresh(0);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    getActionPlanData();
  }, [token, params?.id, refresh]);


  return loading ? (
    <div className="flex items-center justify-center w-full py-12">
      <span className="text-xs text-caspian-gray4">در حال بارگزاری...</span>
    </div>
  ) : (
    <div className="flex flex-col gap-0 w-full">
      <div className="flex items-center justify-between w-full">
        <button
          onClick={() => navigate(-1)}
          className="mr-auto flex items-center gap-2 text-caspian-secondary-700 p-2"
        >
          <span className="text-sm">بازگشت</span>
          <PiCaretLeft />
        </button>
      </div>
      <div className="flex flex-col gap-2 p-2 pt-0">
        {actionPlanData?.statusAction === "PMOPlan" && (
          <button
            onClick={() => setShowEditOfficeForm(true)}
            className="w-fit flex items-center gap-1 rounded-md bg-caspian-secondary-500 text-caspian-surface text-sm p-2"
          >
            <PiPencilSimpleLine className="text-xl" />
            <span className="text-sm">ویرایش برنامه سازمانی</span>
          </button>
        )}
        {showEditOfficeForm && (
          <MainEditOfficeForm
            title={actionPlanData?.title}
            description={actionPlanData?.description}
            actionplanId={params?.id}
            setShowEditOfficeForm={setShowEditOfficeForm}
            setRefresh={setRefresh}
          />
        )}
        <PmoProfile fullData={actionPlanData} setRefresh={setRefresh} />
      </div>
    </div>
  );
};

export default MainPmoProfile;
