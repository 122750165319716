import React, { useContext, useEffect } from "react";
import { Droppable } from "react-beautiful-dnd";
import SingleQuestion from "./SingleQuestion";
import { PiCheckSquareFill, PiWarning } from "react-icons/pi";
import API from "../../../../../../api/API";
import { AuthContext } from "../../../../../../context/auth/AuthContext";
import { useParams } from "react-router-dom";

const ListQuestion = ({
  droppableId,
  items,
  title,
  isDraggable,
  questionSelectedList,
  setQuestionSelectedList,
  refs,
  refsS,
  numberSelected,
}) => {
  const { idSection } = useParams();
  const token = useContext(AuthContext);
  const existQuestion = async (item) => {
    await refs?.current[item?.id]?.scrollIntoView({
      behavior: "smooth",
    });
    // await setIsSelectedAnime(code);
  };

  const addQuestion = async (index) => {
    // console.log(index);
    await setQuestionSelectedList([...questionSelectedList, items[index]]);
    await refs?.current[items[index]?.id]?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const acceptQuestion = async () => {
    if (!token || !idSection || !items) return;
    const formData = {
      sectionRelated: idSection,
      questionRelated: items?.map((item) => item.id),
    };
    try {
      const res = await API.post("/api/v1/audit/checklist/", formData, {
        headers: { Authorization: token },
      });
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="h-[70vh] flex flex-col gap-0 w-1/2 rounded-md">
      {isDraggable ? (
        <div className="rounded-md mb-2 p-3 2xl:p-4 bg-caspian-brown-100 text-caspian-brown-800 font-bold flex items-center justify-center shadow-md">
          <span className="text-sm 2xl:text-base">{title}</span>
        </div>
      ) : (
        <div className="rounded-md mb-2 p-3 2xl:p-4 bg-caspian-blue-100 text-caspian-blue-800 font-bold flex items-center justify-center shadow-md">
          <span className="text-sm 2xl:text-base">{title}</span>
        </div>
      )}

      <Droppable droppableId={droppableId}>
        {(provided, snapshot) => (
          <div
            className={
              isDraggable
                ? "rounded-md p-0 bg-caspian-brown-100 shadow-md h-full overflow-y-auto overflow-x-hidden border border-caspian-transparent"
                : "rounded-t-md pb-20 bg-caspian-blue-50 shadow-md h-[60vh] overflow-y-auto overflow-x-hidden w-full"
            }
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
              border:
                !isDraggable && snapshot.isDraggingOver
                  ? "1px solid #00000090"
                  : "1px solid #00000000",
            }}
          >
            {items?.map((item, index) =>
              isDraggable ? (
                <SingleQuestion
                  onClick={() =>
                    questionSelectedList?.find((f) => f?.id === item?.id)
                      ? existQuestion(item)
                      : addQuestion(index)
                  }
                  key={index}
                  // key={item?.id}
                  item={item}
                  index={index}
                  isSelected={questionSelectedList?.find(
                    (f) => f?.id === item?.id
                  )}
                />
              ) : (
                <div
                  index={index}
                  ref={(el) => (refsS.current[item?.id] = el)}
                  key={index}
                  // key={item?.id}
                  className={`relative odd:bg-[#f4f9fc] even:bg-caspian-blue-100 p-4 w-full`}
                >
                  <PiWarning className="text-[#FF3333] text-lg absolute top-1 left-1" />
                  <div className="flex items-center gap-0 w-full">
                  
                    <div className="flex flex-col items-start gap-2 basis-[20%] 2xl:basis-[15%]">
                      <span className="text-sm">{item?.code}</span>
                      <div className="flex items-center gap-2">
                        <PiCheckSquareFill
                          onClick={() =>
                            setQuestionSelectedList(
                              items?.filter((f) => f?.id !== item?.id)
                            )
                          }
                          className="text-2xl text-caspian-secondary-500 cursor-pointer"
                        />
                      </div>
                      <div className="flex items-center justify-center">
                        <img
                          src={`/assets/images/audit/pdca-${item?.pdcaType}.svg`}
                          alt={item?.pdcaType}
                          className=""
                        />
                      </div>
                    </div>
                    <div className="relative flex flex-col gap-2 basis-[80%] 2xl:basis-[85%] border-r pr-2 border-r-caspian-gray/20">
                    
                      <p className="text-xs leading-5 w-full font-medium">
                        <span className="font-normal text-xs text-caspian-gray4">
                          موضوع
                        </span>{" "}
                        {item?.title}
                      </p>
                      
                      <p className="text-xs leading-5 w-full text-caspian-secondary-500 font-medium">
                        <span className="font-normal text-xs text-caspian-gray4">
                          معیار
                        </span>{" "}
                        {item?.criteriaon}
                      </p>
                    </div>
                  </div>
                </div>
              )
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      {!isDraggable && (
        <div className="border-t border-t-caspian-gray2 flex flex-col items-center justify-center w-full bg-caspian-blue-50 rounded-b-md py-2 h-[10vh]">
          <button
            //   disabled={disable ? true : false}
            onClick={acceptQuestion}
            className="disabled:border-caspian-gray1 disabled:text-caspian-gray4 rounded-md border border-caspian-blue-500 text-caspian-blue-700 p-2 w-1/2 text-sm"
          >
            ثبت موقت
          </button>
        </div>
      )}
    </div>
  );
};

export default ListQuestion;
