import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../context/auth/AuthContext";
import API from "../../../api/API";

function Response({ setResponse, setReplay, replay, setRefresh, idComment }) {
  const { token } = useContext(AuthContext);
  const replayRef = useRef()
  const [isDisable, setIsDisable] = useState(false)

  useEffect(() => {
    if (replay?.length < 1) {
      setIsDisable(true)
    } else {
      setIsDisable(false)
    }
  }, [replay])


  const clearHandler = async () => {
    setResponse(false);
    setReplay("");
    replayRef.current.value = ""
  };

  // post replay
  const formHandler = async () => {
    if (!token || !replay) return;

    let formData = {
      content_type: "comment_app.commentbox",
      object_id: idComment,
      text_comment: replay,
      is_parent: false,
    }

    try {
      const res = await API.post(`/api/v1/actionplan/comment/`, formData,
        { headers: { Authorization: token } }
      )
      console.log(res?.data)
      setRefresh(1)
      clearHandler()

    } catch (error) {
      console.log(error)
    }
  };

  return (
    <div className="flex items-center gap-2 pt-2 pb-4">
      <textarea
        ref={replayRef}
        onChange={(e) => setReplay(e.target.value)}
        placeholder="پاسخ خود را بنویسید"
        className="w-full resize-none text-xs outline-none text-caspian-gray border-2 border-caspian-gray2 rounded-lg p-2"
        rows={3}
      />

      <div className="flex flex-col items-center gap-3">
        <button
          disabled={isDisable ? true : false}
          onClick={formHandler}
          className={`text-xs py-2 px-3 cursor-pointer duration-500 rounded
          ${isDisable ? 'bg-caspian-gray text-caspian-gray1' : 'bg-[#01BC8D] text-caspian-surface'}`}>
          ارسال
        </button>
        <button
          type="button"
          onClick={clearHandler}
          className="text-caspian-error2 text-sm">
          لغو
        </button>
      </div>
    </div>
  );
}

export default Response;
