import React, { useContext, useState } from "react";
import { PiArrowBendUpLeftLight, PiTrash, PiCheckLight, PiXLight, PiCaretDown } from "react-icons/pi";
import ReplyMain from "./reply/ReplyMain";
import Response from "./Response";
import { AuthContext } from "../../../context/auth/AuthContext";
import API from "../../../api/API";
import TooltipReplay from "./reply/TooltipReplay";

const CommentSingle = ({ item, setRefresh, index, scrollRef, accessRead }) => {
  const { token, user } = useContext(AuthContext)

  const [showText, setShowText] = useState(true);
  const [showReply, setShowReply] = useState(false)
  const [response, setResponse] = useState(false)
  const [deleteMessage, setDeleteMessage] = useState(false)
  const [replay, setReplay] = useState("")
  const [idComment, setIdComment] = useState(null)
  const [showPostition, setShowPostition] = useState(false)
  const [showJob, setShowJob] = useState(false)

  let timerPosition;
  const mouseEnterPosition = () => {
    setShowPostition(true);
  };
  const mouseLeavePosition = () => {
    clearTimeout(timerPosition);
    setShowPostition(false);
  };

  let timerJob;
  const mouseEnterJob = () => {
    setShowJob(true)
  };
  const mouseLeaveJob = () => {
    clearTimeout(timerJob);
    setShowJob(false)
  };


  const deleteComment = async (id) => {
    if (!token || !id) return;
    try {
      const res = await API.delete(`/api/v1/actionplan/comment/${id}/`,
        { headers: { Authorization: token } }
      )
      console.log(res?.data)
      setRefresh(1)
    } catch (error) {
      console.log(error)
    }
  }
  const replayShow = () => {
    setIdComment(item?.id)
    setResponse(true)
  }

  const handleDeleteMessage = (id, value) => {
    setDeleteMessage((prev) => ({ ...prev, [id]: value }));
  };

  return (
    <div ref={scrollRef} className="border-b last:border-none border-caspian-gray2/90 w-full h-full px-5 pt-2">
      <div className="flex justify-between items-center pb-2">
        <div className="flex items-center gap-2">
          <img alt="" className="rounded-full w-10 h-10"
            src={(item?.creator_placement?.placementProfileRelated?.personalInfoProfileRelated[0]?.avatar)
              ? API?.defaults?.baseURL + (item?.creator_placement?.placementProfileRelated?.personalInfoProfileRelated[0]?.avatar)
              : "/assets/images/users/not-picture.svg"
            }
          />
          <div className="flex flex-col">
            <div className="flex items-center gap-2 ">
              <h3 className="text-caspian-secondary-900 text-xs font-medium">
                {item?.creator_placement?.placementProfileRelated?.firstName} {item?.creator_placement?.placementProfileRelated?.lastName}
              </h3>

              <div className="relative w-fit">
                <span className="text-caspian-gray text-xs "
                  onMouseEnter={mouseEnterPosition}
                  onMouseLeave={mouseLeavePosition}>
                  {item?.creator_placement?.placementPositionRelated?.title?.split(" ").length > 2
                    ? item?.creator_placement?.placementPositionRelated?.title?.split(" ")?.slice(0, 2)?.join(" ") + "..."
                    : item?.creator_placement?.placementPositionRelated?.title
                  }
                </span>
                {showPostition && item?.creator_placement?.placementPositionRelated?.title?.split(" ").length > 2 &&
                  <TooltipReplay index={index} >
                    {item?.creator_placement?.placementPositionRelated?.title}
                  </TooltipReplay>
                }
              </div>
            </div>

            <div className="relative w-full">
              <span
                className="text-caspian-gray text-xs"
                onMouseEnter={mouseEnterJob}
                onMouseLeave={mouseLeaveJob}
              >
                {Array.isArray(item?.creator_title) && item?.creator_title?.length > 1
                  ? item?.creator_title?.slice(0, 1)?.join(", ") + "..."
                  : item?.creator_title?.join(", ")
                }
              </span>

              {showJob && Array.isArray(item?.creator_title) && item?.creator_title.length > 1 && (
                <TooltipReplay index={index} showJob={showJob}>
                  {item.creator_title.join(", ")}
                </TooltipReplay>
              )}
            </div>

          </div>
        </div>
        <div className="flex justify-center items-center ">
          <p className="text-caspian-gray text-xs">
            {new Date(item?.created_at).toLocaleDateString("fa-IR")}
          </p>
        </div>
      </div>
      <p className="text-xs">
        {showText ? item?.text_comment?.slice(0, 120) : item?.text_comment}
        {item?.text_comment?.length > 120 && (
          <span
            onClick={() => setShowText(!showText)}
            className="text-caspian-secondary-600 px-2 text-xs cursor-pointer">
            {showText ? "بیشتر" : "کمتر"}
          </span>
        )}
      </p>
      {
        response ? (<Response item={item} idComment={idComment} setResponse={setResponse} setRefresh={setRefresh} replay={replay} setReplay={setReplay} />) : (

          <div className=" w-full flex justify-between items-center pt-2 pb-3">

            <div className="flex justify-center items-center">
              {item?.reply_comment?.length > 0 ?
                (<div className="flex items-center cursor-pointer">
                  <span
                    className="text-caspian-gray text-xs"
                    onClick={() => setShowReply(!showReply)}>
                    {!showReply
                      ? (`مشاهده ${item?.reply_comment?.length} پاسخ دیگر`)
                      : "بستن پاسخ‌ها"
                    }
                  </span>
                  <PiCaretDown className="text-caspian-gray" />
                </div>
                ) : (<span className="text-caspian-gray text-xs">پاسخی وجود ندارد</span>)
              }
            </div>

            {!accessRead &&
              <div className="flex gap-5 items-center">
                <button className="flex gap-2 items-center cursor-pointer" onClick={replayShow}>
                  <PiArrowBendUpLeftLight className="text-lg text-caspian-primary-300" />
                </button>


                {(item?.creator_placement?.id === user?.id) &&
                (!deleteMessage[item?.id] ? (
                <button
                  className="flex gap-2 items-center cursor-pointer"
                  onClick={() => handleDeleteMessage(item?.id, true)}>
                  <PiTrash className="text-caspian-error2 text-lg" />
                </button>
                ) : (
                <div className="flex items-center gap-2">
                  <button
                    className="text-caspian-error2 rounded-full text-xl"
                    onClick={() => deleteComment(item?.id)}>
                    <PiCheckLight />
                  </button>

                  <button
                    className="text-caspian-gray4 text-xl"
                    onClick={() => handleDeleteMessage(item?.id, false)}>
                    <PiXLight />
                  </button>
                </div>)
                )}
              </div>
            }
          </div>
        )
      }

      {showReply && <ReplyMain replay={replay} setRefresh={setRefresh} setReplay={setReplay} item={item} />}

    </div >
  );
};

export default CommentSingle;

