import React, { useEffect, useState } from 'react';
// import StackedBarChart from './chart/StackedBarChart';
// import MyPieChart from './chart/MyPieChart';
// import MyRadarChart from './chart/MyRadardChart';
import NestedPieChart from './chart/NestedPieChart';
import RadarChart from './chart/RadarChart';
// import BarChart from './chart/BarChart';
// import TidyTree from './chart/TidyTree';
import NestedPieChart2 from './chart/NestedPieChart2';
// import ZoomableIcicle from './chart/ZoomableIcicle';
import TreemapChart from './chart/TreemapChart';
import MultiLevelPieChart from './chart/MultiLevelPieChart';
import Test from './chart/Test';
import StackedBarChart from './chart/StackedBarChartD3';
import BarChart from './chart/BarChart';
import ModalRequest from './chart/ModalRequest';
import Legend from './chart/Legend';
// import TidyTree from './chart/TidyTree'
const ChartComponent = () => {
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [myData, setMyData] = useState('');
    const [myData2, setMyData2] = useState('');
    // const data = {
    //     name: "root", // ریشه داده‌ها
    //     children: [
    //         {
    //             name: "واحد 1",
    //             observations: 8,
    //             nonConformities: 3,
    //             children: [
    //                 { name: "استاندارد 9001", observations: 3, nonConformities: 1 },
    //                 { name: "استاندارد B", observations: 5, nonConformities: 2 }
    //             ]
    //         },
    //         {
    //             name: "واحد 2",
    //             observations: 6,
    //             nonConformities: 4,
    //             children: [
    //                 { name: "استاندارد A", observations: 2, nonConformities: 1 },
    //                 { name: "استاندارد B", observations: 4, nonConformities: 3 }
    //             ]
    //         }
    //     ]
    // };

    // const data = {
    //     name: "root", // ریشه داده‌ها
    //     children: [
    //         {
    //             name: "واحد 1", // نام واحد
    //             children: [
    //                 { name: "استاندارد A", value: 100 }, // نام استاندارد 1
    //                 { name: "استاندارد B", value: 500 }  // نام استاندارد 2
    //             ]
    //         },
    //         {
    //             name: "واحد 2", // نام واحد
    //             children: [
    //                 { name: "استاندارد A", value: 200 }, // نام استاندارد 1
    //                 { name: "استاندارد B", value: 400 }  // نام استاندارد 2
    //             ]
    //         }
    //     ]
    // };

    // 

    const data = {
        name: 'root',
        children: [
            {
                name: ' واحد منابع انسانی',
                colors: '#1f77b4',
                children: [
                    {
                        category: ' واحد منابع انسانی',
                        name: 'iso 9001',
                        value: 2,
                        nc: 0,
                        obs: 2,
                    },
                    {
                        category: ' واحد منابع انسانی',
                        name: 'iso 5001',
                        value: 2,
                        nc: 0,
                        obs: 2,
                    },
                    {
                        category: ' واحد منابع انسانی',
                        name: 'iso 26001',
                        value: 6,
                        nc: 5,
                        obs: 1
                    },
                    {
                        category: ' واحد منابع انسانی',
                        name: 'iso 3000',
                        value: 3,
                        nc: 1,
                        obs: 2
                    },
                ],
            },
            {
                name: 'واحد مالی',
                colors: '#ff7f0e',
                children: [
                    {
                        category: 'واحد مالی',
                        name: 'iso 26000',
                        value: 8,
                        nc: 5,
                        obs: 3,
                    },
                    {
                        category: 'واحد مالی',
                        name: 'iso 45000',
                        value: 13,
                        nc: 5,
                        obs: 8,
                    },
                    {
                        category: 'واحد مالی',
                        name: '5000',
                        value: 7,
                        nc: 3,
                        obs: 4,
                    }
                ],
            },
            {
                name: 'واحد IT',
                colors: 'gray',
                children: [
                    {
                        category: 'واحد IT',
                        name: 'iso 9001',
                        value: 2,
                        nc: 0,
                        obs: 2,
                    },
                    {
                        category: 'واحد IT',
                        name: 'iso 5001',
                        value: 2,
                        nc: 0,
                        obs: 2,
                    },
                    {
                        category: 'واحد IT',
                        name: 'iso 26001',
                        value: 6,
                        nc: 5,
                        obs: 1
                    }
                ],
            },
            {
                name: 'واحد برندینگ',
                colors: '#9467BD',
                children: [
                    {
                        category: 'واحد برندینگ',
                        name: 'iso 9001',
                        value: 4,
                        nc: 2,
                        obs: 2,
                    },
                    {
                        category: 'واحد برندینگ',
                        name: 'iso 5001',
                        value: 7,
                        nc: 5,
                        obs: 2,
                    },
                    {
                        category: 'واحد برندینگ',
                        name: 'iso 26001',
                        value: 8,
                        nc: 5,
                        obs: 3
                    },
                ],
            },
            {
                name: 'واحد ساخت و ساز',
                colors: '#E377C2',
                children: [
                    {
                        category: 'واحد ساخت و ساز',
                        name: 'iso 9001',
                        value: 8,
                        nc: 6,
                        obs: 2,
                    },
                    {
                        category: 'واحد ساخت و ساز',
                        name: 'iso 5001',
                        value: 4,
                        nc: 0,
                        obs: 4,
                    },
                    {
                        category: 'واحد ساخت و ساز',
                        name: 'iso 26001',
                        value: 8,
                        nc: 5,
                        obs: 3
                    },
                    {
                        category: 'واحد ساخت و ساز',
                        name: 'iso 5001',
                        value: 4,
                        nc: 2,
                        obs: 2
                    },
                ],
            },
        ]
    };

    const [radarData, setRadarData] = useState(
        [
            [
                { axis: "Attribute 1", value: 0.6 },
                { axis: "Attribute 2", value: 0.8 },
                { axis: "Attribute 3", value: 0.7 },
                { axis: "Attribute 4", value: 0.9 },
                { axis: "Attribute 5", value: 0.5 }
            ],
            [
                { axis: "Attribute 1", value: 0.4 },
                { axis: "Attribute 2", value: 0.6 },
                { axis: "Attribute 3", value: 0.5 },
                { axis: "Attribute 4", value: 0.8 },
                { axis: "Attribute 5", value: 0.6 }
            ]
        ]
    );

    // رنگ‌ها برای هر واحد
    const colors = ['#1f77b4', '#ff7f0e','gray','#9467BD','#E377C2'];
    const labels = ['واحد منابع انسانی', 'واحد مالی','واحد IT','واحد برندینگ','واحد ساحت و ساز'];


    return (
        <div className='basis-[75%]'>
            <div className='grid grid-cols-3 gap-2'>
                {/* <div className='col-span-2 p-2 relative pb-4 h-[400px] bg-caspian-surface rounded-lg shadow-[0px_1px_8px_1px_#00000040]'>
                    <span className='text-xs text-caspian-gray4'>استاندارد</span>
                    <StackedBarChart />
                </div> */}
                {/* <div className='col-span-1 h-[400px] bg-caspian-surface rounded-lg shadow-[0px_1px_8px_1px_#00000040]'>
                    <MyPieChart />
                </div> */}
                <div className='col-span-1 h-[400px] flex items-center justify-center bg-caspian-surface rounded-lg shadow-[0px_1px_8px_1px_#00000040]'>
                    <div className='ltr mx-auto'>
                        <RadarChart data={radarData} width={400} height={400} />
                    </div>
                </div>
                <div className='col-span-2 h-[400px] p-2 bg-caspian-surface rounded-lg shadow-[0px_1px_8px_1px_#00000040]'>
                    {/* <MyRadarChart /> */}
                    <span className='text-xs'>تعداد یافته‌ها(عدم انطباق-مشاهده)</span>
                    <div className='flex items-center justify-center'>
                        <NestedPieChart setShow={setShow} setMyData={setMyData} />
                        {show && <Test myData={myData} setShow={setShow} />}
                        {/* <MultiLevelPieChart /> */}
                    </div>
                </div>
                {/* <div className='col-span-3 h-[400px] flex items-center justify-center bg-caspian-surface rounded-lg shadow-[0px_1px_8px_1px_#00000040] ltr'>
                    <BarChart />
                </div> */}
                {/* <div className='col-span-3 h-[400px] overflow-x-auto flex items-center justify-center bg-caspian-surface rounded-lg shadow-[0px_1px_8px_1px_#00000040] ltr'>
                    <TidyTree />
                </div> */}
                <div className='col-span-3 h-[400px] p-2 bg-caspian-surface rounded-lg shadow-[0px_1px_8px_1px_#00000040]'>
                    <span className='text-xs'>تعداد درخواست‌های صادر شده به تفکیک هر استاندارد</span>
                    <div className='flex items-center justify-center'>
                        <NestedPieChart2 setMyData2={setMyData2} setShow2={setShow2} />
                        {show2 && <ModalRequest myData2={myData2} setShow2={setShow2} />}
                    </div>
                </div>

                {/* <div className='col-span-3 min-h-[400px] h-fit bg-caspian-surface rounded-lg shadow-[0px_1px_8px_1px_#00000040]'>
                 <span className='text-xs'>تعداد درخواست‌های صادر شده به تفکیک هر استاندارد</span> 
                 <div className='flex items-center justify-center ltr'>
                        <ZoomableIcicle />
                    </div>
                </div>  */}

                <div className='col-span-3 h-fit bg-caspian-surface rounded-lg shadow-[0px_1px_8px_1px_#00000040]'>
                    {/* <span className='text-xs'>تعداد درخواست‌های صادر شده به تفکیک هر استاندارد</span> */}
                    <div className='flex gap-2 h-fit'>
                        {/* <TreemapChart data={data} width={800} height={500} /> */}
                        <div className='basis-1/5'>
                            <Legend labels={labels} colors={colors} />
                        </div>
                        <div className='basis-4/5 ltr overflow-x-auto'>
                            <TreemapChart data={data} colors={colors} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChartComponent;
