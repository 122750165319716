import React, { useState } from 'react';
import ObservationDetails from './observation-details/ObservationDetails';
import NaDetails from './na-details/NaDetails';

const SingleListItem = ({ index, title, cri, id }) => {
    const [show, setShow] = useState(false);
    const [showNa, setShowNa] = useState(false);
    return (
        <>
            <div className='border border-[#D6D6D6] p-4 flex flex-col gap-6 rounded-lg bg-caspian-surface'>
                <div className='flex gap-3 items-start'>
                    <div className='w-[25px] h-[25px] flex items-center justify-center text-xs border text-[#3CA29C] border-[#3CA29C] rounded-lg'>
                        {index + 1}
                    </div>
                    <div className='text-xs text-justify'>
                        <p>{title}</p>
                        <p className='text-[#3C77A2] mt-2'>{cri}</p>
                    </div>
                </div>
                <div className='flex gap-4 text-xs'>
                    <button
                        onClick={() => setShow(true)}
                        className='text-caspian-surface bg-[#3C77A2] p-2 rounded-lg'>ثبت مشاهدات</button>
                    <button
                    onClick={()=>setShowNa(true)}
                        className='bg-caspian-surface border border-[#3C77A2] text-[#3C77A2] p-2 px-4 rounded-lg'>
                        NA
                    </button>
                </div>
            </div>

            {
                show && <ObservationDetails setShow={setShow} />
            }
            {
                showNa && <NaDetails setShowNa={setShowNa} />
            }
        </>
    );
}

export default SingleListItem;
