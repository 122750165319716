import React, { useState } from 'react';
import { PiCaretLeft } from 'react-icons/pi';

const Accordion = ({ ms, standardsRealated, children }) => {
    const [open, setOpen] = useState(false);
    console.log(children);
    return (
        <div className='rounded px-4 py-2.5 bg-caspian-surface text-xs xl:text-sm shadow-[0px_1px_4px_0px_#00000040]
mb-1.5'>
            <div className='flex justify-between items-center cursor-pointer
            text-[#326791]' onClick={() => setOpen(!open)}>
                <span>{ms}</span>
                <span className={`${open ? '-rotate-90' : ''} transition-all`}><PiCaretLeft /></span>
            </div>
            <div>
                {
                    open && standardsRealated?.map(s => <p className='mb-1' key={s?.id}>{s?.standardNumber}</p>)
                }
            </div>
        </div>
    );
}

export default Accordion;
