import React, { useState } from "react";
import MainInputObservations from "./input-observation/MainInputObservations";
import MainObservationsList from "./input-observation-list/MainObservationsList";
import MainObservationDetailsTabs from "./input-observation-details-tabs/MainObservationDetailsTabs";
import MainFoundList from "./found/found-list/MainFoundList";

const MainAuditObservations = () => {
  const [show,setShow]=useState('main');//list component
  return (
    <div>
      {
        show==='observation'? <MainObservationsList showComponent={show} setShowComponent={setShow} />:
        show==='main' ? <MainInputObservations show={show} setShow={setShow}/>:
        show==='detailsTab' ? <MainObservationDetailsTabs show={show} setShow={setShow} />:<MainFoundList />
      }
    </div>
  );
};

export default MainAuditObservations;
