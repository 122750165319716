import React from "react";
import RisksRiskDetailsMain from "../../risks-risk-details/RisksRiskDetailsMain";

const DoneRisks = ({ riskForm, thisTitleProcess, thisTitleGoal, title }) => {
  return (
    <div className="flex flex-col gap-2 p-2">
      {thisTitleProcess && (
        <h2 className="text-xs font-bold text-caspian-gray4 w-fit py-2 px-4">
          گروه فرایندها - {thisTitleProcess}
        </h2>
      )}
      {thisTitleGoal && (
        <h2 className="text-xs font-bold text-caspian-gray4 w-fit py-2 px-4">
          گروه اهداف کلان - {thisTitleGoal}
        </h2>
      )}
      {riskForm?.length < 1 ? (
        <div className="flex flex-col items-center justify-center py-12 gap-2 w-1/2">
          <span className="text-xs text-caspian-gray4">ریسک ثبت نشده است!</span>
        </div>
      ) : (
        <div className="flex flex-col gap-2 w-1/2">
          <span className="bg-caspian-secondary-500 text-caspian-surface rounded-md p-2 text-xs flex items-center justify-center w-[90%] mx-auto">
            {title}
          </span>
          <div className="flex flex-col gap-2">
            {riskForm?.map((r, i) => (
              <RisksRiskDetailsMain risk={r} key={i} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DoneRisks;
