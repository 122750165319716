import React, { useContext, useEffect, useState } from "react";
import VarifyMember from "./VarifyMember";
import AccountingObserverMember from "./AccountingObserverMember";
import ManagerMember from "./ManagerMember";
import GeneralObserverMember from "./GeneralObserverMember";
import TechObserverMember from "./TechObserverMember";
import ControllerMember from "./ControllerMember";
import LegalObserverMember from "./LegalObserverMember";
import ApprovalMember from "./ApprovalMember";
import BudgetObserverMember from "./BudgetObserverMember";
import HseObserverMember from "./HseObserverMember";
import { AuthContext } from "../../../../../../../../context/auth/AuthContext";
import API from "../../../../../../../../api/API";

const MainStepTwo = ({
  setActiveTab,
  actionplanIdRelated,
  setShowCreateOfficeForm,
  members,
  membersProfile,
  // setRefresh,
}) => {
  const { token } = useContext(AuthContext);
  const [isDisable, setIsDisable] = useState(true);
  const [actionPlanData, setActionPlanData] = useState([]);

  // تایید کننده
  const [varify, setVarify] = useState("");
  const [varifySelected, setVarifySelected] = useState(null);
  const [showListVarify, setShowListVarify] = useState(false);
  // ناظر حسابداری
  const [accountingObserver, setAccountingObserver] = useState("");
  const [accountingObserverSelected, setAccountingObserverSelected] =
    useState(null);
  const [showListAccountingObserver, setShowListAccountingObserver] =
    useState(false);
  // ناظر فنی
  const [techObserver, setTechObserver] = useState("");
  const [techObserverSelected, setTechObserverSelected] = useState(null);
  const [showListTechObserver, setShowListTechObserver] = useState(false);
  // ناظر عمومی
  const [generalObserver, setGeneralObserver] = useState("");
  const [generalObserverSelected, setGeneralObserverSelected] = useState(null);
  const [showListGeneralObserver, setShowListGeneralObserver] = useState(false);
  // ناظر حقوقی
  const [legalObserver, setLegalObserver] = useState("");
  const [legalObserverSelected, setLegalObserverSelected] = useState(null);
  const [showListLegalObserver, setShowListLegalObserver] = useState(false);
  // کنترلر
  const [controller, setController] = useState("");
  const [controllerSelected, setControllerSelected] = useState(null);
  const [showListController, setShowListController] = useState(false);
  // ناظر بودجه
  const [budgetObserver, setBudgetObserver] = useState("");
  const [budgetObserverSelected, setBudgetObserverSelected] = useState(null);
  const [showListBudgetObserver, setShowListBudgetObserver] = useState(false);
  // تصویب کننده
  const [approval, setApproval] = useState("");
  const [approvalSelected, setApprovalSelected] = useState(null);
  const [showListApproval, setShowListApproval] = useState(false);
  // مدیر برنامه اجرایی
  const [manager, setManager] = useState("");
  const [managerSelected, setManagerSelected] = useState(null);
  const [showListManager, setShowListManager] = useState(false);
  // ناظر HSE
  const [hseObserver, setHseObserver] = useState("");
  const [hseObserverSelected, setHseObserverSelected] = useState(null);
  const [showListHseObserver, setShowListHseObserver] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!token || !actionplanIdRelated) return;
    const getActionPlanData = async () => {
      setLoading(true);
      try {
        const res = await API.get(
          `/api/v1/actionplan/responsibles/actionplan/${actionplanIdRelated}`,
          {
            headers: { Authorization: token },
          }
        );
        console.log(res?.data);
        setActionPlanData(res?.data[0]);
        setApproval(res?.data[0]?.approval_placement?.id);
        setApprovalSelected(res?.data[0]?.approval_placement);
        setTechObserver(res?.data[0]?.tech_observer?.id);
        setTechObserverSelected(res?.data[0]?.tech_observer);
        setBudgetObserver(res?.data[0]?.budget_observer?.id);
        setBudgetObserverSelected(res?.data[0]?.budget_observer);
        setLegalObserver(res?.data[0]?.legal_observer?.id);
        setLegalObserverSelected(res?.data[0]?.legal_observer);
        setManager(res?.data[0]?.manager?.id);
        setManagerSelected(res?.data[0]?.manager);
        setGeneralObserver(res?.data[0]?.general_observer?.id);
        setGeneralObserverSelected(res?.data[0]?.general_observer);
        setController(res?.data[0]?.controller?.id);
        setControllerSelected(res?.data[0]?.controller);
        setVarify(res?.data[0]?.varify_placement?.id);
        setVarifySelected(res?.data[0]?.varify_placement);
        setAccountingObserver(res?.data[0]?.accounting_observer?.id);
        setAccountingObserverSelected(res?.data[0]?.accounting_observer);
        setHseObserver(res?.data[0]?.hse_observer?.id);
        setHseObserverSelected(res?.data[0]?.hse_observer);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    getActionPlanData();
  }, [token, actionplanIdRelated]);

  // useEffect(() => {
  //   if (actionplanIdRelated) {
  //     console.log(actionplanIdRelated);
  //   }
  // }, [actionplanIdRelated]);

  const submitHandler = async () => {
    if (!actionplanIdRelated) return;

    if (
      actionPlanData?.id &&
      (approval !== actionPlanData?.approval_placement?.id ||
        techObserver !== actionPlanData?.tech_observer?.id ||
        budgetObserver !== actionPlanData?.budget_observer?.id ||
        legalObserver !== actionPlanData?.legal_observer?.id ||
        manager !== actionPlanData?.manager?.id ||
        generalObserver !== actionPlanData?.general_observer?.id ||
        controller !== actionPlanData?.controller?.id ||
        varify !== actionPlanData?.varify_placement?.id ||
        accountingObserver !== actionPlanData?.accounting_observer?.id ||
        hseObserver !== actionPlanData?.hse_observer?.id)
    ) {
      try {
        let formData = {
          id: actionplanIdRelated,
          approval_placement: approval,
          tech_observer: techObserver,
          budget_observer: budgetObserver,
          legal_observer: legalObserver,
          manager: manager,
          general_observer: generalObserver,
          controller: controller,
          varify_placement: varify,
          accounting_observer: accountingObserver,
          hse_observer: hseObserver,
        };
        const res = await API.patch(
          `/api/v1/actionplan/ActionPlanResponsibles/${actionPlanData?.id}/`,
          formData,
          {
            headers: { Authorization: token },
          }
        );
        console.log(res?.data);
        // await setRefresh(1);
        await setActiveTab("StepThreeFirst");
      } catch (error) {
        console.log(error);
      }
    } else if (
      actionPlanData?.id &&
      approval === actionPlanData?.approval_placement?.id &&
      techObserver === actionPlanData?.tech_observer?.id &&
      budgetObserver === actionPlanData?.budget_observer?.id &&
      legalObserver === actionPlanData?.legal_observer?.id &&
      manager === actionPlanData?.manager?.id &&
      generalObserver === actionPlanData?.general_observer?.id &&
      controller === actionPlanData?.controller?.id &&
      varify === actionPlanData?.varify_placement?.id &&
      accountingObserver === actionPlanData?.accounting_observer?.id &&
      hseObserver === actionPlanData?.hse_observer?.id
    ) {
      await setActiveTab("StepThreeFirst");
    } else {
      try {
        let formData = {
          actionplanRelated: actionplanIdRelated,
          approval_placement: approval,
          tech_observer: techObserver,
          budget_observer: budgetObserver,
          legal_observer: legalObserver,
          manager: manager,
          general_observer: generalObserver,
          controller: controller,
          varify_placement: varify,
          accounting_observer: accountingObserver,
          hse_observer: hseObserver,
        };
        const res = await API.post(
          `/api/v1/actionplan/ActionPlanResponsibles/`,
          formData,
          {
            headers: { Authorization: token },
          }
        );
        console.log(res?.data);
        // await setRefresh(1);
        await setActiveTab("StepThreeFirst");
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (
      !manager ||
      !varify ||
      !approval ||
      !generalObserver ||
      !hseObserver ||
      !accountingObserver ||
      !controller ||
      !techObserver ||
      !legalObserver ||
      !budgetObserver
    ) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [
    manager,
    varify,
    approval,
    generalObserver,
    hseObserver,
    accountingObserver,
    controller,
    techObserver,
    legalObserver,
    budgetObserver,
  ]);

  return (
    <div className="flex flex-col justify-between h-full gap-4 p-2 min-h-[370px]">
      {loading ? (
        <div className="flex items-center justify-center w-full py-12">
          <span className="text-xs text-caspian-gray4">در حال بارگزاری...</span>
        </div>
      ) : (
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-2 w-full">
          <ManagerMember
            lable="مدیر برنامه اجرایی"
            members={membersProfile}
            manager={manager}
            setManager={setManager}
            managerSelected={managerSelected}
            setManagerSelected={setManagerSelected}
            showListManager={showListManager}
            setShowListManager={setShowListManager}
          />
          <AccountingObserverMember
            lable="ناظر حسابداری"
            members={members}
            accountingObserver={accountingObserver}
            setAccountingObserver={setAccountingObserver}
            accountingObserverSelected={accountingObserverSelected}
            setAccountingObserverSelected={setAccountingObserverSelected}
            showListAccountingObserver={showListAccountingObserver}
            setShowListAccountingObserver={setShowListAccountingObserver}
          />
          <VarifyMember
            lable="تائید کننده"
            members={members}
            varify={varify}
            setVarify={setVarify}
            varifySelected={varifySelected}
            setVarifySelected={setVarifySelected}
            showListVarify={showListVarify}
            setShowListVarify={setShowListVarify}
          />

          <GeneralObserverMember
            lable="ناظر عمومی"
            members={members}
            generalObserver={generalObserver}
            setGeneralObserver={setGeneralObserver}
            generalObserverSelected={generalObserverSelected}
            setGeneralObserverSelected={setGeneralObserverSelected}
            showListGeneralObserver={showListGeneralObserver}
            setShowListGeneralObserver={setShowListGeneralObserver}
          />
          <TechObserverMember
            lable="ناظر فنی"
            members={members}
            techObserver={techObserver}
            setTechObserver={setTechObserver}
            techObserverSelected={techObserverSelected}
            setTechObserverSelected={setTechObserverSelected}
            showListTechObserver={showListTechObserver}
            setShowListTechObserver={setShowListTechObserver}
          />
          <ControllerMember
            lable="کنترلر"
            members={membersProfile}
            controller={controller}
            setController={setController}
            controllerSelected={controllerSelected}
            setControllerSelected={setControllerSelected}
            showListController={showListController}
            setShowListController={setShowListController}
          />
          <LegalObserverMember
            lable="ناظر حقوقی"
            members={members}
            legalObserver={legalObserver}
            setLegalObserver={setLegalObserver}
            legalObserverSelected={legalObserverSelected}
            setLegalObserverSelected={setLegalObserverSelected}
            showListLegalObserver={showListLegalObserver}
            setShowListLegalObserver={setShowListLegalObserver}
          />
          <ApprovalMember
            lable="تصویب کننده"
            members={members}
            approval={approval}
            setApproval={setApproval}
            approvalSelected={approvalSelected}
            setApprovalSelected={setApprovalSelected}
            showListApproval={showListApproval}
            setShowListApproval={setShowListApproval}
          />
          <BudgetObserverMember
            lable="ناظر بودجه"
            members={members}
            budgetObserver={budgetObserver}
            setBudgetObserver={setBudgetObserver}
            budgetObserverSelected={budgetObserverSelected}
            setBudgetObserverSelected={setBudgetObserverSelected}
            showListBudgetObserver={showListBudgetObserver}
            setShowListBudgetObserver={setShowListBudgetObserver}
          />
          <HseObserverMember
            lable="ناظر HSE"
            members={members}
            hseObserver={hseObserver}
            setHseObserver={setHseObserver}
            hseObserverSelected={hseObserverSelected}
            setHseObserverSelected={setHseObserverSelected}
            showListHseObserver={showListHseObserver}
            setShowListHseObserver={setShowListHseObserver}
          />
        </div>
      )}
      <div className="flex items-center gap-2">
        <button
          onClick={async () => {
            await setActiveTab("StepOne");
          }}
          className="min-w-[6rem] outline outline-1 outline-caspian-secondary-500 text-caspian-secondary-500 text-xs p-1.5 rounded-md"
        >
          مرحله قبل
        </button>
        <button
          disabled={isDisable ? true : false}
          onClick={submitHandler}
          className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-secondary-500 text-caspian-surface text-xs p-1.5 rounded-md"
        >
          مرحله بعد
        </button>
        {/* <button
          onClick={() => setShowCreateOfficeForm(false)}
          className={`disabled:cursor-not-allowed disabled:text-caspian-gray text-caspian-secondary-500 text-xs p-1.5 rounded-md`}
        >
          ذخیره پیشنویس
        </button> */}
      </div>
    </div>
  );
};

export default MainStepTwo;
