const ListGoal = ({ goalList }) => {
  return goalList?.length > 0 ? (
    goalList?.map((p, i) => (
      <div key={i} className="p-2">
        {p?.goalsRelatedRiskTeam?.map((g, i) => (
          <div className="text-xs flex items-center gap-2" key={i}>
            <span title={g?.title}>
              {g?.title.length > 20 ? g?.title.slice(0, 20) + "..." : g?.title}
            </span>
            <span title={g?.description}>
              {g?.description.length > 30
                ? g?.description.slice(0, 30) + "..."
                : g?.description}
            </span>
          </div>
        ))}
      </div>
    ))
  ) : (
    <div className="flex items-center justify-center gap-2 py-8">
      <span className="text-xs text-caspian-gray4">هدفی موجود نیست</span>
    </div>
  );
};

export default ListGoal;
