import { useContext, useEffect, useRef, useState } from "react";
import { PiArrowsClockwise, PiCaretLeftBold, PiX } from "react-icons/pi";
import API from "../../../api/API";
import { AuthContext } from "../../../context/auth/AuthContext";
import { fromNowPersian } from "../../../helper/fromNowPersian";
import ChatBoxReciver from "./chat-tools/ChatBoxReciver";
import ChatBoxSender from "./chat-tools/ChatBoxSender";
import ChatInput from "./chat-tools/ChatInput";
import ChatBoxSender2 from "./chat-tools/ChatBoxSender2";
import ChatBoxReciver2 from "./chat-tools/ChatBoxReciver2";

const MainContentChat = ({
  setFavoritesShow,
  setShowChatBox,
  setShowChatContent,
  memberSelected,
  setMemberSelected,
  isIdConversation,
  setIsIdConversation,
  isIdUser,
  setIsIdUser,
  setRefreshMyconversation,
  chatType,
  groupName,
}) => {
  const { user, token } = useContext(AuthContext);
  const scrollRef = useRef();
  const [refresh, setRefresh] = useState(0);
  const [refreshMessageList, setRefreshMessageList] = useState(0);

  const [messageInput, setMessageInput] = useState("");
  const [allMessages, setAllMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingGetMessage, setLoadingGetMessage] = useState(false);

  useEffect(() => {
    setRefreshMessageList(1);
  }, []);
  useEffect(() => {
    const messageHandler = async () => {
      if (!token || refreshMessageList === 0) return;

      setLoadingGetMessage(true);
      if (isIdConversation) {
        await API.get(`/api/v1/message/myconversations/${isIdConversation}/`, {
          headers: { Authorization: token },
        })
          .then((res) => {
            setLoadingGetMessage(false);
            setRefreshMessageList(0);
            setAllMessages(res?.data?.sort((a, b) => (b?.id < a?.id ? 1 : -1)));
            console.log(res.data);
          })
          .catch((err) => {
            console.log(err);
            setLoadingGetMessage(false);
          });
      } else if (memberSelected[0]) {
        await API.get(
          `/api/v1/message/ConversationMembers/?id=${+memberSelected[0]}`,
          {
            headers: { Authorization: token },
          }
        )
          .then(async (res) => {
            await API.get(
              `/api/v1/message/myconversations/${res?.data[0]?.id}/`,
              {
                headers: { Authorization: token },
              }
            )
              .then((res) => {
                setLoadingGetMessage(false);
                setRefreshMessageList(0);
                setAllMessages(
                  res?.data?.sort((a, b) => (b?.id < a?.id ? 1 : -1))
                );
                console.log(res.data);
              })
              .catch((err) => {
                console.log(err);
                setLoadingGetMessage(false);
              });
            console.log(res.data);
          })
          .catch((err) => {
            console.log(err);
            setLoadingGetMessage(false);
          });
      }
    };
    messageHandler();
  }, [token, refreshMessageList, memberSelected, isIdConversation]);

  const [userInfo, setUserInfo] = useState(null);
  useEffect(() => {
    // if (isIdUser === null) return null;
    const getUserReceiver = async () => {
      if (token) {
        // setLoading(true);
        await API.get(
          `/api/v1/profile/${isIdUser ? isIdUser : +memberSelected[0]}`,
          {
            headers: { Authorization: token },
          }
        )
          .then((res) => {
            setUserInfo(res?.data);
            console.log(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };
    getUserReceiver();
  }, [token, memberSelected, isIdUser]);

  const handleInputChange = (e) => {
    setMessageInput(e?.target?.value);
  };

  const messageHandler = async () => {
    if (messageInput?.trim() !== "") {
      postMessage();
      setRefreshMessageList(1);
      setMessageInput("");
    }
  };

  const postMessage = async () => {
    if (isIdConversation) {
      await API.post(
        "/api/v1/message/message/",
        {
          conversationRelated: isIdConversation,
          body: messageInput,
        },
        {
          headers: { Authorization: token },
        }
      )
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => console.log(err));
    } else {
      console.log({
        members: memberSelected,
        body: messageInput,
      });
      await API.post(
        "/api/v1/message/message/",
        {
          body: messageInput,
          members: memberSelected,
        },
        {
          headers: { Authorization: token },
        }
      )
        .then((res) => {
          console.log(res.data);
          setIsIdConversation(res?.data?.conversationRelated);
          setRefresh(1);
        })
        .catch((err) => console.log(err));
    }
  };

  const [messageId, setMessageId] = useState(null);

  const editMessageHandler = async () => {
    if (messageInput?.length === 0) return null;
    await API.patch(
      `/api/v1/message/message/${messageId}/`,
      {
        conversationRelated: isIdConversation,
        body: messageInput,
      },
      {
        headers: { Authorization: token },
      }
    )
      .then((res) => {
        console.log(res.data);
        setRefreshMessageList(1);
        setEditButton(false);
        setMessageInput("");
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [allMessages]);

  const [editButton, setEditButton] = useState(false);

  const processedArr = allMessages.reduce((acc, item, index) => {
    if (
      index === 0 ||
      item?.sender?.user !== allMessages[index - 1]?.sender?.user
    ) {
      acc.push({ ...item, showUser: true });
    } else {
      acc.push({ ...item, showUser: false });
    }
    return acc;
  }, []);

  // const [file, setFile] = useState(null);

  // const getFile = async () => {
  //   if (file !== null) {
  //     if (isIdConversation) {
  //       console.log(file);
  //     }
  //   }
  // };

  const fileHandler = () => {};
  return (
    <div className="min-w-[280px] max-w-[280px] min-h-[460px] max-h-[460px] bg-caspian-primary-50 rounded-md flex flex-col fixed bottom-16 left-28 z-50">
      <div className="flex items-center justify-between px-2 border-b border-b-caspian-gray2">
        <div className="flex items-center gap-4">
          <PiX
            onClick={() => {
              setShowChatContent(false);
              setShowChatBox(false);
              setFavoritesShow(false);
            }}
            className="text-caspian-gray4 text-xl cursor-pointer"
          />

          {
            // (isIdUser && chatType === "personal") ||
            memberSelected?.length === 1 ? (
              <div className="py-2 border-b border-b-caspian-gray2 last:border-b-0 flex items-center gap-2 w-full">
                {userInfo?.personalInfoProfileRelated[0]?.avatar !== null &&
                userInfo?.personalInfoProfileRelated[0]?.avatar !== undefined &&
                !userInfo?.personalInfoProfileRelated[0]?.avatar?.endsWith(
                  "null"
                ) ? (
                  <img
                    className="w-7 h-7 rounded-full"
                    src={userInfo?.personalInfoProfileRelated[0]?.avatar}
                    alt={userInfo?.firstName + " " + userInfo?.lastName}
                  />
                ) : (
                  <h4 className="text-xl text-caspian-surface bg-[#FCC934] w-7 h-7 rounded-full flex items-center justify-center">
                    {userInfo?.firstName ? (
                      userInfo?.firstName.trim()[0]
                    ) : (
                      <img
                        src={"/assets/images/users/not-picture.svg"}
                        alt=""
                        className="rounded-full"
                      />
                    )}
                  </h4>
                )}
                <div className="flex flex-col gap-0">
                  <div className="flex items-center gap-2">
                    <span className="text-[11px] font-bold">
                      {(userInfo?.firstName ? userInfo?.firstName : "") +
                        " " +
                        (userInfo?.lastName ? userInfo?.lastName : "")}
                    </span>
                  </div>
                  <div className="flex items-center gap-2">
                    <span className="text-[10px] text-caspian-gray">
                      کارشناس
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="py-2 border-b border-b-caspian-gray2 last:border-b-0 flex items-center gap-2 w-full">
                <h4 className="text-xl text-caspian-surface bg-[#FCC934] w-7 h-7 rounded-full flex items-center justify-center">
                  {groupName?.trim()[0]}
                </h4>
                <span className="text-[11px] font-bold">{groupName}</span>
              </div>
            )
          }
        </div>

        <div className="flex items-center gap-2">
          {/* <PiDotsThreeCircle className="text-caspian-gray4 text-xl cursor-pointer" /> */}
          <PiArrowsClockwise
            onClick={() => setRefreshMessageList(1)}
            className={`z-50 text-xl cursor-pointer text-caspian-gray4 ${
              loadingGetMessage ? "animate-spin" : ""
            }`}
          />
          {/* <PiArrowsClockwise
            onClick={() => setRefreshMessageList(1)}
            className={`z-50 text-2xl cursor-pointer absolute bottom-14 right-2 bg-caspian-secondary-600 text-caspian-surface rounded-full p-0.5 ${
              loadingGetMessage ? "animate-spin" : ""
            }`}
          /> */}
          <PiCaretLeftBold
            onClick={() => {
              setShowChatContent(false);
              setIsIdConversation(null);
              setIsIdUser(null);
              setRefreshMyconversation(1);
              setMemberSelected([]);
            }}
            className="text-caspian-gray4 cursor-pointer"
          />
        </div>
      </div>

      <div className="h-full w-full flex flex-col gap-0">
        <div className="h-[365px]">
          <div className="h-full overflow-y-scroll w-full p-2 border-b border-b-caspian-gray2 pt-6 pb-4">
            {allMessages?.length > 0 ? (
              <div className="flex flex-col gap-2">
                {processedArr?.map((d, i) => (
                  <div ref={scrollRef} className="" key={i}>
                    {d?.sender?.user === user?.placementProfileRelated?.user ? (
                      d?.showUser ? (
                        <ChatBoxSender
                          x={d?.showUser}
                          isFav={d?.is_fav}
                          setMessageId={setMessageId}
                          setEditButton={setEditButton}
                          firstIndex={i}
                          messageId={d?.id}
                          setRefreshMessageList={setRefreshMessageList}
                          sender={
                            d?.sender?.firstName + " " + d?.sender?.lastName ||
                            d?.reciver
                          }
                          avatar={
                            API.defaults.baseURL +
                            d?.sender?.personalInfoProfileRelated[0]?.avatar
                          }
                          message={d?.body.replaceAll("فیلتر", "*****")}
                          date={fromNowPersian(new Date(d?.created))}
                          setMessageInput={setMessageInput}
                          convId={isIdConversation}
                        />
                      ) : (
                        <ChatBoxSender2
                          isFav={d?.is_fav}
                          setMessageId={setMessageId}
                          setEditButton={setEditButton}
                          firstIndex={i}
                          messageId={d?.id}
                          setRefreshMessageList={setRefreshMessageList}
                          sender={
                            d?.sender?.firstName + " " + d?.sender?.lastName ||
                            d?.reciver
                          }
                          avatar={
                            API.defaults.baseURL +
                            d?.sender?.personalInfoProfileRelated[0]?.avatar
                          }
                          message={d?.body.replaceAll("فیلتر", "*****")}
                          date={fromNowPersian(new Date(d?.created))}
                          setMessageInput={setMessageInput}
                          convId={isIdConversation}
                        />
                      )
                    ) : d?.showUser ? (
                      <ChatBoxReciver
                        x={d?.showUser}
                        reciverMenu={true}
                        isFav={d?.is_fav}
                        setMessageId={setMessageId}
                        setEditButton={setEditButton}
                        firstIndex={i}
                        messageId={d?.id}
                        setRefreshMessageList={setRefreshMessageList}
                        reciver={
                          d?.sender?.firstName + " " + d?.sender?.lastName
                        }
                        avatar={
                          API?.defaults?.baseURL +
                          d?.sender?.personalInfoProfileRelated[0]?.avatar
                        }
                        message={d?.body?.replaceAll("فیلتر", "*****")}
                        date={fromNowPersian(new Date(d?.created))}
                      />
                    ) : (
                      <ChatBoxReciver2
                        x={d?.showUser}
                        reciverMenu={true}
                        isFav={d?.is_fav}
                        setMessageId={setMessageId}
                        setEditButton={setEditButton}
                        firstIndex={i}
                        messageId={d?.id}
                        setRefreshMessageList={setRefreshMessageList}
                        reciver={
                          d?.sender?.firstName + " " + d?.sender?.lastName
                        }
                        avatar={
                          API?.defaults?.baseURL +
                          d?.sender?.personalInfoProfileRelated[0]?.avatar
                        }
                        message={d?.body.replaceAll("فیلتر", "*****")}
                        date={fromNowPersian(new Date(d?.created))}
                      />
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex items-center justify-center pt-12 w-full">
                <h2 className="text-xs text-caspian-gray4">پیام وجود ندارد</h2>
              </div>
            )}
          </div>
        </div>
        <ChatInput
          // setFile={setFile}
          fileHandler={fileHandler}
          setMessageInput={setMessageInput}
          setEditButton={setEditButton}
          setMessageId={setMessageId}
          editMessageHandler={editMessageHandler}
          editButton={editButton}
          messageInput={messageInput}
          handleInputChange={handleInputChange}
          messageHandler={messageHandler}
        />
      </div>
    </div>
  );
};

export default MainContentChat;
