import ActionPlanNavbar from "../ActionPlanNavbar";
import { useOutletContext } from "react-router-dom";
// import MainCsr from "./1/MainCsr";
// import MainCsr2 from "./2/MainCsr2";
// import MainProfileCsr from "./profile-csr/MainProfileCsr";
// import MainEvalution from "./evolution/MainEvalution";

const MainDashboard = () => {
  const pmo = useOutletContext();
  return (
    <div>
      <ActionPlanNavbar pmo={pmo} buttonActive="dashboard" />
      {/* <MainCsr /> */}
      {/* <MainCsr2 /> */}
      {/* <MainProfileCsr /> */}
      {/* <MainEvalution /> */}
    </div>
  );
};

export default MainDashboard;
