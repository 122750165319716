import React, { useState } from "react";
import Draggable from "react-draggable";
import { PiCaretUpBold, PiX } from "react-icons/pi";
import DetailsGenuneExternalContact from "./DetailsGenuneExternalContact";
import API from "../../../../api/API";

const MainGenuneExternalContact = ({ setShowPersonInternalContact, fullData }) => {
  const [heightHandler, setHeightHandler] = useState(false);

  return (
    <Draggable
      handle="strong"
      bounds={{ top: 0, bottom: 500, right: 740, left: -250 }}
    >
      <div
        className={`w-[280px] min-h-fit h-fit max-h-[80vh] fixed left-24 top-14 rounded-md shadow-[0_0_10px_#00000050] z-[47] flex flex-col overflow-auto bg-caspian-surface`}
      >
        <strong className="flex items-center justify-between cursor-move p-2 border-b border-b-caspian-gray2 bg-caspian-surface">
          <div className="flex items-center gap-2">
            <img
              src={(fullData?.placementRelated?.placementProfileRelated?.personalInfoProfileRelated[0]?.avatar)
                ? API?.defaults?.baseURL + (fullData?.placementRelated?.placementProfileRelated?.personalInfoProfileRelated[0]?.avatar)
                : '/assets/images/users/not-picture.svg'}
              alt=""
              className="w-8 h-8 rounded-full "
            />
            <div className="flex flex-col gap-1">
              <span className="text-xs font-bold">{fullData?.placementRelated?.placementProfileRelated?.firstName} {fullData?.placementRelated?.placementProfileRelated?.lastName}</span>
              <span className="text-xs font-thin text-caspian-gray4">
                {fullData?.sectionRelated?.title}
              </span>
            </div>
            <div className="flex items-center gap-1">
              <span className="text-xs font-bold">{fullData?.name} {fullData?.family}</span>
              <span className="text-xs font-thin text-caspian-gray4">
                (حقیقی)
              </span>
            </div>
          </div>
          <PiX
            onClick={() => setShowPersonInternalContact(false)}
            className="cursor-pointer"
          />
        </strong>
        <div
          className={`flex flex-col gap-2 p-2 ${heightHandler
            ? "min-h-fit h-[120%] max-h-full"
            : "h-full"
            }`}
        >
          {heightHandler ? (
            <div className="w-full bg-caspian-surface flex items-center justify-center absolute bottom-0 left-0 right-0 mx-auto py-2">
              <PiCaretUpBold
                onClick={() => setHeightHandler(false)}
                className="text-lg text-caspian-secondary-500 cursor-pointer"
              />
            </div>
          ) : (
            <div className="w-full bg-caspian-surface flex items-center justify-center absolute bottom-0 left-0 right-0 mx-auto py-2">
              <span
                onClick={() => setHeightHandler(true)}
                className="font-bold text-xs text-caspian-secondary-500 cursor-pointer"
              >
                بیشتر
              </span>
            </div>
          )}
          <DetailsGenuneExternalContact fullData={fullData} heightHandler={heightHandler} />
        </div>
      </div >
    </Draggable >
  );
};

export default MainGenuneExternalContact;
