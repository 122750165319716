import React, { useState } from 'react';
import SingleListItem from './SingleListItem';
import { PiCircleNotch } from 'react-icons/pi';
import MainObservationDetailsTabs from '../../input-observation-details-tabs/MainObservationDetailsTabs';
// import ObservationDetails from './ObservationDetails';

const questionList = [
    {
        id: 1,
        title: 'سوال :سازمان شما عوامل داخلی و خارجی موثر بر سیستم مدیریت ایمنی و بهداشت شغلی را شناسایی و ارزیابی میکند ؟',
        cri: 'معیار : شناسایی عوامل داخلی و خارجی ، درنظر گرفتن نیاز های ذینفعان و ارزیابی منظم برای بهبود سیستم ایمنی و بهداشت شغلی است.'
    },
    {
        id: 2,
        title: 'سوال :سازمان شما عوامل داخلی و خارجی موثر بر سیستم مدیریت ایمنی و بهداشت شغلی را شناسایی و ارزیابی میکند ؟',
        cri: 'معیار : شناسایی عوامل داخلی و خارجی ، درنظر گرفتن نیاز های ذینفعان و ارزیابی منظم برای بهبود سیستم ایمنی و بهداشت شغلی است.'
    },
    {
        id: 3,
        title: 'سوال :سازمان چگونه دامنه و زمینه سیستم مدیریت کیفیت خود را تعیین و مستند می  کند تا اطمینان حاصل کند نیاز ذینفعان به درستی پوشش داده میشود',
        cri: 'معیار :روش های برای تعیین و مستند سازی دامنه و زمینه مدیریت کیفیت برای پوشش نیاز ها و انتظارات'

    },
]
const MainListComponent = ({showComponent,setShowComponent}) => {
    const [show,setShow]=useState(false);
    const [isComplete,setIsComplete]=useState(false);
    const [isLoading,setIsLoading]=useState(false);
    return (
        <div className='flex flex-col gap-3 justify-between'>
            {/* list and chart */}
            <div className='flex gap-5 justify-between'>
                {/* 33% */}
                <div className='basis-[50%] xl:basis-[40%] pl-2 flex flex-col gap-2 h-[65vh] 2xl:h-[70vh] overflow-y-auto'>
                    {
                        questionList?.length > 0 && questionList?.map((q, i) =>
                            <SingleListItem
                                key={q?.id}
                                {...q}
                                index={i}
                            />
                        )
                    }
                </div>
                {/* chart */}
                <div className='basis-[50%] xl:basis-[56%] h-[65vh] 2xl:h-[70vh] text-center rounded-[26px] shadow-[0px_4px_4px_0px_#00000040] bg-caspian-surface'>
                    chart
                </div>
            </div>
            {/* confirm */}
            <div className='border border-caspian-gray1 rounded-lg bg-caspian-surface p-2 xl:p-2.5 mb-0'>
                <button 
                onClick={()=>setShowComponent('detailsTab')}
                disabled={isComplete || isLoading}
                className='text-caspian-surface bg-[#3C77A2] disabled:bg-[#A4A4A4] flex justify-center items-center gap-1 py-2 px-4 rounded-lg text-xs xl:text-sm'> 
                <span>ارسال به سرممیز جهت بررسی و تایید</span>
                {isLoading && <PiCircleNotch className='text-caspian-surface animate-spin' />}
                </button>
            </div>
            {
                showComponent==='detailsTab' && <MainObservationDetailsTabs />
            }
        </div>
    );
}

export default MainListComponent;
