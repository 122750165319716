import React from 'react';
import { PiCalendar } from 'react-icons/pi';

const InfoAuditPlan = () => {
    return (
        <div className='shadow-[0px_4px_4px_0px_#00000040] bg-caspian-surface flex flex-col
                            rounded-lg basis-[50%] lg:basis-[45%] 2xl:basis-[30%]'>
            <p className='text-sm p-2'>
                مدیریت بحران و پدافند غیرعامل
            </p>
            <div className="p-2 flex justify-between items-center border-t border-caspian-gray2">
                <div className='border border-caspian-gray1 p-1 flex gap-2 items-center rounded-lg text-xs xl:text-sm text-caspian-gray4'>
                    <PiCalendar className='text-[#45706E] text-base xl:text-lg' />
                    <span>دوشنبه </span>
                    <span>1403/05/22</span>
                </div>
                <div className='flex gap-2'>
                    <div className='border border-caspian-gray1 p-1 flex gap-2 items-center rounded-lg text-xs xl:text-sm text-caspian-gray4'>
                        <div className='w-1.5 h-1.5 bg-[#10B981] rounded-full'></div>
                        <span>شروع </span>
                        <span>9:00</span>
                    </div>
                    <div className='border border-caspian-gray1 p-1 flex gap-2 items-center rounded-lg text-xs xl:text-sm text-caspian-gray4'>
                        <div className='w-1.5 h-1.5 bg-caspian-error2 rounded-full'></div>
                        <span>پایان </span>
                        <span>10:00</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InfoAuditPlan;
