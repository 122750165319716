
const MainChartWorkProgressReport = ({ fullData }) => {
    return (
        <div className='flex items-center w-full'>
            <div className='flex flex-col justify-between w-full gap-6'>

                <div className='flex items-center justify-between gap-2'>
                    <span className='text-xs whitespace-nowrap 2xl:text-sm'>درصد پیشرفت تا قبل این مرحله</span>

                    <div className='w-full h-[1px] border border-dashed border-caspian-darkgray-100'></div>
                    <div className='flex items-center gap-5'>
                        <span className='text-xs 2xl:text-sm'>{fullData?.taskRelated?.progress}%</span>
                        <div className='w-3 h-3 rounded-sm bg-caspian-darkblue-700'></div>
                    </div>
                </div>

                <div className='flex items-center justify-between gap-2'>
                    <span className='text-xs whitespace-nowrap 2xl:text-sm'>درصد پیشرفت این مرحله </span>

                    <div className='w-full h-[1px] border border-dashed border-caspian-darkgray-100'></div>

                    <div className='flex items-center gap-5'>
                        <span className='text-xs 2xl:text-sm'>{fullData?.progress_taskreportProfile[0]?.progress_percent}%</span>
                        <div className='w-3 h-3 rounded-sm bg-caspian-blue-400 '></div>
                    </div>
                </div>


                <div className='flex items-center justify-between gap-2'>
                    <span className='text-xs whitespace-nowrap 2xl:text-sm'>درصد پیشرفت تجمعی تا این مرحله</span>

                    <div className='w-full h-[1px] border border-dashed border-caspian-darkgray-100'></div>

                    <div className='flex items-center gap-5'>
                        <span className='text-xs 2xl:text-sm'>
                            {(fullData?.progress_taskreportProfile[0]?.progress_percent) + (fullData?.taskRelated?.progress)}%
                        </span>
                        <div className='w-3 h-3 rounded-sm bg-[#00C02A]'></div>
                    </div>
                </div>

                <div className='flex flex-col items-end w-full gap-3'>
                    <div className='flex items-center w-full'>

                        {(100 - ((fullData?.progress_taskreportProfile[0]?.progress_percent) + (fullData?.taskRelated?.progress))) > 0 &&
                            <div
                                style={{ width: `${100 - ((fullData?.progress_taskreportProfile[0]?.progress_percent) + (fullData?.taskRelated?.progress))}%` }}
                                // w-[${100 - ((fullData?.progress_taskreportProfile[0]?.progress_percent) + (fullData?.taskRelated?.progress))}%]
                                className={` flex items-center justify-center text-xs h-5 bg-caspian-gray1`}>
                                %{100 - ((fullData?.progress_taskreportProfile[0]?.progress_percent) + (fullData?.taskRelated?.progress))}
                            </div>
                        }


                        {/* درصد پیشرفت این مرحله */}
                        {fullData?.progress_taskreportProfile[0]?.progress_percent > 0 &&
                            <div className={` h-5 bg-caspian-blue-400 flex items-center justify-center text-xs`}
                                style={{ width: `${fullData?.progress_taskreportProfile[0]?.progress_percent}%` }}>

                                %{fullData?.progress_taskreportProfile[0]?.progress_percent}

                            </div>
                        }

                        {/* درصد پیشرفت تا قبل از این مرحله */}
                        {fullData?.taskRelated?.progress > 0 &&
                            <div className={`h-5 bg-caspian-darkblue-700 flex items-center justify-center text-caspian-surface text-xs`}
                                style={{ width: `${fullData?.taskRelated?.progress}%` }}>
                                {fullData?.taskRelated?.progress}
                            </div>
                        }
                    </div>

                    <div className={'flex items-center justify-end w-full relative'}>

                        {/* w-[${((fullData?.progress_taskreportProfile[0]?.progress_percent) + (fullData?.taskRelated?.progress)) / 2}%] */}
                        <div className={`flex items-center`}
                            style={{ width: `${(((fullData?.progress_taskreportProfile[0]?.progress_percent) + (fullData?.taskRelated?.progress)) / 2)}%` }}>
                            <div className="w-[2px] h-5 bg-[#00C02A]"></div>
                            <div className={`w-full flex items-center justify-center text-xs h-1 bg-[#00C02A]`}></div>
                        </div>

                        <div className="absolute flex items-center justify-center"
                            style={{ width: `${((fullData?.progress_taskreportProfile[0]?.progress_percent) + (fullData?.taskRelated?.progress))}%` }}>
                            <span className={`text-xs text-center 2xl:text-sm w-fit bg-caspian-surface px-1`}>
                                %{(fullData?.progress_taskreportProfile[0]?.progress_percent) + (fullData?.taskRelated?.progress)}
                            </span>
                        </div>

                        {/* w-[${(((fullData?.progress_taskreportProfile[0]?.progress_percent) + (fullData?.taskRelated?.progress)) / 2)}%] */}
                        <div className={`flex items-center`}
                            style={{ width: `${(((fullData?.progress_taskreportProfile[0]?.progress_percent) + (fullData?.taskRelated?.progress)) / 2)}%` }}>
                            <div className={`w-full flex items-center justify-center text-xs h-1 bg-[#00C02A]`}></div>
                            <div className="w-[2px] h-5 bg-[#00C02A]"></div>
                        </div>

                    </div>

                </div>
            </div>
        </div >
    )
}

export default MainChartWorkProgressReport