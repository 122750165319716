import React, { useContext, useEffect, useState } from "react";
import SearchInput from "./SearchInput";
import FilterDropdown from "./FilterDropdown";
import API from "../../../api/API";
import { AuthContext } from "../../../context/auth/AuthContext";
import MainPersonInternalContact from "./person-internal-contact/MainPersonInternalContact";
import MainSectionInternalContact from "./section-internal-contact/MainSectionInternalContact";

const InternalContact = ({ setShowChatPerson }) => {
  const { token } = useContext(AuthContext);
  const [fullData, setFullData] = useState([-1]);
  const [filterPersonal, setFilterPersonal] = useState(true);
  const [filterSection, setFilterSection] = useState(false);
  const [showPersonInternalContact, setShowPersonInternalContact] = useState(false);
  const [showSectionInternalContact, setShowSectionInternalContact] = useState(false);
  const [idProfile, setIdProfile] = useState(null)

  useEffect(() => {
    const personalHandler = async () => {
      if (token) {
        await API.get("/api/v1/comunication/internal/personal/", {
          headers: { Authorization: token },
        })
          .then((d) => {
            console.log(d.data);
            setFullData(d.data);
          })
          .catch((err) => {
            console.log("error");
          });
      }
    };
    personalHandler();
  }, [token]);

  const personalHandler = async () => {
    if (token) {
      await API.get("/api/v1/comunication/internal/personal/", {
        headers: { Authorization: token },
      })
        .then((d) => {
          console.log(d.data);
          setFullData(d.data);
        })
        .catch((err) => {
          console.log("error");
        });
    }
  };

  const sectionHandler = async () => {
    if (token) {
      await API.get("/api/v1/comunication/internal/section/", {
        headers: { Authorization: token },
      })
        .then((d) => {
          console.log(d.data);
          setFullData(d.data);
        })
        .catch((err) => {
          console.log("error");
        });
    }
  };

  useEffect(() => {
    if (filterPersonal) {
      personalHandler();
    }
  }, [filterPersonal]);
  useEffect(() => {
    if (filterSection) {
      sectionHandler();
    }
  }, [filterSection]);

  const [searchMember, setSearchMember] = useState("");
  let search = [-1];
  if (filterPersonal) {
    search = searchMember
      ? fullData?.filter((um) =>
        (um?.profileRelated?.firstName + " " + um?.profileRelated?.lastName)
          ?.toLocaleLowerCase()
          ?.includes(searchMember)
      )
      : fullData;
  } else if (filterSection) {
    search = searchMember
      ? fullData?.filter((um) =>
        um?.emailRelated?.toLocaleLowerCase()?.includes(searchMember)
      )
      : fullData;
  }
  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-2">
        <SearchInput
          searchMember={searchMember}
          setSearchMember={setSearchMember}
        />
        <FilterDropdown
          filterPersonal={filterPersonal}
          filterSection={filterSection}
          setFilterPersonal={setFilterPersonal}
          setFilterSection={setFilterSection}
        />
      </div>
      {search[0] === -1 ? (
        <div className="flex items-center justify-center w-full pt-12">
          <span className="text-xs text-caspian-gray4">در حال بارگزاری...</span>
        </div>
      ) : search?.length < 1 ? (
        <div className="flex items-center justify-center w-full pt-12">
          <span className="text-xs text-caspian-gray4">موردی یافت نشد...</span>
        </div>
      ) : (
        <div className="flex flex-col gap-0 w-full max-h-full h-full">
          {showPersonInternalContact && (
            fullData?.map((item, index) =>
              item?.id === idProfile &&
              <MainPersonInternalContact
                key={index}
                fullData={item}
                setShowChatPerson={setShowChatPerson}
                setShowPersonInternalContact={setShowPersonInternalContact}
              />
            )
          )}
          {showSectionInternalContact && (
            fullData?.map((item, index) =>
              item?.id === idProfile &&
              <MainSectionInternalContact
                key={index}
                fullData={item}
                setShowSectionInternalContact={setShowSectionInternalContact}
              />
            )
          )}
          {filterPersonal ? (
            search?.map((contact, i) => (
              <div
                key={i}
                className="flex items-center gap-3 border-b border-b-caspian-gray2/50 py-2 last:border-b-0"
              >
                <span
                  onClick={() => {
                    setShowPersonInternalContact(true)
                    setIdProfile(contact?.id)
                  }}
                  className="text-xs font-bold cursor-pointer"
                >
                  {contact?.profileRelated?.firstName +
                    " " +
                    contact?.profileRelated?.lastName}
                </span>
                <span className="text-xs text-caspian-gray4">{contact?.sectionRelated?.title}</span>
              </div>
            ))
          ) : filterSection ? (
            search?.map((contact, i) => (
              <div
                key={i}
                className="flex items-center gap-3 border-b border-b-caspian-gray2/50 py-2 last:border-b-0"
              >
                <span
                  onClick={() => {
                    setShowSectionInternalContact(true)
                    setIdProfile(contact?.id)
                  }}
                  className="text-xs font-bold cursor-pointer"
                >
                  {contact?.sectionRelated?.title}
                </span>
              </div>
            ))
          ) : (
            <div className="flex items-center justify-center w-full pt-12">
              <span className="text-xs text-caspian-gray4">
                در انتظار بررسی
              </span>
            </div>
          )}
        </div>
      )
      }
    </div >
  );
};

export default InternalContact;
