import React, { useEffect, useState } from 'react'
import { useAppContext } from '../../context/app-context/AppContext';
import { Link } from 'react-router-dom';
import ToolTip from './ToolTip';

const NewListItemSingle = ({ active, title, link, icon, buttonActive, }) => {
    const { setNavbarRisk } = useAppContext();

    const [showToolTip, setShowToolTip] = useState(false)
    useEffect(() => {
        if (active === "teams") {
            setNavbarRisk("teams");
        } else if (active === "risks") {
            setNavbarRisk("risks");
        } else if (active === "activities") {
            setNavbarRisk("activities");
        }
    }, [active, setNavbarRisk]);

    return (
        <div className='relative '>
            <Link
                onMouseEnter={() => setShowToolTip(true)}
                onMouseLeave={() => setShowToolTip(false)}
                to={link}
                className={`
                    flex items-center p-1.5 cursor-pointer rounded-full text-sm bg-[#fff]
                    ${buttonActive === active && 'border-2 border-caspian-secondary-600 '}`}
            >
                <div className={`text-2xl
                ${buttonActive === active ? "text-caspian-secondary-600" : ""
                    }`}>
                    {icon}
                </div>
            </Link>
            {showToolTip && <ToolTip title={title} />}
        </div>
    )
}

export default NewListItemSingle