import { useContext,  useState } from "react";
import { PiCheckLight, PiTrash, PiXLight } from "react-icons/pi";
import { AuthContext } from "../../../../context/auth/AuthContext";
import API from "../../../../api/API";
import TooltipReplay from "./TooltipReplay";

const ReplySingle = ({ item, setRefresh }) => {
  const { token, user } = useContext(AuthContext)

  const [showText, setShowText] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(false)
  const [idReplay, setIdReplay] = useState(null)
  const [showPostition, setShowPostition] = useState(false)
  const [showJob, setShowJob] = useState(false)

  let timerPosition;
  const mouseEnterPosition = (idReply) => {
    setShowPostition(true);
    setIdReplay(idReply)
  };

  const mouseLeavePosition = () => {
    clearTimeout(timerPosition);
    setShowPostition(false);
  };

  let timerJob;
  const mouseEnterJob = (idReply) => {
    setShowJob(true)
    setIdReplay(idReply)
  };
  const mouseLeaveJob = () => {
    clearTimeout(timerJob);
    setIdReplay(null)
    setShowJob(false)
  };

  const deleteReplay = async (id) => {
    if (!token) return;
    try {
      const res = await API.delete(`/api/v1/actionplan/comment/${id}/`,
        { headers: { Authorization: token } }
      )
      console.log(res?.data)
      setRefresh(1)
    } catch (error) {
      console.log(error)
    }
  }


  const handleDeleteMessage = (id, value) => {
    setDeleteMessage((prev) => ({ ...prev, [id]: value }));
  };

  return (
    <div className=" flex flex-col w-full">
      {item?.reply_comment?.map((item, index) => (
        <div key={item?.id} className="relative flex flex-col items-center gap-2 pb-4 w-full">
          <div className="flex justify-between items-center w-full">
            <div className="rounded-tr-lg border-t border-r absolute -right-4 top-4 w-4 h-full border-caspian-gray2/90"></div>

            <div className="flex items-center gap-2">
              <img alt="" className="rounded-full w-10 h-10"
                src={(item?.creator_placement?.placementProfileRelated?.personalInfoProfileRelated[0]?.avatar)
                  ? API?.defaults?.baseURL + (item?.creator_placement?.placementProfileRelated?.personalInfoProfileRelated[0]?.avatar)
                  : "/assets/images/users/not-picture.svg"
                }
              />
              <div className="flex flex-col">
                <div className="flex items-center gap-2 ">
                  <h3 className="text-caspian-secondary-900 text-xs">{item?.creator?.firstName} {item?.creator?.lastName}</h3>


                  <div className="relative w-fit">
                    <span className="text-caspian-gray text-xs "
                      onMouseEnter={() => mouseEnterPosition(item?.id)}
                      onMouseLeave={mouseLeavePosition}>
                      {item?.creator_placement?.placementPositionRelated?.title?.split(" ").length > 2
                        ? item?.creator_placement?.placementPositionRelated?.title?.split(" ")?.slice(0, 2)?.join(" ") + "..."
                        : item?.creator_placement?.placementPositionRelated?.title
                      }
                    </span>
                    {showPostition && item?.id === idReplay && item?.creator_placement?.placementPositionRelated?.title?.split(" ").length > 2 &&
                      <TooltipReplay reply={true} index={index} >
                        {item?.creator_placement?.placementPositionRelated?.title}
                      </TooltipReplay>
                    }
                  </div>
                </div>

                <div className="relative w-full">
                  <span
                    className="text-caspian-gray text-xs"
                    onMouseEnter={() => mouseEnterJob(item?.id)}
                    onMouseLeave={mouseLeaveJob}
                  >
                    {Array.isArray(item?.creator_title) && item?.creator_title?.length > 1
                      ? item?.creator_title?.slice(0, 1)?.join(", ") + "..."
                      : item?.creator_title?.join(", ")
                    }
                  </span>

                  {showJob && Array.isArray(item?.creator_title) && item?.id === idReplay && item?.creator_title.length > 1 && (
                    <TooltipReplay index={index} reply={true} showJob={showJob}>
                      {item.creator_title.join(", ")}
                    </TooltipReplay>
                  )}
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center ">
              <p className="text-caspian-gray text-xs">
                {new Date(item?.created_at).toLocaleDateString("fa-IR")}
              </p>
            </div>
          </div>
          <p className="text-xs w-full ">
            {showText ? item?.text_comment(0, 120) : item?.text_comment}
            {item?.text_comment?.length > 120 &&
              (<span
                onClick={() => setShowText(!showText)}
                className="text-caspian-secondary-600 mx-1 text-[12px] cursor-pointer">
                {showText ? "بیشتر" : "کمتر"}
              </span>
              )}
          </p>

          <div className="flex items-center w-full justify-end">

            {(item?.creator_placement?.id === user?.id) &&
              (!deleteMessage[item?.id] ? (
                <button
                  className="flex gap-2 items-center cursor-pointer"
                  onClick={() => handleDeleteMessage(item?.id, true)}>
                  <PiTrash className="text-caspian-error2 text-lg" />
                </button>
              ) : (
                <div className="flex items-center gap-2">
                  <button
                    className="text-caspian-error2 rounded-full text-xl"
                    onClick={() => deleteReplay(item?.id)}>
                    <PiCheckLight />
                  </button>

                  <button
                    className="text-caspian-gray4 text-xl"
                    onClick={() => handleDeleteMessage(item?.id, false)}>
                    <PiXLight />
                  </button>
                </div>)
              )}
          </div>
        </div>
      ))
      }
    </div >
  );
};

export default ReplySingle;
