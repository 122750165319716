import React, { useContext, useState } from 'react'
import Modal from '../../../../modal/Modal'
import { PiX } from 'react-icons/pi'
import MainFormWorkProgress from './form-work-progress/MainFormWorkProgress'
import MainFormCost from './form-cost/MainFormCost'
import MainFormMaterial from './form-material/MainFormMaterial'
import { AuthContext } from '../../../../../context/auth/AuthContext'
import API from '../../../../../api/API'

const MainCreateFormReport = ({ reportId, setShowModalFrom, valuePercent, setCostListObject, costListObject }) => {
    const { token } = useContext(AuthContext);
    const [activeTab, setActiveTab] = useState("workProgress");
    const [dataProgress, setDataProgress] = useState({})
    const [succesData, setSuccesData] = useState(false)
    const [idProgress, setIdProgress] = useState(null)
    const [dataCost, setDataCost] = useState({})
    const [succesDataCost, setSuccesDataCost] = useState(false)
    const [idCost, setIdCost] = useState(null)
    const [recordCost, setRecordCost] = useState([])
    const handleReport = async () => {
        if (!token || !reportId) return

        let formData = {
            reportSelected_id: reportId,
            link: `/action-plan/reports/${reportId}`
        }
        try {
            const res = await API.post('/api/v1/actionplan/activityReport', formData,
                {
                    headers: {
                        Authorization: token,
                        "content-type": "multipart/form-data",
                        Accept: "application/json"
                    }
                }
            )
            console.log(res?.data)

        } catch (error) {
            console.log(error)
        }
    }
    const deleteReport = async () => {
        if (!token) return
        try {
            const res = await API.delete(`/api/v1/actionplan/taskreport/${reportId}/`,
                { headers: { Authorization: token } }
            )
            console.log(res?.data)
            setShowModalFrom(false)
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <Modal>
            <div className='flex min-h-[96vh] h-[96vh] w-[400px] md:w-[500px] flex-col items-start justify-around rounded-lg bg-caspian-surface'>

                <div className='flex items-center justify-between w-full px-5 py-2 border-b-2 border-b-caspian-graygreen-200'>
                    <span className='py-2 text-xs 2xl:text-base'>
                        گزارش‌دهی
                    </span>

                    <div className='flex items-center gap-3'>
                        <PiX className='text-xl cursor-pointer text-caspian-gray4'
                            onClick={deleteReport} />
                    </div>
                </div>
                <div className='flex flex-col items-center w-full '>
                    <div className="flex items-start py-3 px-5 gap-3 w-full">

                        <div className='flex flex-col items-center justify-center gap-3' activetab={activeTab}>
                            <div className={`w-3 h-3 rounded-full
                                ${activeTab === "workProgress" ? "bg-caspian-secondary-600" : "bg-caspian-gray1"} `}>
                            </div>
                            <span className={`text-xs whitespace-nowrap 
                                ${activeTab === "workProgress" ? "text-caspian-secondary-600" : "text-caspian-gray1"}`}>
                                پیشرفت کار
                            </span>
                        </div>


                        <div className='pt-[5px] w-full'>
                            <div className='w-full h-[1px] bg-caspian-gray1 '></div>
                        </div>


                        <div className='flex flex-col items-center justify-center gap-3' activetab={activeTab}>
                            <div className={`w-3 h-3 rounded-full
                                 ${activeTab === "cost" ? "bg-caspian-secondary-600" : "bg-caspian-gray1"}`}>
                            </div>
                            <span className={`text-xs whitespace-nowrap 
                                ${activeTab === "cost" ? "text-caspian-secondary-600" : "text-caspian-gray1"}`}>
                                هزینه
                            </span>
                        </div>


                        <div className='pt-[5px] w-full'>
                            <div className='w-full h-[1px] bg-caspian-gray1 '></div>
                        </div>


                        <div className='flex flex-col items-center justify-center gap-3' activetab={activeTab}>
                            <div className={`w-3 h-3 
                                ${activeTab === "material" ? "bg-caspian-secondary-600" : "bg-caspian-gray1"} rounded-full`}>
                            </div>
                            <span className={`text-xs whitespace-nowrap
                                 ${activeTab === "material" ? "text-caspian-secondary-600" : "text-caspian-gray1"}`}>
                                اقلام مصرفی
                            </span>
                        </div>

                    </div>


                    <div className="flex flex-col w-full gap-4">
                        {activeTab === "workProgress" ? (

                            <MainFormWorkProgress dataProgress={dataProgress} setDataProgress={setDataProgress}
                                succesData={succesData} setSuccesData={setSuccesData} setActiveTab={setActiveTab}
                                valuePercent={valuePercent} reportId={reportId} idProgress={idProgress} setIdProgress={setIdProgress} />

                        ) : activeTab === "cost" ? (
                            <MainFormCost idCost={idCost} setIdCost={setIdCost} recordCost={recordCost} succesDataCost={succesDataCost}
                                setRecordCost={setRecordCost} dataCost={dataCost} setDataCost={setDataCost} setSuccesDataCost={setSuccesDataCost}
                                setActiveTab={setActiveTab} reportId={reportId} setCostListObject={setCostListObject}
                                costListObject={costListObject} />

                        ) : (
                            activeTab === "material" && <MainFormMaterial setShowModalFrom={setShowModalFrom} handleReport={handleReport} setActiveTab={setActiveTab} reportId={reportId} />
                        )}
                    </div>
                </div>
            </div>
        </Modal >
    )
}

export default MainCreateFormReport