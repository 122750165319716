import React from 'react';
import Modal from '../../../../modal/Modal';
import { PiX } from 'react-icons/pi';
import { data } from '../found-list/Data';

const MainProfileFound = ({ showProfile, setShowProfile }) => {
    const filterData = data?.find(d => d?.id === showProfile);
    console.log(filterData);
    return (
        <Modal>
            <div className='w-[82%] bg-caspian-surface p-4 rounded-[26px] flex flex-col gap-2'>
                <div className='w-full flex justify-end'>
                    <PiX className='cursor-pointer' onClick={() => setShowProfile(-1)} />
                </div>
                <div className='flex flex-col gap-2'>
                    {/* info */}
                    <div className={`${filterData?.type === 'OBS' ? 'bg-caspian-blue-400' :
                        filterData?.type === 'OK' ? 'bg-[#ADEBBB]' :
                            filterData?.type === 'NC' ? 'bg-caspian-orange-200' :
                                'bg-caspian-darkgray-200'
                        } flex gap-4 p-2 items-center px-4 rounded`}>
                        <span className='font-bold text-sm 2xl:text-lg'>
                            {filterData?.type}
                        </span>
                        <span className='font-bold text-sm 2xl:text-base'>
                            {filterData?.code}
                        </span>
                        <div className='text-xs font-bold'>
                            <span className='text-[#928F8F]'>واحد: </span>
                            <span> دفتر مدیریت مصرف آب و کاهش آب بدون درآمد </span>
                        </div>
                    </div>
                    {/* question and criteria */}
                    <div className='flex items-start gap-1'>
                        <span className='w-fit text-xs font-bold text-[#928F8F] leading-6'>سوال:</span>
                        <div className='flex flex-col gap-1 text-xs leading-6'>
                            <span className='text-right'>
                                موضوع: {filterData?.question?.title}
                            </span>
                            <span className='text-[#3C77A2] text-right'>
                                معیار: {filterData?.question?.criteria}
                            </span>
                        </div>
                    </div>
                    {/* found */}
                    <div className='flex items-start gap-1'>
                        <span className='w-fit text-xs font-bold text-[#928F8F] leading-6'>یافته:</span>
                        <span className='text-xs leading-6'>
                            {filterData?.description}
                        </span>
                    </div>
                </div>
                {/* standard and clause */}
                <div className='flex flex-col'>
                    {filterData?.requirement?.map((r, i) => <div className='flex gap-1 border-b p-2 border-[#CCCACA] text-xs' key={i}>
                        <p className='w-[100px] whitespace-nowrap'>{r?.standard}</p>
                        <div className='flex gap-4'>
                            {
                                r?.clause?.map((c, i) => <span>{c?.name}</span>)
                            }
                        </div>
                    </div>)
                    }
                </div>
            </div>
        </Modal>
    );
}

export default MainProfileFound;
