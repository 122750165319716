import React, { useState } from 'react';
import Modal from '../../../../../modal/Modal';
import { PiCircleNotch, PiX } from 'react-icons/pi';

const ImproveModal = ({ setShowImprove, setImproveList, improveList, id, setId }) => {
    console.log('id', id);
    let existData = {};
    if (id + 1) {
        existData = improveList?.find((s, i) => s?.id === id);
    }
    console.log(existData);
    const [title, setTitle] = useState(id ? existData?.title : '');
    const [describtion, setDescribtion] = useState(id ? existData?.describtion : '');
    const [isLoading, setIsLoading] = useState(false);
    const handleSubmit = () => {
        if (!title || !describtion) {
            return
        }
        if (id) {
            improveList[id - 1] = { ...improveList[id - 1], title, describtion }
            setId('');
        }
        else {
            setImproveList((s) => [...s, { id: improveList?.length + 1, title, describtion }])
        }
        setIsLoading(true);
        setShowImprove(false)
    }

    console.log(improveList);
    return (
        <Modal>
            <div className='bg-caspian-surface flex flex-col gap-2 w-[40%] rounded-lg'>
                <div className='flex justify-between items-center border-b border-[#ECECEC] p-4'>
                    <span>نقاط قوت</span>
                    <PiX className='cursor-pointer' onClick={() => {
                        setShowImprove(false)
                        setId('')
                    }} />
                </div>
                <div className='flex flex-col gap-2 px-4 text-xs'>
                    <span className='text-sm text-caspian-gray4'>عنوان</span>
                    <input
                        defaultValue={id ? existData?.title : ''}
                        className='p-2 border border-[#E5E7EB] text-xs placeholder:text-xs rounded-lg'
                        placeholder='عنوان مورد نظر خود را یادداشت کنید.'
                        onChange={(e) => setTitle(e.target.value)} type='text'></input>
                </div>
                <div className='flex flex-col gap-2 px-4 text-xs'>
                    <span className='text-sm text-caspian-gray4'>توضیحات</span>
                    <textarea
                        defaultValue={id ? existData?.describtion : ''}
                        placeholder='نقاط قوت مورد نظر خود را یادداشت کنید.'
                        className='p-2 resize-none border border-[#E5E7EB] text-xs placeholder:text-xs rounded-lg'
                        onChange={(e) => setDescribtion(e.target.value)} cols="8" rows="8"></textarea>
                </div>
                <button
                    onClick={handleSubmit}
                    className='text-caspian-surface bg-[#3C77A2] m-4 flex justify-center gap-2 text-sm p-2 w-[80px] rounded-lg'>
                    <span>ثبت</span>
                    {isLoading && <span>
                        <PiCircleNotch className='animate-spin' />
                    </span>}
                </button>
            </div>
        </Modal >
    );
}

export default ImproveModal;
