import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/auth/AuthContext";
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import API from "../../../api/API";
import AuditPlaning from "./AuditPlaning";
import MainAcceptAudit from "./accept-audit/MainAcceptAudit";
import Loading from "../../../Loading";
import MainAuditPlannigList from "../audit-plannig-list/MainAuditPlannigList";

const MainAuditPlanning = () => {
  const [showCreateAuditForm, setShowCreateAuditForm] = useState(false);
  // const isAuditer = useOutletContext();
  const { id, idProfile, idChecklist, idSection } = useParams();

  const { token, user } = useContext(AuthContext);
  const navigate = useNavigate();
  const refresh = useLocation();
  const [profileData, setProfileData] = useState([-1]);
  const [refreshProfile, setRefreshProfile] = useState(0);
  const [isAuditer, setIsAuditer] = useState(false);
  const [isChecklist, setIsChecklist] = useState(false);

  const [showView, setShowView] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get("view");
  useEffect(() => {
    if (query === 'list') {
      setShowView(true);
    }
    else {
      setShowView(false);
    }
  }, [query, showView])

  useEffect(() => {
    setRefreshProfile(1);
  }, [id]);
  useEffect(() => {
    if (!token || refreshProfile !== 1 || !id) return;
    const getProfileData = async () => {
      try {
        const res = await API.get(`/api/v1/audit/profile/detial/${id}/`, {
          headers: { Authorization: token },
        });
        console.log(res?.data);
        setProfileData(res?.data);
        setIsAuditer(
          user?.placementProfileRelated?.id ===
            res?.data.leadAudit.profileAuditorRelated.id
            ? true
            : false
        );
        setIsChecklist(
          res?.data?.auditProcess === "checklist-create" ? true : false
        );
        setRefreshProfile(0);
      } catch (error) {
        setProfileData([]);

        console.log(error);
      }
    };
    getProfileData();
  }, [token, refreshProfile, id, user]);

  useEffect(() => {
    if (refresh.state && refresh.state === "refresh") {
      setRefreshProfile(1);
    }
  }, [refresh]);


  return showView ? <MainAuditPlannigList data={profileData} /> : user?.id ? (
    profileData[0] === -1 ? (
      <Loading />
    ) : profileData?.length < 1 ? (
      <div className="flex flex-col gap-3">
        <div className="flex items-center justify-center py-14 w-full">
          <span className="text-xs 2xl:text-base text-caspian-gray4">
            تقویم ممیزی مورد نظر یافت نشد!
          </span>
        </div>
      </div>
    ) : profileData?.id && (idProfile || idSection) ? (
      <Outlet context={[isAuditer, isChecklist]} />
    ) : (
      <div className="flex flex-col gap-3">
        <AuditPlaning
          isAuditer={isAuditer}
          data={profileData}
          setRefreshProfile={setRefreshProfile}
          loading={profileData[0] === -1 ? true : false}
        />
        {
          // profileData?.auditRelatedSections?.find(
          //   (s) => s?.sectionStatus === "scheduling"
          // ) 
          profileData.auditStatus === "scheduleTime"
            ? (
              <div className="min-h-[10vh] w-full rounded-md bg-caspian-surface border border-caspian-gray2 p-2 flex items-center">
                <div className="flex items-center gap-8">
                  <div className="flex items-center gap-2">
                    <label htmlFor="accept" className="text-xs font-bold">
                      لطفا تمام مرحله برنامه ریزی اولیه را کامل نمائید.
                    </label>
                  </div>
                </div>
              </div>
            ) : (
              <MainAcceptAudit
                sectionList={profileData?.auditRelatedSections?.map(
                  (ids) => ids.id
                )}
                auditProcess={profileData?.auditProcess}
                setRefreshProfile={setRefreshProfile}
              />
            )}
      </div>
    )
  ) : (
    <Loading />
  );
};

export default MainAuditPlanning;
