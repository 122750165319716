import React, { useContext, useEffect, useState } from 'react';
import ListDocument from './ListDocument';
import ListSection from './ListSection';
import ListApprover from './ListApprover';
import ListConfirm from './ListConfirm';
import { PiCircleNotch, PiFileDocDuotone, PiFileImage, PiLinkSimple, PiPlusBold, PiTrash } from 'react-icons/pi';
import ListSec from './ListSec';
import ListPerson from './ListPerson';
import API from '../../../../api/API';
import { AuthContext } from '../../../../context/auth/AuthContext';

const BottomForm = ({ setShowForm, data, title, oldCode, version, typeDocs, conf, startDate, setStartDate, check, setCheck, refresh, setRefresh, tagFinalSelected }) => {
    // const [positionData, setPositionData] = useState([-1]);
    const { token } = useContext(AuthContext);

    const [documentList, setDocumentList] = useState([-1]);
    const [document, setDocument] = useState("");
    const [documentSelected, setDocumentSelected] = useState(null);
    const [showListDocument, setShowListDocument] = useState(false);

    const [sectionList, setSectionList] = useState([]);
    const [section, setSection] = useState("");
    const [sectionSelected, setSectionSelected] = useState(null);
    const [showListSection, setShowListSection] = useState(false);


    const [confirmList, setConfirmList] = useState([]);
    const [confirm, setConfirm] = useState("");
    const [confirmSelected, setConfirmSelected] = useState(null);
    const [showListConfirm, setShowListConfirm] = useState(false);

    const [approverList, setApproverList] = useState();
    const [approver, setApprover] = useState("");
    const [approverSelected, setApproverSelected] = useState(null);
    const [showListApprover, setShowListApprover] = useState(false);

    const [showSec, setShowSec] = useState(false);
    const [secSelected, setSecSelected] = useState([]);
    const [secFinalSelected, setSecFinalSelected] =
        useState(secSelected);

    const [showPerson, setShowPerson] = useState(false);
    const [personSelected, setPersonSelected] = useState([]);
    const [personFinalSelected, setPersonFinalSelected] =
        useState(personSelected);

    const [files, setFiles] = useState(null);

    const [description, setDescription] = useState('');
    const [considerations, setConsiderations] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchPosition = async () => {
            if (!token) return
            try {
                const res = await API.get('/api/v1/organization/role/position/', { headers: { Authorization: token } });
                setApproverList(res?.data);
                setConfirmList(res?.data)
                console.log(res.data);
            }
            catch (err) {
                console.log(err);
            }
        }
        fetchPosition()
    }, [token])

    useEffect(() => {
        const fetchPlacement = async () => {
            if (!token) return
            try {
                const res = await API.get('/api/v1/organization/role/placement/', { headers: { Authorization: token } });
                setDocumentList(res?.data);
                console.log(res?.data);
            }
            catch (err) {
                console.log(err);
            }
        }
        fetchPlacement();
    }, [token])

    useEffect(() => {
        const fetchSection = async () => {
            if (!token) return
            try {
                const res = await API.get('/api/v1/organization/structure/section/', { headers: { Authorization: token } });
                setSectionList(res?.data);
                console.log(res?.data);
            }
            catch (err) {
                console.log(err);
            }
        }
        fetchSection();
    }, [token])

    const handleUpload = (event) => {
        event.preventDefault();
        setFiles(event.target.files[0]);
        // const uploadFiles = event.target.files;
        // if (uploadFiles.length > 0) {
        //     const newFiles = Array.from(uploadFiles);
        //     setFiles((prevFiles) => [...prevFiles, ...newFiles]);
        // }
    };
    const handleRemoveFile = () => {
        setFiles(null);
    }
    const formatSize = (size) => {
        if (size < 1024) return `Bytes ${size} `
        else if (size < 1024 * 1024) return `KB ${(size / 1024).toFixed(2)}`
        else if (size < 1024 * 1024 * 1024) return `MB ${(size / (1024 * 1024)).toFixed(2)}`
        else return `GB ${(size / (1024 * 1024 * 1024)).toFixed(2)}`
    }

    const handleSubmit = async () => {
        if (!token) return
        if (!title || !typeDocs || !document || !approver || !confirm || files === null || !startDate) {
            setCheck(true);
            return
        }
        setCheck(false);

        let formData = {
            codeOld: oldCode,
            InternalDocumentTypeRelated: typeDocs
        }
        console.log(formData, typeDocs);
        setIsLoading(true);
        try {
            const res = await API.post('/api/v1/deocument/internal/', formData, { headers: { Authorization: token } });
            if (res?.data) {
                console.log(res?.data?.id);
                handleDocument(res?.data?.id);
            }
        }
        catch (err) {
            console.log(err);
            setIsLoading(false);
        }
    }
    // useEffect(() => {
    //     if (version == 0) {
    //         let d = new Date();
    //         let day = d.getDate();
    //         let month = d.getMonth() + 1;
    //         let year = d.getFullYear();
    //         d = year + '-' + month + '-' + day;
    //         setStartDate(d)
    //     }
    // }, [version, startDate])
    // console.log(startDate);
    const handleDocument = async (id) => {
        if (!token) return
        let formData = {
            internalDocumentRelated: id,
            title: title,
            version: version || 0,
            versionDate: startDate,
            privacy: conf,
            OwnerSectionsRelated: section,
            description,
            considerations,
            verifying: confirm,
            approval: approver,
            users: secFinalSelected,
            usersProfile: personFinalSelected,
            ownerPlacementRelated: document
        }
        console.log(formData);
        try {
            const res = await API.post('/api/v1/deocument/internal/profile', formData, { headers: { Authorization: token } });
            if (res.data) {
                handlePostFile(res.data.id);
            }

            console.log(res.data);
        }
        catch (err) {
            console.log(err);
            setIsLoading(false);
        }
    }
    const handlePostFile = async (id) => {
        if (!id || !token) return
        let formData = {
            fileDocument: files
        }
        console.log(id);
        try {
            const res = await API.patch(`/api/v1/deocument/internal/profile/${id}/`, formData, {
                headers: {
                    Authorization: token,
                    "Content-Type": "multipart/form-data",
                    Accept: "application/json"
                }
            })
            if (res.data) {
                console.log(res.data);
                setRefresh(1);
                setIsLoading(false);
                setShowForm(false);
            }
        }
        catch (err) {
            console.log(err);
            setIsLoading(false);
        }
    }
    return (
        <div className='flex flex-col gap-2 2xl:gap-3 px-2 2xl:px-5'>
            <div className='flex justify-between items-center gap-4'>
                <div className='flex flex-col gap-2 basis-1/2'>
                    {/* مدرک */}
                    <ListDocument
                        lable="متولی مدرک"
                        documentList={documentList}
                        document={document}
                        setDocument={setDocument}
                        documentSelected={documentSelected}
                        setDocumentSelected={setDocumentSelected}
                        showListDocument={showListDocument}
                        setShowListDocument={setShowListDocument}
                        check={check}
                    />
                </div>
                <div className='flex flex-col gap-2 basis-1/2'>
                    <div className='flex flex-col gap-2 basis-1/2'>
                        {/* واحد */}
                        <ListSection
                            lable='واحد متولی'
                            sectionList={sectionList}
                            section={section}
                            setSection={setSection}
                            sectionSelected={sectionSelected}
                            setSectionSelected={setSectionSelected}
                            showListSection={showListSection}
                            setShowListSection={setShowListSection}
                        />
                    </div>
                </div>
            </div>

            <div className='flex justify-between items-center gap-4'>
                <div className='flex flex-col gap-2 basis-1/2'>
                    {/* پست تایید کننده */}
                    <ListConfirm
                        lable="پست تایید کننده"
                        confirmList={confirmList}
                        confirm={confirm}
                        setConfirm={setConfirm}
                        confirmSelected={confirmSelected}
                        setConfirmSelected={setConfirmSelected}
                        showListConfirm={showListConfirm}
                        setShowListConfirm={setShowListConfirm}
                        check={check}
                    />
                </div>
                <div className='flex flex-col gap-2 basis-1/2'>
                    {/* تصویب کننده */}
                    <ListApprover
                        lable="پست تصویب کننده"
                        approverList={approverList}
                        approver={approver}
                        setApprover={setApprover}
                        approverSelected={approverSelected}
                        setApproverSelected={setApproverSelected}
                        showListApprover={showListApprover}
                        setShowListApprover={setShowListApprover}
                        check={check}
                    />
                </div>
            </div>

            <div className='flex justify-between items-center gap-4'>
                <div className='flex flex-col gap-1 basis-1/2 '>
                    <span className='text-xs text-caspian-gray4'>واحدهای  بهره بردار</span>
                    <div
                        onClick={() => setShowSec(true)}
                        className='cursor-pointer border border-[#3C77A2] rounded-md py-2 p-3 '>
                        <div className='flex justify-between items-center'>
                            <p className='text-[#3C77A2] flex items-center gap-1 text-xs'>
                                <PiPlusBold />
                                <span>انتخاب</span>
                            </p>
                            {secFinalSelected?.length > 0 ? (
                                <span className="text-xs whitespace-nowrap">
                                    {secFinalSelected?.length} مورد افزوده شد
                                </span>
                            ) : (
                                <span className="text-xs whitespace-nowrap">موردی انتخاب نشده</span>
                            )}
                        </div>
                        {showSec && (
                            <ListSec
                                finalSelected={secFinalSelected}
                                setFinalSelected={setSecFinalSelected}
                                selected={secSelected}
                                setSelected={setSecSelected}
                                setShowSection={setShowSec}
                                showSection={showSec}
                                title=""
                                url={'/api/v1/organization/structure/section/'}
                            />
                        )}
                    </div>
                </div>
                <div className='flex flex-col gap-1 basis-1/2'>
                    <span className='text-xs text-caspian-gray4'>انتخاب افراد بهره بردار</span>
                    <div
                        onClick={() => setShowPerson(true)}
                        className='cursor-pointer border border-[#3C77A2] rounded-md py-2 p-3 '>
                        <div className='flex justify-between items-center'>
                            <p className='text-[#3C77A2] flex items-center gap-1 text-xs'>
                                <PiPlusBold />
                                <span>انتخاب</span>
                            </p>
                            {personFinalSelected?.length > 0 ? (
                                <span className="text-xs whitespace-nowrap">
                                    {personFinalSelected?.length} مورد افزوده شد
                                </span>
                            ) : (
                                <span className="text-xs whitespace-nowrap">موردی انتخاب نشده</span>
                            )}
                        </div>
                        {showPerson && (
                            <ListPerson
                                finalSelected={personFinalSelected}
                                setFinalSelected={setPersonFinalSelected}
                                selected={personSelected}
                                setSelected={setPersonSelected}
                                setShowSection={setShowPerson}
                                showSection={showPerson}
                                title=""
                                url={'/api/v1/profile/'}
                            />
                        )}
                    </div>
                </div>
            </div>

            <div className='flex justify-between items-center gap-4'>
                <div className="flex flex-col gap-1 basis-1/2">
                    <span className='text-xs text-caspian-gray4'>توضیحات</span>
                    <textarea rows={2}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder='توضیحات خود را وارد کنید'
                        className='border border-caspian-gray2 resize-none rounded-lg placeholder:text-xs p-3 placeholder:text-caspian-gray text-xs'>

                    </textarea>
                </div>
                <div className="flex flex-col gap-1 basis-1/2">
                    <span className='text-xs text-caspian-gray4'>ملاحظات</span>
                    <textarea rows={2}
                        onChange={(e) => setConsiderations(e.target.value)}
                        placeholder='ملاحظات خود را وارد کنید'
                        className='border border-caspian-gray2 resize-none rounded-lg placeholder:text-xs p-3 placeholder:text-caspian-gray text-xs'>

                    </textarea>
                </div>
            </div>
            <div className="flex justify-between items-center gap-4">
                {/* فایل و دکمه تکمیل */}
                <div className="flex items-center gap-10 basis-1/2">
                    <button
                        disabled={isLoading}
                        onClick={handleSubmit}
                        className='bg-[#3C77A2] flex gap-2 text-caspian-surface text-xs 2xl:text-sm rounded-md py-3 px-11 text-center'>
                        <span>ایجاد</span>
                        {isLoading && <PiCircleNotch className='animate-spin' />}
                    </button>
                    <button className='text-xs 2xl:text-sm text-caspian-error2'
                        onClick={() => setShowForm(false)}
                    >انصراف</button>
                </div>

                <div className=" basis-1/2">
                    {/* <span className={`${check && files === null ? 'visible' : 'invisible'} text-caspian-error2 text-xs`}>فایل خود را  بارگزاری کنید.</span> */}
                    {files === null ?
                        (<div className={`${check ? "border-2 border-dashed border-caspian-error2" : "border-2 border-dashed border-[#5A93B7]"} rounded-lg h-16`}>
                            <label htmlFor='file-upload' className='flex gap-1 justify-between p-2 cursor-pointer'>
                                <div className='flex flex-col gap-1'>
                                    <p className={`${check ? 'text-caspian-error2' : ''} text-xs 2xl:text-sm`}>مستندات خود را ازین قسمت بارگزاری کنید</p>
                                    <p className='text-xs text-caspian-gray4'>
                                        {/* میتوانید فایل  */}
                                    </p>
                                </div>
                                <div className='h-12 w-12'>
                                    <img className='cursor-pointer' src="/assets/images/documents-records/upload.svg" alt="upload file" />
                                </div>
                            </label>
                            <input type="file" id="file-upload"
                                onChange={handleUpload}
                                accept='.jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx,.svg,.ppt,.pptx,.zip,.rar'
                                className='hidden'
                            />
                        </div>) : (<div className='flex flex-col gap-2 h-16'>
                            <div className='text-xs text-[#3C77A2] flex gap-1 items-center'>
                                <PiLinkSimple className='text-base' />
                                <span> بارگزاری فایل</span>
                            </div>
                            <div className='flex gap-3 items-center'>
                                <div className='flex px-3 pb-1 items-center justify-between w-full text-sm border-b border-caspian-gray'>
                                    <div className='flex gap-3 items-center'>
                                        <p className='text-xl text-[#3C77A2]'>
                                            {
                                                files?.type?.includes('jpg') || files?.type?.includes('jpeg') ||
                                                    files?.type?.includes('png') || files?.type?.includes('svg') ?
                                                    <PiFileImage /> :
                                                    <PiFileDocDuotone />
                                            }
                                        </p>
                                        <p className='text-xs'> {files?.name} </p>
                                    </div>
                                    <p className='text-xs rtl text-right'>{formatSize(files?.size)}</p>
                                </div>
                                <PiTrash className='cursor-pointer text-lg text-[#3C77A2]' onClick={handleRemoveFile} />
                            </div>
                        </div>)
                    }
                </div>
            </div>
        </div>
    );
}

export default BottomForm;
