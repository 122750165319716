import React, { useState } from "react";
import RowView from "./RowView";
import Paginate from "./Paginate";
import SingleTableRecords from "./SingleTableRecords";
import { useSearchParams } from "react-router-dom";
import { PiXBold } from "react-icons/pi";

const TableListRecords = ({ data }) => {
  const [searchByTitle, setSearchByTitle] = useState("");
  const [searchByCode, setSearchCode] = useState("");
  const [searchByPreservation, setSearchByPreservation] = useState("");
  const [searchByPrivacy, setSearchByPrivacy] = useState("");
  const [searchByResponsible, setSearchByResponsible] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("search");
  const page = searchParams.get("page");
  const rows = searchParams.get("rows");

  let searchTitle = [];
  let searchCode = [];
  let searchPreservation = [];
  let searchPrivacy = [];
  let searchResponsible = [];

  let newData = [-1];
  if (data[0] !== -1) {
    newData = search
      ? data.filter((d) =>
        d.title.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      )
      : data;
  }

  if (newData[0] !== -1) {
    searchTitle = searchByTitle
      ? newData.filter((d) =>
        d.title
          .toLocaleLowerCase()
          .includes(searchByTitle.toLocaleLowerCase())
      )
      : newData;
    searchCode = searchByCode
      ? searchTitle.filter((d) => d.code.toString().includes(searchByCode))
      : searchTitle;

    searchPreservation = searchByPreservation
      ? searchCode.filter((d) =>
        d.recordRelated.length > 0 &&
          d?.recordRelated[0]?.preservation === "electronic"
          ? "الکترونیکی".toString().includes(searchByPreservation)
          : "کاغذی".toString().includes(searchByPreservation)
      )
      : searchCode;

    searchPrivacy = searchByPrivacy
      ? searchPreservation.filter((d) =>
        d.privacy.toString().includes(searchByPrivacy)
      )
      : searchPreservation;
  }

  searchResponsible = searchByResponsible
    ? searchPrivacy.filter((d) =>
      "مسئول نگهداری".toString().includes(searchByResponsible)
    )
    : searchPrivacy;

  const [currentPage, setCurrentPage] = useState(page ? page : 1);
  const [recordsPerPage, setRecordsPerPage] = useState(rows ? rows : 10);
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = searchResponsible.slice(firstIndex, lastIndex);
  const npage = Math.ceil(searchResponsible.length / recordsPerPage);

  const [showDetails, setShowDetails] = useState(false);

  const c = searchParams.get("code");

  function handleCode(key, value) {
    setSearchParams((prevParams) => {
      if (value === null || value === "") {
        prevParams.delete(key);
      } else {
        prevParams.set(key, value);
      }
      return prevParams;
    });
  }

  return (
    <div className={`flex flex-col relative`}>
      {showDetails && c && (
        <div
          className={
            showDetails &&
            c &&
            "z-50 bg-caspian-surface rounded-xl shadow-[0_0_10px_#00000050] absolute top-1/2 -translate-y-1/2 left-0 right-0 w-[95%] mx-auto"
          }
        >
          <div className="absolute left-0 -top-5 z-50">
            <button
              onClick={() => {
                setShowDetails(false);
                handleCode("code", null);
              }}
            >
              <PiXBold />
            </button>
          </div>
          {records.map(
            (d, i) =>
              d.code === c && (
                <div key={i} className="flex flex-col">
                  <div className="flex items-center justify-center w-full p-4">
                    <div className="w-full flex items-center gap-2 text-xs">
                      <span className="text-caspian-gray">عنوان:</span>
                      <span className="font-bold">{d.title}</span>
                    </div>
                    <div className="w-full flex items-center gap-2 text-xs">
                      <span className="text-caspian-gray">کد سند:</span>
                      <span className="font-bold">{d.code}</span>
                    </div>
                    <div className="w-full flex items-center gap-2 text-xs">
                      <span className="text-caspian-gray">نحوه نگهداری:</span>
                      <span className="font-bold">
                        {d.recordRelated.length > 0
                          ? d?.recordRelated[0]?.preservation === "electronic"
                            ? "الکترونیکی"
                            : "کاغذی"
                          : "-"}
                      </span>
                    </div>
                    <div className="w-full flex items-center gap-2 text-xs">
                      <span className="text-caspian-gray">طبقه بندی:</span>
                      <span className="font-bold">{d.privacy}</span>
                    </div>
                    <div className="w-full flex items-center gap-2 text-xs">
                      <span className="text-caspian-gray">
                        پست مسئول نگه داری:
                      </span>
                      <span className="font-bold">
                        {d.recordRelated.length > 0
                          ? d.recordRelated[0].authorityPositionRelated?.title
                          : "-"}
                      </span>
                    </div>
                  </div>
                  {d.recordRelated.length > 0 && (
                    <div className="flex items-center justify-between w-full p-4 border-t border-dotted border-t-caspian-gray2">
                      <div className="w-full flex flex-col gap-2 text-xs">
                        <span className="text-caspian-gray">
                          محل نگه داری راکد:
                        </span>
                        <span className="font-bold">
                          {d.recordRelated.length > 0 &&
                            d?.recordRelated[1]?.address}
                        </span>
                      </div>
                      <div className="w-full flex flex-col gap-2 text-xs">
                        <span className="text-caspian-gray">
                          مدت نگه‌داری راکد:
                        </span>
                        <span className="font-bold">
                          {d.recordRelated.length > 0 &&
                            d?.recordRelated[1]?.duration}
                        </span>
                      </div>
                      <div className="w-full flex flex-col gap-2 text-xs">
                        <span className="text-caspian-gray">
                          محل نگه داری جاری:
                        </span>
                        <span className="font-bold">
                          {d.recordRelated.length > 0 &&
                            d?.recordRelated[0]?.address}
                        </span>
                      </div>
                      <div className="w-full flex flex-col gap-2 text-xs">
                        <span className="text-caspian-gray">
                          مدت نگه‌داری جاری:
                        </span>
                        <span className="font-bold">
                          {d.recordRelated.length > 0 &&
                            d?.recordRelated[0]?.duration}
                        </span>
                      </div>
                      <div className="w-full flex flex-col gap-2 text-xs">
                        <span className="text-caspian-gray">
                          نحوه تعیین تکلیف (وا‌ رهایی):
                        </span>
                        <span className="font-bold">سطل بازیافت</span>
                      </div>
                    </div>
                  )}
                </div>
              )
          )}
        </div>
      )}
      <div className="flex flex-col overflow-x-auto">
        <div>
          <div>
            <div className="overflow-x-auto">
              {showDetails && c && (
                <div className="absolute top-1/2 -translate-y-1/2 left-0 right-0 w-full h-full z-40"></div>
              )}
              <table
                className={`${showDetails && c ? "blur-[2px]" : ""
                  } min-w-[992px] w-full text-left text-sm font-light`}
              >
                <thead className="border-b border-b-caspian-gray2 font-medium">
                  <tr>
                    <th
                      scope="col"
                      className="rounded-tr-xl bg-caspian-primary-500 relative p-2 w-[20%]"
                    >
                      <input
                        // onClick={() => setCurrentPage(1)}
                        onChange={(e) => {
                          setSearchByTitle(e.target.value);
                          setCurrentPage(1);
                        }}
                        type="search"

                        id="t1"
                        className={`${searchByTitle
                          ? "border border-caspian-surface/50 "
                          : "border border-caspian-transparent"
                          } text-[#FFF7CB] bg-caspian-transparent p-1.5 rounded-md outline-none  w-full focus:border focus:border-caspian-surface/50 pr-11 font-light peer cursor-pointer`}
                      />
                      <label htmlFor="t1"
                        className={`${searchByTitle ? "right-0 left-auto" : ""
                          } text-caspian-surface absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 p-0 border border-caspian-primary-500 bg-caspian-primary-500 rounded-md z-[30] whitespace-nowrap cursor-pointer font-light
                            peer-focus:left-auto peer-focus:right-0`}
                      >عنوان</label>
                    </th>
                    <th
                      scope="col"
                      className="bg-caspian-primary-500 relative p-2 w-[20%]"
                    >
                      <input
                        onChange={(e) => {
                          setSearchCode(e.target.value);
                          setCurrentPage(1);
                        }}
                        type="search"
                        id="t2"
                        className={`${searchByCode
                          ? "border border-caspian-surface/50 "
                          : "border border-caspian-transparent"
                          } text-[#FFF7CB] bg-caspian-transparent p-1.5 rounded-md outline-none  w-full focus:border focus:border-caspian-surface/50 pr-[54px] font-light peer cursor-pointer`}
                      />
                      <label htmlFor="t2"
                        className={`${searchByCode ? "right-4 left-full" : ""
                          } text-caspian-surface absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 p-0 bg-caspian-primary-500 rounded-md whitespace-nowrap cursor-pointer font-light
                            peer-focus:left-full peer-focus:right-4 z-[30]`}
                      >کد سند</label>
                    </th>
                    <th
                      scope="col"
                      className="bg-caspian-primary-500 relative p-2 w-[20%]"
                    >
                      <input
                        onChange={(e) => {
                          setSearchByPreservation(e.target.value);
                          setCurrentPage(1);
                        }}
                        type="search"
                        id="t3"
                        className={`${searchByPreservation
                          ? "border border-caspian-surface/50 "
                          : "border border-caspian-transparent"
                          } text-[#FFF7CB] bg-caspian-transparent p-1.5 rounded-md outline-none  w-full focus:border focus:border-caspian-surface/50 pr-[88px] font-light peer cursor-pointer`}
                      />
                      <label htmlFor="t3"
                        className={`${searchByPreservation ? "right-4 left-full" : ""
                          } text-caspian-surface absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 p-0 bg-caspian-primary-500 rounded-md z-[30] whitespace-nowrap cursor-pointer font-light
                            peer-focus:left-full peer-focus:right-4`}
                      >نحوه نگهداری</label>
                    </th>
                    <th
                      scope="col"
                      className="bg-caspian-primary-500 relative p-2 w-[20%]"
                    >
                      <input
                        onChange={(e) => setSearchByPrivacy(e.target.value)}
                        type="search"
                        id="t4"
                        className={`${searchByPrivacy
                          ? "border border-caspian-surface/50 "
                          : "border border-caspian-transparent"
                          } text-[#FFF7CB] bg-caspian-transparent p-1.5 rounded-md outline-none  w-full focus:border focus:border-caspian-surface/50 pr-[70px] font-light peer cursor-pointer`}
                      />
                      <label htmlFor="t4"
                        className={`${searchByPrivacy ? "right-4 left-full" : ""
                          } text-caspian-surface absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 p-0 bg-caspian-primary-500 rounded-md z-[30] whitespace-nowrap cursor-pointer font-light
                            peer-focus:left-full peer-focus:right-4`}
                      >طبقه بندی</label>
                    </th>
                    <th
                      scope="col"
                      className="rounded-tl-xl bg-caspian-primary-500 relative p-2 w-[20%]"
                    >
                      <input
                        onChange={(e) => setSearchByResponsible(e.target.value)}
                        type="search"
                        id="t5"
                        className={`${searchByResponsible
                          ? "border border-caspian-surface/50 "
                          : "border border-caspian-transparent"
                          } text-[#FFF7CB] bg-caspian-transparent p-1.5 rounded-md outline-none  w-full focus:border focus:border-caspian-surface/50 pr-[135px] font-light peer cursor-pointer`}
                      />
                      <label htmlFor="t5"
                        className={`${searchByResponsible ? "right-4 left-full" : ""
                          } text-caspian-surface absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 p-0 bg-caspian-primary-500 rounded-md z-[30] whitespace-nowrap cursor-pointer font-light
                            peer-focus:left-full peer-focus:right-4`}
                      >پست مسئول نگه داری</label>
                    </th>
                  </tr>
                </thead>
                {records.length > 0 ? (
                  <tbody>
                    {records.map((d, i) => (
                      <SingleTableRecords
                        showDetails={showDetails}
                        setShowDetails={setShowDetails}
                        key={i}
                        //عنوان
                        title={d.title}
                        //کد  سند
                        code={d.code}
                        //نحوه نگهداری
                        preservation={
                          d.recordRelated.length > 0 &&
                            d.recordRelated[0].preservation === "electronic"
                            ? "الکترونیکی"
                            : "کاغذی"
                        }
                        //طبقه بندی
                        privacy={d.privacy}
                        //پست مسئول نگهداری
                        responsible={
                          d.recordRelated.length > 0
                            ? d.recordRelated[0].authorityPositionRelated?.title
                            : "-"
                        }
                      />
                    ))}
                  </tbody>
                ) : (
                  <tbody className="py-24">
                    <tr>
                      <td className="py-24">
                        <div className="flex items-center justify-center w-full py-10">
                          <div className="z-10 absolute left-1/2 -translate-x-1/2 mx-auto">
                            <div className="flex flex-col gap-2 items-center justify-center w-full">
                              <img
                                src="/assets/images/library/not-found.svg"
                                alt="مطلبی یافت نشد..."
                              />
                              <h2>مطلبی یافت نشد...</h2>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
      {records.length > 0 && (
        <div className="flex items-center justify-between py-2">
          <RowView
            setRecordsPerPage={setRecordsPerPage}
            setCurrentPage={setCurrentPage}
          />
          <Paginate
            pageCount={npage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
          <div className="text-sm flex items-center gap-4">
            <span>نمایش {firstIndex + 1}</span>
            <span>تا {lastIndex - (recordsPerPage - records.length)}</span>
            <span>از کل {searchResponsible.length}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableListRecords;
