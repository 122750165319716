import React, { useState } from 'react';
import InfoAuditPlan from '../../input-observation-details-tabs/observation-list-tab/InfoAuditPlan';
import SearchList from '../../input-observation-details-tabs/observation-list-tab/SearchList';
import StatusFilter from '../../input-observation-details-tabs/observation-list-tab/StatusFilter';
import FoundTable from './FoundTable';
import { useSearchParams } from 'react-router-dom';
import { data } from './Data';

const MainFoundList = () => {
    const [searchParams] = useSearchParams();
    const [search, setSearch] = useState('');
    const page = searchParams.get('page');
    const rows = searchParams.get('rows');
    const [currentPage, setCurrentPage] = useState(page || 1);
    const [limit, setLimit] = useState(rows ? rows : 10);
    const [statusFilter, setStatusFilter] = useState('');
    const [statusSelected, setStatusSelected] = useState({});
    const [statusShow, setStatusShow] = useState(false);
    const [statusList, setStatusList] = useState([
        {
            id: 1,
            title: 'همه',
            value: 'all'
        },
        {
            id: 2,
            title: 'ok',
            value: 'ok'
        },
        {
            id: 3,
            title: 'obs',
            value: 'obs'
        },
        {
            id: 4,
            title: 'nc',
            value: 'nc'
        },
        {
            id: 5,
            title: 'n/a',
            value: 'n/a'
        },
    ]);
    // const [data, setData] = useState([
    //     {
    //         id: 1,
    //         code: 'F4367',
    //         type: 'OBS',
    //         question: 'AQ20026',
    //         description: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، ',
    //         requirement: [
    //             { id: 1, standard: 'ISO90001:2015', clause: [{ id: 1, name: '1-1-5' }, { id: 2, name: '1-3-5' }] },
    //             { id: 2, standard: 'ISO45001:2018', clause: [{ id: 1, name: '4-2' }, { id: 2, name: '5-1' }] },
    //         ],
    //         request: [],
    //     },
    //     {
    //         id: 2,
    //         code: 'F4867',
    //         type: 'NC',
    //         question: 'AQ20026',
    //         description: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، ',
    //         requirement: [
    //             { id: 1, standard: 'ISO90001:2015', clause: [{ id: 1, name: '2-5' }, { id: 2, name: '3-4' }] }
    //         ],
    //         request: [{ id: 1, name: 'IR679867' }, { id: 1, name: 'IR68590' }, { id: 2, name: 'IR41589' }],
    //     },
    //     {
    //         id: 3,
    //         code: 'F4867',
    //         type: 'OK',
    //         question: 'AQ20026',
    //         description: 'شرح یافته',
    //         requirement: [
    //             { id: 1, standard: 'ISO90001:2015', clause: [{ id: 1, name: '2-5' }, { id: 2, name: '3-4' }, { id: 3, name: '9-1' }, { id: 4, name: '8-4-5' }, { id: 5, name: '0-2' }] }
    //         ],
    //         request: [{ id: 1, name: 'IR679867' }, { id: 1, name: 'IR68590' }, { id: 2, name: 'IR41589' }],
    //     },
    // ])
    return (
        <div className='flex flex-col gap-4 py-4 w-full 2xl:w-[90%]'>
            <div className='flex justify-between items-end'>
                <div className='basis-[50%] lg:basis-[35%]'>
                    <InfoAuditPlan />
                </div>
                <div className='basis-[50%] 2xl:basis-[65%] flex justify-end items-end'>
                    <div className='flex gap-1'>
                        {/* search */}
                        <div className='relative 2xl:w-[350px] self-end'>
                            <SearchList search={search} setSearch={setSearch}
                                setCurrenPage={setCurrentPage}
                            // setOffset={setOffset}
                            />
                        </div>
                        {/* status filter */}
                        <div className='w-[150px] self-start'>
                            <StatusFilter
                                status={statusFilter}
                                setstatus={setStatusFilter}
                                statusList={statusList}
                                statusSelected={statusSelected}
                                setstatusSelected={setStatusSelected}
                                lable={'مرتب‌سازی'}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <FoundTable
                data={data}
                search={search}
                statusFilter={statusFilter}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                limit={limit}
                setLimit={setLimit}
            />
        </div>
    );
}

export default MainFoundList;
