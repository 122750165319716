import InfoBox from "./InfoBox";

const DetailsPersonInternalContact = ({ heightHandler, fullData }) => {

  return (
    <div className="w-full h-full flex flex-col gap-2">
      <InfoBox fullData={fullData} heightHandler={heightHandler} />
    </div>
  );
};

export default DetailsPersonInternalContact;
