import React, { useState } from "react";
import Draggable from "react-draggable";
import { PiBuildings, PiCaretUpBold, PiChatCircleDots, PiX } from "react-icons/pi";
import DetailsSectionInternalContact from "./DetailsSectionInternalContact";

const MainSectionInternalContact = ({
  setShowSectionInternalContact,
  showChatPerson,
  setShowChatPerson,
  fullData
}) => {
  const [heightHandler, setHeightHandler] = useState(false);

  return (
    <Draggable
      handle="strong"
      bounds={{ top: 0, bottom: 500, right: 740, left: -250 }}>
      <div
        className={`w-[280px]  min-h-fit h-fit max-h-[80vh] fixed left-24 top-14 rounded-md shadow-[0_0_10px_#00000050] z-[47] flex flex-col overflow-hidden bg-caspian-surface`}>
        <strong className="flex items-center justify-between cursor-move p-2 border-b border-b-caspian-gray2 bg-caspian-surface">
          <div className="flex items-center gap-2">
            <div className="w-8 h-8 rounded-full">
              <PiBuildings className="text-2xl text-caspian-gray4" />
            </div>
            <span className="text-xs font-bold">{fullData?.sectionRelated?.title}</span>
          </div>
          <div className="flex items-center gap-2">
            {/* <PiChatCircleDots
              onClick={() => setShowChatPerson(!showChatPerson)}
              className="cursor-pointer text-lg text-caspian-secondary-500"
            /> */}
            <PiX
              onClick={() => setShowSectionInternalContact(false)}
              className="cursor-pointer"
            />
          </div>
        </strong>
        <div
          className={`flex flex-col gap-2 p-2 ${heightHandler
              ? "min-h-fit h-[120%] max-h-full"
            : "h-full"
            }`}
        >
          {heightHandler ? (
            <div className="w-full bg-caspian-surface flex items-center justify-center absolute bottom-0 left-0 right-0 mx-auto py-2">
              <PiCaretUpBold
                onClick={() => setHeightHandler(false)}
                className="text-lg text-caspian-secondary-500 cursor-pointer"
              />
            </div>
          ) : (
            <div className="w-full bg-caspian-surface flex items-center justify-center absolute bottom-0 left-0 right-0 mx-auto py-2">
              <span
                onClick={() => setHeightHandler(true)}
                className="font-bold text-xs text-caspian-secondary-500 cursor-pointer"
              >
                بیشتر
              </span>
            </div>
          )}
          <DetailsSectionInternalContact fullData={fullData} heightHandler={heightHandler} />
        </div>
      </div>
    </Draggable>
  );
};

export default MainSectionInternalContact;
