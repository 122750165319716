import React from "react";

const Logo = () => {
  return (
    <div className="p-1 flex items-center gap-2">
      <img
        src="/assets/images/logo/logo-hamgara.svg"
        alt="caspian team"
        className="w-9 h-auto"
      />
      {/* <span className="text-xs caret-caspian-secondary-700 ">همگرا</span> */}
    </div>
  );
};

export default Logo;
