import React, { useContext, useEffect, useRef, useState } from "react";
import SingleInput from "./SingleInput";
import ListProcessSelected from "./ListProcessSelected";
import ListGoalSelected from "./ListGoalSelected";
import ListEffectSelected from "./ListEffectSelected";
import ListProcessDetailsSelected from "./ListProcessDetailsSelected";
import ListGoalDetailsSelected from "./ListGoalDetailsSelected";
import { AuthContext } from "../../../../../../context/auth/AuthContext";
import API from "../../../../../../api/API";
import ActivityRiskDetailsMain from "../../activity-risk-details/ActivityRiskDetailsMain";
import Modal from "../../../../../modal/Modal";
import { useNavigate, useParams } from "react-router-dom";
import ListSelected from "./ListSelected";

const MainCreateForm = ({
  thisProfileId,
  thisIdProcess,
  thisTitleProcess,
  thisIdGoal,
  thisTitleGoal,
  riskForm,
  setRefreshRiskActivity,
  isDoing,
  dataCopy,
  setDataCopy,
}) => {
  const { token } = useContext(AuthContext);
  const params = useParams();
  const titleRef = useRef();
  const effectRef = useRef();
  const causeRef = useRef();
  const controlRef = useRef();
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [cause, setCause] = useState("");
  const [effect, setEffect] = useState("");
  const [control, setControl] = useState("");
  // const [detective, setDetective] = useState("");

  const [processSelected, setProcessSelected] = useState([]);
  const [processFinalSelected, setProcessFinalSelected] =
    useState(processSelected);

  const [processDetailsSelected, setProcessDetailsSelected] = useState([]);
  const [processDetailsFinalSelected, setProcessDetailsFinalSelected] =
    useState(processDetailsSelected);

  const [goalSelected, setGoalSelected] = useState([]);
  const [goalFinalSelected, setGoalFinalSelected] = useState(goalSelected);

  const [goalDetailsSelected, setGoalDetailsSelected] = useState([]);
  const [goalDetailsFinalSelected, setGoalDetailsFinalSelected] =
    useState(goalDetailsSelected);

  const [effectSelected, setEffectSelected] = useState([]);
  const [effectFinalSelected, setEffectFinalSelected] =
    useState(effectSelected);

  const [showProcessList, setShowProcessList] = useState(false);
  const [showProcessDetailsList, setShowProcessDetailsList] = useState(false);

  const [showGoalList, setShowGoalList] = useState(false);
  const [showGoalDetailsList, setShowGoalDetailsList] = useState(false);

  const [showEffectList, setShowEffectList] = useState(false);

  const submitHandler = async () => {
    if (!token || !title || !params?.id) return;

    const formData = {
      title: title || dataCopy?.title,
      effect: effect || dataCopy?.effect,
      cause: cause || dataCopy?.cause,
      control: control || dataCopy?.control,
      processRelated: processDetailsFinalSelected,
      groupProcessImpressRelated: processFinalSelected,
      objectiveGoalRelated: goalDetailsFinalSelected,
      goalImpressRelated: goalFinalSelected,
      riskIdentificationProfileRelated: +params.id,
    };

    try {
      const res = await API.post(
        `/api/v1/risk/identification/detail/`,
        formData,
        {
          headers: { Authorization: token },
        }
      );
      submitHandlerStakHolder(res?.data?.id);
      clearHandler();
      setDataCopy(null);
    } catch (error) {
      console.log(error);
    }
  };

  const submitHandlerStakHolder = async (riskId) => {
    const riskStakHolderFormData = {
      content_type: "risk_app.riskidentification",
      RiskType: "to",
      object_id: riskId,
      stackHoldersRiskImpact: effectFinalSelected,
    };
    await console.log(riskStakHolderFormData);
    if (!token) return;

    try {
      await API.post(
        `/api/v1/risk/stackholder/detail/`,
        riskStakHolderFormData,
        {
          headers: { Authorization: token },
        }
      ).then((res) => {
        // submitHandlerAdd(thisProfileId, res.data.object_id);
        console.log(res?.data);
        setRefreshRiskActivity(1);
      });
    } catch (err) {
      console.log(err);
    }
  };

  // const submitHandlerAdd = async (profileId, riskId) => {
  //   const identificationFormData = {
  //     RiskIdentificationProfileId: +profileId,
  //     RiskIdentificationId: +riskId,
  //   };
  //   await console.log(identificationFormData);
  //   if (!token) return;

  //   try {
  //     const res = await API.post(
  //       `/api/v1/risk/identification/add`,
  //       identificationFormData,
  //       {
  //         headers: { Authorization: token },
  //       }
  //     );
  //     setRefreshRiskActivity(1);
  //     console.log(res?.data);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const [showCompleteHandler, setShowCompleteHandler] = useState(false);
  const submitCompleteHandler = async () => {
    const identificationFormData = {
      RiskIdentificationProfileId: +thisProfileId,
    };
    await console.log(identificationFormData);
    if (!token) return;

    try {
      const res = await API.post(
        `/api/v1/risk/identification/done`,
        identificationFormData,
        {
          headers: { Authorization: token },
        }
      );
      navigate("/risk/activities");
      // setRefreshRiskActivity(1);
      console.log(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const clearHandler = async () => {
    setTitle("");
    setEffect("");
    setCause("");
    setControl("");
    setProcessDetailsSelected([]);
    setProcessDetailsFinalSelected([]);
    setProcessSelected([]);
    setProcessFinalSelected([]);
    setGoalDetailsSelected([]);
    setGoalDetailsFinalSelected([]);
    setGoalSelected([]);
    setGoalFinalSelected([]);
    setEffectSelected([]);
    setEffectFinalSelected([]);
    titleRef.current.value = "";
    effectRef.current.value = "";
    causeRef.current.value = "";
    controlRef.current.value = "";
  };

  useEffect(() => {
    if (dataCopy) {
      setTitle(dataCopy?.title);
      setEffect(dataCopy?.effect);
      setCause(dataCopy?.cause);
      setControl(dataCopy?.control);
      titleRef.current.value = dataCopy?.title;
      effectRef.current.value = dataCopy?.effect;
      causeRef.current.value = dataCopy?.cause;
      controlRef.current.value = dataCopy?.control;
    }
  }, [dataCopy]);
  return (
    <div className="flex items-start gap-2">
      <div className="flex flex-col gap-0 w-1/2 rounded-md bg-caspian-surface shadow-[0_0_5px_#00000050]">
        <div className="rounded-t-md bg-[#94BED4] text-xs flex items-center justify-center p-2">
          <span>شناسایی ریسک</span>
        </div>
        {thisTitleProcess && (
          <h2 className="text-xs font-bold text-caspian-gray4 w-fit py-2 px-4">
            گروه فرایندها - {thisTitleProcess}
          </h2>
        )}
        {thisTitleGoal && (
          <h2 className="text-xs font-bold text-caspian-gray4 w-fit py-2 px-4">
            گروه اهداف کلان - {thisTitleGoal}
          </h2>
        )}
        <div className="flex flex-col gap-2 w-full border-b border-b-caspian-secondary-100 pb-4 p-2">
          {/* start عنوان */}
          <SingleInput lable={"عنوان"}>
            <input
              ref={titleRef}
              defaultValue={dataCopy?.title || title}
              onChange={(e) => setTitle(e.target.value)}
              className="text-xs p-2 rounded-md bg-caspian-transparent outline-none border border-caspian-gray2 w-full"
              placeholder="عنوان را وارد کنید"
            />
          </SingleInput>
          {/* end عنوان */}
        </div>
        <div className="flex items-center justify-between gap-2 w-full border-b border-b-caspian-secondary-100 pb-4 p-2 bg-caspian-surface">
          {/* start اثر */}
          <SingleInput lable={"اثر"}>
            <textarea
              ref={effectRef}
              rows={13}
              onChange={(e) => setEffect(e.target.value)}
              defaultValue={dataCopy?.effect || effect}
              // value={effect}
              className="resize-none text-xs p-2 rounded-md bg-caspian-transparent outline-none border border-caspian-gray2 w-full"
              placeholder="اثر را وارد کنید"
            />
          </SingleInput>
          {/* end اثر */}
          <div className="flex flex-col h-full gap-2 w-full">
            <div className="flex items-center justify-between gap-2 w-full">
              {/* start منشا */}
              {thisIdProcess && (
                <SingleInput lable={"منشا"}>
                  <button
                    onClick={() => setShowProcessDetailsList(true)}
                    className="w-full p-1.5 rounded-md bg-caspian-gray2/30 hover:bg-caspian-gray2/40 duration-300"
                  >
                    {processDetailsFinalSelected?.length > 0 ? (
                      <span className="text-xs">
                        {processDetailsFinalSelected?.length} منشا انتخاب شده
                        است
                      </span>
                    ) : (
                      <span className="text-xs">انتخاب منشا</span>
                    )}
                  </button>
                </SingleInput>
              )}
              {/* {showProcessDetailsList && (
                <ListProcessDetailsSelected
                  thisIdProcess={thisIdProcess}
                  thisTitleProcess={thisTitleProcess}
                  processDetailsFinalSelected={processDetailsFinalSelected}
                  setProcessDetailsFinalSelected={
                    setProcessDetailsFinalSelected
                  }
                  processDetailsSelected={processDetailsSelected}
                  setProcessDetailsSelected={setProcessDetailsSelected}
                  setShowProcessDetailsList={setShowProcessDetailsList}
                />
              )} */}
              {showProcessDetailsList && (
                <ListSelected
                  finalSelected={processDetailsFinalSelected}
                  setFinalSelected={setProcessDetailsFinalSelected}
                  selected={processDetailsSelected}
                  setSelected={setProcessDetailsSelected}
                  setShowListSelected={setShowProcessDetailsList}
                  title="لیست منشا"
                  url={`/api/v1/businessProcess/process/group/${thisIdProcess}`}
                />
              )}
              {/* end منشا */}

              {/* start منشا */}
              {thisIdGoal && (
                <SingleInput lable={"منشا"}>
                  <button
                    onClick={() => setShowGoalDetailsList(true)}
                    className="w-full p-1.5 rounded-md bg-caspian-gray2/30 hover:bg-caspian-gray2/40 duration-300"
                  >
                    {goalDetailsFinalSelected?.length > 0 ? (
                      <span className="text-xs">
                        {goalDetailsFinalSelected?.length} منشا انتخاب شده است
                      </span>
                    ) : (
                      <span className="text-xs">انتخاب منشا</span>
                    )}
                  </button>
                </SingleInput>
              )}
              {/* {showGoalDetailsList && (
                <ListGoalDetailsSelected
                  thisIdGoal={thisIdGoal}
                  thisTitleGoal={thisTitleGoal}
                  goalDetailsFinalSelected={goalDetailsFinalSelected}
                  setGoalDetailsFinalSelected={setGoalDetailsFinalSelected}
                  goalDetailsSelected={goalDetailsSelected}
                  setGoalDetailsSelected={setGoalDetailsSelected}
                  setShowGoalDetailsList={setShowGoalDetailsList}
                />
              )} */}
              {showGoalDetailsList && (
                <ListSelected
                  finalSelected={goalDetailsFinalSelected}
                  setFinalSelected={setGoalDetailsFinalSelected}
                  selected={goalDetailsSelected}
                  setSelected={setGoalDetailsSelected}
                  setShowListSelected={setShowGoalDetailsList}
                  title="لیست منشا"
                  url={`/api/v1/goal/objective/goal/${thisIdGoal}`}
                />
              )}
              {/* end منشا */}
            </div>

            {/* start سایر فرایند تاثیرپذیر */}
            <SingleInput lable={"سایر فرایند‌های تاثیرپذیر"}>
              <button
                onClick={() => setShowProcessList(true)}
                className="w-full p-1.5 rounded-md bg-caspian-gray2/30 hover:bg-caspian-gray2/40 duration-300"
              >
                {processFinalSelected?.length > 0 ? (
                  <span className="text-xs">
                    {processFinalSelected?.length} گروه فرایند تاثیرپذیر انتخاب
                    شده است
                  </span>
                ) : (
                  <span className="text-xs">انتخاب گروه فرایند تاثیرپذیر</span>
                )}
              </button>
            </SingleInput>

            {showProcessList && (
              <ListSelected
                finalSelected={processFinalSelected}
                setFinalSelected={setProcessFinalSelected}
                selected={processSelected}
                setSelected={setProcessSelected}
                setShowListSelected={setShowProcessList}
                title="گروه های فرایندی تاثیرپذیر"
                url={`/api/v1/businessProcess/group/`}
              />
            )}

            {/* {showProcessList && (
              <ListProcessSelected
                processFinalSelected={processFinalSelected}
                setProcessFinalSelected={setProcessFinalSelected}
                processSelected={processSelected}
                setProcessSelected={setProcessSelected}
                setShowProcessList={setShowProcessList}
              />
            )} */}
            {/* end سایر فرایند تاثیرپذیر */}
            {/* start اهداف کلان تاثیر پذیر */}
            <SingleInput lable={"اهداف کلان تاثیر پذیر"}>
              <button
                onClick={() => setShowGoalList(true)}
                className="w-full p-1.5 rounded-md bg-caspian-gray2/30 hover:bg-caspian-gray2/40 duration-300"
              >
                {goalFinalSelected?.length > 0 ? (
                  <span className="text-xs">
                    {goalFinalSelected?.length} هدف کلان تاثیرپذیر انتخاب شده
                    است
                  </span>
                ) : (
                  <span className="text-xs">
                    انتخاب گروه هدف کلان تاثیرپذیر
                  </span>
                )}
              </button>
            </SingleInput>
            {showGoalList && (
              <ListSelected
                finalSelected={goalFinalSelected}
                setFinalSelected={setGoalFinalSelected}
                selected={goalSelected}
                setSelected={setGoalSelected}
                setShowListSelected={setShowGoalList}
                title="لیست اهداف کلان تاثیرپذیر"
                url={`/api/v1/goal/goal/`}
              />
            )}
            {/* {showGoalList && (
              <ListGoalSelected
                goalFinalSelected={goalFinalSelected}
                setGoalFinalSelected={setGoalFinalSelected}
                goalSelected={goalSelected}
                setGoalSelected={setGoalSelected}
                setShowGoalList={setShowGoalList}
              />
            )} */}
            {/* end هدف کلان تاثیر پذیر */}
            {/* start ذینفع تاثیرپذیر */}
            <SingleInput lable={"ذینفعان تاثیرپذیر"}>
              <button
                onClick={() => setShowEffectList(true)}
                className="w-full p-1.5 rounded-md bg-caspian-gray2/30 hover:bg-caspian-gray2/40 duration-300"
              >
                {effectFinalSelected?.length > 0 ? (
                  <span className="text-xs">
                    {effectFinalSelected?.length} ذینفع تاثیرپذیر انتخاب شده است
                  </span>
                ) : (
                  <span className="text-xs">انتخاب ذینفع تاثیرپذیر</span>
                )}
              </button>
            </SingleInput>
            {/* {showEffectList && (
              <ListEffectSelected
                effectFinalSelected={effectFinalSelected}
                setEffectFinalSelected={setEffectFinalSelected}
                effectSelected={effectSelected}
                setEffectSelected={setEffectSelected}
                setShowEffectList={setShowEffectList}
              />
            )} */}
            {showEffectList && (
              <ListSelected
                finalSelected={effectFinalSelected}
                setFinalSelected={setEffectFinalSelected}
                selected={effectSelected}
                setSelected={setEffectSelected}
                setShowListSelected={setShowEffectList}
                title="لیست ذینفع ها"
                url={`/api/v1/stackholder/stackholder/`}
              />
            )}
            {/* end ذینفع تاثیرپذیر */}
          </div>
        </div>
        <div className="flex items-center justify-between gap-2 w-full p-2 bg-caspian-surface">
          {/* start علت وقوع خطا */}
          <SingleInput lable={"علل وقوع خطا"}>
            <textarea
              ref={causeRef}
              rows={10}
              // value={dataCopy.cause || cause}
              defaultValue={dataCopy?.cause || cause}
              onChange={(e) => setCause(e.target.value)}
              className="resize-none text-xs p-2 rounded-md bg-caspian-transparent outline-none border border-caspian-gray2 w-full"
              placeholder="علل وقوع خطا را وارد کنید"
            />
          </SingleInput>
          {/* end علت وقوع خطا */}
          {/* start کنترل های موجود */}
          <SingleInput lable={"کنترل‌های موجود"}>
            <textarea
              ref={controlRef}
              rows={10}
              // value={dataCopy.control || control}
              defaultValue={dataCopy?.control || control}
              onChange={(e) => setControl(e.target.value)}
              className="resize-none text-xs p-2 rounded-md bg-caspian-transparent outline-none border border-caspian-gray2 w-full"
              placeholder="کنترل‌های موجود را وارد کنید"
            />
          </SingleInput>
          {/* end کنترل های موجود */}
          {/* start روش های شناسایی بروز خطا */}
          {/* <SingleInput lable={"روش های شناسایی بروز خطا"}>
          <textarea
            rows={6}
            onChange={(e) => setDetective(e.target.value)}
            className="resize-none text-xs p-2 rounded-md bg-caspian-transparent outline-none border border-caspian-gray2 w-full"
            placeholder="روش های شناسایی بروز خطا را وارد کنید"
          />
        </SingleInput> */}
          {/* end روش های شناسایی بروز خطا */}
        </div>
        {/* start دکمه ها */}
        <div className="flex items-center gap-2 p-2 bg-caspian-surface rounded-b-md">
          <button
            onClick={submitHandler}
            className="bg-caspian-secondary-500 text-caspian-surface text-xs py-1.5 px-6 rounded-md"
          >
            ثبت
          </button>
          <button
            onClick={clearHandler}
            className="text-xs text-[#FF5B5B] mr-4"
          >
            انصراف
          </button>
        </div>
        {/* end دکمه ها */}
      </div>
      <div className="flex flex-col gap-2 w-1/2 shadow-[0_0_5px_#00000050] rounded-md pb-2">
        <div className="rounded-t-md bg-[#94BED4] text-xs flex items-center justify-center p-2">
          <span>لیست ریسک های شناسایی شده</span>
        </div>
        {riskForm?.length < 1 ? (
          <div className="flex items-center justify-center py-4">
            <span className="text-xs text-caspian-gray4">
              ریسک ثبت نشده است!
            </span>
          </div>
        ) : (
          riskForm?.map((r, i) => (
            <ActivityRiskDetailsMain
              dataCopy={dataCopy}
              setDataCopy={setDataCopy}
              isDoing={isDoing}
              risk={r}
              key={i}
              setRefreshRiskActivity={setRefreshRiskActivity}
            />
          ))
        )}
        <button
          onClick={() => setShowCompleteHandler(true)}
          className="bg-caspian-secondary-500 text-caspian-surface text-xs py-2 px-6 rounded-md w-[90%] mx-auto"
        >
          ثبت نهایی
        </button>
      </div>
      {showCompleteHandler && (
        <Modal>
          <div className="w-[300px] p-2 rounded-md bg-caspian-surface shadow-[0_0_5px_#00000050]">
            <div className="flex flex-col gap-4">
              {riskForm?.length < 1 ? (
                <div className="flex flex-col gap-2">
                  <div className="flex items-center gap-2">
                    <span className="text-xs text-caspian-error2 font-bold">
                      اخطار:
                    </span>
                    <p className="text-xs text-caspian-error2">
                      شما ریسک ثبت نکرده اید!
                    </p>
                  </div>
                  <p className="text-xs text-caspian-gray4">
                    آیا با ثبت نهایی این فعالیت موافق هستید؟
                  </p>
                </div>
              ) : (
                <p className="text-xs text-caspian-gray4">
                  آیا با تایید نهایی فعالیت های ثبت شده موافق هستید؟
                </p>
              )}
              <div className="flex items-center gap-2">
                <button
                  onClick={submitCompleteHandler}
                  className="bg-caspian-secondary-500 text-caspian-surface text-xs py-1.5 px-6 rounded-md"
                >
                  بله، ثبت شود
                </button>
                <button
                  onClick={() => setShowCompleteHandler(false)}
                  className="text-xs text-[#FF5B5B] mr-4"
                >
                  خیر
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default MainCreateForm;
